import type { FetchReverseEltsQuery } from '@salescore/client-api'
import { SqlSyntaxHighlighter } from '@salescore/frontend-common'
import { Descriptions, type ModalFuncProps } from 'antd'
import { t } from 'i18next'
import type React from 'react'
import { z } from 'zod'

import { JobQueueDetail } from './JobQueueDetail'

interface ReverseEltJobModalProperties {
  jobId: string
  reverseElts: FetchReverseEltsQuery['reverseElts']
}

export function buildReverseEltJobModal(properties: ReverseEltJobModalProperties): ModalFuncProps {
  return {
    width: '90%',
    style: { top: '3%' },
    okButtonProps: { style: { display: 'none' } },
    cancelText: t(`閉じる`),
    maskClosable: true,
    content: <Body {...properties} />,
  }
}

function Body({ jobId, reverseElts }: ReverseEltJobModalProperties): React.ReactElement {
  return (
    <JobQueueDetail
      queueId={jobId}
      detail={(queue) => {
        const data = z.object({ reverseEltId: z.string() }).safeParse(queue.jobArg)
        if (!data.success) {
          return <></>
        }
        const reverseElt = reverseElts.find((x) => x.id === data.data.reverseEltId)
        if (reverseElt === undefined) {
          return <></>
        }
        return (
          <Descriptions title={t(`設定詳細`)} column={3}>
            <Descriptions.Item label={t(`設定名`)}>{reverseElt.name}</Descriptions.Item>
            <Descriptions.Item span={1} label={t(`データベース`)}>
              {reverseElt.destination.provider}
            </Descriptions.Item>
            <Descriptions.Item span={1} label={t(`更新先`)}>
              {reverseElt.eltModel.model.label}({reverseElt.eltModel.name})
            </Descriptions.Item>
            <Descriptions.Item span={4} label="SQL">
              <SqlSyntaxHighlighter sql={reverseElt.sql} language="sql" />
            </Descriptions.Item>
          </Descriptions>
        )
      }}
    />
  )
}
