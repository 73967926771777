import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { isSome } from '@salescore/buff-common'
import type { ViewUIChildRecordsForm } from '@salescore/core'
import { Avatar, Badge, Button, Col, Collapse, Empty, Row, Space } from 'antd'
import { t } from 'i18next'
import { useState } from 'react'

import { VIEW_NEW_RECORD_PREFIX } from '../../domain/constant'
import type { RecordNodePath } from '../../recoil/records/mutations/upsertViewRecordByPath'
import { useUpsertViewRecordsByPathMutation } from '../../recoil/records/mutations/upsertViewRecordsByPathMutation'
import { useViewUIRecordNodeContext, ViewUIRecordNodeContext, ViewUIRecordNodeContextClass } from './contexts'
import { ModelIcon } from './ModelIcon'
import { ViewUIComponentC } from './ViewUIComponent'

export function ViewUIChildRecordsFormC({ component }: { component: ViewUIChildRecordsForm }) {
  const { getValue, onChange, recordNodePath, recordNode } = useViewUIRecordNodeContext()
  const childTableNode = recordNode?.children.find((x) => x.nodeName === component.tableNodeName)
  const upsertViewRecordsByPath = useUpsertViewRecordsByPathMutation()
  const [collapsedKeys, setCollapsedKeys] = useState<string[]>([])

  if (childTableNode === undefined) {
    // TODO
    return <></>
  }

  return (
    <Row className="m-0">
      <Collapse
        defaultActiveKey={[]}
        className="mb-4 w-full"
        onChange={(keys) => {
          setCollapsedKeys(keys)
        }}
      >
        <Collapse.Panel
          key="items"
          className="p-0"
          // collapsible={childTableNode.children.isBlank() ? 'disabled' : undefined}
          header={
            <Row justify="space-between" className="w-full">
              <Space className="font-bold">
                <Avatar
                  style={{ backgroundColor: component.color ?? '#888' }}
                  size={28}
                  shape={'square'}
                  icon={<ModelIcon icon={component.icon} fontSize={16} />}
                />
                {component.label}
                {component.relation === 'one_to_many' && (
                  <>
                    <Badge count={childTableNode.children.length} style={{ backgroundColor: '#aaa' }} />
                  </>
                )}
              </Space>
              <Space>
                {component.relation === 'one_to_many' && collapsedKeys.isPresent() && (
                  <>
                    {/* TODO: expandしているときだけ表示 */}
                    <Button
                      onClick={(e) => {
                        upsertViewRecordsByPath({
                          newRecordChanges: [
                            {
                              recordNodePath,
                              tableNodeName: childTableNode.nodeName,
                              insertIndex: 0,
                            },
                          ],
                        })
                        e.preventDefault()
                        e.stopPropagation()
                      }}
                      // type="text"
                      icon={<PlusOutlined />}
                      className="text-blue-600"
                    >
                      {t(`追加`)}
                    </Button>
                  </>
                )}
              </Space>
            </Row>
          }
        >
          <div>
            {childTableNode.children.isBlank() && <Empty />}
            {childTableNode.children.map((childRecordNode, index) => {
              const isNewRecord = childRecordNode.id?.startsWith(VIEW_NEW_RECORD_PREFIX) ?? false // TODO: どこかのメタ属性で持つか？
              const nameValue = isNewRecord
                ? t(`新しいレコード`)
                : // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
                  (childRecordNode.attributes[component.nameFieldName ?? ''] as string | undefined) // TODO
              // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
              const urlValue = childRecordNode.attributes[component.recordUrlFieldName ?? ''] as string | undefined // TODO
              const childRecordNodePath: RecordNodePath = [
                ...recordNodePath,
                {
                  tableNodeName: childTableNode.nodeName,
                  recordNodeId: childRecordNode.id!, // TODO
                },
              ]
              return (
                <div
                  key={childRecordNode.id}
                  className="mb-4 p-0 px-2 pb-2"
                  style={{
                    borderBottom: `1px solid #DADADA`,
                  }}
                >
                  {/* シートのカスタム列を定義したときに、name要素と削除を表示する必要がなかったので、以下ロジックをいれている。
                    現状one_to_oneになるのはシートのカスタム列だけなので、雑にone_to_oneを指定してしまっているが、他でもone_to_oneを使うことになった際に、この条件で正しいかはやや微妙かも
                    one_to_oneがが使われるユースケースを正確に全部把握できていないが、一旦この形とする（これで悪くはないはず）。
                   */}
                  {component.relation !== 'one_to_one' && (
                    <Row className="mb-2" justify="space-between">
                      <Col>
                        <Space
                          className="text-md"
                          style={{
                            fontSize: 16,
                          }}
                        >
                          <Avatar
                            style={{ backgroundColor: component.color ?? '#888' }}
                            size={24}
                            shape={'square'}
                            icon={<ModelIcon icon={component.icon} fontSize={14} />}
                          />
                          {isSome(urlValue) ? (
                            <a target="_blank" href={urlValue} rel="noreferrer">
                              {nameValue ?? childRecordNode.id ?? ''}
                            </a>
                          ) : (
                            <span>{nameValue ?? childRecordNode.id ?? ''}</span>
                          )}
                        </Space>
                      </Col>
                      <Col>
                        <Space>
                          <Button
                            type="text"
                            danger
                            icon={<DeleteOutlined />}
                            onClick={(e) => {
                              upsertViewRecordsByPath({
                                deleteRecordChanges: [
                                  {
                                    recordNodePath: childRecordNodePath,
                                    // isDeleteAllChildren: true // TODO: 全て削除のUI
                                  },
                                ],
                              })
                              e.preventDefault()
                              e.stopPropagation()
                            }}
                          >
                            {t(`削除`)}
                          </Button>
                        </Space>
                      </Col>
                    </Row>
                  )}
                  <ViewUIRecordNodeContext.Provider
                    value={
                      new ViewUIRecordNodeContextClass({
                        recordNode: childRecordNode,
                        recordNodePath: childRecordNodePath,
                        upsertViewRecordsByPath,
                      })
                    }
                  >
                    {component.children.map((child) => (
                      <ViewUIComponentC component={child} />
                    ))}
                  </ViewUIRecordNodeContext.Provider>
                </div>
              )
            })}
            {/* <div>
              <Button icon={<PlusOutlined />}>追加</Button>
            </div> */}
          </div>
        </Collapse.Panel>
      </Collapse>
    </Row>
  )
}
