import { SourceProviderEnum } from '@salescore/client-api'
import { Avatar } from 'antd'

export const SourceProviderIcon = ({ provider }: { provider: SourceProviderEnum | undefined }) => {
  if (provider === undefined) {
    return <></>
  }
  switch (provider) {
    case SourceProviderEnum.Salescore: {
      return <Avatar size="small" src="/images/provider_icon/salescore.png" />
    }
    case SourceProviderEnum.Salesforce: {
      return <Avatar size="small" src="/images/provider_icon/salesforce.png" />
    }
    case SourceProviderEnum.Hubspot: {
      return <Avatar size="small" src="/images/provider_icon/hubspot.png" />
    }
    case SourceProviderEnum.Zoho: {
      return <Avatar size="small" src="/images/provider_icon/zoho.png" />
    }
    case SourceProviderEnum.Kintone: {
      return <Avatar size="small" src="/images/provider_icon/kintone.png" />
    }
    case SourceProviderEnum.NextSfa: {
      return <Avatar size="small" src="/images/provider_icon/next_sfa.png" />
    }
    case SourceProviderEnum.GoogleSheets: {
      return <Avatar size="small" src="/images/provider_icon/google_sheets.png" />
    }
    case SourceProviderEnum.Comdesk: {
      return <Avatar size="small" src="/images/provider_icon/comdesk.png" />
    }
    case SourceProviderEnum.Senses: {
      return <Avatar size="small" src="/images/provider_icon/senses.png" />
    }
    case SourceProviderEnum.MicrosoftDynamics: {
      return <Avatar size="small" src="/images/provider_icon/microsoft_dynamics.png" />
    }
    case SourceProviderEnum.Zoom: {
      return <Avatar size="small" src="/images/provider_icon/zoom.png" />
    }
  }
}

export function getProviderFromModelName(modelName: string): SourceProviderEnum | undefined {
  // プロバイダを文字数の長い順にソートすることで、
  // google_sheets_xxというモデルのプロバイダがgoogleと判定される、というような事態を回避する
  const providers = Object.values(SourceProviderEnum).sort((a, b) => b.length - a.length)
  return providers.find((provider) => modelName.startsWith(provider))
}
