import { isNull, isSome } from '@salescore/buff-common'
import {
  CORE_CONSTANT,
  createDimensionFieldName,
  getRecordNodeParents,
  type ViewKpiAppearance,
  type ViewQueryRecordNodeWithParent,
} from '@salescore/core'
import { parseJsonIfValid } from '@salescore/frontend-common'

import type { KpiUserAppearance } from '../../../../../recoil/navigation/atoms'
import type { ProgressType } from '../../../../../recoil/navigation/selector/calculateAverageProgressService'
import type { RSheetColumn, RSheetContext, RSheetRecordNode } from '../../../../types'

export interface KpiInfo {
  actual: number | undefined // 実績
  actualPerElapsed: number | undefined // 実績/経過日数
  goal: number | undefined // 目標
  ratio: number | undefined // 実績/目標
  actualIdeal: number | undefined // 理想の実績(=目標*進捗率)
  diffActualIdeal: number | undefined // 実績-理想の実績
  kpi: ViewKpiAppearance | undefined
  progressRate: number | undefined // 進捗率(これがここに入っているのが分かりづらい)
  elapsedDays: number // 経過日(これがここに入っているのが分かりづらい)
  businessDays: number // 営業日(これがここに入っているのが分かりづらい)
}

// eslint-disable-next-line @typescript-eslint/max-params
export function extractKpiInfo(
  column: RSheetColumn<RSheetRecordNode>,
  recordNode: ViewQueryRecordNodeWithParent,
  progress: ProgressType,
  kpiUserAppearance: KpiUserAppearance,
  context: RSheetContext,
): KpiInfo {
  const days = getElapsedDays(column, recordNode, progress)
  const progressRate = calculateProgress(days, progress)

  const kpi = extractKpi(column, context, recordNode)
  const { actual, goal } = extractActualAndGoal(column.field?.name ?? '', recordNode)
  const ratio = calculateRatio(actual, goal)
  const actualIdeal = calculateActualIdeal(progressRate, actual, goal, kpi)
  const diffActualIdeal = isSome(actualIdeal) && isSome(actual) ? Math.round(actual - actualIdeal) : undefined // ここがroundでいいのかどうか、やや怪しい
  const actualPerElapsed = perElapsedDays(days, actual)

  return {
    actual,
    actualPerElapsed,
    goal,
    ratio,
    actualIdeal,
    diffActualIdeal,
    kpi,
    progressRate,
    elapsedDays: days.elapsedDays,
    businessDays: days.businessDays,
  }
}

// eslint-disable-next-line complexity
function getElapsedDays(
  column: RSheetColumn<RSheetRecordNode>,
  recordNode: ViewQueryRecordNodeWithParent,
  progress: ProgressType,
): { elapsedDays: number; businessDays: number } {
  if (!progress.hasTimeRelatedDimension) {
    // 分析軸に日付系の軸がない場合
    return progress
  }

  // 分析軸に日付系の軸がある場合は、行（列）ごとに営業日・経過日を求めなければならない
  // calculateAverageProgressServiceのコメント参照
  const parents = getRecordNodeParents(recordNode).reverse()
  for (const dimension of progress.kpiDateDimensions) {
    if (dimension.type === 'row') {
      const parent = parents[dimension.index]
      const kpiDate = parent?.attributes[`dimension${dimension.index + 1}`] // TODO
      if (kpiDate === undefined || typeof kpiDate !== 'string') {
        continue
      }

      const elapsedDays = progress.daysByTimeframe[dimension.timeframe]?.elapsedDays[kpiDate]
      const businessDays = progress.daysByTimeframe[dimension.timeframe]?.businessDays[kpiDate]
      if (elapsedDays !== undefined && businessDays !== undefined) {
        return {
          elapsedDays,
          businessDays,
        }
      }
    }

    if (dimension.type === 'column') {
      const fieldName = column.field?.name ?? ''
      const columnValues = parseJsonIfValid(fieldName) // TODO: ここがJSONであることが暗黙的
      if (!Array.isArray(columnValues)) {
        continue
      }
      const kpiDate = columnValues[dimension.index] as unknown
      if (kpiDate === undefined || typeof kpiDate !== 'string') {
        continue
      }
      const elapsedDays = progress.daysByTimeframe[dimension.timeframe]?.elapsedDays[kpiDate]
      const businessDays = progress.daysByTimeframe[dimension.timeframe]?.businessDays[kpiDate]
      if (businessDays !== undefined) {
        return {
          elapsedDays: elapsedDays ?? 0, // elapsedDaysが存在しないことあるが、期間内であれば常にbusinessDaysは存在するはず。存在しない時はkpiDateが_TOTAL_のときだけ
          businessDays,
        }
      }
    }
  }

  // 分析軸に日付系の軸があるが、このセルは「小計」などで時刻に依存しない場合
  return progress
}

function calculateProgress(
  { elapsedDays, businessDays }: { elapsedDays: number; businessDays: number },
  progress: ProgressType,
): ProgressType['progressRate'] | undefined {
  if (!progress.hasTimeRelatedDimension) {
    // 時刻系の軸がなければ、そのまま返すだけ
    return progress.progressRate
  }

  if (businessDays === 0 || elapsedDays === 0) {
    // 未来の日程のとき、進捗率をだして緑赤表示したくないのでundefinedにする。また0で割ると死ぬのでこれもundefinedとして扱う。
    return undefined
  }
  return elapsedDays / businessDays
}

function perElapsedDays(
  { elapsedDays, businessDays }: { elapsedDays: number; businessDays: number },
  actual: number | undefined,
) {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (actual === undefined || elapsedDays === undefined || elapsedDays === 0) {
    return actual
  }

  return actual / elapsedDays
}

function extractKpi(
  column: RSheetColumn<RSheetRecordNode>,
  context: RSheetContext,
  recordNode: ViewQueryRecordNodeWithParent,
) {
  // kpiビューのときは、kpiがcontextに入っている
  if (context.kpi !== undefined) {
    return context.kpi
  }

  // kpiPivotビューの時
  const kpis = context.kpis ?? []
  const { pivot } = context
  if (pivot === undefined) {
    return
  }
  const kpiColumnIndex = pivot.columns.findIndex((x) => x.key === CORE_CONSTANT.KPI_PIVOT_KPI_DIMENSION.key)
  // 列にKPIがあるとき
  if (kpiColumnIndex !== -1) {
    const fieldName = column.field?.name ?? ''
    const columnValues = parseJsonIfValid(fieldName) // TODO: ここがJSONであることが暗黙的
    if (!Array.isArray(columnValues)) {
      return
    }
    const kpiId = columnValues[kpiColumnIndex] as unknown
    const kpi = kpis.find((x) => x.id === kpiId)
    return kpi
  }

  const kpiRowIndex = pivot.rows.findIndex((x) => x.key === CORE_CONSTANT.KPI_PIVOT_KPI_DIMENSION.key)
  if (kpiRowIndex === -1) {
    // 行にも列にもKPIがない場合
    return
  }
  const parentDepth = pivot.rows.length - kpiRowIndex
  const parent = getParent(recordNode, parentDepth)
  const kpiId = parent.attributes[createDimensionFieldName(kpiRowIndex + 1)]
  const kpi = kpis.find((x) => x.id === kpiId)
  return kpi
}

function getParent(recordNode: ViewQueryRecordNodeWithParent, depth: number): ViewQueryRecordNodeWithParent {
  if (depth === 0 || recordNode.parent === undefined) {
    return recordNode
  }
  return getParent(recordNode.parent, depth - 1)
}

export function extractActualAndGoal(fieldName: string, recordNode: RSheetRecordNode) {
  // actual
  const value = recordNode.attributes[fieldName]
  const actual = forceNumber(value) // 実績はなくても必ず0と表示する仕様と迷ったが、やはり-と表示したいという声が大きかった

  // goal
  const goalFieldName = fieldName.replace(`"actual"`, `"goal"`)
  const goalValue = recordNode.attributes[goalFieldName]
  const goal = forceNumber(goalValue)

  // 実績の値がないとき、通常は-という表示にするが、目標があるときは0と表示する
  if (goal !== undefined && actual === undefined) {
    return {
      actual: 0,
      goal,
    }
  }

  return {
    actual,
    goal,
  }
}

function forceNumber(x: unknown) {
  if (typeof x === 'number') {
    return x
  }

  if (typeof x === 'string') {
    const y = Number(x)
    return Number.isNaN(y) ? undefined : y
  }
}

function calculateRatio(actual?: number, goal?: number) {
  if (isNull(actual)) {
    return
  }
  return goal !== undefined && goal !== 0 ? Math.floor((actual * 100) / goal) : undefined
}

function calculateActualIdeal(progressRate?: number, actual?: number, goal?: number, kpi?: ViewKpiAppearance) {
  if (isNull(actual) || isNull(goal)) {
    return
  }

  // sum以外の時(現状はavgのみ)、理想の実績値は常に目標値のはず
  // optionalな値なので、!==を使わないこと
  if (kpi?.aggregationFunction === 'avg') {
    return goal
  }

  // 目標タイプが定められていればその通りにする
  if (kpi?.achievementStatusTargetValueType === 'goal') {
    return goal
  }
  if (kpi?.achievementStatusTargetValueType === 'zero') {
    return 0
  }

  if (isNull(progressRate)) {
    return
  }

  const actualIdeal = progressRate * goal
  if (actualIdeal >= 1) {
    // 切り上げ（例えば14.2のとき、15を理想とする）
    return Math.ceil(actualIdeal)
  }
  return Number(actualIdeal.toPrecision(2))
}
