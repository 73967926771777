import { r } from '@salescore/buff-common'
import type { ViewQueryList, ViewQueryNode, ViewQueryRecordNode } from '@salescore/core'
import { logger } from '@salescore/frontend-common'
import type { SetRecoilState } from 'recoil'

import { rowConfigAtomFamily } from '../../../../rsheet/recoil/atoms'
import { generateNewRecordId, getNode } from '../../../../state/nodeUtil'
import type { ViewRecordFieldChange } from '../../../../state/useViewRecordsState/useChangesState'
import { recordAtomFamily } from '../../atoms'
import { getDefaultAttributes } from '../../selectors/querySelector'
import {
  getRecordNodeFromRecordNodePath,
  type RecordChangeByPath,
  type RecordNodePath,
} from '../upsertViewRecordByPath'
import { updateHeight, updateInnerRow } from '../upsertViewRecords'

export interface NewRecordChangeByPath {
  recordNodePath: RecordNodePath
  tableNodeName: string
  insertIndex: number
}

export function addNewChildRecordByPath(
  query: ViewQueryList,
  rootRecordNode: ViewQueryRecordNode,
  newRecordChange: NewRecordChangeByPath,
  set: SetRecoilState,
  // onAfterInsert?: OnAfterInsert,
) {
  const { tree } = query
  const { recordNodePath, tableNodeName, insertIndex } = newRecordChange
  const newRootRecordNode = rootRecordNode
  const targetRecordNode = getRecordNodeFromRecordNodePath(rootRecordNode, recordNodePath)
  const targetTableNode = targetRecordNode?.children.find((x) => x.nodeName === tableNodeName)
  const nodePath = [tree.name, ...recordNodePath.map((x) => x.tableNodeName), tableNodeName]
  const targetQueryNode = getNode(tree, nodePath)
  if (targetTableNode === undefined || targetRecordNode === undefined || targetQueryNode === undefined) {
    logger.debug(
      `[addNewChildRecordByPath] not found.`,
      targetTableNode,
      targetRecordNode,
      targetQueryNode,
      nodePath,
      tree,
    )
    return
  }

  // 新規レコードを生成
  const recordNode = generateNewChildRecord(targetQueryNode, targetRecordNode.id).newRecord

  // 新規レコードを挿入
  targetTableNode.children.splice(insertIndex, 0, recordNode)

  // メタ情報を更新
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (newRootRecordNode !== undefined) {
    // 挿入後なので、ないことはありえないが一応チェック
    // heightを参照渡しで更新
    updateHeight(newRootRecordNode)
    // innerStartIndexを参照渡しで更新
    updateInnerRow(newRootRecordNode)
  }

  // if (onAfterInsert !== undefined) {
  //   onAfterInsert(recordNode) // このrecordNodeは、メタ情報が更新されている
  // }

  // familyを更新
  set(recordAtomFamily({ id: newRootRecordNode.id! }), r(newRootRecordNode).clone().data) // 複製して渡さないとfreezeされてしまい、後続の関数で編集できなくなる
  set(rowConfigAtomFamily({ id: newRootRecordNode.id! }), (x) => ({
    ...x,
    expanded: true,
  }))

  const { defaultValues } = getDefaultAttributes(targetQueryNode, query)
  const recordChangesForDefault = defaultValues.map(
    ({ field, value }): RecordChangeByPath => ({
      recordNodePath: [...recordNodePath, { tableNodeName: targetTableNode.nodeName, recordNodeId: recordNode.id! }],
      id: recordNode.id!,
      fieldName: field.name,
      value,
    }),
  )

  // 変更差分を生成
  return {
    recordNode,
    recordChangesForDefault,
    change: generateNewChildRecordChange(rootRecordNode, targetQueryNode, recordNode, targetRecordNode),
  }
}

function generateNewChildRecord(node: ViewQueryNode, parentRecordId: string | undefined) {
  // changesの生成
  const relationColumn =
    node.read.join?.joinOn.type === 'sql' ? undefined : node.read.join?.joinOn.meta.currentNodePropertyName

  // const defaultAttributes = getDefaultAttributes(node, view) // デフォルト系は別途求める
  // レコードの作成
  const id = generateNewRecordId()
  const newRecord: ViewQueryRecordNode = {
    id,
    attributes: {
      // ...defaultAttributes.recordAttributes,
      // ...attributes,
      ...(relationColumn !== undefined && parentRecordId !== undefined
        ? {
            // relationColumnは厳密にはattributesでないのでrecordNodeに含める必要はないが、changesのみに存在するのが分かりづらいためいれておく。
            // サーバーサイドのレスポンスも同様になっている。
            [`${node.name}_${relationColumn}`]: parentRecordId,
          }
        : {}),
    },
    children: [],
    meta: {
      height: 1,
      innerRowIndexStart: 0, // TODO
      innerRowIndexEnd: 1,
    },
  }
  const changes: ViewRecordFieldChange[] = [
    {
      id,
      streamName: node.write!.streamName,
      fieldChanges: [],
      // recordNameLabel,
      before: {},
      after: {
        [relationColumn!]: parentRecordId,
      },
    },
  ]

  return {
    newRecord,
    changes,
  }
}

function generateNewChildRecordChange(
  rootRecordNode: ViewQueryRecordNode,
  node: ViewQueryNode,
  recordNode: ViewQueryRecordNode,
  parentRecord: ViewQueryRecordNode | undefined,
): ViewRecordFieldChange | undefined {
  const joinOn = node.read.join?.joinOn
  if (joinOn === undefined || joinOn.type === 'sql') {
    return
  }
  const relationColumn = joinOn.meta.currentNodePropertyName
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (node.write?.streamName === undefined || relationColumn === undefined || parentRecord === undefined) {
    return
  }

  return {
    rootNodeId: rootRecordNode.id,
    id: recordNode.id,
    streamName: node.write.streamName,
    fieldChanges: [],
    before: {},
    after: {
      [relationColumn]: parentRecord.id,
    },
  }
}
