import { buildStateHook } from '@salescore/frontend-common'

import { searchKeyAtom, viewAtomFamily } from './atoms'
import {
  useArchivedViewGroupTree,
  useHasChanges,
  useIsSaveConfigButtonActive,
  usePickedViewIds,
  usePrivateViewGroupTree,
  useViewGroupTree,
} from './hooks'
import { useSyncProperties } from './initializer'
import { useClearViewQueryCacheMutation } from './mutation/clearViewQueryCacheMutation'
import { useCreateFavorite, useDeleteFavorite } from './mutation/favoriteMutation'
import { useMoveViewOrViewGroup } from './mutation/moveViewOrViewGroupMutation'
import { useRefetchViewGroups } from './mutation/refetchViewGroups'
import { useRefreshViewGroupTreeMutation } from './mutation/refreshViewGroupTreeMutation'
import { useSheetThreadCommentNotifications } from './mutation/sheetThreadCommentNotificationsMutation'
import { useUpsertViewMutation } from './mutation/upsertViewMutation'
import { useArchiveViewGroup, useCreateViewGroup, useDeleteViewGroup } from './mutation/viewGroupMutation'
import { useArchiveView, useCopyView, useCreateView, useDeleteView, useUpdateView } from './mutation/viewMutation'
import { removePrefix, useFavoriteRelated } from './selector/favoritesSelector'
import { useSearchResultNodes } from './selector/searcedNodesSelector'
import { useViewsRelated } from './selector/viewsRelatedSelector'

export const siderRecoil = {
  removePrefix,
  useArchiveView,
  useArchiveViewGroup,
  useArchivedViewGroupTree,
  useClearViewQueryCacheMutation,
  useCopyView,
  useCreateFavorite,
  useCreateView,
  useCreateViewGroup,
  useDeleteFavorite,
  useDeleteView,
  useDeleteViewGroup,
  useFavoriteRelated,
  useHasChanges,
  useIsSaveConfigButtonActive,
  useMoveViewOrViewGroup,
  usePickedViewIds,
  usePrivateViewGroupTree,
  useRefetchViewGroups,
  useRefreshViewGroupTreeMutation,
  useSearchKey: buildStateHook(searchKeyAtom),
  useSearchResultNodes,
  useSheetThreadCommentNotifications,
  useSyncProperties,
  useUpdateView,
  useUpsertViewMutation,
  useViewGroupTree,
  useViewsRelated,
  viewAtomFamily,
}
