import { useApolloClient } from '@apollo/client'
import { isTruthy } from '@salescore/buff-common'
import { FetchViewQueryResultsDocument } from '@salescore/client-api'
import { message } from 'antd'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import { aggregationQueriesSelecttor } from '../../view/atoms'
import { useViewValue } from '../../view/hooks'
import { aggregationErrorsAtom, errorsAtom, organizationIdAtom, viewAggregationQueryResultsAtom } from '../atoms'
import { useLoadingState } from '../hooks'
import { useMustacheParameterSelector } from '../selectors/mustacheParameterSelector'

export const useRefetchAggregationQueryResult = () => {
  const organizationId = useRecoilValue(organizationIdAtom)
  const mustacheParameter = useMustacheParameterSelector()
  const queries = useRecoilValue(aggregationQueriesSelecttor)
  const client = useApolloClient()
  const loading = useLoadingState()
  const view = useViewValue()

  const setErrors = useSetRecoilState(errorsAtom)
  const setAggregationErrorsAtom = useSetRecoilState(aggregationErrorsAtom)
  const setViewAggregationQueryResults = useSetRecoilState(viewAggregationQueryResultsAtom)

  const fetchViewQueryResults = async ({ useCache }: { useCache?: boolean }) => {
    const { data: result } = await client.query({
      query: FetchViewQueryResultsDocument,
      variables: {
        organizationId,
        viewQueries: queries,
        mustacheParameter,
        viewId: view.id,
      },
      fetchPolicy: isTruthy(useCache) ? 'cache-first' : 'network-only',
    })

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const viewQueryResults = (result.viewQueryResults ?? []).compact() // TODO: compactは不要なはずだが、なぜかnullableになっている
    const errors = viewQueryResults.map((x) => x.error).compact()
    setAggregationErrorsAtom(errors)
    if (errors.isPresent()) {
      for (const error of errors) {
        void message.error(error)
      }
    }
    return viewQueryResults
  }

  const fetch = async (useCache?: boolean) => {
    if (queries.isBlank()) {
      return
    }

    try {
      loading.setTrue()
      const viewQueryResults = await fetchViewQueryResults({ useCache })
      setViewAggregationQueryResults(viewQueryResults)
      loading.setFalse()
    } catch (error) {
      if (error instanceof Error) {
        setErrors([error.message])
        setAggregationErrorsAtom([error.message])
      }
    } finally {
      loading.setFalse()
    }
  }

  return {
    async refetch(useCache?: boolean) {
      // TODO: 複数ページになっているとき、全ページ分をrefetchする必要がある
      await fetch(useCache)
    },
  }
}
