import { VERSION } from '@salescore/buff-common'
import { UserRoleEnumV2 } from '@salescore/client-api'
import { openIntercom, openMiitel, Posthog, Tracker } from '@salescore/client-base'
import {
  ErrorProvider,
  handleMe,
  setCurrentOrganizationIdToLocalStorage,
  SuspenseWithLoading,
} from '@salescore/client-common'
import { type Me, recoil, RecoilRoot } from '@salescore/client-recoil'
import { logger, LoggerEvents, RecoilDebugObserver } from '@salescore/frontend-common'
import { App } from 'antd'
import dayjs from 'dayjs'
import type { AppProps } from 'next/app'
import platform from 'platform'
import { type ReactNode, useEffect } from 'react'

import { AuthenticatedApolloProvider } from '../../providers/AuthenticatedApolloProvider'
import { I18nProvider } from '../../providers/I18nProvider'
import { DefaultHead } from './DefaultHead'
import { DefaultScript } from './DefaultScript'

type BodyArgument = (properties: AppProps & { me: Me }) => JSX.Element

const Body = ({ Component, router, pageProps, me, body }: AppProps & { me: Me; body: BodyArgument }) => {
  const { myUser, myIdentity, organization } = me

  const posthogProperty = {
    // FIXME: template文字列使っていて空文字にはならないのでUnknownになることはない
    // eslint-disable-next-line no-constant-binary-expression,@typescript-eslint/strict-boolean-expressions
    name: `${myUser.name} / ${myUser.organization.name}` || 'Unknown',
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    email: myUser.identity.email || 'unknown@buffup.jp',
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    role: myUser.role || 'Unknown',
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions,@typescript-eslint/no-unnecessary-condition
    plan: myUser.plan || 'Unknown',
    // description: myUser.team?.name ?? '',
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    organizationId: myUser.organization.id || 'Unknown',
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    organizationName: myUser.organization.name || 'Unknown',
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    userId: myUser.id || 'Unknown',
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    userName: myUser.name || 'Unknown',
    signUpDate: myUser.createdAt,
    // posthog.ts側で不要なidentityやpeople.setが走ってトラッキング回数が増えるのを防ぐため、キャッシュの機構をいれているが、
    // posthogの挙動が怪しく、property全てが記録されないような挙動を見せている。
    // 何度かやり直すとこれが防げるようなので、とりあえず日毎に1回やり直すため、以下のpropertyを設定しておく
    propertyConfiguredAt: dayjs().format(`YYYY-MM-DD`),
    platform: platform.toString(),
  }
  const posthogProperties = [
    { name: posthogProperty.name },
    { email: posthogProperty.email },
    { role: posthogProperty.role },
    { plan: posthogProperty.plan },
    // { description: posthogproperty.meta.label },
    { organizationId: posthogProperty.organizationId },
    { organizationName: posthogProperty.organizationName },
    { userId: posthogProperty.userId },
    { userName: posthogProperty.userName },
    { signUpDate: posthogProperty.signUpDate },
    { propertyConfiguredAt: posthogProperty.propertyConfiguredAt },
    { platform: posthogProperty.platform },
  ]

  if (myUser.role !== UserRoleEnumV2.Admin) {
    Posthog.identifyOnce(myUser.id, posthogProperties)
    Posthog.setPeopleOnce(myUser.id, posthogProperties) // identifyOnceでもpropertyの設定はできるが、一度設定した後に上書きができないので、プロパティが変わった際はここで上書きする
    Posthog.trackAccess(router.pathname, posthogProperty)
  }

  useEffect(() => {
    if (typeof window === 'undefined') {
      return
    }
    // eslint-disable-next-line no-console
    console.log(`SALESCOREへようこそ！お使いのバージョンは${VERSION}です`)
    logger.info(LoggerEvents.VISIT, `/ ${posthogProperty.name}`, posthogProperty)
  }, [])

  useEffect(() => {
    Tracker.shared().setUser(
      { id: myUser.organization.id, name: myUser.organization.name },
      { id: myUser.id, name: myUser.name },
    )

    openMiitel({
      companyId: organization.setting.miitelCompanyId,
      accessKey: organization.setting.miitelAccessKey,
    })
    openIntercom({
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      name: `${myUser.name ?? ''} / ${myUser.organization.name}`,
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      email: myUser.identity.email ?? 'unknown@buffup.jp',
      user: myUser,
    })
  }, [myUser.id])

  // 以下、effect内で行わないと、setUserの前にtrackが走ったりするので注意
  useEffect(() => {
    Tracker.shared().track({ category: 'visit', value: router.pathname })
  }, [router.pathname])

  return (
    <RecoilRoot
      initializeState={recoil.global.initializeState({
        me,
      })}
    >
      <RecoilDebugObserver namespace="root" />
      <ErrorProvider router={router} myUser={myUser} myIdentity={myIdentity}>
        <DefaultHead organizationName={myUser.organization.name} />
        <App component={false}>
          <SuspenseWithLoading>
            {body({
              Component,
              router,
              pageProps,
              me,
            })}
          </SuspenseWithLoading>
          {/* 動的なクラス指定を行う際、purge cssが認識できず勝手にパージされることがある？ため、最低限のクラス指定をするためのdiv要素 */}
          <div className="text-green-600" />
          <div className="text-red-600" />
          <div className="text-gray-700" />
          <div className="text-blue-800" />
        </App>
        <DefaultScript me={me} />
      </ErrorProvider>
    </RecoilRoot>
  )
}

const DefaultLayoutContent = (properties: AppProps & { body: BodyArgument }): ReactNode =>
  handleMe((me) => {
    setCurrentOrganizationIdToLocalStorage(me.myUser.organization.id)

    return <Body me={me} {...properties}></Body>
  })

export const MemberLayoutBase = (properties: AppProps & { body: BodyArgument }) => (
  <AuthenticatedApolloProvider>
    <SuspenseWithLoading>
      <I18nProvider>
        <DefaultLayoutContent {...properties} />
      </I18nProvider>
    </SuspenseWithLoading>
  </AuthenticatedApolloProvider>
)
