import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Form, Input, Popover, Space } from 'antd'
import { t } from 'i18next'
import dynamic from 'next/dynamic'
import { useState } from 'react'

const Picker = dynamic(async () => await import('emoji-picker-react'), { ssr: false })

function EmojiPicker({ onSelected }: { onSelected: (emoji: string) => void }) {
  return (
    <div>
      <Picker
        onEmojiClick={(emoji) => {
          onSelected(emoji.emoji) // TODO
        }}
      />
    </div>
  )
}

export function EmojiPickerFormItem({
  emoji,
  onSelected,
  disabled,
}: {
  emoji: string | null
  onSelected: (emoji: string | null) => void
  disabled?: boolean
}) {
  const [visible, setVisible] = useState(false)
  return (
    <Form.Item label={t(`アイコン`)}>
      <Popover
        content={
          <EmojiPicker
            onSelected={(emoji) => {
              onSelected(emoji)
              setVisible(false)
            }}
          />
        }
        visible={visible}
        trigger="click"
        onVisibleChange={(v) => {
          if (disabled === true) {
            return
          }
          setVisible(v)
        }}
      >
        <Input
          style={{
            width: 44,
          }}
          value={emoji ?? ''}
          // allowClear
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
          readOnly
          // options={[{
          //   key: emoji,
          //   value: '🏢'
          // }]}
        />
      </Popover>
      {disabled !== true && (
        <Space size={'small'} className="ml-1">
          <Button
            size="small"
            type="text"
            className="text-blue-600"
            icon={<EditOutlined />}
            onClick={() => {
              setVisible(true)
            }}
          >
            {t(`選択`)}
          </Button>
          <Button
            size="small"
            type="text"
            className="text-blue-600"
            icon={<DeleteOutlined />}
            onClick={() => {
              onSelected(null)
            }}
          />
        </Space>
      )}
    </Form.Item>
  )
}
