import {
  DownCircleOutlined,
  FilterOutlined,
  HighlightOutlined,
  LinkOutlined,
  LockOutlined,
  MailOutlined,
  PhoneOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
  StarOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons'
import { isPresent } from '@salescore/buff-common'
import type { PropertyTypeEnum } from '@salescore/client-api'
import { HUB_PROPERTY_TYPE_ICONS, KpiIcon } from '@salescore/client-common'
import { Space, Tooltip } from 'antd'
import { useState } from 'react'
import { useRecoilState } from 'recoil'

import { openColumnContextMenuAtom } from '../../recoil/atoms'
import { useContextValue } from '../../recoil/models/propModels'
import { useResizeColumnMutation } from '../../recoil/mutations/resizeColumnMutation'
import { useColumnBlocksRelatedSelector } from '../../recoil/selectors/columnBlockRelatedSelector'
import type { RSheetColumn, RSheetRecordNode } from '../../types'
import { RSheetsStyle } from '../../util/RSheetsStyle'
import { CELL_WIDTH } from '../body/cell/RsheetsCell'
import { DraggableBar, MINIMUM_WIDTH } from './DraggableBar'
import { DraggableHeaderColumn } from './DraggableHeaderColumn'
import { RSheetsHeaderContextMenu } from './HeaderContextMenu'
import { getHeaderColor } from './RSheetsNodeBlockHeader'

export function RSheetsHeaderCell({
  column,
  allColumnIndex,
  allColumns,
}: {
  column: RSheetColumn<RSheetRecordNode>
  allColumnIndex: number
  allColumns: Array<RSheetColumn<RSheetRecordNode>>
}) {
  const resizeColumnMutation = useResizeColumnMutation()
  const blockRelated = useColumnBlocksRelatedSelector()
  const width = column.width ?? CELL_WIDTH
  const height = blockRelated.headerCellHeight
  const [openColumnContextMenu, setOpenColumnContextMenu] = useRecoilState(openColumnContextMenuAtom)
  const [headerFocused, setHeaderFocused] = useState(false)
  const context = useContextValue()

  const contextMenuVisibility = openColumnContextMenu?.column.key === column.key

  const setContextMenuVisibility = (visibility: boolean) => {
    if (visibility) {
      if (openColumnContextMenu?.column.key === column.key) {
        // すでに開いている場合は何もしない
        return
      }
      setOpenColumnContextMenu({
        column,
        addColumnDropdownVisible: false,
      })
    } else {
      if (openColumnContextMenu?.column.key !== column.key) {
        // 他のカラムのコンテキストメニューが開いていない場合は何もしない
        return
      }
      setOpenColumnContextMenu(undefined)
    }
  }

  // Tooltipの領域がコンテキストメニューまで含んでおり、コンテキストメニューを開いた際にフォーカスが残ったままになってしまうこと防ぐ
  const tooltipVisibility = isPresent(column.helpText) && headerFocused && !contextMenuVisibility

  return (
    <Tooltip title={column.helpText} placement="right" open={tooltipVisibility}>
      <div
        onMouseEnter={() => {
          setHeaderFocused(true)
        }}
        onMouseLeave={() => {
          setHeaderFocused(false)
        }}
        onDragOver={(e) => {
          e.preventDefault() // onDropを発火させるのに必要
        }}
        onDrop={(e) => {
          // onDropで特にロジックは書かないが、onDropの定義はドロップアニメーション(要素の元の位置に戻るアニメーション)をdisableするために必要
          e.preventDefault()
        }}
        style={{
          width,
          position: 'relative',
          transition: RSheetsStyle.cellTransition,
        }}
      >
        <DraggableHeaderColumn
          width={width}
          height={height}
          widths={allColumns.map((x) => x.width ?? CELL_WIDTH)}
          column={column}
          columnIndex={allColumnIndex}
          onClick={() => {
            setContextMenuVisibility(true)
            setHeaderFocused(false)
          }}
          onContextMenu={() => {
            setContextMenuVisibility(true)
            setHeaderFocused(false)
          }}
          popoverContent={
            <div>
              {column.title}
              <br />
              {column.headerPopoverContent}
            </div>
          }
        />
        <div
          className={`cell-body p-1`}
          style={{
            borderRight: `1px ${getHeaderColor(column.color).borderColor} solid`,
            color: contextMenuVisibility
              ? getHeaderColor(column.color).textColorWhite
              : getHeaderColor(column.color).textColor,
            backgroundColor: contextMenuVisibility
              ? getHeaderColor(column.color).bgColorDark
              : getHeaderColor(column.color).bgColor,
            padding: '0px 4px',
            // backgroundColor: '#F6F8F9',
            width: '100%',
            position: 'relative',
            overflow: 'hidden',
            height: height - 2,
            ...(context.headerWithBigIcon === true
              ? {}
              : {
                  display: 'flex',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }),
          }}
        >
          <CellBody column={column} />
        </div>
        <DraggableBar
          width={width}
          height={height}
          setWidth={(width) => {
            resizeColumnMutation({ column, width, allColumnIndex })
          }}
        />
        {contextMenuVisibility && (
          <RSheetsHeaderContextMenu
            column={column}
            hide={() => {
              setContextMenuVisibility(false)
              setHeaderFocused(false)
            }}
          />
        )}
      </div>
    </Tooltip>
  )
}

function CellBody<T extends RSheetRecordNode>({ column }: { column: RSheetColumn<T> }) {
  const context = useContextValue()
  if (column.metaType === 'record_url') {
    return <></>
  }
  if (column.width === MINIMUM_WIDTH) {
    return <></>
  }
  const headerWithBigIcon = context.headerWithBigIcon ?? false
  const asKpiTable = context.asKpiTable ?? false

  return (
    <>
      {headerWithBigIcon && (
        <div
          style={{
            textAlign: 'center',
            fontSize: 24,
            marginBottom: 2,
            marginTop: 5,
          }}
        >
          <ColumnIcon column={column} />
        </div>
      )}
      <div
        className={
          headerWithBigIcon
            ? 'header-with-bigicon flex w-full items-center justify-center align-middle'
            : `flex pl-1 align-middle`
        }
      >
        <Space
          style={
            headerWithBigIcon
              ? {
                  margin: 0,
                  padding: 0,
                  fontSize: 11,
                  textAlign: 'center',
                }
              : {}
          }
        >
          {!headerWithBigIcon && !asKpiTable && <ColumnIcon column={column} />}
          <span className="whitespace-normal break-all">
            {column.title ?? ''}
            {column.required === true ? '*' : ''}
          </span>
          {column.readonly === true && !asKpiTable && <LockOutlined style={{ opacity: 0.5 }} />}
          {column.isFiltered === true && <FilterOutlined style={{ opacity: 0.5 }} />}
          {column.sortState === 'asc' ? (
            <SortAscendingOutlined />
          ) : column.sortState === 'desc' ? (
            <SortDescendingOutlined />
          ) : (
            <></>
          )}
          {column.isHighlighted === true && <HighlightOutlined style={{ opacity: 0.5 }} />}
        </Space>
      </div>
      {/* {column.smallTitle !== undefined && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            fontSize: 10,
            color: getHeaderColor(column.color).textColor,
            opacity: 0.7,
            transform: 'scale(0.8)',
          }}
        >
          {column.smallTitle}
        </div>
      )} */}
    </>
  )
}

function ColumnIcon<T extends RSheetRecordNode>({ column }: { column: RSheetColumn<T> }) {
  if (column.icon !== undefined) {
    if (typeof column.icon === 'string') {
      return <KpiIcon imageName={column.icon} />
    }
    return column.icon
  }
  if (column.type === 'kpi') {
    return <StarOutlined />
  }

  switch (column.metaType) {
    case 'relation': {
      return <SearchOutlined />
    }
    case 'url': {
      return <LinkOutlined />
    }
    case 'phone_number': {
      return <PhoneOutlined />
    }
    case 'email': {
      return <MailOutlined />
    }
    case 'select': {
      return <DownCircleOutlined />
    }
    case 'multi_select': {
      return <UnorderedListOutlined />
    }
    default: {
      break
    }
  }
  if (column.type === 'notFound') {
    return <QuestionCircleOutlined />
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion,@typescript-eslint/no-unnecessary-condition
  const typeIcon = HUB_PROPERTY_TYPE_ICONS[(column.type as PropertyTypeEnum) ?? ('string' as const)]
  return <>{typeIcon}</>
}
