import { isPresent, isSome } from '@salescore/buff-common'
import type { ViewQueryRecordNode } from '@salescore/core'
import Mustache from 'mustache'

const OLD_SEARCH_SQL_KEY_PLACEHOLDER = `{{ searchKey }}` // v0
// export const SEARCH_CONDITION_PLACEHOLDER = `{{ '' | search }}` // v1

export interface SearchResultRow {
  value: string
  label: string
  meta_label?: string
}

// TODO: この辺もcoreに移行すべきか？

export function compileSearchSql(
  sql: string,
  searchKeyword: string,
  recordNode: ViewQueryRecordNode | undefined,
  parentRecordNode: ViewQueryRecordNode | undefined,
) {
  // v0時代(deprecated)
  if (sql.includes(OLD_SEARCH_SQL_KEY_PLACEHOLDER)) {
    return sql.replace(OLD_SEARCH_SQL_KEY_PLACEHOLDER, searchKeyword)
  }

  // v1時代(deprecated)

  const matched = /\{\{ '(.*)' \| search \}\}/.exec(sql)
  if (isSome(matched) && isSome(matched[0]) && isSome(matched[1])) {
    const fieldSql = matched[1]
    const keys = searchKeyword.split(/\s/).filter((x) => isPresent(x)) // 空白でsplitする
    const condition = keys.map((key) => `${fieldSql} LIKE '%${key}%'`).join(' AND ')
    const convertedSql = sql.replace(matched[0], isPresent(condition) ? condition : 'TRUE = TRUE')
    const mustached = Mustache.render(convertedSql, { record: recordNode, parentRecord: parentRecordNode })
    return mustached
  }

  // 最新版では、単純に文字列を渡してrenderする。上記のv1のように、こちら側でロジックを持たない
  // その分「searchヘルパーでLIKE文を生成」「半角スペースで複数検索」のようなロジックを、mustacheテンプレートに持たせる形になる
  const rendered = Mustache.render(sql, {
    record: recordNode,
    query: searchKeyword,
    queries: searchKeyword.split(/\s/),
  })
  return rendered
}
