import type { SourceProviderEnum } from '@salescore/client-api'

const providerJa: Record<SourceProviderEnum, string> = {
  comdesk: 'comdesk',
  google_sheets: 'Google Sheets',
  hubspot: 'Hubspot',
  kintone: 'Kintone',
  microsoft_dynamics: 'Microsoft Dynamics',
  next_sfa: 'ネクストSFA',
  salescore: 'SALESCORE',
  salesforce: 'Salesforce',
  senses: 'Senses',
  zoho: 'Zoho',
  zoom: 'Zoom',
}

export const CLIENT_COMMON_CONSTANT = {
  // どういう形でi18n設定するか、まだ決めかねている。。
  i18n: {
    ja: {
      provider: providerJa,
    },
  },
}
