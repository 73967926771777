import { ApolloConsumer } from '@apollo/client'
import { wrapKeyboardEvent } from '@salescore/frontend-common'
import { type KeyboardEvent, type ReactElement, useEffect } from 'react'

import { LauncherBodyV2 } from '../GlobalLauncherV2/LauncherBodyV2'
import { SuspenseWithLoading } from '../SuspenseWithLoading'

export function GlobalLauncher({
  isLauncherOpen,
  openLauncher,
  closeLauncher,
}: {
  isLauncherOpen: boolean
  openLauncher: () => void
  closeLauncher: () => void
}): ReactElement {
  useEffect(() => {
    const handler = (event: unknown): void => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
      const e = event as KeyboardEvent<HTMLDivElement> // TODO
      const wrapped = wrapKeyboardEvent(e)
      if (wrapped.key === 'k' && wrapped.commandKey && !isLauncherOpen) {
        openLauncher()
        e.preventDefault()
      }
      if (wrapped.code === 'Escape') {
        closeLauncher()
        e.preventDefault()
      }
    }
    window.addEventListener('keydown', handler)
    return () => {
      window.removeEventListener('keydown', handler)
    }
  })

  // エラー表示などを出したくないのでhandleQueryは使わない
  if (!isLauncherOpen) {
    return <></>
  }

  return (
    <ApolloConsumer>
      {(client) => (
        <SuspenseWithLoading>
          <LauncherBodyV2 closeLauncher={closeLauncher} />
        </SuspenseWithLoading>
      )}
    </ApolloConsumer>
  )
}
