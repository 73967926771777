// eslint-disable-next-line @eslint-community/eslint-comments/disable-enable-pair
/* eslint-disable max-lines */
import { faPaintBrush } from '@fortawesome/pro-light-svg-icons'
import { faTablePivot } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isNull, isSome } from '@salescore/buff-common'
import { CONSTANT } from '@salescore/client-base'
import {
  CORE_CONSTANT,
  generateLabelSqlFieldName,
  type ViewConfigDimension,
  type ViewUiKpi,
  type ViewUiKpiPivot,
} from '@salescore/core'
import {
  ButtonWithTooltip,
  type ChartLabelDisplayModeValue,
  type ChartLegendsDisplayModeValue,
  type StackedChartType,
} from '@salescore/frontend-common'
import { Button, Checkbox, Divider, Empty, Row, Select, Space } from 'antd'
import { t } from 'i18next'
import { useEffect } from 'react'
import { Trans } from 'react-i18next'
import { useRecoilState } from 'recoil'

import {
  chartDesignSettingsAtom,
  type KpiDisplayFormat,
  kpiDisplayFormatAtom,
  kpiPieChartCategoryFiltersAtom,
} from '../../../recoil/navigation/atoms'
import {
  useKpiPivotNavigationModal,
  useKpiPivotParameter,
  useKpiPivotParameterDrawerVisibility,
} from '../../../recoil/navigation/hooks'
import { useViewAbilityValue, useViewConfigKpi, useViewValue } from '../../../recoil/view/hooks'
import { useRecords } from '../ViewUIKpi/KpiStackedChart'
import { DimensionPicker } from './DimensionPicker'
import { DimensionsPicker } from './DimensionsPicker'
import { DECIMALPLACE_TYPES, UNIT_TYPES } from './KpiForm/KpiFormDetailFormItems'

const stackedChartTypeOptions: Array<{ label: string; value: StackedChartType }> = [
  { label: t(`棒グラフ`), value: 'bar' },
  { label: t(`折れ線グラフ`), value: 'line' },
  { label: t(`エリアグラフ`), value: 'area' },
]

export const labelDisplayOptions: Array<{ label: string; value: ChartLabelDisplayModeValue }> = [
  { label: t(`自動`), value: 'auto' },
  { label: t(`全て表示する`), value: 'showAll' },
  { label: t(`表示しない`), value: 'none' },
]

export const labelUnitOptions = UNIT_TYPES
export const labelDecimalPlaceOptions = DECIMALPLACE_TYPES

const legendsDisplayOptions: Array<{ label: string; value: ChartLegendsDisplayModeValue }> = [
  { label: t(`自動`), value: 'auto' },
  { label: t(`表示する`), value: 'show' },
  { label: t(`表示しない`), value: 'none' },
]

export function KpiParameterSider({
  component,
  closable,
  close,
}: {
  component: ViewUiKpiPivot | ViewUiKpi
  closable?: boolean
  close?: () => void
}) {
  const { kpiPivotKpisModal } = useKpiPivotNavigationModal()
  const [visible, setVisibility] = useKpiPivotParameterDrawerVisibility()
  const view = useViewValue()
  const ability = useViewAbilityValue()

  return (
    <div className="h-full overflow-y-auto bg-white p-4" style={{ borderRight: `1px solid ${CONSTANT.colors.border}` }}>
      <Row justify="space-between">
        <Space className="mb-3 text-lg font-bold">
          <FontAwesomeIcon icon={faTablePivot} />
          {t(`集計設定`)}
        </Space>
        {closable !== false && (
          <Space>
            <Button
              onClick={() => {
                // TODO: この辺の実装が前の負債をひきづったまま
                if (close === undefined) {
                  setVisibility(false)
                } else {
                  close()
                }
              }}
              type="text"
            >
              {t(`閉じる`)}
            </Button>
          </Space>
        )}
      </Row>
      <DimensionPickerWrapper component={component} />
      {component.type === 'KpiPivotSheet' && (
        <Section title={t(`KPIグループ設定`)} description={t(`KPIの追加、削除、並び替え、グルーピング`)}>
          <ButtonWithTooltip
            disabled={!ability.canUpdate}
            showTooltip={!ability.canUpdate}
            tooltipTitle={t(`権限がないため編集できません`)}
            onClick={() => {
              kpiPivotKpisModal.showModal()
            }}
          >
            {t(`設定画面を開く`)}
          </ButtonWithTooltip>
        </Section>
      )}
    </div>
  )
}

function DimensionPickerWrapper({ component }: { component: ViewUiKpiPivot | ViewUiKpi }) {
  const [format] = useRecoilState(kpiDisplayFormatAtom)

  if (component.type === 'KpiPivotSheet' || format === 'table') {
    // 行列を選ばせる基本的なUI
    return (
      <>
        <Section
          title={t(`集計項目 - 行`)}
          description={t(`集計したい項目をクリックしてください。並び替えもできます。`)}
        >
          <DimensionsPicker dimensions={component.dimensions} type={'rows'} />
        </Section>
        <Section
          title={t(`集計項目 - 列`)}
          description={t(`集計したい項目をクリックしてください。並び替えもできます。`)}
        >
          <DimensionsPicker dimensions={component.dimensions} type={'columns'} />
        </Section>
      </>
    )
  }

  if (format === 'pie') {
    return (
      <>
        <PieChartDimensionsPicker component={component} />
        <DesignConfigPart format={format} />
      </>
    )
  }

  if (format === 'bar') {
    return (
      <>
        <BarChartDimensionsPicker component={component} />
        <DesignConfigPart format={format} />
      </>
    )
  }

  if (format === 'bar-time-series') {
    return (
      <>
        <BarTimeSeriesChartDimensionsPicker component={component} />
        <DesignConfigPart format={format} />
      </>
    )
  }

  // 行列を選ばせる基本的なUI
  return (
    <>
      <Section title={t(`集計項目 - 行`)} description={t(`集計したい項目をクリックしてください。並び替えもできます。`)}>
        <DimensionsPicker dimensions={component.dimensions} type={'rows'} />
      </Section>
      <Section title={t(`集計項目 - 列`)} description={t(`集計したい項目をクリックしてください。並び替えもできます。`)}>
        <DimensionsPicker dimensions={component.dimensions} type={'columns'} />
      </Section>
    </>
  )
}

function PieChartDimensionsPicker({ component }: { component: ViewUiKpiPivot | ViewUiKpi }) {
  const [kpiPivotParameter, setKpiPivotParameter] = useKpiPivotParameter()
  const { rows } = kpiPivotParameter.pivot
  // ややこしいが、rows.length
  const [dimension, dimensionAsCategory] = [rows[0], rows[1]]
  // const [dimension, dimensionAsCategory] = rows.length === 2 ? [rows[1], rows[0]] : [rows[0], undefined]

  const setToFirst = (dimension: ViewConfigDimension | undefined) => {
    setKpiPivotParameter((oldValue) => ({
      ...oldValue,
      pivot: {
        rows: [dimension, ...oldValue.pivot.rows.slice(1)].compact(),
        columns: [],
      },
    }))
  }

  const setToSecond = (dimensionCategory: ViewConfigDimension | undefined) => {
    setKpiPivotParameter((oldValue) => ({
      ...oldValue,
      pivot: {
        rows: [oldValue.pivot.rows[0], dimensionCategory].compact(),
        columns: [],
      },
    }))
  }

  // columnsは使わないので空にする
  useEffect(() => {
    const firstDimension = component.dimensions.sortBy((x) => x.dimensionCount ?? 0).first()
    setKpiPivotParameter((oldValue) => ({
      ...oldValue,
      pivot: {
        rows: oldValue.pivot.rows.isPresent() ? oldValue.pivot.rows.slice(0, 2) : [firstDimension].compact(),
        columns: [],
      },
    }))
  }, [])

  return (
    <>
      <Section title={t(`内訳(必須)`)} description={t(`内訳にしたい項目を選択してください`)}>
        <DimensionPicker
          dimensions={component.dimensions}
          currentDimension={dimension}
          onChange={(dimension) => {
            // if (rows.length === 2) {
            //   setToSecond(dimension)
            // } else {
            //   setToFirst(dimension)
            // }
            setToFirst(dimension)
          }}
        />
      </Section>
      <Section title={t(`カテゴリ`)} description={t(`カテゴリにしたい項目を選択してください`)}>
        <DimensionPicker
          allowClear
          dimensions={component.dimensions}
          currentDimension={dimensionAsCategory}
          onChange={(dimension) => {
            setToSecond(dimension)
          }}
        />
      </Section>
      {component.type === 'KpiSheet' && <PieChartCategoryFiltersPicker component={component} />}
    </>
  )
}

function PieChartCategoryFiltersPicker({ component }: { component: ViewUiKpi }) {
  const { records, key2 } = useRecords({ component })
  const dimensionAsCategory = component.pivot.rows[1]
  const [kpiPieChartCategoryFilters, setKpiPieChartCategoryFilters] = useRecoilState(kpiPieChartCategoryFiltersAtom)
  const filterOptions = records
    // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
    .groupBy((x) => x[key2] as string)
    .map((key, records) => {
      // records.first()は必ず存在する
      const firstRecord = records.first()!
      return {
        key,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion,@typescript-eslint/no-unnecessary-condition
        label: (firstRecord[generateLabelSqlFieldName(key2)] as string) ?? (firstRecord[key2] as string),
      }
    })
    .sortBy((x) => x.label)

  useEffect(() => {
    // カテゴリの選択解除タイミングでフィルターをリセットしてしまうと、
    // その後recordsが変更されたタイミングと合わせてグラフが2回描画されてしまうため、
    // recordsの変更タイミングに合わせてフィルターをリセットしている
    setKpiPieChartCategoryFilters((oldValue) =>
      // eslint-disable-next-line max-nested-callbacks
      oldValue.filter((x) => filterOptions.some((option) => option.key === x.key)),
    )
  }, [records])

  if (isNull(dimensionAsCategory)) {
    return <></>
  }

  return (
    <Section title={t(`カテゴリのフィルター`)}>
      <div className="flex w-full">
        <Select
          className="max-w-xs grow-[7]"
          mode="multiple"
          allowClear
          options={filterOptions.map((option) => ({
            value: option.key,
            label: <span className="whitespace-pre-wrap break-words">{option.label}</span>,
          }))}
          value={kpiPieChartCategoryFilters.map((x) => x.key)}
          onChange={(values) => {
            setKpiPieChartCategoryFilters(values.map((key) => ({ key })))
          }}
        />
      </div>
    </Section>
  )
}

function BarChartDimensionsPicker({ component }: { component: ViewUiKpiPivot | ViewUiKpi }) {
  const [kpiPivotParameter, setKpiPivotParameter] = useKpiPivotParameter()
  const [chartDesignSettings, setChartDesignSettings] = useRecoilState(chartDesignSettingsAtom)
  const { rows } = kpiPivotParameter.pivot
  // ややこしいが、rows.length
  const [dimension, dimensionAsCategory] = [rows[0], rows[1]]
  // const [dimension, dimensionAsCategory] = rows.length === 2 ? [rows[1], rows[0]] : [rows[0], undefined]
  // const [valueLabelDisplayMode, setValueLabelDisplayMode] = useRecoilState(chartValueLabelDisplayModeAtom)
  // const [axisLabelDisplayMode, setAxisLabelDisplayMode] = useRecoilState(chartAxisLabelDisplayModeAtom)

  const setToFirst = (dimension: ViewConfigDimension | undefined) => {
    setKpiPivotParameter((oldValue) => ({
      ...oldValue,
      pivot: {
        rows: [dimension, ...oldValue.pivot.rows.slice(1)].compact(),
        columns: [],
      },
    }))
  }

  const setToSecond = (dimensionCategory: ViewConfigDimension | undefined) => {
    setKpiPivotParameter((oldValue) => ({
      ...oldValue,
      pivot: {
        rows: [oldValue.pivot.rows[0], dimensionCategory].compact(),
        columns: [],
      },
    }))
  }

  // columnsは使わないので空にする
  useEffect(() => {
    const firstDimension = component.dimensions.sortBy((x) => x.dimensionCount ?? 0).first()
    setKpiPivotParameter((oldValue) => ({
      ...oldValue,
      pivot: {
        rows: oldValue.pivot.rows.isPresent() ? oldValue.pivot.rows.slice(0, 2) : [firstDimension].compact(),
        columns: [],
      },
    }))
  }, [])

  return (
    <>
      <Section title={t(`X軸(必須)`)} description={t(`X軸にしたい項目を選択してください`)}>
        <DimensionPicker
          dimensions={component.dimensions}
          currentDimension={dimension}
          onChange={(dimension) => {
            // if (rows.length === 2) {
            //   setToSecond(dimension)
            // } else {
            //   setToFirst(dimension)
            // }
            setToFirst(dimension)
          }}
        />
      </Section>
      <Section title={t(`内訳`)} description={t(`内訳にしたい項目を選択してください`)}>
        <Space className="w-full" direction="vertical">
          <DimensionPicker
            allowClear
            dimensions={component.dimensions}
            currentDimension={dimensionAsCategory}
            onChange={(dimension) => {
              setToSecond(dimension)

              if (isNull(dimension)) {
                setChartDesignSettings((oldValue) => ({
                  ...oldValue,
                  chartValueLabelShouldShowPercentage: false,
                }))
              }
            }}
          />
          {isSome(dimensionAsCategory) && (
            <Checkbox
              checked={chartDesignSettings.chartValueLabelShouldShowPercentage}
              onChange={(e) => {
                setChartDesignSettings((oldValue) => ({
                  ...oldValue,
                  chartValueLabelShouldShowPercentage: e.target.checked,
                }))
              }}
            >
              {t('パーセンテージを表示')}
            </Checkbox>
          )}
        </Space>
      </Section>
    </>
  )
}

function BarTimeSeriesChartDimensionsPicker({ component }: { component: ViewUiKpiPivot | ViewUiKpi }) {
  const [kpiConfig] = useViewConfigKpi()
  const [kpiPivotParameter, setKpiPivotParameter] = useKpiPivotParameter()
  const [chartDesignSettings, setChartDesignSettings] = useRecoilState(chartDesignSettingsAtom)
  const { rows } = kpiPivotParameter.pivot
  const dateDimensions = component.dimensions.filter((x) => x.fieldType === 'date' || x.fieldType === 'datetime')

  const setToFirst = (dimension: ViewConfigDimension | undefined) => {
    setKpiPivotParameter((oldValue) => ({
      ...oldValue,
      pivot: {
        rows: [dimension, ...oldValue.pivot.rows.slice(1)].compact(),
        columns: [],
      },
    }))
  }

  const setToSecond = (dimensionCategory: ViewConfigDimension | undefined) => {
    setKpiPivotParameter((oldValue) => ({
      ...oldValue,
      pivot: {
        rows: [oldValue.pivot.rows[0], dimensionCategory].compact(),
        columns: [],
      },
    }))
  }

  const date = kpiConfig?.date
  const kpiDateDimension =
    date === undefined
      ? undefined
      : component.dimensions.find((x) => x.key === CORE_CONSTANT.KPI_PIVOT_KPI_DATE_DIMENSION().key)

  // 行の1つ目には必ずKPIのdateを選択する
  // columnsは使わないので空にする
  useEffect(() => {
    const defaultDimension = kpiDateDimension ?? dateDimensions.first()
    if (defaultDimension === undefined) {
      return
    }

    setKpiPivotParameter((oldValue) => ({
      ...oldValue,
      pivot: {
        rows: [
          {
            ...defaultDimension,
            dateSpan: 'month' as const,
          },
          rows[1],
        ].compact(),
        columns: [],
      },
    }))
  }, [])

  if (dateDimensions.isBlank()) {
    return (
      <Empty
        description={
          <Trans>
            <div className="mb-8 text-xs text-red-500">
              このKPIには時間項目が設定されていないため、
              <br />
              このグラフ種別は選べません。
              <br />
              KPI設定より、時間項目を設定してください。
            </div>
          </Trans>
        }
      />
    )
  }

  return (
    <>
      <Section title={t(`時間軸`)} description={t(`時間軸にしたい項目を選択してください`)}>
        <DimensionPicker
          allowClear
          dimensions={dateDimensions}
          currentDimension={rows[0]}
          onChange={(dimension) => {
            setToFirst(dimension)
          }}
        />
      </Section>
      <Section title={t(`内訳`)} description={t(`内訳にしたい項目を選択してください`)}>
        <Space className="w-full" direction="vertical">
          <DimensionPicker
            allowClear
            dimensions={component.dimensions}
            currentDimension={rows[1]}
            onChange={(dimension) => {
              setToSecond(dimension)

              if (isNull(dimension)) {
                setChartDesignSettings((oldValue) => ({
                  ...oldValue,
                  chartValueLabelShouldShowPercentage: false,
                }))
              }
            }}
          />
          {isSome(rows[1]) && (
            <Checkbox
              checked={chartDesignSettings.chartValueLabelShouldShowPercentage}
              onChange={(e) => {
                setChartDesignSettings((oldValue) => ({
                  ...oldValue,
                  chartValueLabelShouldShowPercentage: e.target.checked,
                }))
              }}
            >
              {t('パーセンテージを表示')}
            </Checkbox>
          )}
        </Space>
      </Section>
    </>
  )
}

function DesignConfigPart({ format }: { format: KpiDisplayFormat }) {
  const [chartDesignSettings, setChartDesignSettings] = useRecoilState(chartDesignSettingsAtom)

  return (
    <>
      <Space className="mb-3 text-lg font-bold">
        <FontAwesomeIcon icon={faPaintBrush} />
        {t(`デザイン設定`)}
      </Space>
      {format !== 'pie' && (
        <>
          <Section title={t('グラフの種類')} isSmall={true}>
            <div className="flex w-full">
              <Select
                className="max-w-xs grow-[7]"
                options={stackedChartTypeOptions}
                value={chartDesignSettings.chartType}
                onChange={(value) => {
                  setChartDesignSettings((oldValue) => ({
                    ...oldValue,
                    chartType: value,
                  }))
                }}
              />
            </div>
          </Section>
          <Section title={t(`X軸のラベル表示`)} isSmall={true}>
            <div className="flex w-full">
              <Select
                className="max-w-xs grow-[7]"
                options={labelDisplayOptions}
                value={chartDesignSettings.chartAxisLabelDisplayMode}
                onChange={(value) => {
                  setChartDesignSettings((oldValue) => ({
                    ...oldValue,
                    chartAxisLabelDisplayMode: value,
                  }))
                }}
              />
            </div>
          </Section>
        </>
      )}
      <Section title={t(`値ラベルの表示`)} isSmall={true}>
        <div className="flex w-full">
          <Select
            className="max-w-xs grow-[7]"
            options={labelDisplayOptions}
            value={chartDesignSettings.chartValueLabelDisplayMode}
            onChange={(value) => {
              setChartDesignSettings((oldValue) => ({
                ...oldValue,
                chartValueLabelDisplayMode: value,
              }))
            }}
          />
        </div>
      </Section>
      <Section title={t(`値ラベルの単位`)} isSmall={true}>
        <div className="flex w-full">
          <Select
            className="max-w-xs grow-[7]"
            options={labelUnitOptions}
            value={chartDesignSettings.chartValueLabelUnitType}
            onChange={(value) => {
              setChartDesignSettings((oldValue) => ({
                ...oldValue,
                chartValueLabelUnitType: value,
              }))
            }}
          />
        </div>
      </Section>
      <Section title={t(`値ラベルの桁`)} isSmall={true}>
        <div className="flex w-full">
          <Select
            className="max-w-xs grow-[7]"
            options={labelDecimalPlaceOptions}
            value={chartDesignSettings.chartValueLabelDecimalPlaceType}
            onChange={(value) => {
              setChartDesignSettings((oldValue) => ({
                ...oldValue,
                chartValueLabelDecimalPlaceType: value,
              }))
            }}
          />
        </div>
      </Section>
      <Section title={t(`凡例の表示`)} isSmall={true}>
        <div className="flex w-full">
          <Select
            className="max-w-xs grow-[7]"
            options={legendsDisplayOptions}
            value={chartDesignSettings.chartLegendsDisplayMode}
            onChange={(value) => {
              setChartDesignSettings((oldValue) => ({
                ...oldValue,
                chartLegendsDisplayMode: value,
              }))
            }}
          />
        </div>
      </Section>
    </>
  )
}

function Section({
  title,
  description,
  children,
  isSmall,
}: {
  title: string
  description?: string
  children: JSX.Element
  isSmall?: boolean
}) {
  return (
    <div>
      <div className="mb-8 w-full">
        <p>
          {title}
          <br />
          {/* <InfoCircleOutlined className="text-xs text-gray-600 mr-1"/> */}
          {isSome(description) && <span className="text-xs text-gray-600">{description}</span>}
        </p>
        {children}
      </div>
      {isSmall !== true && <Divider />}
    </div>
  )
}
