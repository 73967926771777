import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import Editor from '@monaco-editor/react'
import { isSome } from '@salescore/buff-common'
import {
  CreateEltModelDocument,
  type EltModelFieldsFragment,
  type EltModelTransformInput,
  FetchConnectionsDocument,
  FetchEltModelsDocument,
  FetchPropertiesBoilerplateDocument,
  UpdateEltModelDocument,
} from '@salescore/client-api'
import { Posthog, POSTHOG_EVENTS } from '@salescore/client-base'
import { getOrganizationIdFromPath } from '@salescore/client-common'
import { CORE_CONSTANT, type CoreModel, modelPropertySchema } from '@salescore/core'
import { App, Button, Checkbox, Col, Form, Input, Row, Select, Space } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { t } from 'i18next'
import { useState } from 'react'
import { format } from 'sql-formatter'

const PREFIX = CORE_CONSTANT.CUSTOM_MODEL_PREFIX

function generateModelName(name: string): string {
  return [PREFIX, name].join('')
}

interface FormValue {
  label: string
  name: string
  propertiesJson: string
  sql: string
  materialized: boolean
  connectionId: string | undefined
}

// eslint-disable-next-line complexity
export const CustomizedModelForm = ({
  editingEltModel,
  onAfterFinish,
  onAfterCancel,
}: {
  editingEltModel?: EltModelFieldsFragment
  onAfterFinish: () => void
  onAfterCancel: () => void
}): JSX.Element => {
  const { message } = App.useApp()
  const [fetchPropertiesBoilerplate] = useLazyQuery(FetchPropertiesBoilerplateDocument)
  const { data, refetch } = useQuery(FetchEltModelsDocument, {
    variables: {
      organizationId: getOrganizationIdFromPath(),
    },
  })
  const eltModels = data?.eltModels ?? []
  const connections =
    useQuery(FetchConnectionsDocument, {
      variables: {
        organizationId: getOrganizationIdFromPath(),
      },
    }).data?.connections ?? []
  const salescoreConnection = connections.find((x) => x.source.provider === 'salescore')

  const [form] = useForm<FormValue>()
  const [updateEltModel, { loading: updateLoading }] = useMutation(UpdateEltModelDocument)
  const [createEltModel, { loading: createLoading }] = useMutation(CreateEltModelDocument)
  const loading = updateLoading || createLoading
  const initialValue: Partial<FormValue> = {
    label: editingEltModel?.model.label,
    name: editingEltModel?.name,
    sql: editingEltModel?.transform?.sql,
    propertiesJson: JSON.stringify(editingEltModel?.model.properties, null, 2),
    materialized: editingEltModel?.transform?.type === 'materialized_view',
    connectionId: editingEltModel?.connectionId ?? salescoreConnection?.id ?? connections[0]?.id,
  }
  const [materialized, setMaterialized] = useState(initialValue.materialized)

  const onFinish = (value: FormValue): void => {
    const name = editingEltModel?.name ?? generateModelName(value.name)
    const propertiesRaw = JSON.parse(value.propertiesJson) as unknown
    const properties = modelPropertySchema.array().safeParse(propertiesRaw)
    if (!properties.success) {
      void message.error(properties.error.message)
      return
    }
    const transform: EltModelTransformInput = {
      sql: value.sql,
      type: value.materialized ? 'materialized_view' : 'view',
    }
    const model: CoreModel = {
      name,
      label: value.label,
      properties: properties.data,
    }
    void (isSome(editingEltModel)
      ? updateEltModel({
          variables: {
            organizationId: getOrganizationIdFromPath(),
            eltModel: {
              id: editingEltModel.id,
              connectionId: value.connectionId ?? salescoreConnection?.id ?? connections[0]!.id,
              transform,
              model,
            },
          },
          onCompleted: (result) => {
            void message.success(t(`カスタムモデルを更新しました`))
            void refetch()
            onAfterFinish()
            Posthog.track(POSTHOG_EVENTS.save_custom_object, {
              organizationId: getOrganizationIdFromPath(),
              config: result.updateEltModel,
            })
          },
          onError: (error) => {
            void message.error(error.message)
          },
        })
      : createEltModel({
          variables: {
            organizationId: getOrganizationIdFromPath(),
            eltModel: {
              type: 'userCustomizedDerived',
              name,
              connectionId: value.connectionId ?? salescoreConnection?.id ?? connections[0]!.id,
              transform,
              model,
            },
          },
          onCompleted: (result) => {
            void message.success(t(`カスタムモデルを作成しました`))
            void refetch()
            onAfterFinish()
            Posthog.track(POSTHOG_EVENTS.create_custom_object, {
              organizationId: getOrganizationIdFromPath(),
              config: result.createEltModel,
            })
          },
          onError: (error) => {
            void message.error(error.message)
          },
        }))
  }

  const generatePropertiesBoilerplate = (): void => {
    const sql = form.getFieldValue(`sql`) as unknown
    if (typeof sql !== 'string') {
      return
    }

    void fetchPropertiesBoilerplate({
      variables: {
        organizationId: getOrganizationIdFromPath(),
        shouldResolveId: false,
        sql,
      },
      onCompleted: (data) => {
        const json = JSON.stringify(data.propertiesBoilerplate, null, 2)
        form.setFieldsValue({ propertiesJson: json })
      },
      onError: (error) => {
        void message.error(`${t(`エラーが発生しました。`)}${error.message}`)
      },
    })
  }

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout="vertical"
      initialValues={initialValue}
      key={editingEltModel?.id ?? 'new'}
    >
      <Row>
        <Col span={12}>
          <Form.Item
            name="sql"
            label={
              <div>
                {t(`カスタムモデル定義(SQL) `)}
                <Button
                  onClick={() => {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
                    const sql = form.getFieldValue(`sql`) as string
                    form.setFieldsValue({ sql: format(sql, { language: 'postgresql' }) })
                  }}
                >
                  {t(`フォーマット`)}
                </Button>
              </div>
            }
            rules={[{ required: true, message: t(`カスタムモデル定義を入力してください`) }]}
          >
            <Editor
              height={600}
              theme="vs-dark"
              defaultLanguage="sql"
              defaultValue={`SELECT
-- ${t(`フィールド定義`)}
FROM
-- ${t(`テーブル名`)}`}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="propertiesJson"
            label={
              <div>
                {t(`プロパティ一覧(JSON) `)}
                <Button onClick={generatePropertiesBoilerplate}>{t(`SQLからプロパティを生成`)}</Button>
              </div>
            }
            rules={[{ required: true, message: t(`プロパティ一覧を入力してください`) }]}
          >
            <Editor height={600} theme="vs-dark" defaultLanguage="json" defaultValue={`[]`} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name="label"
        label={t(`オブジェクト名`)}
        rules={[{ required: true, message: t(`オブジェクト名を入力してください`) }]}
      >
        <Input width={200}></Input>
      </Form.Item>
      <Form.Item
        name="name"
        label={t(`内部名 ※作成後変更不可。小文字アルファベットと_のみ`)}
        rules={[
          { message: t(`使える文字は小文字アルファベットと_のみです`), pattern: /^[_a-z]+$/ },
          { required: true, message: t(`内部名を入力してください`) },
          {
            validator: async (_, value: string) => {
              if (isSome(initialValue.name)) {
                await Promise.resolve()
              } else if (eltModels.map((x) => x.name).includes(generateModelName(value))) {
                // TODO: prefix
                return await Promise.reject(new Error('Stream name must be unique'))
              } else {
                await Promise.resolve()
              }
            },
            message: t(`既に存在する内部名は入力できません`),
          },
        ]}
      >
        {isSome(initialValue.name) ? (
          <Input disabled={isSome(initialValue.name)} width={200}></Input>
        ) : (
          <div className="flex items-center align-middle">
            <span className="mr-2">{PREFIX}</span>
            <Input width={200}></Input>
          </div>
        )}
      </Form.Item>
      <Form.Item name="materialized" label={t(`マテリアライズドビューにする`)} valuePropName="checked">
        <Checkbox
          onChange={(event) => {
            setMaterialized(event.target.checked)
          }}
        />
      </Form.Item>
      {/* TODO: 説明を追加？ */}
      {materialized === true && (
        <Form.Item name="connectionId" label={t(`紐づける連携先`)}>
          <Select
            options={connections.map((x) => ({
              value: x.id,
              label: (
                <div>
                  {x.source.provider} <span className="text-gray-400">{x.source.name}</span>
                </div>
              ),
            }))}
          />
        </Form.Item>
      )}
      <Space direction="horizontal" className="w-full justify-end">
        <Form.Item>
          <Button
            htmlType="button"
            onClick={() => {
              onAfterCancel()
            }}
          >
            {t('閉じる')}
          </Button>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            {isSome(editingEltModel) ? t(`更新`) : t(`作成`)}
          </Button>
        </Form.Item>
      </Space>
    </Form>
  )
}
