import { Col, Row, Select } from 'antd'
import { t } from 'i18next'
import { type ReactElement, useState } from 'react'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { monokaiSublime } from 'react-syntax-highlighter/dist/esm/styles/hljs'

import { AddColumnForm } from './snapshot_action_forms/AddColumnForm'

type Operation = 'ADD_COLUMN'

export function SnapshotModelDefinition({
  eltModelId,
  sql,
  model,
  onFinish,
}: Readonly<{
  eltModelId: string
  sql: string
  model: string
  onFinish: () => void
}>): ReactElement {
  const [operation, setOperation] = useState<Operation | undefined>()

  return (
    <>
      <Row>
        <Col span={12} className="p-1">
          <h2>SQL</h2>
          <SyntaxHighlighter
            language="pgsql"
            style={monokaiSublime}
            customStyle={{ padding: 10, height: 450 }}
            wrapLongLines={false}
          >
            {sql}
          </SyntaxHighlighter>
        </Col>
        <Col span={12} className="p-1">
          <h2>{t('モデル定義')}</h2>
          <SyntaxHighlighter
            language="json"
            style={monokaiSublime}
            customStyle={{ padding: 10, height: 450 }}
            wrapLongLines={false}
          >
            {model}
          </SyntaxHighlighter>
        </Col>
      </Row>
      <Row className="pb-2">
        <Col span={24}>
          <Select<Operation>
            options={[{ value: 'ADD_COLUMN', label: t('SELECT 句に列を追加') }]}
            placeholder={t('操作を選択')}
            onChange={(value) => {
              setOperation(value)
            }}
            allowClear
            className="min-w-64"
          />
        </Col>
      </Row>

      {operation === 'ADD_COLUMN' && <AddColumnForm eltModelId={eltModelId} onFinish={onFinish} />}
    </>
  )
}
