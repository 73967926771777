import { useApolloClient } from '@apollo/client'
import {
  type NotificationStatusEnum,
  UpdateSheetThreadCommentNotificationDocument,
  type UpdateSheetThreadCommentNotificationMutation,
} from '@salescore/client-api'
import { organizationHasFeature } from '@salescore/frontend-common'

import { useMe } from '../../global/hooks'

export function useSheetThreadCommentNotifications() {
  const client = useApolloClient()
  const me = useMe()
  const commentFeatureEnabled = organizationHasFeature(me.organization, 'enable_slack_comment')

  async function updateSheetThreadCommentNotification(
    sheetThreadCommentNotificationId: string,
    status: NotificationStatusEnum,
  ) {
    if (!commentFeatureEnabled) {
      return
    }
    await client.mutate<UpdateSheetThreadCommentNotificationMutation>({
      mutation: UpdateSheetThreadCommentNotificationDocument,
      variables: {
        input: {
          organizationId: me.organization.id,
          sheetThreadCommentNotificationId,
          status,
        },
      },
    })
  }

  return {
    updateSheetThreadCommentNotification,
  }
}
