import { DeleteOutlined, EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'
import { faColumns3 } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isPresent } from '@salescore/buff-common'
import { between, type ViewQueryRecordNode } from '@salescore/core'
import { PopconfirmIf, useModalAtom } from '@salescore/frontend-common'
import { Drawer, Input, Tooltip, Tree } from 'antd'
import { t } from 'i18next'
import { useState } from 'react'
import { useRecoilValue } from 'recoil'

import { columnsDrawerAtom } from '../../../../recoil/navigation/atoms'
import { useSheetColumns } from '../../../../recoil/selectors/useSheetColumns'
import { sheetCustomModelsAtom } from '../../../../recoil/view/atoms'
import { useFieldMutations } from '../../../../recoil/view/mutations/fieldMutations'
import { getHeaderColor } from '../../../../rsheet/components/header/RSheetsNodeBlockHeader'
import type { RSheetColumn } from '../../../../rsheet/types'

export function ColumnsDrawer() {
  const columns = useSheetColumns()
  const { setUiColumn, moveField, remove } = useFieldMutations()
  const sheetCustomModels = useRecoilValue(sheetCustomModelsAtom)
  const [draggedNodeKey, setDraggedNodeKey] = useState<number | undefined>()

  const drawer = useModalAtom(columnsDrawerAtom)
  const [searchKey, setSearchKey] = useState(``)
  const filteredColumns = isPresent(columns)
    ? // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      columns.filter((column) => {
        const titleHit = column.title?.includes(searchKey) ?? false
        const object = column.nodeBlock?.labels.last()
        const pathHit = object?.includes(searchKey)
        return titleHit || pathHit
      })
    : columns

  function toggleVisibility(column: RSheetColumn<ViewQueryRecordNode>) {
    const { field } = column
    if (field === undefined) {
      return
    }
    setUiColumn({
      field,
      column: {
        visible: !(column.visible ?? true),
      },
    })
  }

  function removeColumn(column: RSheetColumn<ViewQueryRecordNode>) {
    const { field } = column
    if (field === undefined) {
      return
    }
    remove({ field })
  }

  return (
    <Drawer
      open={drawer.isModalVisible}
      onClose={drawer.hideModal}
      title={
        <div className="flex items-center gap-1.5">
          <FontAwesomeIcon icon={faColumns3} color="black" height={16} width={16} />
          {t(`カラム`)}
        </div>
      }
      closeIcon={false}
      destroyOnClose
    >
      <Input.Search
        placeholder={t(`検索`)}
        allowClear
        onSearch={setSearchKey}
        onChange={(e) => {
          e.target.value === '' && setSearchKey('')
        }}
      />
      <div
        className="sticky -top-6 z-10 mb-2 h-6 w-full bg-white"
        onDragOver={(e) => {
          e.preventDefault()
        }}
      />
      <Tree
        className="antd-tree-one-level-columns"
        treeData={filteredColumns.map((column) =>
          createNodeData(
            column,
            sheetCustomModels.some((x) => x.name === column.node.name),
            toggleVisibility,
            removeColumn,
          ),
        )}
        blockNode
        draggable
        allowDrop={(info) =>
          // -1: 一番上に移動させる場合
          // 0: 他のアイテムの子供にする場合
          // 1: 他のアイテムと横並びにする場合
          info.dropPosition === 1 || info.dropPosition === -1
        }
        onDrop={(info) => {
          const sourceIndex = info.dragNode.key
          const destinationIndex =
            sourceIndex < info.dropPosition
              ? between(info.dropPosition - 1, 0, columns.length - 1) // 下に動かす場合 -1 が必要
              : between(info.dropPosition, 0, columns.length - 1)

          const sourceColumn = columns[sourceIndex]
          const destinationColumn = columns[destinationIndex]
          if (sourceColumn?.configIndex === undefined || destinationColumn?.configIndex === undefined) {
            return
          }
          moveField({
            oldFieldIndex: sourceColumn.configIndex,
            newFieldIndex: destinationColumn.configIndex,
          })
        }}
        onDragStart={(info) => {
          setDraggedNodeKey(info.node.key)
        }}
        onDragEnd={() => {
          setDraggedNodeKey(undefined)
        }}
      />
      <div
        className="sticky -bottom-6 z-10 mt-2 h-6 w-full bg-white"
        onDragOver={(e) => {
          e.preventDefault()
        }}
      />
    </Drawer>
  )
}

function createNodeData(
  column: RSheetColumn<ViewQueryRecordNode>,
  isSheetCustomModelNode: boolean,
  toggleVisibility: (column: RSheetColumn<ViewQueryRecordNode>) => void,
  removeColumn: (column: RSheetColumn<ViewQueryRecordNode>) => void,
) {
  const labelAndColors = column.nodeBlock?.labels.zip(column.nodeBlock.colors)
  const pathComponent = (
    <span className="flex flex-wrap gap-0.5">
      <span
        className="inline-block whitespace-pre-wrap break-words"
        style={{
          color: getHeaderColor(labelAndColors?.last()?.[1]).textColor,
        }}
      >
        {labelAndColors?.last()?.[0]}
      </span>
    </span>
  )
  const node = (
    <div className="flex w-full flex-nowrap justify-between gap-2">
      <div className="flex w-full justify-start gap-1.5">
        <span className="min-w-[30%]">{column.title}</span>
        {pathComponent}
      </div>
      {(column.visible ?? true) ? (
        <EyeOutlined
          onClick={() => {
            toggleVisibility(column)
          }}
        />
      ) : (
        <EyeInvisibleOutlined
          onClick={() => {
            toggleVisibility(column)
          }}
        />
      )}
      <PopconfirmIf
        condition={isSheetCustomModelNode}
        title={
          <span>
            {t(`本当に削除しますか？`)}
            <br />
            {t(`カスタム列を削除すると、データも全て削除されます`)}
          </span>
        }
        onConfirm={() => {
          removeColumn(column)
        }}
      >
        <Tooltip title={column.columnDeletable === false ? t(`この項目は削除できません`) : ''}>
          <DeleteOutlined
            className={column.columnDeletable === false ? 'text-gray-300' : ''}
            onClick={() => {
              if (isSheetCustomModelNode) {
                // PopconfirmIf の onConfirm で処理するのでここでは何もしない
                return
              }
              if (column.columnDeletable === false) {
                return
              }
              removeColumn(column)
            }}
          />
        </Tooltip>
      </PopconfirmIf>
    </div>
  )
  return {
    key: column.index,
    title: node,
    children: [],
  }
}
