import { range } from '@salescore/buff-common'
import { type GetRecoilValue, selector, useRecoilValue } from 'recoil'
import { z } from 'zod'

import { rowLineSelector } from '../../../recoil/navigation/selector'
import { recordsAtom } from '../../../recoil/records/atoms'
import type { RSheetRecordNode } from '../../types'
import type { RSheetsCursor, RSheetsCursorPosition } from '../../types/CursorTypes'
import { contextAtom } from '../atoms'
import { cursorModel } from '../models/cursorModel'
import { columnsModel } from '../models/propModels'
import { columnBlocksRelatedSelector } from './columnBlockRelatedSelector'
import { columnRelatedSelector } from './columnRelatedSelector'
import { rowRelatedSelector, rowRelatedWithRowIndexSelector } from './rowRelatedSelector'
import { rowsRelatedSelector } from './rowsRelatedSelector'

export const cursorSelector = selector({
  key: 'cursorSelector',

  get: ({ get }) => {
    const cursor = get(cursorModel)
    if (cursor === undefined) {
      return {
        cursor,
      }
    }

    const columnRelated = get(columnRelatedSelector)
    const column = columnRelated.columns[cursor.main.columnIndex]
    const rows = get(recordsAtom)
    const row = rows[cursor.main.rowIndex]
    if (column === undefined || row === undefined) {
      return {
        cursor,
        column,
        row,
      }
    }

    const columnBlocksRelated = get(columnBlocksRelatedSelector)

    return {
      cursor,
      column,
      row,
      columnBlocksRelated,
      position: calculateMainCursorArea(get, columnRelated.widths, cursor, cursor.main.columnIndex, row),
      // Shift選択をした範囲範囲（＝expandがない場合は空になりうる）
      expandArea: calculateExpandCursorArea(get, columnRelated.widths, cursor.main, cursor.expand),
      // Shift選択、クリック選択の両方を含む範囲（＝直感的な意味での「選択範囲」）
      selectedArea: calculateExpandCursorArea(get, columnRelated.widths, cursor.main, cursor.expand ?? cursor.main),
      copiedArea: calculateExpandCursorArea(
        get,
        columnRelated.widths,
        cursor.copying?.main,
        cursor.copying?.expand ?? cursor.copying?.main,
      ),
    }
  },
})

export const useCursorSelector = () => useRecoilValue(cursorSelector)

// eslint-disable-next-line @typescript-eslint/max-params
function calculateMainCursorArea(
  get: GetRecoilValue,
  widths: number[],
  cursor: RSheetsCursor,
  columnIndex: number,
  row: RSheetRecordNode,
) {
  const context = get(contextAtom)
  const fixedLeftColumnIndex = context.fixedLeftColumnIndex ?? 0
  const columnBlocksRelated = get(columnBlocksRelatedSelector)
  const dataRelated = get(rowsRelatedSelector)
  const { rowHeight } = get(rowLineSelector)
  const rowRelated = get(rowRelatedSelector(row.id!))

  const widthsAndHeights = {
    widths,
    // heights: dataRelated.heights,
    rowHeights: dataRelated.rowHeights,
  }
  const width = widthsAndHeights.widths[cursor.main.columnIndex]
  const x = widthsAndHeights.widths.slice(0, cursor.main.columnIndex).sum()
  const fixedLeftColumnWidth = widthsAndHeights.widths.slice(0, fixedLeftColumnIndex).sum()

  const recordNodeWithInnerRowIndex = rowRelated.getSheetCell(columnIndex, cursor.main.innerRowIndex)
  if (recordNodeWithInnerRowIndex === undefined || width === undefined) {
    return
  }
  const height = recordNodeWithInnerRowIndex.height * rowHeight
  const y =
    [
      widthsAndHeights.rowHeights.slice(0, cursor.main.rowIndex).sum(),
      recordNodeWithInnerRowIndex.innerRowIndexStart,
    ].sum() *
      rowHeight +
    columnBlocksRelated.headerHeight

  // シートの場合のみ、右端が揃ってしまうとテキスト編集する際に右に伸びる編集領域が見えず使い辛くなるためオフセットを用意
  const offsetX = context.asKpiTable === true ? 0 : 300

  const position = {
    x,
    y,
    offsetX,
    width,
    height,
    fixedLeftColumnIndex,
    fixedLeftColumnWidth,
  }
  return position
}

export type ExpandArea = ReturnType<typeof calculateExpandCursorArea>

function calculateExpandCursorArea(
  get: GetRecoilValue,
  widths: number[],
  mainCursor: RSheetsCursorPosition | undefined,
  expandCursor: RSheetsCursorPosition | undefined,
) {
  const context = get(contextAtom)
  const fixedLeftColumnIndex = context.fixedLeftColumnIndex ?? 0
  const dataRelated = get(rowsRelatedSelector)
  const { rowHeight } = get(rowLineSelector)
  const widthsAndHeights = {
    widths,
    rowHeights: dataRelated.rowHeights,
  }

  if (mainCursor === undefined) {
    return
  }

  if (expandCursor === undefined) {
    return
  }

  const area = getExpandCursorArea(mainCursor, expandCursor, get)
  const x = widthsAndHeights.widths.slice(0, area.leftColumnIndex).sum() // 0からleftまで
  const width = widthsAndHeights.widths.slice(area.leftColumnIndex, area.rightColumnIndex + 1).sum() // leftからrightまで
  const fixedRightWidth = widthsAndHeights.widths.slice(area.leftColumnIndex, fixedLeftColumnIndex).sum()
  const y =
    [
      widthsAndHeights.rowHeights.slice(0, area.upRowIndex).sum(), // 0からupまで
      area.upInnerRowIndex, // 最上段のネストしたセル分の高さ
    ].sum() * rowHeight
  const hs = [calculateUpperHeight(area, get), calculateMiddleHeight(area, get), calculateDownerHeight(area, get)]
  const height = hs.sum() * rowHeight

  return {
    area,
    x,
    y,
    width,
    height,
    fixedRightWidth,
  }
}

function getExpandCursorArea(positionA: RSheetsCursor['main'], positionB: RSheetsCursor['main'], get: GetRecoilValue) {
  // 上(下)にあるカーソルを求める（行が同じならinnerRowIndexで比較、行が異なる場合はrowIndex）
  const downerCursor =
    positionA.rowIndex > positionB.rowIndex
      ? positionA
      : positionA.rowIndex < positionB.rowIndex
        ? positionB
        : positionA.innerRowIndex > positionB.innerRowIndex
          ? positionA
          : positionB
  const upperCursor =
    positionA.rowIndex > positionB.rowIndex
      ? positionB
      : positionA.rowIndex < positionB.rowIndex
        ? positionA
        : positionA.innerRowIndex > positionB.innerRowIndex
          ? positionB
          : positionA
  // 左右のカラムのインデックスを求める
  const leftColumnIndex = Math.min(positionA.columnIndex, positionB.columnIndex)
  const rightColumnIndex = Math.max(positionA.columnIndex, positionB.columnIndex)

  // 求めるべきareaは、単純なup-downの領域ではなく、up-downの領域が含むセルが含む領域となる
  // よって、最上段(最下段)のレコードを求める
  const columns = get(columnsModel)
  const currentColumns = columns.slice(leftColumnIndex, rightColumnIndex + 1)
  const getRecordNodesByCursor = (position: RSheetsCursorPosition) => {
    const res = get(rowRelatedWithRowIndexSelector(position.rowIndex))
    if (res === undefined) {
      return []
    }

    const recordNodes = currentColumns.map((column, index) =>
      res.getSheetCell(index + leftColumnIndex, position.innerRowIndex),
    )
    return recordNodes
  }
  const upperRecordNodes = getRecordNodesByCursor(upperCursor)
  const downerRecordNodes = getRecordNodesByCursor(downerCursor)

  return {
    leftColumnIndex,
    rightColumnIndex,
    upInnerRowIndex: upperRecordNodes.map((x) => x?.innerRowIndexStart ?? 0).min() ?? 0, // TODO: 存在しないことはありうる？
    downInnerRowIndex: downerRecordNodes.map((x) => x?.innerRowIndexEnd ?? 0).max() ?? 0, // endのindexは、次のセルのindex。
    upRowIndex: upperCursor.rowIndex,
    downRowIndex: downerCursor.rowIndex,
  }
}

export const expandCursorAreaSchema = z.object({
  leftColumnIndex: z.number(),
  rightColumnIndex: z.number(),
  upInnerRowIndex: z.number(),
  downInnerRowIndex: z.number(),
  upRowIndex: z.number(),
  downRowIndex: z.number(),
})

export type ExpandCursorArea = z.infer<typeof expandCursorAreaSchema>

function calculateUpperHeight(area: ExpandCursorArea, get: GetRecoilValue): number {
  const x = get(rowRelatedWithRowIndexSelector(area.upRowIndex))
  if (x === undefined) {
    return 0
  }
  // upとdownが同じ行の時
  if (area.upRowIndex === area.downRowIndex) {
    // donw-upで、upからdownの上までの行の高さ（ただし、表示制限しているときは高さを超えないようにする）
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const min = [area.downInnerRowIndex - area.upInnerRowIndex, x.visibleSheetRow.height ?? 0].min()!
    return [1, min].max()! // 最低でも1
  }
  // upとdownが違う行のとき、upから最下段(=行の高さがindexとなる)までの高さ
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return (x.visibleSheetRow.height ?? 0) - area.upInnerRowIndex
}

function calculateMiddleHeight(area: ExpandCursorArea, get: GetRecoilValue): number {
  // upとdownが同じ行の時
  if (area.upRowIndex === area.downRowIndex) {
    // これはupper側で計算するので、ここでは0
    return 0
  }

  // upとdownが隣り合う行のとき
  if (area.upRowIndex + 1 === area.downRowIndex) {
    // これはdowner側で計算するので、ここでは0
    return 0
  }

  // upとdownが違う行のとき
  const rootRecordNodes = range(area.upRowIndex + 1, area.downRowIndex - 1)
    .map((index) => get(rowRelatedWithRowIndexSelector(index)))
    .compact()
    .map((x) => x.visibleSheetRow)
    .compact()
  return rootRecordNodes.map((x) => x.height).sum()
}

function calculateDownerHeight(area: ExpandCursorArea, get: GetRecoilValue): number {
  // upとdownが同じ行の時
  if (area.upRowIndex === area.downRowIndex) {
    // これはupper側で計算するので、ここでは0
    return 0
  }
  // upとdownが違う行のとき
  const x = get(rowRelatedWithRowIndexSelector(area.downRowIndex))
  if (x === undefined) {
    return 0
  }
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return [area.downInnerRowIndex, x.visibleSheetRow.height ?? 0].min()!
}
