import { range } from '@salescore/buff-common'
import { mutation } from '@salescore/frontend-common'
import { t } from 'i18next'
import { useSetRecoilState } from 'recoil'

import { feedbackMessagesAtom } from '../../../../recoil/records/atoms'
import { upsertViewRecordsMutation } from '../../../../recoil/records/mutations/upsertViewRecordsMutation'
import { getActiveConditionalEffects } from '../../../components/body/cell/RsheetsCell'
import { getCellRelated } from '../../../util/getCellValue'
import { contextAtom } from '../../atoms'
import { cursorModel } from '../../models/cursorModel'
import { columnsModel, rowsModel } from '../../models/propModels'
import { cursorSelector } from '../../selectors/cursorSelector'
import { changeSystemFieldMutation } from '../changeSystemFieldMutation'

export const clickCurrentCursorMutation = mutation<{ pressedKey?: string }>({
  key: `clickCurrentCursorMutation`,
  // eslint-disable-next-line complexity
  set({ get, set }, { pressedKey }) {
    const { cursor } = get(cursorSelector)
    const rows = get(rowsModel)
    const columns = get(columnsModel)
    const context = get(contextAtom)
    if (cursor === undefined) {
      return
    }
    const { recordNode, value, column } = getCellRelated(columns, rows, cursor.main)

    if (column === undefined) {
      return
    }

    // checkbox系であれば、編集モードにするのではなく、直接値をtoggleする
    if (
      recordNode !== undefined &&
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      column !== undefined &&
      (column.type === 'checkbox' || column.type === 'rowCheckbox' || column.type === 'boolean')
    ) {
      const toggledValue = typeof value === 'boolean' ? !value : true
      if (cursor.expand !== undefined) {
        const up = Math.min(cursor.main.rowIndex, cursor.expand.rowIndex)
        const down = Math.max(cursor.main.rowIndex, cursor.expand.rowIndex)
        for (const rowIndex of range(up, down)) {
          set(changeSystemFieldMutation, {
            value: toggledValue,
            rowIndex,
            innerRowIndex: 0,
            column,
          })
        }
      } else if (column.isSystem === true) {
        set(changeSystemFieldMutation, {
          value: toggledValue,
          rowIndex: cursor.main.rowIndex,
          innerRowIndex: cursor.main.innerRowIndex,
          column,
        })
      } else {
        set(upsertViewRecordsMutation, {
          recordChanges: [
            {
              rowIndex: cursor.main.rowIndex,
              innerRowIndex: cursor.main.innerRowIndex,
              isNestedColumn: column.node.path.length > 1,
              nodePath: column.node.path,
              field: column.field!,
              value: toggledValue,
              label: undefined,
            },
          ],
        })
      }

      return
    }

    // レコードURLであれば、リンクを開く
    if (recordNode !== undefined && column.metaType === 'record_url' && typeof value === 'string') {
      window.open(value)
      return
    }

    // open系であればonChangeする
    if ((recordNode !== undefined && column.type === 'open') || column.type === 'kpi') {
      set(changeSystemFieldMutation, {
        value,
        rowIndex: cursor.main.rowIndex,
        innerRowIndex: cursor.main.innerRowIndex,
        column,
      })
      return
    }

    // NOTE: 権限による書き込み不可は、カーソル側(=isEditMode)で制御している
    // 書き込み禁止
    if (column.readonly === true) {
      set(feedbackMessagesAtom, [
        {
          message: context.asKpiTable === true ? t(`集計表示中は編集できません`) : t(`このカラムは書き込み禁止です`),
          type: 'warn' as const,
        },
      ])
      return
    }
    if (recordNode !== undefined) {
      const effects = getActiveConditionalEffects(column.conditionalEffects, recordNode, {})
      const disabled = effects.some((x) => x.effect === 'disable')
      if (disabled) {
        set(feedbackMessagesAtom, [
          {
            message: t(`このセルは書き込み禁止です`),
            type: `warn` as const,
          },
        ])
        return
      }
    }

    // 空セルかつchildrenの関係であれば、先にレコードを作成
    if (recordNode === undefined && (column.nodeType === 'children' || column.nodeType === 'child')) {
      // awaitが必要なので、useAsyncHandlekeyDown側で行う
      // 作成後、即編集モードにするので、returnせずにこのまま続ける
    }

    set(cursorModel, (oldValue) => {
      if (oldValue === undefined) {
        return
      }

      return {
        ...oldValue,
        contextMenuPosition: undefined,
        editing: {
          isEditing: !(oldValue.editing?.isEditing === true),
          initialPressedKey: pressedKey,
        },
      }
    })
  },
})

export const useClickCurrentCursorMutation = () => useSetRecoilState(clickCurrentCursorMutation)
