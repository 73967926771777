import { useMutation, useSubscription, useSuspenseQuery } from '@apollo/client'
import { isNull } from '@salescore/buff-common'
import {
  FetchImportButtonParamDocument,
  type FetchImportButtonParamQuery,
  SubscribeJobQueuesDocument,
  SyncContextEnum,
  SyncModelsDocument,
  type ViewFieldsFragment,
  type ViewSyncResult,
} from '@salescore/client-api'
import { Posthog, POSTHOG_EVENTS } from '@salescore/client-base'
import { getOrganizationIdFromPath, HandleQuery, ProviderLogo } from '@salescore/client-common'
import { ButtonWithTooltip } from '@salescore/frontend-common'
import { App, Badge, Button, List, Tooltip } from 'antd'
import dayjs from 'dayjs'
import { t } from 'i18next'
import { type ReactElement, type ReactNode, useState } from 'react'

import { useViewValue } from '../../../../recoil/view/hooks'

export const SubscribeJobQueues = ({
  jobQueueIds,
  onSuccess,
}: {
  jobQueueIds: string[]
  onSuccess: () => void
}): ReactElement => {
  const { message } = App.useApp()

  useSubscription(SubscribeJobQueuesDocument, {
    variables: {
      organizationId: getOrganizationIdFromPath(),
      jobQueueIds,
    },
    onError: () => {
      message.error(t('エラーが発生しました'))
    },
    onComplete: () => {
      message.success(t('同期が完了しました'))
      onSuccess()
    },
  })

  return <></>
}

// 現在時刻から最後にSyncのあった連携設定の時刻がどれぐらい前かの相対日付(文言)を返す
// その際にできるだけ上位の単位(月>週>日>時間>分)でまとめる (最小は0分前) e.g. ↓
// 24日前 -> 3週間前に更新
// 67日前 -> 2ヶ月前に更新

const getElapsedTimeText = (lastSyncedAt: string | undefined): string | undefined => {
  if (isNull(lastSyncedAt) || !dayjs(lastSyncedAt).isValid()) {
    return undefined
  }
  const currentDate = dayjs()
  const monthDiff = currentDate.diff(dayjs(lastSyncedAt), 'month')
  const weekDiff = currentDate.diff(dayjs(lastSyncedAt), 'week')
  const dayDiff = currentDate.diff(dayjs(lastSyncedAt), 'day')
  const hourDiff = currentDate.diff(dayjs(lastSyncedAt), 'hour')
  const minuteDiff = currentDate.diff(dayjs(lastSyncedAt), 'minute')
  if (monthDiff > 0) {
    return t(`{{monthDiff}}ヶ月前に更新`, { monthDiff })
  }
  if (weekDiff > 0) {
    return t(`{{weekDiff}}週間前に更新`, { weekDiff })
  }
  if (dayDiff > 0) {
    return t(`{{dayDiff}}日前に更新`, { dayDiff })
  }
  if (hourDiff > 0) {
    return t(`{{hourDiff}}時間前に更新`, { hourDiff })
  }
  return t(`{{minuteDiff}}分前に更新`, { minuteDiff })
}

const Body = ({
  relatedModels,
  buttonType,
  onSuccess,
  hasHubspot,
  oldestSyncTime,
  viewSyncResult,
}: {
  relatedModels: FetchImportButtonParamQuery['viewRelatedModels']
  hasHubspot: boolean
  oldestSyncTime: string | undefined
  viewSyncResult: ViewSyncResult
  buttonType?: 'default' | 'link'
  onSuccess?: () => void
}): ReactNode => {
  const organizationId = getOrganizationIdFromPath()
  const { modal, message } = App.useApp()
  const [jobQueueIds, setJobQueueIds] = useState<string[]>([])
  // custom objectのviewの場合は同期の必要はないので消せるのだが別でバグを踏んで操作不能になるより表示したほうが良いという判断
  const elapsedTimeText = getElapsedTimeText(oldestSyncTime) ?? t('同期する')
  const connectionModelPair = relatedModels.flatMap((c) =>
    c.eltModels.map((m) => ({
      connection: c.connection,
      eltModel: m,
    })),
  )
  const isInactiveSyncButton = connectionModelPair.isPresent() && connectionModelPair.every((x) => !x.connection.active)

  const [syncModelsMutation] = useMutation(SyncModelsDocument)

  const queueSyncModels = async (syncContext: SyncContextEnum): Promise<void> => {
    const connectionModels = relatedModels.map((it) => ({
      connectionId: it.connection.id,
      eltModelIds: it.eltModels.map((m) => m.id),
    }))
    await syncModelsMutation({
      variables: {
        connectionModels,
        organizationId,
        syncContext,
      },
      onCompleted(data) {
        void message.success(t('同期を開始しました'))
        const jobQueueIds = data.syncModels.map((jobQueue) => jobQueue.id)
        setJobQueueIds(jobQueueIds)
      },
    })
    Posthog.track('click-syncConnectionButton', {
      organizationId,
      connectionModels,
    })
  }

  return (
    <ButtonWithTooltip
      tooltipTitle={t('連携が停止されているため、同期を行うことができません。')}
      showTooltip={isInactiveSyncButton}
      disabled={isInactiveSyncButton}
      loading={!jobQueueIds.isEmpty()}
      type={buttonType ?? 'default'}
      onClick={() => {
        void modal.confirm({
          maskClosable: true,
          title: t(`内容を同期しますか?`),
          content: (
            <List
              className="max-h-[calc(100vh-300px)] overflow-y-scroll"
              dataSource={connectionModelPair.filter((x) => x.connection.active)}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={<ProviderLogo provider={item.connection.source.provider} width={48} />}
                    title={`${item.eltModel.model.label} (${getElapsedTimeText(item.eltModel.transformedAt ?? undefined)})`}
                    description={item.connection.source.name}
                  />
                </List.Item>
              )}
              split={false}
              footer={
                <>
                  {t(`1時間に1度自動的に更新しています。`)}
                  <br />
                  {t(`連携先サービス上で変更を行なっていない場合、実行の必要はありません。`)}
                  <br />
                  {t(`※変更中の項目は全てクリアされます`)}
                </>
              }
            >
              <></>
            </List>
          ),
          okText: t(`同期`),
          onOk: () => {
            void queueSyncModels(SyncContextEnum.ManualIncremental)
          },
          footer: (_, { CancelBtn, OkBtn }): ReactElement => (
            <>
              <CancelBtn />
              {hasHubspot && (
                <Button
                  type="primary"
                  onClick={() => {
                    void queueSyncModels(SyncContextEnum.ManualBulkSoftDelete)
                  }}
                >
                  {t(`削除差分を同期`)}
                </Button>
              )}
              <OkBtn />
            </>
          ),
        })
      }}
    >
      {!jobQueueIds.isEmpty() && (
        <SubscribeJobQueues
          jobQueueIds={jobQueueIds}
          onSuccess={() => {
            onSuccess?.()
            setJobQueueIds([])
          }}
        />
      )}
      {viewSyncResult.success ? (
        elapsedTimeText
      ) : (
        <Tooltip
          title={
            <div>
              {!viewSyncResult.failedEltModels.isEmpty() && t('以下のオブジェクトの同期が失敗しているため、')}
              {t(
                '表示されているデータが最新でない可能性があります。書き込みは可能です。同期が失敗していることを管理者にお問い合わせください。',
              )}
              <ul>
                {viewSyncResult.failedEltModels.map((value, index) => (
                  <li key={index}>{value.model.label}</li>
                ))}
              </ul>
            </div>
          }
        >
          <Badge dot>
            <span className={isInactiveSyncButton ? 'text-gray-300' : ''}>{elapsedTimeText}</span>
          </Badge>
        </Tooltip>
      )}
    </ButtonWithTooltip>
  )
}
const ButtonByViewId = ({
  viewId,
  onSuccess,
  buttonType,
}: {
  viewId: string
  onSuccess?: () => void
  buttonType?: 'default' | 'link'
}): ReactNode => {
  const {
    data: { viewHasHubspot, viewSyncResult, viewRelatedModels, viewOldestSyncTime },
    refetch,
  } = useSuspenseQuery(FetchImportButtonParamDocument, {
    variables: {
      organizationId: getOrganizationIdFromPath(),
      viewId,
    },
  })

  return (
    <Body
      relatedModels={viewRelatedModels}
      hasHubspot={viewHasHubspot}
      viewSyncResult={viewSyncResult}
      oldestSyncTime={viewOldestSyncTime ?? undefined}
      onSuccess={() => {
        onSuccess?.()
        void refetch()
      }}
      buttonType={buttonType}
    />
  )
}

const getFallbackViewId = (viewValue: ViewFieldsFragment): string | undefined => {
  // ここを通過するのはURLからviewIdが取得できなかったケースなので計測したい
  Posthog.track(POSTHOG_EVENTS.can_not_get_view_id, {
    message: 'no viewId in URL',
    locationHref: window.location.href,
    viewValueId: viewValue.id,
  })
  const uuidLength = 36
  const fallbackViewId = viewValue.id.slice(-uuidLength)
  const uuidRegexp = /([\da-f]{8})-([\da-f]{4})-([\da-f]{4})-([\da-f]{4})-([\da-f]{12})/
  if (fallbackViewId.length === uuidLength && uuidRegexp.test(fallbackViewId)) {
    return fallbackViewId
  }
  return undefined
}

const getViewIdFromUrlParameters = (): string | undefined =>
  new URLSearchParams(window.location.search).get('viewId') ?? undefined

export const ImportSourceRecordsButton = ({
  buttonType,
  onSuccess,
}: {
  buttonType?: 'default' | 'link'
  onSuccess?: () => void
}): ReactNode => {
  const viewValue: ViewFieldsFragment = useViewValue()

  const viewId = getViewIdFromUrlParameters() ?? getFallbackViewId(viewValue)
  if (viewId !== undefined) {
    return (
      <HandleQuery isSmallComponent={true}>
        <ButtonByViewId viewId={viewId} onSuccess={onSuccess} buttonType={buttonType} />
      </HandleQuery>
    )
  }

  // ここを通過するのはviewId が全く取得できなかったケースなので計測したい
  Posthog.track(POSTHOG_EVENTS.can_not_get_view_id, {
    message: 'no viewId',
    locationHref: window.location.href,
    viewValueId: viewValue.id,
  })
  return (
    <Body
      relatedModels={[]}
      onSuccess={onSuccess}
      buttonType={buttonType}
      hasHubspot={false}
      oldestSyncTime={undefined}
      viewSyncResult={{ success: true, failedEltModels: [] }}
    />
  )
}
