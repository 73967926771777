import { ButtonWithTooltip } from '@salescore/frontend-common'
import { t } from 'i18next'
import type { ReactNode } from 'react'

interface ViewUIRiFormCompleteButtonProperties {
  disabled: boolean
  errorMessage: string
  onClick: () => void | Promise<void>
}

export function ViewUIRiFormCompleteButton(properties: ViewUIRiFormCompleteButtonProperties): ReactNode {
  return (
    <ButtonWithTooltip
      type="primary"
      onClick={() => {
        void properties.onClick()
      }}
      disabled={properties.disabled}
      tooltipTitle={properties.errorMessage}
    >
      {t(`完了`)}
    </ButtonWithTooltip>
  )
}
