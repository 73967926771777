import { SettingOutlined } from '@ant-design/icons'
import { useSuspenseQuery } from '@apollo/client'
import { FetchViewsDocument } from '@salescore/client-api'
import { getOrganizationIdFromPath, routes } from '@salescore/client-base'
import { recoil } from '@salescore/client-recoil'
import { type CoreModel, isRequiredProperty, type ViewConfigForm, type ViewConfigUiComponent } from '@salescore/core'
import { ButtonWithTooltip, useRedirect } from '@salescore/frontend-common'
import { Button, type ModalFuncProps, Popconfirm, Spin } from 'antd'
import { t } from 'i18next'
import type React from 'react'
import { Suspense } from 'react'

import type { ViewAbility } from '../../../../recoil/view/atoms'
import { ViewComponent, type ViewsContext } from '../../../ViewComponent'
import { dynamicViewFields } from '../../../ViewDrillDownModal'

//
// モデルに紐づくフォームビューを開くモーダル
// 名前がややこしいが、いい名前が思いつかなかった… :bow:
//

interface ModalBuilderInput {
  onAfterFinish: (id?: string) => void
  onRedirectToSettings: () => void
  id?: string
  modelName: string
  viewAbility: ViewAbility
  viewsContext: ViewsContext
}

export const buildModelFormViewModal = (modalFunctionProperties: ModalBuilderInput): ModalFuncProps => ({
  width: 'min(90%, 1000px)',
  destroyOnClose: true,
  cancelText: t(`閉じる`),
  okButtonProps: { style: { display: 'none' } },
  content: (
    <Suspense fallback={<Spin />}>
      <Body {...modalFunctionProperties} />
    </Suspense>
  ),
  maskClosable: true,
})

function Body({
  id,
  modelName,
  viewAbility,
  viewsContext,
  onAfterFinish,
  onRedirectToSettings,
}: ModalBuilderInput): React.ReactElement {
  const featureAbility = recoil.global.policy.useCanForFeatures()
  const redirect = useRedirect()
  const { data } = useSuspenseQuery(FetchViewsDocument, {
    variables: {
      organizationId: getOrganizationIdFromPath(),
      type: 'form',
      modelName,
    },
  })
  const formView = data.views.first()
  // TODO: formビューをfetch
  // なかった場合は、モデルから生成する
  const { models } = viewsContext
  const model = models.find((x) => x.name === modelName)
  // ないことはありえないはず
  if (model === undefined) {
    return <>オブジェクトが見つかりません</>
  }
  // ビューに依存しない権限にした方が望ましいか？悩ましいが一旦これで
  if (!viewAbility.canCreateNewRecord) {
    return <div>{t(`権限がないため、新規レコードの作成はできません`)}</div>
  }
  const formConfig = formView?.config ?? convertModelToFormView(model)

  return (
    <div>
      {/* フォームUI側でPageHeader相当があるので、ここでのheaderは不要 */}
      <ViewComponent
        view={{
          ...dynamicViewFields,
          // id: CORE_CONSTANT.SHEET_FORM_DYNAMIC_VIEW_ID_PREFIX + (id ?? 'new-model'),
          id: formView?.id ?? ``,
          name: formView?.name ?? ``,
          config: formConfig,
          queries: [],
          ui: [],
        }}
        parameter={{
          recordId: id,
        }}
        onFinish={(properties) => {
          onAfterFinish(properties?.id)
        }}
        viewsContext={{
          ...viewsContext,
          shouldInitializeWithNewRecord: true,
          models: [...viewsContext.models],
        }}
      />
      <div className="mt-3 flex flex-row-reverse text-gray-500">
        {featureAbility.canManageConnection ? (
          <Popconfirm
            title={
              <div>
                設定画面に遷移します。
                <br />
                ここまでの変更が保存されていない場合は、保存されません。
                <br />
                遷移してよろしいですか？
              </div>
            }
            onConfirm={() => {
              redirect(routes.eltModelFormPathV2(model.name))
              onRedirectToSettings()
            }}
          >
            <Button icon={<SettingOutlined />} type="text" className="text-gray-500">
              表示設定のカスタマイズ
            </Button>
          </Popconfirm>
        ) : (
          <ButtonWithTooltip
            showTooltip
            tooltipTitle={
              <div>
                権限がないため、カスタマイズできません。
                <br />
                表示をカスタマイズしたい場合、組織管理者にご連絡ください。
              </div>
            }
            icon={<SettingOutlined />}
            type="text"
            className="text-gray-500"
            disabled
          >
            表示設定のカスタマイズ
          </ButtonWithTooltip>
        )}
      </div>
    </div>
  )
}

function convertModelToFormView(model: CoreModel): ViewConfigForm {
  const components = model.properties
    .map((property): ViewConfigUiComponent | undefined => {
      const isRequired = isRequiredProperty(property)
      if (!isRequired) {
        return undefined
      }
      return {
        componentType: `Col`,
        children: [
          {
            property: {
              nodeName: model.name,
              modelName: model.name,
              propertyName: property.name,
            },
          },
        ],
      }
    })
    .compact()

  return {
    type: 'form',
    tree: {
      type: 'model',
      name: model.name,
      modelName: model.name,
    },
    filterTree: {
      logicalOperator: 'and',
      children: [],
      leafs: [
        {
          type: 'property',
          filterType: 'equal',
          property: {
            nodeName: model.name,
            modelName: model.name,
            propertyName: 'id',
          },
          filterValueParameterName: 'recordId',
        },
      ],
    },
    components: [
      {
        componentType: `Row`,
        children: components,
      },
    ],
  }
}
