import { PlusOutlined } from '@ant-design/icons'
import { isNull, isSome } from '@salescore/buff-common'
import { ButtonWithTooltip, isSharedLinkPresetName, NotionLikeSelector, useMessage } from '@salescore/frontend-common'
import { Button } from 'antd'
import { t } from 'i18next'
import { useEffect } from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'

import { useNavigationModal, useSheetPickedPresetName } from '../../../../recoil/navigation/hooks'
import { useChangesValue } from '../../../../recoil/records/hooks'
import {
  configAtom,
  hasChangeToViewSchemaAtom,
  isSavingCurrentViewAtom,
  isSavingWithoutInteractionAtom,
} from '../../../../recoil/view/atoms'
import { useViewAbilityValue } from '../../../../recoil/view/hooks'
import { useCachedViewsSelector } from '../../../../recoil/view/selectors/cachedViewSelector'

export function SheetPresetsPicker() {
  const { sheetPresetFormModal } = useNavigationModal()

  const [config, setConfig] = useRecoilState(configAtom)
  const [hasChangeToViewSchema, setHasChangeToViewSchema] = useRecoilState(hasChangeToViewSchemaAtom)
  const [, setIsSavingCurrentView] = useRecoilState(isSavingCurrentViewAtom)
  const setIsSavingWithoutInteraction = useSetRecoilState(isSavingWithoutInteractionAtom)
  const [picked, setPicked] = useSheetPickedPresetName()
  const { currentCachedView } = useCachedViewsSelector()
  const ability = useViewAbilityValue()
  const changes = useChangesValue()
  const message = useMessage()

  useEffect(() => {
    // プリセットを選択しているキャッシュが存在する場合は何もしない
    // sheetPickedPresetNameへの値のセット自体はinitializeStateで行なっている
    if (isSome(currentCachedView?.sheetPickedPresetName)) {
      return
    }

    // 初期表示時は先頭のプリセットをデフォルト設定とする
    if (isNull(picked) && config.type === 'sheet' && config.presets?.[0] !== undefined) {
      select(config.presets[0].name)
    }
  }, [])

  function select(presetName: string) {
    setConfig((previous) => {
      if (previous.type !== 'sheet' || previous.presets === undefined) {
        return previous
      }

      const pickedPreset = previous.presets.find((x) => x.name === presetName)
      return {
        ...previous,
        tree: pickedPreset?.tree ?? previous.tree,
        fields: pickedPreset?.fields ?? previous.fields,
        // 以下propertyは未設定の場合undefinedになりうるためprevで上書きしない
        filterTree: pickedPreset?.filterTree,
        sorters: pickedPreset?.sorters,
        meta: pickedPreset?.meta,
        // プリセットを選択した時点で共有リンクプリセットを消した方が良いかもしれない
        // presets: previous.presets.filter(x => !isSharedLinkPresetName(x.name)),
      }
    })
    setPicked(presetName)
    setIsSavingWithoutInteraction(true)
  }

  if (config.type !== 'sheet') {
    return null
  }

  if (config.presets === undefined || config.presets.length === 0) {
    return (
      <ButtonWithTooltip
        type="text"
        disabled={!ability.canUpdate}
        showTooltip={!ability.canUpdate}
        tooltipTitle={t(`権限がありません`)}
        icon={<PlusOutlined />}
        onClick={() => {
          sheetPresetFormModal.showModal({ preset: undefined })
        }}
      >
        {t(`プリセットの新規作成`)}
      </ButtonWithTooltip>
    )
  }

  return (
    <>
      <NotionLikeSelector
        value={picked ?? config.presets[0]?.name}
        options={config.presets.map((x) => ({ value: x.name, label: x.name }))}
        placeholder={t(`プリセット`)}
        additionText={t(`プリセットの新規作成`)}
        uneditable={isSharedLinkPresetName}
        onAddButtonClick={() => {
          sheetPresetFormModal.showModal({ preset: undefined })
        }}
        setValue={(value) => {
          if (changes.length > 0 || hasChangeToViewSchema) {
            const messageKey = 'select-preset-warning'
            message.warning({
              key: messageKey,
              content: (
                <span>
                  {t(`変更差分がある場合はプリセットの変更により差分が削除される可能性があります。`)}
                  <br />
                  {t(`プリセットを変更しますか？`)}
                  <Button
                    className="ml-2"
                    onClick={() => {
                      select(value)
                      message.destroy(messageKey)
                    }}
                  >
                    {t(`はい`)}
                  </Button>
                </span>
              ),
              duration: 3,
            })
          } else {
            select(value)
          }
        }}
        onSort={
          ability.canUpdate
            ? (values: string[]) => {
                const sortedPresets = values.map((value) => config.presets?.find((x) => x.name === value)).compact()
                setConfig({
                  ...config,
                  presets: sortedPresets,
                })
                // ダッシュボードと仕様を合わせるため「設定を保存」ボタン押下をskipし並び替えた直後に保存する
                setIsSavingCurrentView(true)
              }
            : undefined
        }
        onEdit={
          ability.canUpdate
            ? (value) => {
                const preset = config.presets?.find((x) => x.name === value)
                sheetPresetFormModal.showModal({ preset })
              }
            : undefined
        }
        onDestroy={
          ability.canUpdate
            ? (value: string) => {
                setConfig((previous) => {
                  if (previous.type !== 'sheet' || previous.presets === undefined) {
                    return previous
                  }
                  const newPresets = previous.presets.filter((p) => p.name !== value)
                  const firstPreset = newPresets[0]
                  if ((isSome(picked) && picked !== value) || firstPreset === undefined) {
                    return {
                      ...previous,
                      presets: newPresets,
                    }
                  }
                  // 削除対象が選択中のプリセットの場合は先頭のプリセットを選択する
                  return {
                    ...previous,
                    tree: firstPreset.tree ?? previous.tree,
                    fields: firstPreset.fields ?? previous.fields,
                    // 以下propertyは未設定の場合undefinedになりうるためprevで上書きしない
                    filterTree: firstPreset.filterTree,
                    sorters: firstPreset.sorters,
                    meta: firstPreset.meta,
                    presets: newPresets,
                  }
                })
                if (picked === value) {
                  setPicked(undefined)
                }
                setHasChangeToViewSchema(true)
              }
            : undefined
        }
        disableAddition={!ability.canUpdate}
        showAddition={true}
        className="mr-2"
        buttonProps={{ type: 'text' }}
      />
    </>
  )
}
