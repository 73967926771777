import { useScroll } from '@salescore/frontend-common'
import { useMemo } from 'react'

import { useRowHeightState } from '../../recoil/navigation/hooks'
import { useRootRefValue as useRootReferenceValue } from '../recoil/models/propModels'
import { useColumnsRelatedSelector } from '../recoil/selectors/columnRelatedSelector'
import { useRowsRelatedSelector } from '../recoil/selectors/rowsRelatedSelector'

interface RenderingOffset {
  offsetTop: number // top から何行分を上にレンダリングしておくか
  offsetBottom: number // top から何行分を下にレンダリングしておくか
  offsetLeft: number // left から何列分を左にレンダリングしておくか
  offsetRight: number // left から何列分を右にレンダリングしておくか
}

function calcRenderingOffsets(numberColumns: number): RenderingOffset {
  // 何列表示できるかを計算
  return numberColumns < 20
    ? {
        offsetTop: -10,
        offsetBottom: 40,
        offsetLeft: -20,
        offsetRight: 20,
      }
    : numberColumns < 50
      ? {
          offsetTop: -7,
          offsetBottom: 30,
          offsetLeft: -15,
          offsetRight: 20,
        }
      : numberColumns < 70
        ? {
            offsetTop: -3,
            offsetBottom: 22,
            offsetLeft: -10,
            offsetRight: 20,
          }
        : {
            offsetTop: 0,
            offsetBottom: 17,
            offsetLeft: -5,
            offsetRight: 20,
          }
}

export function useVirtualizedBody() {
  const rootReference = useRootReferenceValue() // rsheet div の参照
  const { rowHeights } = useRowsRelatedSelector() // 子レコードを合わせた行の高さ
  const { rowHeight } = useRowHeightState() // 1 行あたりの高さ
  const { leftFixedColumns, notFixedColumns } = useColumnsRelatedSelector()
  const scrollState = useScroll(rootReference) // rsheet div のスクロールの状態 { x: number, y: number }
  const cumulativeHeights = useMemo(
    () =>
      // eslint-disable-next-line unicorn/no-array-reduce
      rowHeights.reduce(
        (accumulator, height) => {
          const lastValue = accumulator.last() ?? 0
          accumulator.push(lastValue + height * rowHeight)
          return accumulator
        },
        [0],
      ),
    [rowHeights, rowHeight],
  )
  const { offsetTop, offsetBottom, offsetLeft, offsetRight } = calcRenderingOffsets(
    leftFixedColumns.length + notFixedColumns.length,
  )
  const renderingRowsTopIndex = calcRenderingIndex(cumulativeHeights, scrollState.y, offsetTop) // レンダリングする最初の行のインデックス
  const bodyHeight = cumulativeHeights.last()!
  const cumulativeWidths = useMemo(
    () =>
      // eslint-disable-next-line unicorn/no-array-reduce
      notFixedColumns.reduce(
        (accumulator, column) => {
          const lastValue = accumulator.last() ?? 0
          accumulator.push(lastValue + (column.width ?? 300))
          return accumulator
        },
        [0],
      ),
    [notFixedColumns],
  )
  const renderingColumnsLeftIndex = calcRenderingIndex(cumulativeWidths, scrollState.x, offsetLeft) // レンダリングする最初の列のインデックス
  const renderingColumnsLeft = cumulativeWidths[renderingColumnsLeftIndex]!
  const renderingColumnsRightIndex = calcRenderingIndex(cumulativeWidths, scrollState.x, offsetRight) // レンダリングする最後の列のインデックス

  const renderingRowsTop = cumulativeHeights[renderingRowsTopIndex]!
  const renderingRowsBottomIndex = Math.min(renderingRowsTopIndex - offsetTop + offsetBottom, rowHeights.length) // レンダリングする最後の行のインデックス TODO: ちゃんと直す
  const renderingRowsHeight = cumulativeHeights[renderingRowsBottomIndex]! - renderingRowsTop

  return {
    bodyHeight,
    renderingRowsTop,
    renderingRowsTopIndex,
    renderingRowsBottomIndex,
    renderingRowsBottom: bodyHeight - renderingRowsTop - renderingRowsHeight,
    renderingColumnsLeftIndex,
    renderingColumnsLeft,
    renderingColumnsRightIndex,
  }
}

function calcRenderingIndex(cumulativeValues: number[], scroll: number, offset: number): number {
  const newIndexRaw = cumulativeValues.findIndex((cumulativeValue) => cumulativeValue > scroll)
  const newIndex = newIndexRaw === -1 ? cumulativeValues.length - 1 : newIndexRaw - 1
  return Math.max(newIndex + offset, 0)
}
