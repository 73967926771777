import { r } from '@salescore/buff-common'
import type { NodePath, ViewQueryNode, ViewQueryRecordNode, ViewQueryTableNode } from '@salescore/core'
import { t } from 'i18next'
import type { SetRecoilState } from 'recoil'

import { rowConfigAtomFamily } from '../../../../rsheet/recoil/atoms'
import { generateNewRecordId, getInnerRecordNode, getRecordNodeFromRootNode } from '../../../../state/nodeUtil'
import type { ViewRecordFieldChange } from '../../../../state/useViewRecordsState/useChangesState'
import { feedbackMessagesAtom, recordAtomFamily } from '../../atoms'
import type { NewRecordChange, OnAfterInsert } from '../addNewRecords'
import { updateHeight, updateInnerRow } from '../upsertViewRecords'

export function addNewChildRecord(
  rootRecordNodes: ViewQueryRecordNode[],
  newRecordChange: NewRecordChange,
  set: SetRecoilState,
  onAfterInsert?: OnAfterInsert,
) {
  const { rowIndex, innerRowIndex, node } = newRecordChange

  if (node.path.length === 1) {
    // 親レコードは別操作で行なっているはず
    return // TODO
  }

  // ルートレコードを求める
  const rootRecordNode = rootRecordNodes[rowIndex]
  if (rootRecordNode === undefined) {
    return // TODO
  }
  // const newRootRecordNode = r(rootRecordNode).clone().data
  const newRootRecordNode = rootRecordNode

  // 新規レコードを挿入するRecordNodeの配列を求める
  const { recordNodes, parentRecord } = getChildRecordsWithParent(newRootRecordNode, newRecordChange, set) ?? {}
  if (recordNodes === undefined) {
    return // TODO
  }

  // 新規レコードを生成
  const recordNode = generateNewChildRecord(node, parentRecord?.id).newRecord

  // 新規レコードを挿入
  const insertIndex = recordNodes.findIndex(
    (x) => x.meta.innerRowIndexStart <= innerRowIndex && innerRowIndex < x.meta.innerRowIndexEnd,
  )
  recordNodes.splice(insertIndex + 1, 0, recordNode)

  // メタ情報を更新
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (newRootRecordNode !== undefined) {
    // 挿入後なので、ないことはありえないが一応チェック
    // heightを参照渡しで更新
    updateHeight(newRootRecordNode)
    // innerStartIndexを参照渡しで更新
    updateInnerRow(newRootRecordNode)
  }

  if (onAfterInsert !== undefined) {
    onAfterInsert(recordNode) // このrecordNodeは、メタ情報が更新されている
  }

  // familyを更新
  set(recordAtomFamily({ id: newRootRecordNode.id! }), r(newRootRecordNode).clone().data) // 複製して渡さないとfreezeされてしまい、後続の関数で編集できなくなる
  set(rowConfigAtomFamily({ id: newRootRecordNode.id! }), (x) => ({
    ...x,
    expanded: true,
  }))

  // 変更差分を生成
  return {
    recordNode,
    change: generateNewChildRecordChange(rootRecordNode, node, recordNode, parentRecord),
  }
}

export function getChildRecordsWithParent(
  rootRecordNode: ViewQueryRecordNode,
  { innerRowIndex, node }: { innerRowIndex: number; node: ViewQueryNode },
  set: SetRecoilState,
) {
  if (node.write?.streamName === undefined) {
    set(feedbackMessagesAtom, [
      {
        message: t(`このブロックは書き込み禁止です。`),
        type: 'warn' as const,
      },
    ])
    return // TODO
  }

  if (node.write.parentIdColumn === undefined) {
    // TODO: ViewTree上での子ノードが、DB上での親テーブルに相当するときの分岐
    // このケースの場合は、親テーブルのレコードを新規作成した上で、そのIDをViewTree上での親ノードの参照IDに更新すべきだが、現状これが行えないため、なにも行わない形にする
    set(feedbackMessagesAtom, [
      {
        message: t(`参照先を新規作成することはできません`),
        type: 'warn' as const,
      },
    ])
    return // TODO
  }

  // 親のchildrenに対して、レコードを追加する
  const parentRecord = getParentRecord(rootRecordNode, node.path, innerRowIndex)
  if (parentRecord === undefined) {
    // TODO: そもそもこの条件になるセルは書き込み禁止にする
    set(feedbackMessagesAtom, [
      {
        message: t(`親レコードが存在しません。先に親レコードを作成してください。`),
        type: 'warn' as const,
      },
    ])
    return
  }
  // if (parentRecord.id === undefined || parentRecord.id?.startsWith(VIEW_NEW_RECORD_PREFIX)) {
  //   set(feedbackMessagesAtom, [
  //     {
  //       message: `レコードを保存してから、子レコードを作成してください。`,
  //       type: 'warn' as const,
  //     },
  //   ])
  //   return undefined
  // }
  const currentNodeName = node.path.last() ?? ''
  const parentTableRecord = parentRecord.children.find((x) => x.nodeName === currentNodeName)
  if (parentTableRecord === undefined) {
    // なかったら作成する
    const newParentTableRecord: ViewQueryTableNode = {
      nodeName: currentNodeName,
      children: [],
    }
    parentRecord.children.push(newParentTableRecord)
    return {
      parentRecord,
      recordNodes: newParentTableRecord.children,
    }
  }

  return {
    parentRecord,
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    recordNodes: parentTableRecord.children ?? [],
  }
}

function getParentRecord(rootRecordNode: ViewQueryRecordNode, nodePath: NodePath, innerRowIndex: number) {
  // 先に、親レコードのチェックを行う
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (rootRecordNode === undefined) {
    throw new Error(`rootRecordNode not found`)
  }
  if (nodePath.length === 1) {
    throw new Error(`parent of rootRecordNode must not be exist`)
  }

  const parentDepthRecords = getRecordNodeFromRootNode(rootRecordNode, nodePath.slice(0, -1))

  if (parentDepthRecords === undefined) {
    throw new Error(`parentDepthRecords must be exist`)
  }
  if (!Array.isArray(parentDepthRecords)) {
    if (nodePath.length !== 2) {
      throw new Error(`parentDepthRecords must be array`)
    }
    return parentDepthRecords
  }

  return getInnerRecordNode(parentDepthRecords, innerRowIndex)
}

export function generateNewChildRecord(node: ViewQueryNode, parentRecordId: string | undefined) {
  // changesの生成
  const relationColumn =
    node.read.join?.joinOn.type === 'sql' ? undefined : node.read.join?.joinOn.meta.currentNodePropertyName

  // const defaultAttributes = getDefaultAttributes(node, view) // デフォルト系は別途求める
  // レコードの作成
  const id = generateNewRecordId()
  const newRecord: ViewQueryRecordNode = {
    id,
    attributes: {
      // ...defaultAttributes.recordAttributes,
      // ...attributes,
      ...(relationColumn !== undefined && parentRecordId !== undefined
        ? {
            // relationColumnは厳密にはattributesでないのでrecordNodeに含める必要はないが、changesのみに存在するのが分かりづらいためいれておく。
            // サーバーサイドのレスポンスも同様になっている。
            [`${node.name}_${relationColumn}`]: parentRecordId,
          }
        : {}),
    },
    children: [],
    meta: {
      height: 1,
      innerRowIndexStart: 0, // TODO
      innerRowIndexEnd: 1,
    },
  }
  const changes: ViewRecordFieldChange[] = [
    {
      id,
      streamName: node.write!.streamName,
      fieldChanges: [],
      // recordNameLabel,
      before: {},
      after: {
        [relationColumn!]: parentRecordId,
      },
    },
  ]

  return {
    newRecord,
    changes,
  }
}

function generateNewChildRecordChange(
  rootRecordNode: ViewQueryRecordNode,
  node: ViewQueryNode,
  recordNode: ViewQueryRecordNode,
  parentRecord: ViewQueryRecordNode | undefined,
): ViewRecordFieldChange | undefined {
  const joinOn = node.read.join?.joinOn
  if (joinOn === undefined || joinOn.type === 'sql') {
    return
  }
  const relationColumn = joinOn.meta.currentNodePropertyName
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (node.write?.streamName === undefined || relationColumn === undefined || parentRecord === undefined) {
    return
  }

  return {
    rootNodeId: rootRecordNode.id,
    id: recordNode.id,
    streamName: node.write.streamName,
    fieldChanges: [],
    before: {},
    after: {
      [relationColumn]: parentRecord.id,
    },
  }
}
