import {
  BankOutlined,
  BarChartOutlined,
  BranchesOutlined,
  CloseCircleOutlined,
  DoubleRightOutlined,
  ExclamationCircleOutlined,
  FlagOutlined,
  HomeOutlined,
  LockOutlined,
  LogoutOutlined,
  MailOutlined,
  PartitionOutlined,
  QuestionCircleOutlined,
  SyncOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { useQuery } from '@apollo/client'
import { FetchSourcesTableRecordsDocument } from '@salescore/client-api'
import { CONSTANT, Posthog, POSTHOG_EVENTS, routes } from '@salescore/client-base'
import { handleMe, Logo, SiderOrganizationV2, SiderUserV2 } from '@salescore/client-common'
import { SelectUser } from '@salescore/client-feature-setting'
import { checkVisualizeEnabled, recoil } from '@salescore/client-recoil'
import { useModal, useRedirect } from '@salescore/frontend-common'
import { Layout, Menu, type MenuProps, Modal, Space } from 'antd'
import { Array } from 'effect'
import Link from 'next/link'
import { useRouter } from 'next/router'
import type { ReactNode } from 'react'
import { Trans } from 'react-i18next'

type ItemType = Required<MenuProps>['items'][number]

const Divider = (): ReactNode => <hr className="m-0 bg-white opacity-30"></hr>

const SourceSettingsLabel = ({ organizationId }: { organizationId: string }): ReactNode => {
  const { data } = useQuery(FetchSourcesTableRecordsDocument, {
    variables: {
      organizationId,
    },
    fetchPolicy: 'cache-and-network',
  })
  const hasError = data?.sourcesTableRecords.some((c) => c.hasError) ?? false
  const hasChange =
    data?.sourcesTableRecords.some((record) => record.connection.eltModels.some((p) => p.eltModelChange !== null)) ??
    false

  return hasError ? (
    <Space>
      <Trans>連携設定</Trans>
      {hasChange && <ExclamationCircleOutlined className="text-yellow-500" />}
      {<CloseCircleOutlined className="text-red-500" />}
    </Space>
  ) : (
    <Trans>連携設定</Trans>
  )
}

export function SiderV2(): ReactNode {
  const selectUserModal = useModal()
  const redirect = useRedirect()
  const router = useRouter()
  const ability = recoil.global.policy.useCanForFeatures()

  return handleMe(({ myUser, myIdentity, organization }) => {
    const isAdmin = myUser.role === 'admin'
    const items: ItemType[] = []
    items.push(
      {
        label: <Trans>ホーム</Trans>,
        key: '/o/[organizationId]/v2',
        onClick: () => {
          redirect(routes.topPathV2())
        },
        icon: <HomeOutlined />,
      },
      {
        label: <Trans>個人設定</Trans>,
        key: '/o/[organizationId]/v2/settings/me',
        icon: <UserOutlined />,
        onClick: () => {
          redirect(routes.mePathV2())
        },
      },
    )

    if (ability.canManageOrganizationSetting) {
      items.push({
        label: <Trans>組織設定</Trans>,
        key: '/o/[organizationId]/v2/settings',
        icon: <BankOutlined />,
        onClick: () => {
          redirect(routes.organizationSettingsPathV2())
        },
      })
    }
    if (ability.canManageConnection) {
      items.push({
        label: <SourceSettingsLabel organizationId={organization.id} />,
        key: '/o/[organizationId]/v2/settings/sources',
        icon: <BranchesOutlined />,
        onClick: () => {
          redirect(routes.sourceSettingsPathV2())
        },
      })
      // items.push({
      //   label: <Trans>オブジェクト一覧</Trans>,
      //   key: '/o/[organizationId]/v2/settings/elt_models',
      //   icon: <AppstoreOutlined />,
      //   onClick: () => {
      //     redirect(routes.eltModelsSettingsPathV2())
      //   },
      // })
    }

    const userRelatedItems: ItemType[] = []
    if (ability.canManageUsers) {
      userRelatedItems.push({
        label: <Trans>ユーザー一覧</Trans>,
        key: '/o/[organizationId]/v2/settings/users',
        icon: <UserOutlined />,
        onClick: () => {
          redirect(routes.usersSettingsPathV2())
        },
      })
    }
    if (ability.canManageUserGroups) {
      userRelatedItems.push({
        label: <Trans>グループ一覧</Trans>,
        key: '/o/[organizationId]/v2/settings/user_groups',
        icon: <TeamOutlined />,
        onClick: () => {
          redirect(routes.userGroupsSettingsPathV2())
        },
      })
    }
    if (ability.canManageInvitation) {
      userRelatedItems.push({
        label: <Trans>ユーザー招待</Trans>,
        key: '/o/[organizationId]/v2/settings/invitations',
        icon: <MailOutlined />,
        onClick: () => {
          redirect(routes.invitedUsersSettingsPathV2())
        },
      })
    }
    if (ability.canManagePolicy) {
      userRelatedItems.push({
        label: <Trans>権限セット一覧</Trans>,
        key: '/o/[organizationId]/v2/settings/policies',
        icon: <LockOutlined />,
        onClick: () => {
          redirect(routes.policiesSettingPath())
        },
      })
    }
    if (Array.isNonEmptyArray(userRelatedItems)) {
      items.push({
        label: <Trans>ユーザー設定</Trans>,
        key: `usersAndGroups`,
        icon: <TeamOutlined />,
        children: userRelatedItems,
      })
    }

    const goalRelatedItems: ItemType[] = []
    if (ability.canManageGoal) {
      goalRelatedItems.push(
        {
          label: <Trans>目標設定</Trans>,
          key: '/o/[organizationId]/v2/settings/goal_configs',
          icon: <FlagOutlined />,
          onClick: () => {
            redirect(routes.goalConfigsPathV2())
          },
        },
        // 2023/01/25 サイドバーには不要なのでは？という話をした。
        // {
        //   label: '目標カテゴリ',
        //   key: '/o/[organizationId]/v2/settings/goal_dimensions',
        //   icon: <PartitionOutlined />,
        //   onClick: () => {
        //     redirect(routes.goalDimensionsPathV2())
        //   },
        // },
      )
    }
    if (ability.canManageDimensionGroup) {
      goalRelatedItems.push({
        label: <Trans>軸グループ設定</Trans>,
        key: '/o/[organizationId]/v2/settings/dimension_groups',
        icon: <PartitionOutlined />,
        onClick: () => {
          redirect(routes.dimensionGroupsPathV2())
        },
      })
    }
    if (Array.isNonEmptyArray(goalRelatedItems) && checkVisualizeEnabled(myUser)) {
      items.push({
        label: <Trans>目標と集計軸</Trans>,
        key: `kpi`,
        icon: <FlagOutlined />,
        children: goalRelatedItems,
      })
    }

    if (ability.canReadUserActivity) {
      items.push({
        label: <Trans>利用状況</Trans>,
        key: '/o/[organizationId]/v2/settings/user_activities',
        icon: <BarChartOutlined />,
        onClick: () => {
          redirect(routes.userActivitiesPathV2())
        },
      })
    }

    if (isAdmin) {
      items.push({
        label: <Trans>R-ELT設定</Trans>,
        key: '/o/[organizationId]/v2/settings/reverse_elts',
        icon: <SyncOutlined />,
        onClick: () => {
          redirect(routes.reverseEltsPath())
        },
      })
    }

    items.push(
      {
        label: <Trans>ヘルプ</Trans>,
        key: '/help',
        icon: <QuestionCircleOutlined />,
        onClick: () => {
          Posthog.track(POSTHOG_EVENTS.click_help, {})
          window.open(CONSTANT.link.helpV2)
        },
      },
      {
        label: <Trans>ログアウト</Trans>,
        key: '/sign_out',
        onClick: () => {
          redirect('/sign_out')
        },
        icon: <LogoutOutlined />,
      },
    )

    if (myIdentity.users.length > 5) {
      items.push({
        label: <Trans>他の組織へ</Trans>,
        key: '/other_organization',
        icon: <DoubleRightOutlined />,
        onClick: () => {
          selectUserModal.showModal()
        },
      })
    }

    return (
      <Layout.Sider
        theme="light"
        style={{ fontWeight: 'bold', height: '100vh' }}
        className="dispaly-none-print overflow-hidden"
      >
        <div className="px-4 py-5 text-center">
          <Link href={routes.topPathV2()}>
            <Logo type="black" />
          </Link>
        </div>
        <Divider />
        <Menu
          items={items}
          defaultSelectedKeys={[router.pathname]}
          selectedKeys={[router.pathname]}
          mode="inline"
          theme="light"
          style={{
            paddingTop: 12,
            paddingBottom: 30,
            height: 'calc(100vh - 170px)',
            overflow: 'scroll',
          }}
        />

        {/* footer */}
        <div className="absolute z-10 w-full bg-white" style={{ bottom: 0 }}>
          <Divider />
          <SiderOrganizationV2 myUser={myUser} myIdentity={myIdentity} />
          <Divider />
          <SiderUserV2 myUser={myUser} />
        </div>

        <Modal
          visible={selectUserModal.isModalVisible}
          onCancel={selectUserModal.hideModal}
          width={1000}
          okButtonProps={{ style: { display: 'none' } }}
          cancelButtonProps={{ style: { display: 'none' } }}
          style={{ top: '3%' }}
        >
          <SelectUser myIdentity={myIdentity} />
        </Modal>
      </Layout.Sider>
    )
  })
}
