import { useSuspenseQuery } from '@apollo/client'
import { isNull, isPresent } from '@salescore/buff-common'
import type { ResourceUserWithGroup, ResourceUserWithGroupFieldsFragment } from '@salescore/client-api'
import { FetchResourceUsersWithGroupDocument } from '@salescore/client-api'
import {
  CORE_CONSTANT,
  getRecordNodesByPath,
  type ViewConfigDimensionFilter,
  type ViewConfigFilter,
  type ViewConfigKpiParameter,
  type ViewQueryField,
} from '@salescore/core'
import { Button, Checkbox, Divider, Empty, Input, message, Space } from 'antd'
import { t } from 'i18next'
import { useEffect, useMemo, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useRecoilState } from 'recoil'
import { z } from 'zod'

import { dimensionFilterOptionsWithCacheAtomFamily } from '../../../recoil/navigation/atoms'
import { useKpiPivotParameter } from '../../../recoil/navigation/hooks'
import { useRecordsValue } from '../../../recoil/records/hooks'
import { useMeValue } from '../../../recoil/view/hooks'
import { getLabeledFieldName } from '../../../state/util'

interface Option {
  label: string
  value: string | null // nullのときは所属なし(id指定なし)
  notIncludedInCurrentRecordValues?: boolean
}

const MAX_FILTER_COUNT = 100

export function DimensionFilterPicker({ field, hide }: { hide: () => void; field: ViewQueryField }) {
  const [kpiPivotParameter, setKpiPivotParameter] = useKpiPivotParameter()
  // fieldからdimensionを取得する
  // ややこしいが、KPIダッシュボード（＝ピボットテーブル）もシートと同じロジックをベースとしており、
  // すなわちRSheetを使っており、このコンポーネントに渡されるのはfield(ViewQueryField)の情報である
  // field.nameからdimensionを推測する
  const dimension = getDimension(field, kpiPivotParameter.pivot)
  const dimensionKey = [dimension?.key, dimension?.dateSpan].compact().join('_')
  const { options } = useOptions({
    // 選択肢関連の処理を行うカスタムフック
    dimensionKey,
    field,
    kpiPivotParameter,
  })

  if (dimension === undefined) {
    // ありえないはず
    return <>error</>
  }

  const currentLeaf = kpiPivotParameter.dimensionFilterLeafs?.find(
    (leaf) => leaf.type === 'dimension' && leaf.dimension.key === dimension.key,
  )
  // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion,@typescript-eslint/no-unnecessary-condition
  const selectedKeys = (currentLeaf?.filterValue as string[]) ?? []

  return (
    <FilterDropdown
      options={options}
      selectedKeys={selectedKeys}
      setSelectedKeys={(options) => {
        if (options.length > MAX_FILTER_COUNT) {
          // 10個以上選択することはできない
          void message.warning(t(`${MAX_FILTER_COUNT}個以上の選択はできません。`))
          return
        }
        // additionalConfigとして保存
        const leaf: ViewConfigFilter = {
          type: 'dimension' as const,
          dimension,
          filterValue: options.map((x) => x.value),
          filterValueLabel: options.map((x) => x.label).join(','),
          filterType: 'in',
        }
        setKpiPivotParameter((oldValue) => ({
          ...oldValue,
          dimensionFilterLeafs: [
            ...(oldValue.dimensionFilterLeafs?.filter(
              (x) => x.type !== 'dimension' || x.dimension.key !== dimension.key,
            ) ?? []),
            options.isPresent() ? leaf : undefined,
          ].compact(),
        }))
      }}
      confirm={() => {
        hide()
      }}
      clearFilters={() => {
        setKpiPivotParameter((oldValue) => ({
          ...oldValue,
          dimensionFilterLeafs:
            oldValue.dimensionFilterLeafs?.filter((x) => x.type !== 'dimension' || x.dimension.key !== dimension.key) ??
            [],
        }))
        hide()
      }}
    />
  )
}

function useOptions({
  field,
  dimensionKey,
  kpiPivotParameter,
}: {
  field: ViewQueryField
  dimensionKey: string
  kpiPivotParameter: ViewConfigKpiParameter
}) {
  const isUserOrUserGroupDimension = isUserOrUserGroupDimensionKey(dimensionKey)
  const me = useMeValue()

  // recordsの中に含まれるfieldの値を取得する
  // valueとlabelを取得するので、optionsと呼んでおく
  const records = useRecordsValue()
  const { optionsInCurrentRecords, mapper } = useMemo(() => {
    const targetRecords = records.flatMap((record) => getRecordNodesByPath(record, field.nodePath))
    const optionsInCurrentRecords: Option[] = targetRecords

      .map((record) => {
        const value = record.attributes[field.name]
        if (!isPresent(value) || typeof value !== 'string' || value === CORE_CONSTANT.KPI_PIVOT_TOTAL_STRING) {
          return
        }
        const label = record.attributes[getLabeledFieldName(field.name)] ?? value
        return {
          value,
          label: typeof label === 'string' ? label : JSON.stringify(label),
        }
      })
      .compact()
    const mapper = optionsInCurrentRecords.groupByUniqueKey((x) => x.value ?? CORE_CONSTANT.KPI_PIVOT_NULL_STRING)
    return {
      optionsInCurrentRecords,
      mapper,
    }
  }, [records, field])

  // ユーザー、ユーザーグループの時以外の処理
  // if分岐により使用するhooksが異なってしまっているが、isUserOrUserGroupDimensionの値は変わることがないのでこれで問題ない
  if (!isUserOrUserGroupDimension) {
    // 「一度表示した選択肢は、タブが切り替わらない限りは保持しておく」という挙動を実現するため、
    // optionsInCurrentRecordsの値をキャッシュする
    const [optionsWithCache, setOptionsWithCache] = useRecoilState(
      dimensionFilterOptionsWithCacheAtomFamily({ dimensionKey }),
    )
    useEffect(() => {
      setOptionsWithCache((oldValue) => [...oldValue, ...optionsInCurrentRecords].uniqueBy((x) => x.value))
    }, [optionsInCurrentRecords, dimensionKey])
    const defaultOptions = optionsWithCache
      .map((x) => ({
        ...x,
        notIncludedInCurrentRecordValues: mapper[x.value ?? CORE_CONSTANT.KPI_PIVOT_NULL_STRING] === undefined,
      }))
      .sortBy((x) => (x.notIncludedInCurrentRecordValues ? 1 : 0))
    return { options: defaultOptions }
  }

  // ユーザー、グループについての絞り込みであればユーザーとグループがそのまま選択肢となる
  // ただし、親の階層の軸フィルタを適用させる必要がある
  const { data } = useSuspenseQuery(FetchResourceUsersWithGroupDocument, {
    variables: {
      organizationId: me.organization.id,
    },
  })
  const users = data.resourceUsersWithGroup
  const options = useMemo((): Option[] => {
    const filters = (kpiPivotParameter.dimensionFilterLeafs ?? [])
      .map((x) => (x.type === 'dimension' ? x : undefined))
      .compact()
    const options = generateUserOrUserGroupOptions(users, dimensionKey, filters)
    return options
      .map((x) => ({
        ...x,
        notIncludedInCurrentRecordValues: mapper[x.value ?? CORE_CONSTANT.KPI_PIVOT_NULL_STRING] === undefined,
      }))
      .sortBy((x) => [x.notIncludedInCurrentRecordValues ? 1 : 0, x.maybeChecked ? 0 : 1])
  }, [users, optionsInCurrentRecords])
  return {
    options,
  }
}

function getDimension(field: ViewQueryField, pivot: ViewConfigKpiParameter['pivot']) {
  const rowIndex = Number(field.name.replace(CORE_CONSTANT.DIMENSION_FIELD_NAME_PREFIX, '')) - 1 // XXX: このように文字列に依存する実装は見通しが悪く、また破綻しそうなのでやめたい
  const row = pivot.rows[rowIndex]
  return row
}

interface FilterDropdownProperties {
  options: Option[]
  selectedKeys: string[]
  setSelectedKeys: (options: Option[]) => void
  confirm: () => void
  clearFilters: () => void
}

function FilterDropdown({ options, selectedKeys, setSelectedKeys, confirm, clearFilters }: FilterDropdownProperties) {
  const [pickedOptions, setPickedOptions] = useState(
    selectedKeys.map((key) => options.find((option) => option.value === key)).compact(),
  )
  const [searchKeyword, setSearchKeyword] = useState<string | null>(null)

  const filteredOptions = isPresent(searchKeyword)
    ? options.filter((option) => option.label.includes(searchKeyword))
    : options

  if (options.isBlank()) {
    return (
      <Empty
        description={
          <div>
            {t(`選択肢がありません。`)}
            <br />
            <Button
              className="my-3"
              onClick={() => {
                clearFilters()
              }}
            >
              {t(`クリア`)}
            </Button>
          </div>
        }
      />
    )
  }

  return (
    <div
      className="p-2"
      style={{
        maxWidth: 400,
        overflowX: 'auto',
      }}
    >
      <div className="py-1">
        <Input.Search
          placeholder={t(`検索`)}
          onKeyDown={(e) => {
            e.stopPropagation()
          }}
          onSearch={(keyword) => {
            setSearchKeyword(keyword)
          }}
        />
      </div>
      <Divider style={{ marginTop: 6, marginBottom: 6 }} />
      {pickedOptions.length === options.length ? (
        <Checkbox
          onChange={() => {
            setPickedOptions([])
          }}
          checked
        >
          {' '}
          {t(`全て解除`)}{' '}
        </Checkbox>
      ) : (
        <Checkbox
          onChange={() => {
            setPickedOptions(options)
          }}
          checked={false}
        >
          {' '}
          {t(`全て選択`)}{' '}
        </Checkbox>
      )}
      <Divider style={{ marginTop: 6, marginBottom: 6 }} />
      <div
        style={{
          maxHeight: 254,
          overflow: 'auto',
        }}
      >
        <VirtualizedList
          pagerKey={searchKeyword ?? CORE_CONSTANT.KPI_PIVOT_NULL_STRING}
          items={filteredOptions}
          render={(option) => (
            <>
              <Checkbox
                onChange={(value) => {
                  if (value.target.checked) {
                    setPickedOptions([...pickedOptions, option].uniqueBy((x) => x.value))
                  } else {
                    setPickedOptions([...pickedOptions].filter((x) => x.value !== option.value))
                  }
                }}
                checked={pickedOptions.map((x) => x.value).includes(option.value)}
                style={{
                  color: option.notIncludedInCurrentRecordValues === true ? '#999' : undefined,
                }}
              >
                {option.label}
              </Checkbox>
              <br />
            </>
          )}
        />
      </div>
      <hr className="m-0 my-1 opacity-20" />
      <Space className="mt-2 flex justify-end">
        <Button
          onClick={() => {
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (clearFilters !== undefined) {
              clearFilters()
            }
            setPickedOptions([])
            confirm()
          }}
          type="text"
          size="small"
        >
          {t(`リセット`)}
        </Button>
        <Button
          onClick={() => {
            setSelectedKeys(pickedOptions)
            confirm()
          }}
          type="primary"
          size="small"
        >
          {t(`適用`)}
        </Button>
      </Space>
    </div>
  )
}

const USER_OR_USER_GROUP_RELATED_DIMENSION_KEYS = [
  `p_salescore_user_groups_d1_id`,
  `p_salescore_user_groups_d2_id`,
  `p_salescore_user_groups_d3_id`,
  `p_salescore_user_groups_d4_id`,
  `p_salescore_user_groups_d5_id`,
  `salescore_users_id`,
]
const dimensionKeyToUserWithGroupPropertyName: Record<string, keyof ResourceUserWithGroupFieldsFragment> = {
  p_salescore_user_groups_d1_id: 'user_group_d1_id',
  p_salescore_user_groups_d2_id: 'user_group_d2_id',
  p_salescore_user_groups_d3_id: 'user_group_d3_id',
  p_salescore_user_groups_d4_id: 'user_group_d4_id',
  p_salescore_user_groups_d5_id: 'user_group_d5_id',
  salescore_users_id: 'id', // これは使われないはずだが一応
}

const dimensionKeyToUserWithGroupLabelPropertyName: Record<string, keyof ResourceUserWithGroupFieldsFragment> = {
  p_salescore_user_groups_d1_id: 'user_group_d1_name',
  p_salescore_user_groups_d2_id: 'user_group_d2_name',
  p_salescore_user_groups_d3_id: 'user_group_d3_name',
  p_salescore_user_groups_d4_id: 'user_group_d4_name',
  p_salescore_user_groups_d5_id: 'user_group_d5_name',
  salescore_users_id: 'name', // これは使われないはずだが一応
}

function isUserOrUserGroupDimensionKey(key: string) {
  return USER_OR_USER_GROUP_RELATED_DIMENSION_KEYS.includes(key)
}

function generateUserOrUserGroupOptions(
  users: ResourceUserWithGroup[],
  dimensionKey: string,
  filters: ViewConfigDimensionFilter[],
): Array<Option & { maybeChecked: boolean }> {
  if (!isUserOrUserGroupDimensionKey(dimensionKey)) {
    return []
  }
  const keyIndex = USER_OR_USER_GROUP_RELATED_DIMENSION_KEYS.indexOf(dimensionKey)
  if (keyIndex === -1) {
    return []
  }

  // 今対象としているdimensionのユーザー/グループよりも、上の階層のグループのフィルタを取得する
  // （例）「チーム」を対象としているときは、「部署」のフィルタを取得する。「ユーザー」「リードソース」のフィルタは無視する
  const targetFilters = filters.filter((x) => {
    if (!isUserOrUserGroupDimensionKey(x.dimension.key)) {
      return false
    }
    // 一応フィルタの中身もチェックしておく。ここでひっかかることはないはず
    if (!Array.isArray(x.filterValue) || x.filterType !== 'in') {
      return false
    }
    const filterDimensionKeyIndex = USER_OR_USER_GROUP_RELATED_DIMENSION_KEYS.indexOf(x.dimension.key)
    return filterDimensionKeyIndex < keyIndex
  })
  // フィルタを適用する
  const filteredUsers = users.filter((user) =>
    targetFilters.every((filter) => {
      const ids = z.string().array().safeParse(filter.filterValue)
      if (!ids.success) {
        return true
      }
      const property = dimensionKeyToUserWithGroupPropertyName[filter.dimension.key]!
      const id = user[property]!
      // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
      return ids.data.includes(id as string)
    }),
  )
  // 選択肢として返す
  const idProperty = dimensionKeyToUserWithGroupPropertyName[dimensionKey]!
  const labelProperty = dimensionKeyToUserWithGroupLabelPropertyName[dimensionKey]!
  const currentDimensionFilterValues = new Set(
    filters
      .filter((x) => x.dimension.key === dimensionKey)
      .flatMap((x) => {
        const validated = z.string().array().safeParse(x.filterValue)
        return validated.success ? validated.data : []
      }),
  )

  return filteredUsers
    .map((user) => {
      if (dimensionKey === `salescore_users_id`) {
        // 以下相当をバックエンド側で出しておきたいが、現状できていないのでここでやる
        const groupNames = [
          user.user_group_d1_name,
          user.user_group_d2_name,
          user.user_group_d3_name,
          user.user_group_d4_name,
          user.user_group_d5_name,
        ].compact()
        const groupNameLabel = groupNames.isPresent() ? `(${groupNames.last()!})` : ''
        return {
          value: user.id,
          label: [user.name, groupNameLabel].join(' '),
          // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
          maybeChecked: currentDimensionFilterValues.has(user[idProperty] as string),
        }
      }
      const value = user[idProperty]
      if (isNull(value)) {
        return {
          value: null,
          label: '所属なし',
          // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
          maybeChecked: currentDimensionFilterValues.has(user[idProperty] as string),
        }
      }
      if (typeof value !== 'string') {
        return
      }
      return {
        value,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
        label: user[labelProperty] as string,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
        maybeChecked: currentDimensionFilterValues.has(user[idProperty] as string),
      }
    })
    .compact()
    .uniqueBy((x) => x.value)
}

function VirtualizedList<T>({
  items,
  pageSize,
  pagerKey,
  render,
}: {
  items: T[]
  pageSize?: number
  pagerKey?: string
  render: (item: T) => React.ReactNode
}) {
  const [page, setPage] = useState(1)
  const size = pageSize ?? 50
  const { ref, inView } = useInView({ threshold: 0 })

  // footerがビューに入ったら追加読み込み
  useEffect(() => {
    if (!inView) {
      return
    }
    if (page * size < items.length) {
      setPage((x) => x + 1)
    }
  }, [inView, items.length, page * size, pagerKey])

  return (
    <div>
      {/* eslint-disable-next-line @typescript-eslint/promise-function-async */}
      {items.slice(0, page * size).map((item) => render(item))}
      <div ref={ref} className="virtualized-list-footer" style={{ height: 0 }} />
    </div>
  )
}
