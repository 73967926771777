import { DeleteOutlined } from '@ant-design/icons'
import type { ViewForSiderFieldsFragment } from '@salescore/client-api'
import {
  flatNodes,
  generateFieldName,
  makeIdentifiable,
  presetFunctions,
  type PresetMeasureFunctions,
  type ViewConfigField,
  type ViewConfigMeasure,
  type ViewConfigSheet,
} from '@salescore/core'
import { useBooleanState } from '@salescore/frontend-common'
import { Button, Empty, Form, Input, message, Popconfirm, Select, Space } from 'antd'
import { type FormInstance, useForm } from 'antd/es/form/Form'
import { t } from 'i18next'
import { useState } from 'react'
import { format } from 'sql-formatter'
import { v4 as uuidv4 } from 'uuid'

import { convertToFilterNodeForForm } from '../../../recoil/navigation/hooks'
import { isSameNodeProperty } from '../../../recoil/view/mutations/field/util'
import { useConnectionsSelector } from '../../../recoil/view/selectors/connectionsSelector'
import { MeasureCustomSqlEditor } from './MeasureCustomSqlEditor'
import { MeasureFilterForm } from './MeasureFilterForm'

export function MeasureForm({
  sheetView,
  field,
  measureName,
  onFinish,
}: {
  sheetView: Omit<ViewForSiderFieldsFragment, '__typename'> & { config: ViewConfigSheet }
  field: ViewConfigField
  measureName: string | undefined
  onFinish: (x: { sheetViewConfig?: ViewConfigSheet }) => void
}) {
  const [form] = useForm<MeasureFormValue>()
  const measureFormState = useMeasureFormState({
    sheetView,
    field,
    measureName,
  })
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (measureFormState === undefined) {
    return <Empty description={<span>{t(`ビューが見つかりませんでした`)}</span>} />
  }
  const { measure } = measureFormState
  const loading = useBooleanState()

  return (
    <Form<MeasureFormValue>
      form={form}
      // wrapperCol={{ span: 8 }}
      onKeyDown={(e) => {
        // シート側のkeyDownが発火しないように必要
        e.stopPropagation()
      }}
      onFinish={(value) => {
        try {
          loading.setTrue()
          const result = measureFormState.onFinish(value)
          if (!result.success) {
            void message.error(result.message)
            return
          }
          onFinish({
            sheetViewConfig: result.config,
          })
          void message.success(t(`設定を保存しました。`))
        } catch (error) {
          if (error instanceof Error) {
            void message.error(error.message)
          } else {
            void message.error(t(`エラーが発生しました。`))
          }
        } finally {
          loading.setFalse()
        }
      }}
      initialValues={{
        ...measure,
        function: measure?.type === 'custom' ? 'custom' : measure?.function,
      }}
    >
      <MeasureFormItems form={form} measureFormState={measureFormState} />
      <Space className="flex flex-row-reverse">
        <Form.Item>
          <Button htmlType="submit" loading={loading.isTrue}>
            {t(`適用`)}
          </Button>
        </Form.Item>
        {measureName !== undefined && (
          <Form.Item>
            <Popconfirm
              title={t(`本当に削除しますか?`)}
              onConfirm={() => {
                try {
                  const sheetViewConfig = removeMeasure(sheetView.config, field, measureName)
                  void message.success(t(`集計を削除しました。`))
                  onFinish({
                    sheetViewConfig,
                  })
                } catch (error) {
                  if (error instanceof Error) {
                    void message.error(`${t(`削除中にエラーが発生しました。`)}${error.message}`)
                  } else {
                    void message.error(t(`削除中にエラーが発生しました。`))
                  }
                }
              }}
              okText={t(`はい`)}
              cancelText={t(`いいえ`)}
            >
              <Button danger icon={<DeleteOutlined />}>
                {t(`削除`)}
              </Button>
            </Popconfirm>
          </Form.Item>
        )}
      </Space>
    </Form>
  )
}

function MeasureFormItems({
  form,
  measureFormState,
}: {
  form: FormInstance<MeasureFormValue>
  measureFormState: ReturnType<typeof useMeasureFormState>
}) {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (measureFormState === undefined) {
    // ありえないはず
    return <></>
  }

  const { setFormState, field, formState, availablePresetFunctions, measure } = measureFormState
  return (
    <>
      <Form.Item name={'function'} label={t(`集計方法`)} rules={[{ required: true }]} wrapperCol={{ span: 8 }}>
        <Select
          onChange={(v) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
            setFormState((x) => ({ ...x, function: v as PresetMeasureFunctions }))
          }}
          options={[
            ...availablePresetFunctions,
            {
              value: 'custom',
              label: t(`カスタム`),
            },
          ]}
        />
      </Form.Item>
      {formState.function !== `custom` && (
        <>
          <MeasureFilterForm
            sheetConfig={measureFormState.sheetView.config}
            originalField={field}
            onChange={(filterTree) => {
              setFormState((x) => ({
                ...x,
                filterTree,
              }))
            }}
            initialFilterTree={
              measure?.type === 'preset' && measure.filterTree !== undefined
                ? convertToFilterNodeForForm(measure.filterTree)
                : undefined
            }
          />
          {formState.filterTree !== undefined && (
            <Form.Item
              name={'label'}
              label={t(`表示ラベル`)}
              tooltip={t(`フィルタ条件を設定した時のみ、別名で表示することができます`)}
              wrapperCol={{ span: 8 }}
            >
              <Input
                disabled={
                  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                  formState.filterTree === undefined
                }
              />
            </Form.Item>
          )}
        </>
      )}
      {formState.function === `custom` && (
        <>
          <MeasureCustomSqlEditor sheetConfig={measureFormState.sheetView.config} />
          <Form.Item name={'label'} label={t(`表示ラベル`)} wrapperCol={{ span: 8 }}>
            <Input />
          </Form.Item>
        </>
      )}
    </>
  )
}

interface MeasureFormValue {
  function: PresetMeasureFunctions | 'custom'
  label?: string
  sql?: string
}

function useMeasureFormState({
  field,
  measureName,
  sheetView,
}: {
  sheetView: ViewForSiderFieldsFragment & { config: ViewConfigSheet }
  field: ViewConfigField
  measureName: string | undefined
}) {
  const { getModelProperty } = useConnectionsSelector()
  const property = getModelProperty(field.property.modelName, field.property.propertyName)
  const sheetConfig = sheetView.config
  const measure = (sheetConfig.fields ?? []).flatMap((x) => x.measures ?? []).find((x) => x.name === measureName)
  const availablePresetFunctions = presetFunctions()
    .filter(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
      (x) => x.availableFieldType === undefined || x.availableFieldType.includes(property?.type ?? ('' as 'string')),
    )
    .filter((x) => x.value !== 'countUnique' || field.property.nodeName !== sheetConfig.tree?.name) // countUniqueはノードがルートの時は表示しない

  const [formState, setFormState] = useState(() => ({
    filterTree: measure?.type === 'preset' ? measure.filterTree : undefined,
    function: measure?.type === 'custom' ? 'custom' : measure?.function,
  }))

  const onFinish = (value: MeasureFormValue) => {
    const getCustomMeasure = () => {
      const { sql } = value
      if (sql === undefined) {
        return {
          success: false as const,
          message: t(`SQLを定義してください`),
        }
      }
      const formatted = format(sql, { language: 'postgresql' })
      const name = measureName ?? generateMeasureName(`custom`)
      const measure: ViewConfigMeasure = {
        type: 'custom',
        name,
        label: value.label,
        sql: formatted,
        dependentNodeNames: getDependentNodeNames(formatted, sheetConfig.tree),
      }
      return {
        success: true as const,
        measure,
      }
    }

    const getDefaultMeasure = () => {
      if (value.function === 'custom') {
        // ありえないはず
        throw new Error(`implmenetation error`)
      }
      const pickedFunction = availablePresetFunctions.find((x) => x.value === value.function)
      if (pickedFunction === undefined) {
        // TODO
        return {
          success: false as const,
          message: t(`集計方法が見つかりません`),
        }
      }
      const fieldName = generateFieldName(field.property)
      const name = measureName ?? generateMeasureName([fieldName, value.function].join('_'))
      const measure: ViewConfigMeasure = {
        type: 'preset',
        name,
        label: formState.filterTree === undefined ? pickedFunction.label : (value.label ?? pickedFunction.label),
        function: value.function,
        fieldName,
        filterTree: formState.filterTree,
      }
      return {
        success: true as const,
        measure,
      }
    }
    const result = value.function === 'custom' ? getCustomMeasure() : getDefaultMeasure()
    if (!result.success) {
      return result
    }

    return {
      success: true as const,
      name: result.measure.name,
      config: addMeasure(sheetConfig, field, result.measure),
    }
  }

  return {
    sheetView,
    measure,
    field,
    formState,
    availablePresetFunctions,
    property,
    onFinish,
    setFormState,
  }
}

function addMeasure(
  config: ViewConfigSheet,
  targetField: ViewConfigField,
  measure: ViewConfigMeasure,
): ViewConfigSheet {
  return {
    ...config,
    fields: (config.fields ?? []).map((field) => {
      if (!isSameNodeProperty(field.property, targetField.property)) {
        return field
      }
      return {
        ...targetField,
        measures: (targetField.measures ?? []).upsertBy(measure, (x) => x.name),
      }
    }),
  }
}

function removeMeasure(config: ViewConfigSheet, targetField: ViewConfigField, measureName: string): ViewConfigSheet {
  return {
    ...config,
    fields: (config.fields ?? []).map((field) => {
      if (!isSameNodeProperty(field.property, targetField.property)) {
        return field
      }
      return {
        ...targetField,
        measures:
          targetField.measures === undefined ? undefined : targetField.measures.filter((x) => x.name !== measureName),
      }
    }),
  }
}

function getDependentNodeNames(sql: string, configTree: ViewConfigSheet['tree']): string[] {
  if (configTree === undefined) {
    // ありえないはず
    return []
  }
  const nodes = flatNodes(configTree)
  const nodeNames = nodes.map((x) => x.name)
  return nodeNames.filter((nodeName) => sql.includes(nodeName)) // 「名前が含まれてれば使っている」という雑な計算を行う
}

function generateMeasureName(nameRaw: string): string {
  const digest = uuidv4().split('-').first()!.toLowerCase()
  return makeIdentifiable([nameRaw, digest].join('_'))
}
