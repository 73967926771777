import { compileViewConfig, type ViewConfig } from '@salescore/core'
import { mutation } from '@salescore/frontend-common'

import { configAtom, configSheetAtom, hasChangeToViewSchemaAtom, meAtom, queriesAtom, uiAtom, viewAtom } from '../atoms'
import { connectionsSelector } from '../selectors/connectionsSelector'
import { viewsRelatedSelector } from '../selectors/viewsRelatedSelector'

export const setConfigWithoutChangeMutation = mutation<ViewConfig>({
  key: `view/setConfigWithoutChangeMutation`,
  set({ get, set }, newConfig) {
    const { views } = get(viewsRelatedSelector)
    const { models } = get(connectionsSelector)
    const me = get(meAtom)
    set(configAtom, newConfig)
    if (newConfig.type === 'sheet') {
      // deprecated
      set(configSheetAtom, newConfig)
    }
    const view = get(viewAtom)
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (view === undefined) {
      // 初期化中に呼ばれると空のことがある？
      return
    }

    const compiled = compileViewConfig(
      newConfig,
      {
        view,
        models,
        views,
        organizationSetting: {
          userGroupCategoryNames: me.organization.setting.userGroupCategoryNames,
          dimensionGroups: me.organization.dimensionGroups,
          accountClosingMonth: me.organization.setting.accountClosingMonth,
        },
      },
      {},
    )
    if (compiled === undefined) {
      // TODO: この辺のロジック検討
      return
    }
    set(queriesAtom, compiled.queries)
    set(uiAtom, compiled.ui)
  },
})

export const setConfigMutation = mutation<ViewConfig>({
  key: `view/setConfigMutation`,
  set({ get, set }, newConfig) {
    set(setConfigWithoutChangeMutation, newConfig)
    set(hasChangeToViewSchemaAtom, true)
  },
})
