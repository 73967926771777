import { PlusOutlined } from '@ant-design/icons'
import { useSuspenseQuery } from '@apollo/client'
import { isPresent } from '@salescore/buff-common'
import { FetchViewsDocument, ViewConfigTypeEnum } from '@salescore/client-api'
import { getOrganizationIdFromPath, SuspenseWithLoading } from '@salescore/client-common'
import { Button, Divider, Form, Select } from 'antd'
import { t } from 'i18next'
import { useEffect, useMemo, useState } from 'react'

import { useKpiPivotNavigationModal } from '../../../../recoil/navigation/hooks'
import { useViewsContextValue, useViewValue } from '../../../../recoil/view/hooks'
import { useKpiFormSelectors } from '../../../../recoil/view/selectors/kpiFormSelector'
import { ViewListItem } from './ListItem/ViewListItem'

export const KpiFormDashboardIdsFormItem = () => (
  <Form.Item label={t(`表示するダッシュボード`)}>
    <SuspenseWithLoading type="select">
      <Picker />
    </SuspenseWithLoading>
  </Form.Item>
)

function Picker() {
  const { onAddResource } = useViewsContextValue()
  const { kpiPivotFormModal } = useKpiPivotNavigationModal()
  const { data, refetch: invalidate } = useSuspenseQuery(FetchViewsDocument, {
    variables: {
      organizationId: getOrganizationIdFromPath(),
      type: ViewConfigTypeEnum.KpiPivot,
    },
  })

  const view = useViewValue()
  const { setForm, form, setFormModified } = useKpiFormSelectors()
  const [isSearching, setIsSearching] = useState<boolean>(false)

  const { views } = data

  const { filteredViews } = useMemo(() => {
    // ゴミ箱のビューは除外。
    // 公開設定は「公開ダッシュボードから非公開KPIを参照」だけがダメ。すなわち、KPIが公開されているか、KPIもダッシュボードが非公開のものだけがOK。
    const filteredViews = views.filter(
      (kpiPivotView) => !kpiPivotView.archived && (!view.private || kpiPivotView.private),
    )
    return {
      filteredViews,
    }
  }, [views])

  useEffect(() => {
    const ids = form.dashboardIds ?? []
    if (ids.isBlank()) {
      // まだセットされていないとき
      const kpiConfigs = filteredViews
        .map((x) => (x.config.type === 'kpiPivot' ? { config: x.config, id: x.id } : undefined))
        .compact()

      setForm((oldValue) => ({
        ...oldValue,
        dashboardIds: kpiConfigs
          // eslint-disable-next-line max-nested-callbacks
          .filter((x) => x.config.kpis.map((kpi) => kpi.viewId).includes(view.id))
          .map((x) => x.id),
      }))
    }
  }, [filteredViews])

  return (
    <Select
      showSearch
      onSearch={(value) => {
        setIsSearching(isPresent(value))
      }}
      filterOption={(input, option) =>
        // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion,@typescript-eslint/strict-boolean-expressions,@typescript-eslint/no-unnecessary-condition
        ((option?.key as string) || '').toLowerCase().includes(input.toLowerCase()) ?? false
      }
      optionLabelProp="label"
      mode="multiple"
      value={form.dashboardIds ?? []}
      onChange={(value) => {
        setForm((oldValue) => ({ ...oldValue, dashboardIds: value }))
        setFormModified(true)
      }}
      dropdownRender={(menu) => (
        <>
          {menu}
          <Divider style={{ margin: '8px 0' }} />
          <Button
            type="text"
            className="w-full text-blue-500"
            icon={<PlusOutlined />}
            onClick={() => {
              kpiPivotFormModal.showModal({
                viewGroupId: ``, // TODO
                onCreate: async (viewId) => {
                  setForm((oldValue) => ({ ...oldValue, dashboardIds: [...(oldValue.dashboardIds ?? []), viewId] }))
                  setFormModified(true)
                  await onAddResource({ viewIds: [viewId] })
                  void invalidate()
                },
              })
            }}
          >
            {t(`新規作成`)}
          </Button>
        </>
      )}
    >
      {filteredViews.map((view) => (
        <Select.Option value={view.id} label={view.name} key={`${view.id}-${view.name}-${view.createdBy?.name ?? ''}`}>
          <ViewListItem view={view} />
        </Select.Option>
      ))}
    </Select>
  )
}
