import { isPresent } from '@salescore/buff-common'

import { getOrganizationIdFromPath } from './getOrganizationId'

const generateQueryParameterString = (record: Record<string, string>) => {
  const searchParameters = new URLSearchParams()
  Object.entries(record).map(([key, value], index) => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (value === undefined || value === null) {
      return
    }
    searchParameters.append(key, value)
  })
  return searchParameters.toString()
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
const getOrganizationPath = (path: string) => `/o/${getOrganizationIdFromPath() ?? ''}${path}`

// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
const getV2SettingPath = (path: string) => `/o/${getOrganizationIdFromPath() ?? ''}/v2/settings${path}`

export const routes = {
  // 認証直後、URLにoidがない時に使うパス
  topPathWithOrganizationId: (organizationId: string) => `/o/${organizationId}`,
  sheetPathWithOrganizationId: (organizationId: string) => `/o/${organizationId}/views`,
  topPathWithOrganizationIdV2: (organizationId: string) => `/o/${organizationId}/v2`,

  // URLにoidがあるとき
  signInPath: () => {
    const oid = getOrganizationIdFromPath()
    if (isPresent(oid)) {
      return `/o/${oid}/sign_in`
    }
    return '/sign_in'
  },
  signUpPath: () => '/sign_up',
  signOutPath: () => '/sign_out',
  topPath: () => getOrganizationPath('/v2'),
  topPathV1: () => getOrganizationPath(''),
  sheetPath: () => getOrganizationPath('/views'),
  newDashboardPath: () => getOrganizationPath('/new_dashboard'),
  myActionsPath: () => getOrganizationPath('/my_actions'),
  userActivitiesPath: () => getV2SettingPath('/user_activities'),
  userActivitiesPathV1: () => getOrganizationPath('/user_activities'),
  editMyActionPath: (id: string) => getOrganizationPath('/my_actions/' + id),
  mePath: () => getV2SettingPath('/me'),
  mePathV1: () => getOrganizationPath('/me'),
  settingsPath: () => getV2SettingPath(''),
  settingsPathV1: () => getOrganizationPath('/settings'),
  updateGoalsPath: () => getOrganizationPath('/settings/update_goals'), // deprecated
  goalsPath: () => getV2SettingPath('/goal_configs'),
  goalsPathV1: () => getOrganizationPath('/settings/goals'),
  goalsCsvPath: () => getOrganizationPath('/settings/goals/csv'),
  goalsDimensionsPath: () => getOrganizationPath('/settings/goals/dimensions'),
  kpisSettingsPath: () => getOrganizationPath('/settings/kpis'),
  userGroupsSettingsPath: () => getV2SettingPath('/user_groups'),
  userGroupsSettingsPathV1: () => getOrganizationPath('/settings/user_groups'),
  dashboardItemSettingsPath: () => getOrganizationPath('/settings/dashboard_items'),
  hubReportsSettingsPath: () => getOrganizationPath('/settings/hub_reports'),
  newHubReportsSettingsPath: (queryParameter: { baseHubReportId?: string; kpiId?: string }) => {
    const parameters = generateQueryParameterString(queryParameter)
    return getOrganizationPath(`/settings/hub_reports/new?${parameters}`)
  },
  editHubReportsSettingsPath: (id: string) => getOrganizationPath(`/settings/hub_reports/${id}/edit`),
  hubReportSettingsPath: (id: string) => getOrganizationPath(`/settings/hub_reports/${id}`),
  sourceSettingsPath: () => getV2SettingPath('/sources'),
  sourceSettingsPathV1: () => getOrganizationPath('/settings/sources'),
  connectionSourceSettingsPath: (id: string) => getV2SettingPath(`/sources/${id}/connection`),
  invitedUsersSettingsPath: () => getV2SettingPath('/invitations'),
  invitedUsersSettingsPathV1: () => getOrganizationPath('/settings/invitations'),
  usersSettingsPath: () => getV2SettingPath('/users'),
  usersSettingsPathV1: () => getOrganizationPath('/settings/users'),
  businessDaysSettingsPath: () => getV2SettingPath('/business_day'),

  // v2
  topPathV2: (parameter?: string) => getOrganizationPath('/v2') + (parameter === undefined ? `` : `?${parameter}`),
  organizationSettingsPathV2: () => getV2SettingPath(''),
  usersSettingsPathV2: () => getV2SettingPath('/users'),
  userGroupsSettingsPathV2: () => getV2SettingPath('/user_groups'),
  userGroupsSettingsCsvPathV2: () => getV2SettingPath('/user_groups/csv'),
  invitedUsersSettingsPathV2: () => getV2SettingPath('/invitations'),
  businessDaysSettingsPathV2: () => getV2SettingPath('/business_day'),
  sourceSettingsPathV2: () => getV2SettingPath('/sources'),
  connectionSourceSettingsPathV2: (id: string) => getV2SettingPath(`/sources/${id}/connection`),
  eltModelsSettingsPathV2: (connectionId?: string) =>
    getV2SettingPath(`/elt_models${connectionId === undefined ? '' : `?connectionId=${connectionId}`}`),
  eltModelFormPathV2: (modelName: string) => getV2SettingPath(`/elt_models/${modelName}/form`),
  goalDimensionsPathV2: () => getV2SettingPath('/goal_dimensions'),
  goalConfigsPathV2: (parameter?: string) =>
    getV2SettingPath('/goal_configs') + (parameter === undefined ? `` : `?${parameter}`),
  goalConfigPathV2: (id: string) => getV2SettingPath(`/goal_configs/${id}`),
  editGoalConfigPathV2: (id: string) => getV2SettingPath(`/goal_configs/${id}/edit`),
  goalConfigCsvPathV2: (id: string) => getV2SettingPath(`/goal_configs/${id}/csv`),
  dimensionGroupsPathV2: () => getV2SettingPath('/dimension_groups'),
  // settingsか悩ましいが一旦
  mePathV2: () => getV2SettingPath('/me'),
  settingsPathV2: () => getV2SettingPath(''),
  userActivitiesPathV2: () => getV2SettingPath('/user_activities'),
  reverseEltsPath: () => getV2SettingPath('/reverse_elts'),
  policiesSettingPath: () => getV2SettingPath('/policies'),
}
