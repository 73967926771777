import type { ViewQueryNode, ViewQueryRecordNode } from '@salescore/core'
import { t } from 'i18next'
import type { SetRecoilState } from 'recoil'

import { feedbackMessagesAtom } from '../../../../recoil/records/atoms'
import { getRecordNodeFromRootNode } from '../../../../state/nodeUtil'
import type { RSheetColumn, RSheetRecordNode } from '../../../types'
import { getRecordNodesWithInnerRowIndex } from '../../../util/innerRowRelated'
import type { ExpandCursorArea } from '../../selectors/cursorSelector'
import type { PasteRelatedData } from './getRowsAndColumns'
import { paste, type PasteChange } from './paste'
import type { PseudoRowIterator } from './PseudoRowIterator'

// eslint-disable-next-line @typescript-eslint/max-params
export function getChangesByPseudoRootNode(
  sourcePseudoRootNode: ViewQueryNode,
  destinationPseudoRootNode: ViewQueryNode,
  source: PasteRelatedData,
  destination: PasteRelatedData,
  destinationIterator: PseudoRowIterator,
  set: SetRecoilState,
): PasteChange[] {
  // 擬似ルート行を抽出
  const sourcePseudoRows = getPseudoRows(source.rows, sourcePseudoRootNode, source.area)
  const destinationPseudoRows = getPseudoRows(destination.rows, destinationPseudoRootNode, destination.area)
  // 単一行コピー複数行ペーストの場合、コピーされたレコードを増やす
  const fixedPseudoCopiedRecords =
    sourcePseudoRows.length === 1 ? destinationPseudoRows.map((_) => sourcePseudoRows.first()!) : sourcePseudoRows

  const sourceColumnsWithPseudoRootNode = source.columns.map(
    (column): RSheetColumn<RSheetRecordNode> => ({
      ...column,
      node: {
        ...column.node,
        path: column.node.path.slice(sourcePseudoRootNode.path.length - 1),
      },
    }),
  )
  const destinationColumnsWithPseudoRootNode = destination.columns.map(
    (column): RSheetColumn<RSheetRecordNode> => ({
      ...column,
      node: {
        ...column.node,
        path: column.node.path.slice(destinationPseudoRootNode.path.length - 1),
      },
    }),
  )

  if (
    !validateNodes(
      sourceColumnsWithPseudoRootNode.map((x) => x.node),
      destinationColumnsWithPseudoRootNode.map((x) => x.node),
    )
  ) {
    set(feedbackMessagesAtom, [
      {
        type: 'warn' as const,
        message: t(`ブロックが異なる場合はペーストできません`),
      },
    ])
    return []
  }

  // 擬似ルートに属するカラムを抽出。またnodeのpathをpseudoRootNodeに沿って変更
  // TODO: destのカラムも抽出する場合、並び順が維持されていることを確認
  const sourceColumnsByNode = source.columns
    .filter((x) => x.node.path.join(',').startsWith(sourcePseudoRootNode.path.join(',')))
    .groupBy((x) => x.node.path.join(','))
    .map((_nodePath, groupedColumns) => {
      const { node } = groupedColumns.first()!
      return {
        node: {
          ...node,
          path: node.path.slice(sourcePseudoRootNode.path.length - 1), // ここでpathを変えてしまって大丈夫か？
        },
        sourceColumns: groupedColumns,
      }
    })
    .sortBy((x) => x.node.path.length)
  const destinationColumnsByNode = destination.columns
    .filter((x) => x.node.path.join(',').startsWith(destinationPseudoRootNode.path.join(',')))
    .groupBy((x) => x.node.path.join(','))
    .map((_nodePath, groupedColumns) => {
      const { node } = groupedColumns.first()!
      return {
        node: {
          ...node,
          path: node.path.slice(destinationPseudoRootNode.path.length - 1), // ここでpathを変えてしまって大丈夫か？
        },
        destColumns: groupedColumns,
      }
    })
    .sortBy((x) => x.node.path.length)

  // 擬似行ごとに処理を行う
  return fixedPseudoCopiedRecords.flatMap((sourceRecord) => {
    const destinationRecord = destinationIterator.next()
    if (destinationRecord === undefined) {
      // 行が足りない場合は前もって生成しているので基本的にありえないはず
      return []
    }
    const changes = sourceColumnsByNode.flatMap(({ node, sourceColumns }, index) => {
      // TODO: 対応するルートノードを取得。いったん「並び順が等しい」という仮定を置いている
      const destinationColumns = destinationColumnsByNode[index]?.destColumns ?? []

      return paste(node, sourceColumns, destinationColumns, sourceRecord, destinationRecord)
    })
    return changes
  })
}

export function getPseudoRows(
  rows: ViewQueryRecordNode[],
  pseudoRootNode: ViewQueryNode,
  area: ExpandCursorArea,
): ViewQueryRecordNode[] {
  // 擬似ルートノードがルートノードであれば、元々の行を返す
  if (pseudoRootNode.path.length === 1) {
    return rows
  }

  // TODO
  return [
    ...getPseudoRowsUpper(rows, pseudoRootNode, area),
    ...getPseudoRowsMiddle(rows, pseudoRootNode, area),
    ...getPseudoRowsDowner(rows, pseudoRootNode, area),
  ]
}

function getPseudoRowsUpper(
  rows: ViewQueryRecordNode[],
  pseudoRootNode: ViewQueryNode,
  area: ExpandCursorArea,
): ViewQueryRecordNode[] {
  const row = rows.first()!
  const xs = getRecordNodesWithInnerRowIndex(row, pseudoRootNode)

  // upとdownが同じ行の時
  if (area.upRowIndex === area.downRowIndex) {
    // donw-upで、upからdownの間にあるレコード
    return xs
      .filter((x) => area.upInnerRowIndex <= x.innerRowIndexStart && x.innerRowIndexStart < area.downInnerRowIndex)
      .map((x) => x.recordNode)
      .compact() // TODO: compactでいいか？
  }
  // upとdownが違う行のとき、upから最下段(=行の高さがindexとなる)までの高さ
  return xs
    .filter((x) => area.upInnerRowIndex <= x.innerRowIndexStart)
    .map((x) => x.recordNode)
    .compact() // TODO: compactでいいか？
}

function getPseudoRowsMiddle(
  rows: ViewQueryRecordNode[],
  pseudoRootNode: ViewQueryNode,
  area: ExpandCursorArea,
): ViewQueryRecordNode[] {
  // upとdownが同じ行の時
  if (area.upRowIndex === area.downRowIndex) {
    // これはupper側で計算するので、ここでは空
    return []
  }

  // upとdownが隣り合う行のとき
  if (area.upRowIndex + 1 === area.downRowIndex) {
    // これはdowner側で計算するので、ここでは空
    return []
  }

  // upとdownの間に行があるとき、この行を取得
  const middleRows = rows.slice(1, -1)
  return middleRows.flatMap((x) => getRecordNodeFromRootNode(x, pseudoRootNode.path)).compact() // TODO: compactで良いか？
}

function getPseudoRowsDowner(
  rows: ViewQueryRecordNode[],
  pseudoRootNode: ViewQueryNode,
  area: ExpandCursorArea,
): ViewQueryRecordNode[] {
  // upとdownが同じ行の時
  if (area.upRowIndex === area.downRowIndex) {
    // これはupper側で計算するので、ここでは空
    return []
  }
  // upとdownが違う行のとき、最後の行を取得
  const row = rows.last()!
  const xs = getRecordNodesWithInnerRowIndex(row, pseudoRootNode)
  return xs
    .filter((x) => x.innerRowIndexStart < area.downInnerRowIndex)
    .map((x) => x.recordNode)
    .compact() // TODO: compactでいいか？
}

function validateNodes(sourceNodes: ViewQueryNode[], destinationNodes: ViewQueryNode[]): boolean {
  const mapper = new Map<string, string>()
  const registerNodeNameMapping = (sourceNodeName: string, destinationNodeName: string) => {
    const registeredNodeName = mapper.get(destinationNodeName)
    if (registeredNodeName !== undefined) {
      return registeredNodeName
    }
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (registeredNodeName === sourceNodeName) {
      throw new Error(`REGISTERED_NAME_NOT_EQUAL`)
    }
    mapper.set(destinationNodeName, sourceNodeName)
    return sourceNodeName
  }

  try {
    for (const [index, sourceNode] of sourceNodes.entries()) {
      const destinationNode = destinationNodes[index]
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (sourceNode === undefined || destinationNode === undefined) {
        return false
      }
      if (sourceNode.path.length !== destinationNode.path.length) {
        // 擬似ルートで変換した後なので、pathの長さは必ず揃うはず
        return false
      }
      const zippedPaths = sourceNode.path.zip(destinationNode.path)
      if (
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions,@typescript-eslint/no-unnecessary-condition
        zippedPaths.some(([sourceNodeName, destinationNodeName]) => {
          registerNodeNameMapping(sourceNodeName, destinationNodeName)
        })
      ) {
        return false
      }
    }
    return true
  } catch (error) {
    if (error instanceof Error && error.message === `REGISTERED_NAME_NOT_EQUAL`) {
      return false
    }
    throw error
  }
}
