import { ArrowLeftOutlined } from '@ant-design/icons'
import { PageHeader } from '@ant-design/pro-layout'
import { useMutation, useSuspenseQuery } from '@apollo/client'
import {
  FetchGoalConfigFormRelatedDocument,
  type GoalConfigFieldsFragment,
  UpdateGoalConfigDocument,
} from '@salescore/client-api'
import { ErrorContext, getOrganizationIdFromPath, HandleQuery, ViewPicker } from '@salescore/client-common'
import { recoil } from '@salescore/client-recoil'
import { Button, Col, Form, Input, message, Row, Select, Skeleton } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { t } from 'i18next'
import { type ReactNode, useContext } from 'react'
import { z } from 'zod'

import { UserAndGroupPickerFormItem } from './CreateGoalConfigForm'

const goalConfigSchema = z.object({
  name: z.string(),
  userType: z.string(),
  userAndGroupIdsConfig: z.object({ key: z.string(), value: z.string() }).array().optional(),
})
type FormValue = z.infer<typeof goalConfigSchema>

export const goalConfigTimeSpanTypeOptions = [
  {
    value: 'month',
    label: t(`月`),
  },
  {
    value: 'week',
    label: t(`週`),
  },
  {
    value: 'day',
    label: t(`日`),
  },
  // いったん必要なさそうという話になり削る
  // {
  //   value: 'none',
  //   label: '時間に依存しない目標',
  // },
]

export const goalConfigUserTypeOptions = [
  {
    value: 'user',
    label: t(`ユーザー`),
  },
  {
    value: 'group',
    label: t(`ユーザーグループ`),
  },
  {
    value: 'none',
    label: t(`ユーザーに依存しない目標`),
  },
]

export const UpdateGoalConfigForm = ({
  goalConfig,
  onAfterFinish,
  onBack,
}: {
  goalConfig: GoalConfigFieldsFragment
  onBack?: () => void
  onAfterFinish: () => void
}): ReactNode => (
  <HandleQuery loadingElement={<Skeleton />}>
    <Body goalConfig={goalConfig} onAfterFinish={onAfterFinish} onBack={onBack} />
  </HandleQuery>
)

function Body({
  goalConfig,
  onAfterFinish,
  onBack,
}: {
  goalConfig: GoalConfigFieldsFragment
  onAfterFinish: () => void
  onBack?: () => void
}): ReactNode {
  const {
    data: { goalDimensions },
  } = useSuspenseQuery(FetchGoalConfigFormRelatedDocument, {
    variables: {
      organizationId: getOrganizationIdFromPath(),
    },
  })
  const [form] = useForm<FormValue>()
  const [updateGoalConfigMutation, { loading }] = useMutation(UpdateGoalConfigDocument)
  const errorContext = useContext(ErrorContext)
  const canManageGoal = recoil.global.policy.useCan('manage-goal')

  const onFinish = (values: unknown): void => {
    const formValue = goalConfigSchema.parse(values)
    const userGroupIdsConfig =
      formValue.userAndGroupIdsConfig?.filter((x) => x.key.endsWith('group')).map((x) => x.value) ?? []
    const userIdsConfig =
      formValue.userAndGroupIdsConfig?.filter((x) => x.key.endsWith('user')).map((x) => x.value) ?? []

    if (formValue.userType === 'user' && [...userGroupIdsConfig, ...userIdsConfig].isBlank()) {
      void message.error(t(`目標を設定するチーム・ユーザーを指定してください`))
      return
    }
    if (goalConfig.userType === 'group' && [...userGroupIdsConfig].isBlank()) {
      void message.error(t(`目標を設定するチームを指定してください`))
      return
    }

    void updateGoalConfigMutation({
      variables: {
        organizationId: getOrganizationIdFromPath(),
        goalConfig: {
          id: goalConfig.id,
          name: formValue.name,
          userGroupIdsConfig,
          userIdsConfig,
          rank: goalConfig.rank,
        },
      },
      onCompleted: () => {
        void message.info(t(`目標設定を更新しました！`))
        onAfterFinish() // redirect
      },
      onError: (error) => {
        errorContext.throwErrors(error as Error)
      },
    })
  }

  if (!canManageGoal) {
    return (
      <PageHeader title={t(`目標設定を編集`)} onBack={onBack}>
        {t(`設定権限がありません。`)}
      </PageHeader>
    )
  }

  const goalConfigInitial: Partial<FormValue> = {
    ...goalConfig,
    userAndGroupIdsConfig: [
      ...goalConfig.userGroupIdsConfig.map((x) => ({ key: `${x}-group`, value: x })),
      ...goalConfig.userIdsConfig.map((x) => ({ key: `${x}-user`, value: x })),
    ],
  }
  return (
    <PageHeader onBack={onBack}>
      <Form<FormValue> form={form} onFinish={onFinish} layout="vertical" initialValues={goalConfigInitial}>
        <Row>
          <Col>
            <Button type="text" icon={<ArrowLeftOutlined />} onClick={onBack} className="my-3" />
          </Col>
          <Col span={20}>
            <Form.Item name="name" rules={[{ required: true, message: t(`目標設定の名前を入力してください`) }]}>
              <Input
                size="large"
                bordered={false}
                placeholder="目標設定の名前を入力"
                style={{ fontSize: 24, fontWeight: 'bold' }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name="dashboardViewId" label={t(`ダッシュボード`)} required>
          <ViewPicker disabled />
        </Form.Item>
        <Form.Item name="timeSpanType" label={t(`時間軸`)} required>
          <Select disabled options={goalConfigTimeSpanTypeOptions} />
        </Form.Item>
        <Form.Item name="userType" label={t(`ユーザー軸`)} required>
          <Select disabled options={goalConfigUserTypeOptions} />
        </Form.Item>
        {goalConfig.userType === 'user' && (
          <>
            <UserAndGroupPickerFormItem />
          </>
        )}
        {goalConfig.userType === 'group' && (
          <>
            <UserAndGroupPickerFormItem label={t(`チームを指定`)} />
          </>
        )}
        {
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          goalConfig.goalDimension1 && (
            <Form.Item name="goalDimension1Id" label={t(`目標軸1`)}>
              <Select disabled options={goalDimensions.map((x) => ({ value: x.id, label: x.label }))} />
            </Form.Item>
          )
        }
        {
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          goalConfig.goalDimension2 && (
            <Form.Item name="goalDimension2Id" label={t(`目標軸2`)}>
              <Select disabled options={goalDimensions.map((x) => ({ value: x.id, label: x.label }))} />
            </Form.Item>
          )
        }
        {
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          goalConfig.goalDimension3 && (
            <Form.Item name="goalDimension3Id" label={t(`目標軸3`)}>
              <Select disabled options={goalDimensions.map((x) => ({ value: x.id, label: x.label }))} />
            </Form.Item>
          )
        }
        {
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          goalConfig.goalDimension4 && (
            <Form.Item name="goalDimension4Id" label={t(`目標軸4`)}>
              <Select disabled options={goalDimensions.map((x) => ({ value: x.id, label: x.label }))} />
            </Form.Item>
          )
        }
        {
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          goalConfig.goalDimension5 && (
            <Form.Item name="goalDimension5Id" label={t(`目標軸5`)}>
              <Select disabled options={goalDimensions.map((x) => ({ value: x.id, label: x.label }))} />
            </Form.Item>
          )
        }
        <Form.Item>
          <Row justify="end">
            <Button type="primary" loading={loading} htmlType="submit">
              {t(`保存`)}
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </PageHeader>
  )
}
