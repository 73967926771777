import { useApolloClient } from '@apollo/client'
import { FetchViewGroupsDocument } from '@salescore/client-api'

import { useOrganizationId } from '../../global/hooks'
import { useInitializeViewGroupsMutation } from './initializeViewGroupsMutation'

// viewGroups,viewsをfetchし、ツリーを初期化する
export function useRefetchViewGroups() {
  const organizationId = useOrganizationId()
  const client = useApolloClient()
  const initialize = useInitializeViewGroupsMutation()
  const refetchViewGroups = async () => {
    // ロジック
    const result = await client.query({
      query: FetchViewGroupsDocument,

      variables: {
        organizationId,
      },
    })
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    initialize({ viewGroups: result.data.viewGroups ?? [], views: result.data.viewsForSider ?? [] })
    // void message.info(`refetchしました`) // 動作確認用
  }

  return refetchViewGroups
}
