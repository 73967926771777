import { useMutation } from '@apollo/client'
import { compareFunction } from '@salescore/buff-common'
import { type FetchInvitationsQuery, UpsertInvitationsDocument, UserRoleEnumV2 } from '@salescore/client-api'
import { getOrganizationIdFromPath, UserPlanTag } from '@salescore/client-common'
import { createDestroyColumn, getColumnSearchProps } from '@salescore/frontend-common'
import { message, Select, Table } from 'antd'
import { t } from 'i18next'
import { type ReactNode, useState } from 'react'

import { createUserRoleOptions, USER_PROVIDER_JA, USER_ROLE_JA } from './CreateInvitationForm'

type Invitation = FetchInvitationsQuery['invitations'][0]
export const InvitationsTable = ({
  afterDestroy,
  invitations,
}: {
  invitations: FetchInvitationsQuery['invitations']
  afterDestroy: () => void
}): ReactNode => {
  const options = createUserRoleOptions()
  const [upsertInvitationsMutation] = useMutation(UpsertInvitationsDocument)
  const [updatingUserIds, setUpdatingUserIds] = useState<string[]>([])
  const [loading, setLoading] = useState(false)

  const onDestroy = async (record: Invitation) => {
    await upsertInvitationsMutation({
      variables: {
        organizationId: getOrganizationIdFromPath(),
        invitations: [
          {
            id: record.id,
            email: record.email,
            provider: record.provider,
            role: record.role,
            deleted: true,
          },
        ],
      },
      onCompleted: () => {
        afterDestroy()
      },
    })
  }
  return (
    <>
      <Table
        rowKey="id"
        pagination={false}
        columns={[
          {
            title: t(`メールアドレス`),
            dataIndex: 'email',
            sorter: compareFunction,
            ...getColumnSearchProps((record: Invitation) => record.email),
          },
          {
            title: t(`ライセンス`),
            width: 100,
            sorter: (a, b) => compareFunction([a.planForSync, a.planForVis], [b.planForSync, b.planForVis]),
            render(_, record) {
              return <UserPlanTag userOrInvitation={record} />
            },
          },
          {
            title: t(`権限`),
            width: 200,
            sorter: compareFunction,
            render(_, invitation) {
              const { role } = invitation
              return (
                <Select
                  style={{ width: 200 }}
                  className="w-full"
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
                  value={{ label: USER_ROLE_JA[role as UserRoleEnumV2], value: role }}
                  labelInValue
                  options={options.filter((x) => x.value !== UserRoleEnumV2.Minimal)}
                  optionRender={(option) => <span className="whitespace-normal break-words ">{option.label}</span>}
                  loading={updatingUserIds.includes(invitation.id)}
                  disabled={updatingUserIds.includes(invitation.id) || invitation.role === UserRoleEnumV2.Minimal} // Minimalはadmin画面でのみ設定可能
                  onChange={(e: { label: string; value: string }) => {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
                    const role = e.value as UserRoleEnumV2
                    setUpdatingUserIds((old) => [...old, invitation.id])

                    void upsertInvitationsMutation({
                      variables: {
                        organizationId: getOrganizationIdFromPath(),
                        invitations: [
                          {
                            id: invitation.id,
                            email: invitation.email,
                            provider: invitation.provider,
                            role,
                          },
                        ],
                      },
                      onCompleted: () => {
                        setUpdatingUserIds((old) => old.filter((x) => x !== invitation.id))
                        void message.success(t(`役割を更新しました`))
                      },
                    })
                  }}
                />
              )
            },
          },
          {
            title: t(`ログイン方法`),
            width: 200,
            sorter: compareFunction,
            render(_, record) {
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              return <div className="">{USER_PROVIDER_JA[record.provider] ?? record.provider}</div>
            },
          },
          createDestroyColumn(loading, setLoading, onDestroy),
        ]}
        dataSource={invitations}
      />
    </>
  )
}
