import { PageHeader } from '@ant-design/pro-layout'
import type { ViewConfigField } from '@salescore/core'
import { Empty, type ModalFuncProps } from 'antd'
import { t } from 'i18next'
import { useSetRecoilState } from 'recoil'

import { useViewConfigSheet, useViewValue } from '../../../recoil/view/hooks'
import { setConfigMutation } from '../../../recoil/view/mutations/setConfigMutation'
import { MeasureForm } from './MeasureForm'

export const buildMeasureFormModalProperties = ({
  field,
  measureName,
  onFinish,
}: {
  field: ViewConfigField
  measureName?: string | undefined
  onFinish: () => void
}): ModalFuncProps => ({
  icon: null,
  width: '90%',
  cancelText: t(`閉じる`),
  okButtonProps: { style: { display: 'none' } },
  style: { top: '3%', padding: 0 },
  destroyOnClose: true,
  maskClosable: true,
  footer: <></>,
  content: <Body field={field} measureName={measureName} onFinish={onFinish} />,
})

function Body({
  field,
  measureName,
  onFinish,
}: {
  field: ViewConfigField
  measureName: string | undefined
  onFinish: () => void
}) {
  const view = useViewValue()
  const sheetConfig = useViewConfigSheet() // XXX: viewからconfigを取得するとmeasuresが削除されている…？詳細不明
  const setConfig = useSetRecoilState(setConfigMutation)

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (sheetConfig.type !== 'sheet') {
    return <Empty description={t(`ビューが見つかりません`)} />
  }

  return (
    <PageHeader title={t(`集計設定`)}>
      <MeasureForm
        sheetView={{
          ...view,
          config: sheetConfig,
        }}
        field={field}
        measureName={measureName}
        onFinish={({ sheetViewConfig }) => {
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          if (sheetViewConfig !== undefined && sheetViewConfig.type === 'sheet') {
            setConfig(sheetViewConfig)
          }
          onFinish()
        }}
      />
    </PageHeader>
  )
}
