import { useMutation } from '@apollo/client'
import { AddColumnToSnapshotModelDocument } from '@salescore/client-api'
import { recoil } from '@salescore/client-recoil'
import { modelPropertySchema } from '@salescore/core'
import { App, Button, Col, Flex, Form, Input, Row } from 'antd'
import { t } from 'i18next'
import type { ReactElement } from 'react'

const placeholderAddColumnProperty = `例
{
  "name": "amount",
  "type": "numeric",
  "label": "金額"
}`

export function AddColumnForm({
  eltModelId,
  onFinish,
}: Readonly<{
  eltModelId: string
  onFinish: () => void
}>): ReactElement {
  const { message } = App.useApp()
  const { organization } = recoil.global.useMe()
  const [addColumnToSnapshotModel, { loading }] = useMutation(AddColumnToSnapshotModelDocument)
  const [form] = Form.useForm<{
    columnSql: string
    property: string
  }>()

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={(formValue) => {
        void addColumnToSnapshotModel({
          variables: {
            organizationId: organization.id,
            addColumnInput: {
              id: eltModelId,
              columnSql: formValue.columnSql,
              property: modelPropertySchema.parse(JSON.parse(formValue.property)),
            },
          },
          onCompleted: () => {
            message.success(t('列を追加しました'))
            form.resetFields()
            onFinish()
          },
          onError: (error) => {
            message.error(t('列の追加に失敗しました。') + error.message)
          },
        })
      }}
    >
      <Row>
        <Col span={12} className="p-1">
          <Form.Item
            name="columnSql"
            layout="vertical"
            rules={[{ required: true, message: '入力してください' }]}
            label={<h2>{t(`SELECT 句に追加する列名`)}</h2>}
            extra={t(`トップレベルの SELECT 句に追加する列名を入力してください。`)}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12} className="p-1">
          <Form.Item
            name="property"
            layout="vertical"
            label={<h2>{t(`追加するプロパティ`)}</h2>}
            extra={t(`『SELECT 句に追加する列名』に対応するプロパティを入力してください。`)}
            rules={[
              {
                required: true,
                validator: async function (_rule, value: string) {
                  try {
                    modelPropertySchema.parse(JSON.parse(value))
                    await Promise.resolve()
                  } catch {
                    throw new Error(t('正しい形式で入力してください'))
                  }
                },
              },
            ]}
          >
            <Input.TextArea rows={5} placeholder={placeholderAddColumnProperty} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Flex justify="end">
            <Form.Item shouldUpdate>
              {() => (
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  disabled={
                    !form.isFieldsTouched() || form.getFieldsError().some(({ errors }) => errors.length > 0) || loading
                  }
                >
                  {t(`追加`)}
                </Button>
              )}
            </Form.Item>
          </Flex>
        </Col>
      </Row>
    </Form>
  )
}
