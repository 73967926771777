import { isNull } from '@salescore/buff-common'
import type { ResourceUser } from '@salescore/client-api'
import { Avatar } from 'antd'
import type { CSSProperties } from 'react'

const COLOR_CLASSNAMES = ['bg-blue-500', 'bg-yellow-400', 'bg-red-400', 'bg-green-400', 'bg-purple-400']

export const UserAvatar = ({
  user,
  size,
  style,
}: {
  user?: Pick<ResourceUser, 'name' | 'image_url'> | null
  size?: 'small' | 'default' | 'large'
  style?: CSSProperties
}) => {
  if (isNull(user)) {
    return <></>
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-type-assertion
  const imageUrl: string = (user as any).imageUrl ?? (user as any).image_url
  if (typeof imageUrl === 'string') {
    return <Avatar size={size ?? 'default'} shape="circle" src={imageUrl} style={{ ...style }} />
  }
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const userName = user.name ?? ''
  // eslint-disable-next-line unicorn/prefer-code-point,unicorn/prefer-spread
  const colorSeed = userName.split('').reduce((accumulator, x) => accumulator + x.charCodeAt(0), 0)
  const color = COLOR_CLASSNAMES[colorSeed % COLOR_CLASSNAMES.length]
  return (
    <Avatar size={size ?? 'default'} shape="circle" className={color} style={{ marginRight: 0 }}>
      {userName[0]}
    </Avatar>
  )
}
