import type { SheetThreadComment } from '@salescore/client-api'

export function commentDisplayName(comment: SheetThreadComment): string {
  return (
    comment.createdByUserName ??
    comment.createdBy?.name ??
    comment.slackSheetThreadComment?.createdBySlackUserName ??
    ''
  )
}
