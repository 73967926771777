// TODO

import { PageHeader } from '@ant-design/pro-layout'
import { useMutation } from '@apollo/client'
import { isSome } from '@salescore/buff-common'
import { type FetchUserGroupsQuery, UpsertUserGroupsDocument } from '@salescore/client-api'
import { ErrorContext, getOrganizationIdFromPath } from '@salescore/client-common'
import { Button, Form, Input, message, Row, Select, Tag } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { t } from 'i18next'
import { type ReactNode, useContext } from 'react'

type UserGroupForTable = FetchUserGroupsQuery['userGroups'][0]

interface UserGroupUser {
  user: FetchUserGroupsQuery['userGroups'][0]['users'][0]
  team: UserGroupForTable | null
}

interface FormValue {
  name: string
  userIds?: string[]
}

export const UpsertUserGroupForm = ({
  team,
  allUsers,
  allUserGroups,
  parentUserGroup,
  onUpdateUserGroupSort = async () => {
    // empty
  },
  onAfterFinish = async () => {
    // empty
  },
}: {
  team?: UserGroupForTable
  allUsers: UserGroupUser[]
  allUserGroups: UserGroupForTable[]
  parentUserGroup?: UserGroupForTable | null
  onUpdateUserGroupSort?: () => void
  onAfterFinish?: () => void
}): ReactNode => {
  const [form] = useForm()
  const [upsertUserGroupsMutation, { loading }] = useMutation(UpsertUserGroupsDocument)
  const errorContext = useContext(ErrorContext)

  const onFinish = (values: FormValue): void => {
    void upsertUserGroupsMutation({
      variables: {
        organizationId: getOrganizationIdFromPath(),
        userGroups: [
          {
            id: team?.id,
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            name: values.name ?? team?.name,
            rank: team?.rank ?? allUserGroups.length,
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            depth: parentUserGroup ? parentUserGroup.depth + 1 : (team?.depth ?? 1),
            user_group_id: parentUserGroup?.id ?? team?.user_group_id,
            userIds: values.userIds ?? team?.users.map((x) => x.id) ?? [],
          },
        ],
      },
      onCompleted: () => {
        onUpdateUserGroupSort()
        void message.info(t(`チームを更新しました！`))
        onAfterFinish()
        form.resetFields()
      },
      onError: (error) => {
        errorContext.throwErrors(error as Error)
      },
    })
  }

  return (
    <PageHeader
      title={
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        team ? t(`チームを編集`) : t(`チームを作成`)
      }
      // teamが更新された際に無理やり更新する
      key={JSON.stringify(team)}
    >
      <Form onFinish={onFinish} initialValues={team} layout="vertical" form={form}>
        <Form.Item
          name="name"
          label={t(`チーム名`)}
          rules={[{ required: true, message: t(`チーム名を入力してください`) }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="userIds" label={t(`所属ユーザー`)}>
          <Select
            mode="multiple"
            showArrow
            defaultValue={(team?.users ?? []).map((user) => user.id)}
            filterOption={(input, option) =>
              // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion,@typescript-eslint/strict-boolean-expressions
              ((option?.key as string) || '').toLowerCase().includes(input.toLowerCase())
            }
            tagRender={(option) => {
              if (isSome(option.value)) {
                const user = allUsers.find((user) => user.user.id === option.value)
                if (user !== undefined) {
                  return (
                    <Tag closable={true} bordered={false} onClose={option.onClose}>
                      {user.user.name} - {t(`現在`)}: {user.team?.name ?? t(`無所属`)}
                    </Tag>
                  )
                }
              }
              return <></>
            }}
          >
            {allUsers
              // .filter((x) => x.user.visibility)
              .map((user) => (
                <Select.Option
                  value={user.user.id}
                  key={`${user.user.id}-${user.user.name}${user.team?.name ?? ''}-${user.user.email}`}
                >
                  {/* <UserAvatar user={user}/> */}
                  <span>{user.user.name}</span>
                  <span className="ml-3 text-gray-600">
                    - {t(`現在`)}: {user.team?.name ?? t(`無所属`)}
                    {/* {user.user.role === UserRoleEnum.UserOnIntegration && <>（未ログインユーザー）</>} */}{' '}
                    {user.user.email}
                  </span>
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Row justify="end">
            <Button type="primary" htmlType="submit" loading={loading}>
              {t(`保存`)}
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </PageHeader>
  )
}
