// eslint-disable-next-line @eslint-community/eslint-comments/disable-enable-pair
/* eslint-disable max-lines */
import { isNull } from '@salescore/buff-common'
import type {
  MixedUserFieldsFragment,
  SharedUrl,
  SheetCustomModelFieldsFragment,
  ViewFieldsFragment,
} from '@salescore/client-api'
import type { Me } from '@salescore/client-recoil'
import {
  compileViewConfig,
  type EltChangeResult,
  type GoalConfig,
  type ViewConfig,
  type ViewConfigAdditionalConfig,
  type ViewConfigKpi,
  type ViewConfigKpiPivot,
  type ViewConfigKpiTimeSeries,
  type ViewConfigSheet,
  type ViewQuery,
  type ViewQueryAggregation,
  type ViewQueryList,
  type ViewQueryMultiTablePivot,
  type ViewUI,
} from '@salescore/core'
import { convertSheetCustomModel } from '@salescore/features'
import type { ModalAtomType } from '@salescore/frontend-common'
import { atom, selector } from 'recoil'

import type { ViewsContext } from '../../components/ViewComponent'
import type { ChangeHistory } from '../../state/useViewRecordsState/useChangesState'
import { type DrillDownParameter, kpiParameterAtom, kpiUserAppearanceAtom } from '../navigation/atoms'
import { additionalConfigAtom } from '../records/atoms'
import { viewsRelatedSelector } from './selectors/viewsRelatedSelector'

const prefix = `view`

// TODO
export const emptyQuery: ViewQueryList = {
  type: 'list',
  tree: {
    name: `salescore_users`,
    path: [`salescore_users`],
    read: {
      idColumn: 'id', // TODO: idが常にある前提。カスタムストリームなど、idがない場合にどうするか？destinationで指定すべきか？
      tableSql: `salescore_users`,
      tableType: 'table',
    },
    write: {
      streamName: `salescore_users`,
    },
    meta: {
      label: ``,
      dependedStreamNames: [`salescore_users`],
    },
  },
  fields: [],
  sorters: [],
  filterTree: {
    logicalOperator: 'and',
    leafs: [
      {
        nodePaths: [],
        read: {
          sql: 'TRUE = FALSE',
        },
      },
    ],
    children: [],
  },
}

// サーバーデータ
export const meAtom = atom<Me>({
  key: `me`, // XXX: view側のrecoilと名前を統一している
  // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
  default: undefined as never,
})

export interface ViewAbility {
  canCreate: boolean
  canUpdate: boolean
  canDelete: boolean
  canCreateNewRecord: boolean
  canOpenRelationInputForm: boolean
  canDownloadCsv: boolean
  canSaveRecord: boolean
  canDeleteRecord: boolean
  // ライセンスのチェックはabilityではないので、ここに含めるべき内容ではないが一旦許す。名前を検討し直したい。
  isVisualizeEnabled: boolean
  isSyncEnabled: boolean
}

export const viewAbilityAtom = atom<ViewAbility>({
  key: `${prefix}/viewAbilityAtom`,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
  default: undefined as never,
})

export const usersAtom = atom<MixedUserFieldsFragment[]>({
  key: `${prefix}/users`,
  default: [],
})

export const viewsContextAtom = atom<ViewsContext>({
  key: `${prefix}/viewsContextAtom`,
  default: {
    models: [],
    views: [],
    viewGroups: [],
    viewsForSider: [],
    onSaveView: async () => {
      await Promise.resolve()
    },
    createView: async (): Promise<undefined> => {
      await Promise.resolve()
    },
    updateView: async (): Promise<undefined> => {
      await Promise.resolve()
    },
    deleteView: async () => {
      await Promise.resolve()
    },
    pickView: async () => {
      await Promise.resolve()
    },
    refetchViews: async () => {
      await Promise.resolve()
    },
    onAddResource: async () => {
      // empty
    },
    onChangesChange: () => {
      // empty
    },
    hasChanges: false,
    setIsSaveConfigButtonActive: () => {
      // empty
    },
    isSaveConfigButtonActive: false,
    isSheetMoreActionsCopyVisible: true,
  },
})

// view
export const viewAtom = atom<ViewFieldsFragment>({
  key: `${prefix}/view`,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
  default: undefined as never, // これで正しいだろうか？
})

const configAtomRaw = atom<ViewConfig>({
  key: `${prefix}/configAtomRaw`,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
  default: undefined as never,
})

export const configAtom = selector<ViewConfig>({
  key: `view/configAtom`,
  get({ get }) {
    const config = get(configAtomRaw)
    return config
  },
  set({ get, set }, config) {
    const view = get(viewAtom)
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (view === undefined) {
      // TODO: 初期化時にviewがundefinedになりうる？
      set(configAtomRaw, config)
      return
    }
    set(configAtomRaw, config)
  },
})

// export const goalConfigIdAtom = atom<string | undefined>({
//   key: `${prefix}/goalConfigIdAtom`,
//   default: undefined as never,
// })

// export const goalConfigsAtom = atom<GoalConfigFieldsFragment[]>({
//   key: `${prefix}/goalConfigsAtom`,
//   default: []
// })

// export const goalConfigSelector = selector({
//   key: `${prefix}/goalConfigSelector`,
//   get({ get }) {
//     const goalConfigId = get(goalConfigIdAtom)
//     const goalConfigs = get(goalConfigsAtom)
//     if (goalConfigId === undefined) {
//       return undefined
//     }
//     return goalConfigs.find(x => x.id === goalConfigId)
//   }
// })

export const goalConfigAtom = selector<GoalConfig | undefined>({
  key: `view/goalConfigAtom`,
  get({ get }) {
    const kpiParameter = get(kpiParameterAtom)
    return kpiParameter.goalConfig
  },
  set({ get, set }, goalConfig) {
    set(kpiParameterAtom, (oldValue) => ({
      ...oldValue,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
      goalConfig: goalConfig as GoalConfig | undefined, // DefaultValueを避ける
    }))
  },
})

export const sheetCustomModelsAtom = atom<SheetCustomModelFieldsFragment[]>({
  key: `${prefix}/sheetCustomModelsAtom`,
  default: [],
})

export const sharedUrlAtom = atom<SharedUrl | undefined>({
  key: `${prefix}/sharedUrlAtom`,
  default: undefined,
})

export const alreadyScrolledToCommentAtom = atom<boolean>({
  key: `${prefix}/alreadyScrolledToCommentAtom`,
  default: false,
})

export const autoDrillDownParameterAtom = atom<DrillDownParameter | undefined>({
  key: `${prefix}/autoDrillDownParameterAtom`,
  default: undefined,
})

export const convertedSheetCustomModelsSelector = selector({
  key: `view/convertedSheetCustomModelsSelector`,
  get({ get }) {
    const view = get(viewAtom)
    const me = get(meAtom)
    if (isNull(view)) {
      // ありえないはずだが一応
      return []
    }
    const sheetCustomModels = get(sheetCustomModelsAtom)
    return sheetCustomModels.map((x) => convertSheetCustomModel(x))
  },
})

export const compiledConfigSelector = selector({
  key: `view/compiledConfigSelector`,
  get({ get }) {
    const view = get(viewAtom)
    const config = get(configAtom)
    const empty = {
      config: { type: 'sheet' },
      queries: [],
      ui: [],
      logs: undefined,
    }
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (view === undefined) {
      // 初期化のタイミングのみのはず
      return empty
    }
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (config === undefined) {
      return {
        config: view.config,
        queries: view.queries,
        ui: view.ui,
        logs: undefined,
      }
    }

    const { views } = get(viewsRelatedSelector)
    const { models } = get(viewsContextAtom)
    const convertedSheetCustomModels = get(convertedSheetCustomModelsSelector)
    const me = get(meAtom)
    const additionalConfig = get(additionalConfigAtom)
    const kpiUserAppearance = get(kpiUserAppearanceAtom)

    const compiled = compileViewConfig(
      config,
      {
        view,
        models: [...models, ...convertedSheetCustomModels],
        views,
        organizationSetting: {
          ...me.organization.setting,
          dimensionGroups: me.organization.dimensionGroups,
        },
      },
      {
        additionalConfig: {
          ...additionalConfig,
          kpiParameterPerUser: {
            totalRowOrder: kpiUserAppearance.totalRowOrder,
            totalColumnOrder: kpiUserAppearance.totalColumnOrder,
            skipTotal: kpiUserAppearance.skipTotal,
          },
        },
      },
    )
    if (compiled === undefined) {
      return empty
    }

    return {
      config,
      queries: compiled.queries,
      ui: compiled.ui,
      logs: compiled.context.logs,
    }
  },
})

export const configSheetAtom = selector<ViewConfigSheet>({
  key: `${prefix}/configSheet`,
  get({ get }) {
    const config = get(configAtom)

    if (config.type === 'sheet') {
      return config
    }

    return {
      type: 'sheet',
    }
  },
  set({ get, set }, config) {
    set(configAtom, config)
  },
})

export const kpiPivotConfigSelector = selector<ViewConfigKpiPivot | undefined>({
  key: `${prefix}/kpiPivotConfigSelector`,
  get({ get }) {
    const config = get(configAtom)

    if (config.type === 'kpiPivot') {
      return config
    }
  },
  set({ get, set }, config) {
    if (config === undefined) {
      return
    }
    set(configAtom, config)
  },
})

export const kpiConfigSelector = selector<ViewConfigKpi | undefined>({
  key: `${prefix}/kpiConfigSelector`,
  get({ get }) {
    const config = get(configAtom)

    if (config.type === 'kpi') {
      return config
    }
  },
  set({ get, set }, config) {
    if (config === undefined) {
      return
    }
    set(configAtom, config)
  },
})

export const kpiTimeSeriesConfigSelector = selector<ViewConfigKpiTimeSeries | undefined>({
  key: `${prefix}/kpiTimeSeriesConfigSelector`,
  get({ get }) {
    const config = get(configAtom)

    if (config.type === 'kpiTimeSeries') {
      return config
    }
  },
  set({ get, set }, config) {
    if (config === undefined) {
      return
    }
    set(configAtom, config)
  },
})

// export const queriesAtom = atom<ViewQuery[]>({
//   key: `${prefix}/queries`,
//   default: [],
// })

// export const uiAtom = atom<ViewUI>({
//   key: `${prefix}/ui`,
//   default: undefined as never,
// })

export const queriesAtom = selector<ViewQuery[]>({
  key: `${prefix}/queries`,
  get({ get }) {
    const { queries } = get(compiledConfigSelector)
    return queries
  },
  // deprecated
  set({ get, set }, value) {
    // empty
  },
})

export const uiAtom = selector<ViewUI>({
  key: `${prefix}/ui`,
  get({ get }) {
    const { ui } = get(compiledConfigSelector)
    return ui
  },
  // deprecated
  set({ get, set }, value) {
    // empty
  },
})

export const listQueryAtom = selector<ViewQueryList>({
  key: `${prefix}/query`,
  get({ get }) {
    const queries = get(queriesAtom)
    const query = queries.find((x) => x.type === 'list')

    // TODO: undefinedを許したい
    if (query === undefined) {
      return emptyQuery
    }
    // 冗長だが、型をつけるために必要
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (query.type === 'list') {
      return query
    }

    return emptyQuery
  },
  // deprecated
  set({ get, set }, query) {
    const queries = get(queriesAtom)
    // DefaultValue型を避けるためにasが必要。他に回避方法はないか？
    // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
    set(queriesAtom, [query as ViewQueryList, ...queries.filter((x) => x.type !== 'list')].compact())
  },
})

export const aggregationQueriesSelecttor = selector<ViewQueryAggregation[]>({
  key: `${prefix}/aggregationQueriesSelecttor`,
  get({ get }) {
    const queries = get(queriesAtom)
    const aggregationQueries = queries.map((x) => (x.type === 'aggregation' ? x : undefined)).compact()

    return aggregationQueries
  },
})

export const multiTablePivotQuerySelecttor = selector<ViewQueryMultiTablePivot | undefined>({
  key: `${prefix}/multiTablePivotQuery`,
  get({ get }) {
    const queries = get(queriesAtom)
    const query = queries.find((x) => x.type === 'multiTablePivot')

    // TODO: emptyを許したい
    if (query === undefined) {
      return
    }
    // 冗長だが、型をつけるために必要
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (query.type === 'multiTablePivot') {
      return query
    }
  },
})

export const hasChangeToKpiPivotParameterAtom = atom<boolean>({
  key: `${prefix}/hasChangeToKpiPivotParameter`,
  default: false,
})

// viewがundefinedでも値をセットしたいケースがあったので初期化時(initializeViewMutation)にのみ使用。
// その他の箇所での呼び出しはhasChangeToViewSchemaAtomを利用すること
export const hasChangeToViewSchemaAtomRaw = atom<boolean>({
  key: `${prefix}/hasChangeToViewSchemaAtomRaw`,
  default: false,
})

export const hasChangeToViewSchemaAtom = selector<boolean>({
  key: `view/hasChangeToViewSchemaAtom`,
  get({ get }) {
    return get(hasChangeToViewSchemaAtomRaw)
  },
  set({ get, set }, flag) {
    // 「lockedなときにconfigが更新されない」というロジックをconfigのatomsのレイヤーで書いてしまっているので、
    // それに合わせてchangesもここで書く
    const view = get(viewAtom)
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (view === undefined) {
      // TODO: 初期化時にviewがundefinedになりうる？
      return
    }
    set(hasChangeToViewSchemaAtomRaw, flag)
  },
})

export const isSavingCurrentViewAtom = atom<boolean>({
  key: `${prefix}/isSavingCurrentViewAtom`,
  default: false,
})

export const isSavingWithoutInteractionAtom = atom<boolean>({
  key: `${prefix}/isSavingWithoutInteractionAtom`,
  default: false,
})

export const isUpdatedSheetPresetAtom = atom<boolean>({
  key: `${prefix}/isUpdatedSheetPresetAtom`,
  default: false,
})

export const oldestSyncedAtAtom = atom<string | undefined>({
  key: `${prefix}/oldestSyncedAtAtom`,
  default: undefined,
})

export const isPastingAtom = atom<boolean>({
  key: `${prefix}/isPastingAtom`,
  default: false,
})

export interface CachedView {
  viewId: string
  config?: ViewConfig
  changes?: ChangeHistory[]
  additionalConfig?: ViewConfigAdditionalConfig
  kpiPivotPickedPresetName?: string
  sheetPickedPresetName?: string
  kpiForm?: KpiForm
  isModifiedKpiForm?: boolean
  oldestSyncedAt?: string
  updatedAt?: string
  hasChangeToViewSchema?: boolean
  hasChangeToKpiPivotParameter?: boolean
  sheetInSaveErrors?: EltChangeResult[]
  errorsDrawerOpen?: boolean
  changesModal?: ModalAtomType<void>
  // シートを保存してタブを移動した時など、次回のrefetchでレコードのキャッシュを使わないためのフラグ
  shouldIgnoreRefetchCache?: boolean
}

export const cachedViewsAtom = atom<CachedView[]>({
  key: `${prefix}/cachedViewsAtom`,
  default: [],
})

export interface DurationMetrics {
  viewId: string
  trigger: 'tabChanged' | 'drillDownModalOpened' | 'kpiPreviewUpdated'
  startAt: number
  isRefetchCacheUsed: boolean
  cachedPredictedDuration?: number
  noCachedPredictedDuration?: number
}

export const durationMetricsListAtom = atom<DurationMetrics[]>({
  key: `${prefix}/durationMetricsListAtom`,
  default: [],
})

export interface LoadingProgress {
  completed: boolean
}

export const loadingProgressAtom = atom<LoadingProgress>({
  key: `${prefix}/renderProgressAtom`,
  default: {
    completed: false,
  },
})

// XXX: こういうatomをどこに置くのか？設計が破綻している。
type KpiForm = Partial<Omit<ViewConfigKpi, 'measure' | 'user'>> & {
  name?: string
  viewGroupId?: string | null
  dashboardIds?: string[] // TODO 初期化
  measure?: Partial<ViewConfigKpi['measure']>
  user?: Partial<ViewConfigKpi['user']>
  // 基本的にこのKpiFormの状態=UIの状態になっているが、
  // 絞り込みのUIのみ、他の箇所でも使い回すetcのため、絞り込みコンポーネント内部で独自の状態を持っている。
  // この絞り込みUI以外の箇所から状態を変更した時、コンポーネント内部の状態を更新する必要があるので、
  // これを検出するために以下のキャッシュキーを使っている（詳細はこの呼び出し元であるKpiFormFilterTreeFormItem側を参照）
  filterFormItemCacheKey?: string
  dateY?: ViewConfigKpi['date']
}

export const kpiFormAtom = atom<KpiForm>({
  key: `${prefix}/kpiForm`,
  default: {},
})

export const kpiFormModifiedAtom = atom<boolean>({
  key: `${prefix}/kpiFormModified`,
  default: false,
})

// antdの都合で0はじまり
export const kpiFormStepAtom = atom<0 | 1 | 2 | 3>({
  key: `${prefix}/kpiFormStepAtom`,
  default: 0,
})

// antdの都合で0はじまり
export const kpiFormCallbackFlagsAtom = atom<{ setDefaultByRoot: boolean }>({
  key: `${prefix}/kpiFormCallbackFlagsAtom`,
  default: {
    setDefaultByRoot: false,
  },
})

// フォームUIのバリデーションエラー表示を制御するために使う予定
// 初期表示はfalseで、一度でも保存ボタンを押したらtrue、保存成功したらfalseにする
export const validationErrorVisibilityAtom = atom<boolean>({
  key: `${prefix}/validationErrorVisibilityAtom`,
  default: false,
})

// RI
export const riFormAtom = atom<ViewConfigKpiTimeSeries | undefined>({
  key: `${prefix}/riFormAtom`,
  default: undefined,
})
