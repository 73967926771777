import { isNull, isPresent, isSome, isTruthy } from '@salescore/buff-common'
import { CORE_CONSTANT } from '@salescore/core'
import { selector, useRecoilValue } from 'recoil'

import { type CachedView, cachedViewsAtom, oldestSyncedAtAtom, viewAtom, viewsContextAtom } from '../atoms'

// TODO: 見通しが悪くなってきたので、コードを整理した方が良さそう
export const cachedViewsSelector = selector({
  key: `view/cachedViewsSelector`,
  get({ get }) {
    const view = get(viewAtom)
    const viewsContext = get(viewsContextAtom)
    const cachedViews = viewsContext.cachedViews ?? get(cachedViewsAtom)
    const oldestSyncedAt = get(oldestSyncedAtAtom)
    const { updateCachedView } = viewsContext

    // KPIプレビューやドリルダウンモーダルではcachedViewを利用しない
    const ignoreViewIdPrefixes = [
      CORE_CONSTANT.KPI_PREVIEW_VIEW_ID_PREFIX,
      CORE_CONSTANT.KPI_PREVIEW_DRILL_DOWN_VIEW_ID_PREFIX,
      CORE_CONSTANT.KPI_SHEET_DYNAMIC_VIEW_ID_PREFIX,
      CORE_CONSTANT.SHEET_FORM_DYNAMIC_VIEW_ID_PREFIX,
    ]
    const filteredCachedViews = getIgnoreFilteredCachedViews()
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const currentCachedView = getCachedView(view?.id)
    const unsavedCachedViews = getUnsavedCachedViews(cachedViews)
    // refetch時にApolloのキャッシュを利用するかどうか
    const shouldUseRefetchCache = getShouldUseRefetchCache({
      currentCachedView,
      oldestSyncedAt,
      isDrillDown: viewsContext.isDrillDown,
    })

    function getIgnoreFilteredCachedViews() {
      return cachedViews.filter((x) => !ignoreViewIdPrefixes.some((y) => x.viewId.startsWith(y)))
    }

    function getCachedView(viewId?: string) {
      return getIgnoreFilteredCachedViews().find((x) => x.viewId === viewId)
    }

    function resetIgnoreCacheFlag() {
      // shouldIgnoreRefetchCacheフラグは一度refetchしたらリセットする
      if (isSome(currentCachedView) && isTruthy(currentCachedView.shouldIgnoreRefetchCache)) {
        updateCachedView?.({
          viewId: currentCachedView.viewId,
          shouldIgnoreRefetchCache: false,
        })
      }
    }

    return {
      cachedViews,
      filteredCachedViews,
      currentCachedView,
      unsavedCachedViews,
      shouldUseRefetchCache,
      getCachedView,
      getShouldUseRefetchCache,
      updateCachedView,
      resetIgnoreCacheFlag,
    }
  },
})

function getUnsavedCachedViews(cachedViews: CachedView[]) {
  return cachedViews.filter((cachedView) => {
    switch (cachedView.config?.type) {
      case 'sheet': {
        return isTruthy(cachedView.hasChangeToViewSchema) || isPresent(cachedView.changes)
      }
      case 'kpiPivot': {
        return isTruthy(cachedView.hasChangeToKpiPivotParameter)
      }
      case 'kpi': {
        return isTruthy(cachedView.isModifiedKpiForm)
      }
      default: {
        return false
      }
    }
  })
}

function getShouldUseRefetchCache({
  currentCachedView,
  oldestSyncedAt,
  isDrillDown,
}: {
  currentCachedView?: CachedView
  oldestSyncedAt?: string
  isDrillDown?: boolean
}): boolean {
  // ドリルダウンモーダル内でキャッシュを利用すると、ドリルダウン元でデータが変更されたり、
  // さらに子階層のドリルダウンでデータが変更された場合などにデータ不整合が起きることがあったため、
  // ドリルダウン内ではキャッシュを利用しないようにしている
  if (isTruthy(isDrillDown)) {
    return false
  }

  return (
    isEqualOldestSyncedAt({ currentCachedView, oldestSyncedAt }) &&
    !isTruthy(currentCachedView?.shouldIgnoreRefetchCache)
  )
}

function isEqualOldestSyncedAt({
  currentCachedView,
  oldestSyncedAt,
}: {
  currentCachedView?: CachedView
  oldestSyncedAt?: string
}) {
  if (isNull(currentCachedView)) {
    return false
  }
  // キャッシュにはoldestSyncedAtが入っているが、RecoilのoldestSyncedAtの値はundefinedである時
  // これは現在のviewのoldestSyncedAtのフェッチが終わっていないケースなので差分として検知しない
  if (isPresent(currentCachedView.oldestSyncedAt) && isNull(oldestSyncedAt)) {
    return true
  }
  return currentCachedView.oldestSyncedAt === oldestSyncedAt
}

export function useCachedViewsSelector() {
  return useRecoilValue(cachedViewsSelector)
}
