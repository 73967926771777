import { useMutation } from '@apollo/client'
import type { SourceFieldsFragment } from '@salescore/client-api'
import { CreateKintoneUserSourceDocument } from '@salescore/client-api'
import {
  CLIENT_COMMON_CONSTANT,
  getOrganizationIdFromPath,
  ProviderLogo,
  useOpenAuthorizationUrl,
} from '@salescore/client-common'
import { App, Button, Form, Input, Table } from 'antd'
import { t } from 'i18next'

const KintoneForm = ({
  source,
  afterFinish,
}: {
  source: SourceFieldsFragment
  afterFinish: () => void
}): JSX.Element => {
  const { message } = App.useApp()
  const [form] = Form.useForm<{ id: string; password: string }>()
  const [createKintoneUserSource] = useMutation(CreateKintoneUserSourceDocument)
  return (
    <>
      <Form
        layout="vertical"
        form={form}
        onFinish={(f): void => {
          void createKintoneUserSource({
            variables: {
              sourceId: source.id,
              kintoneId: f.id,
              kintonePassword: f.password,
              organizationId: getOrganizationIdFromPath(),
            },
            onCompleted: () => {
              // form.resetFields()
              message.success(t('設定を保存しました'))
              afterFinish()
            },
            onError: () => {
              message.error(t('エラーが発生しました'))
            },
          })
        }}
      >
        <Form.Item label={t(`ID`)} name="id" required rules={[{ message: t(`IDを入力してください。`) }]}>
          <Input placeholder="" />
        </Form.Item>
        <Form.Item
          label={t(`パスワード`)}
          name="password"
          required
          rules={[{ message: t(`パスワードを入力してください。`) }]}
        >
          <Input type="password" placeholder="" />
        </Form.Item>
        <Form.Item>
          <Button type={'primary'} htmlType="submit">
            {t(`{{provider}}と連携`, {
              provider: CLIENT_COMMON_CONSTANT.i18n.ja.provider.kintone,
            })}
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}

export const CreateUserSourceModalContent = ({
  sources,
  afterFinish,
}: {
  sources: SourceFieldsFragment[]
  afterFinish: () => void
}): JSX.Element => {
  const { openAuthorizationUrl, loadingProvider } = useOpenAuthorizationUrl()
  const availableSources = sources.filter((x) => !['salescore', 'google_sheets', 'postgres'].includes(x.provider)) // TODO: source側で、ユーザー連携が可能なもの(=sync対応しているもの)のリストが欲しい
  return (
    <Table
      dataSource={availableSources}
      rowKey="id"
      pagination={false}
      columns={[
        {
          title: t(`連携先`),
          render(_text, record) {
            return <ProviderLogo provider={record.provider} />
          },
        },
        {
          title: t(`連携先名称`),
          render(_text, record) {
            return <span>{record.name}</span>
          },
        },
        {
          title: '',
          render(_text, record) {
            const onClick = (): void => {
              void openAuthorizationUrl({
                type: 'userSource',
                provider: record.provider,
                source: record,
              })
            }
            if (record.provider === 'kintone') {
              return <KintoneForm source={record} afterFinish={afterFinish} />
            }
            return (
              <Button loading={record.provider === loadingProvider} type="primary" onClick={onClick}>
                {t(`{{provider}}と連携`, { provider: CLIENT_COMMON_CONSTANT.i18n.ja.provider[record.provider] })}
              </Button>
            )
          },
        },
      ]}
    />
  )
}

// FYI
// https://developers.google.com/identity/branding-guidelines?hl=ja
function GoogleSignInButton({ onClick }: { onClick: () => void }): JSX.Element {
  return (
    <span className=" cursor-pointer hover:opacity-80" onClick={onClick}>
      {t(`Googleと連携`)}
      <img style={{ paddingLeft: 10 }} width={180} src={`/images/provider_icon/google_button.png`} alt={'google'} />
    </span>
  )
}
