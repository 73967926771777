import { useCursorSelector } from '../../recoil/selectors/cursorSelector'
import { RSheetsStyle } from '../../util/RSheetsStyle'

export function CopyingCursorBox({ asFixed }: { asFixed: boolean }) {
  const { columnBlocksRelated, copiedArea, cursor } = useCursorSelector()
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (copiedArea === undefined || columnBlocksRelated === undefined || cursor.copying?.visible === false) {
    return <></>
  }
  const { x, y, width, height, fixedRightWidth } = copiedArea
  const boxWidth = asFixed ? Math.min(width, fixedRightWidth) : width

  if (boxWidth === 0) {
    return <></>
  }

  return (
    <div
      style={{
        position: 'absolute',
        top: y + columnBlocksRelated.headerHeight,
        left: x,
        width: boxWidth,
        height,
        pointerEvents: 'none',
        border: `2px #3274E0 dashed`,
        zIndex: asFixed ? RSheetsStyle.zIndex.cursorWhenLeftFixed : RSheetsStyle.zIndex.cursor,
      }}
    />
  )
}
