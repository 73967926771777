import {
  MinusCircleOutlined,
  PartitionOutlined,
  PlusCircleOutlined,
  RightOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import { isNull } from '@salescore/buff-common'
import type { ViewConfigTreeNode } from '@salescore/core'
import { Button, Table } from 'antd'
import { t } from 'i18next'
import { useMemo, useState } from 'react'

import type { ReferenceToPropertyForAddChildNodeMutation } from '../../../../recoil/view/mutations/tree/addChildNode'
import { useConnectionsSelector } from '../../../../recoil/view/selectors/connectionsSelector'
import { TABLE_HEIGHT } from './const'

export function isSystemModel(modelName: string): boolean {
  return [
    `salescore_users`,
    `salescore_user_groups`,
    `salescore_kpis`,
    `salescore_goals`,
    `salescore_record_changes`,
    `salescore_my_actions`,
    `salescore_reverse_elt_logs`,
  ].includes(modelName)
}

export function PropertySelectorTableBodyForParentChildrenNode({
  node,
  addChildNode,
}: {
  node: ViewConfigTreeNode
  addChildNode: (referenceToProperty: ReferenceToPropertyForAddChildNodeMutation) => void
}) {
  const { models, getModel } = useConnectionsSelector()

  const { referenceToProperties } = useMemo(() => {
    const currentModel = getModel(node.modelName)
    if (currentModel === undefined) {
      return {
        referenceToProperties: [],
      }
    }

    //
    // 子ノードの候補を作成
    //
    // このノードのモデルの参照プロパティ
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const parentRelationNodes = (currentModel.properties ?? []).flatMap((property) => {
      const referenceTo = property.referenceTo ?? []
      if (referenceTo.isEmpty()) {
        return []
      }

      return referenceTo
        .map((referenceToValue) => {
          const referencedModel = getModel(referenceToValue.modelName)
          if (referencedModel === undefined) {
            return
          }

          const referenceToProperty: ReferenceToPropertyForAddChildNodeMutation = {
            parentNodeName: node.name,
            nodeName: node.name,
            modelName: currentModel.name,
            propertyName: property.name,
            referenceTo: referenceToValue,
          }
          return {
            type: 'parent',
            newNodeModel: referencedModel,
            property,
            description: t(`{{label1}}と{{label2}}で紐付け`, {
              label1: `${referencedModel.label}.${referenceToValue.key ?? 'id'}`,
              label2: `${currentModel.label}.${property.label}`,
            }),
            referenceToProperty,
          }
        })
        .compact()
    })

    // このノードのモデルを参照している、他のモデルの参照プロパティ
    const childRelationNodes = models
      .filter((x) => !isSystemModel(x.name))
      .flatMap((model) =>
        // 2023/02/13 同じモデルでも表示させることにした。元々なぜこれを廃止していたか謎。ブロックの表示が微妙になるのは別途解消する
        // if (model.name === currentModel.name) {
        //   return []
        // }
        model.properties.map((property) => {
          const referenceTo = property.referenceTo ?? []
          // eslint-disable-next-line max-nested-callbacks
          const referenceToValue = referenceTo.find((x) => x.modelName === currentModel.name)
          if (isNull(referenceToValue)) {
            return
          }

          const referenceToProperty: ReferenceToPropertyForAddChildNodeMutation = {
            parentNodeName: node.name,
            nodeName: undefined,
            modelName: model.name,
            propertyName: property.name,
            referenceTo: referenceToValue,
          }

          return {
            type: 'child',
            newNodeModel: model,
            property,
            description: t(`{{label1}}と{{label2}}で紐づけ`, {
              label1: `${model.label}.${property.label}`,
              label2: `${currentModel.label}.${referenceToValue.key ?? 'id'}`,
            }),
            referenceToProperty,
          }
        }),
      )
      .compact()

    return {
      referenceToProperties: [...parentRelationNodes, ...childRelationNodes],
    }
  }, [node])

  const nodeGroups = referenceToProperties
    .groupBy((x) => [x.newNodeModel.name, x.type].join(','))
    .map((_, rProperties) => {
      const { newNodeModel } = rProperties.first()!
      return {
        key: [newNodeModel.name, rProperties.first()!.type].join(`_`),
        newNodeModel,
        type: rProperties.first()!.type,
        referenceToProperties: rProperties,
      }
    })

  const [expandedRowKey, setExpandedRowKey] = useState<string | undefined>()
  return (
    <>
      <Table
        scroll={{ y: TABLE_HEIGHT }}
        dataSource={nodeGroups}
        pagination={false}
        size="small"
        rowClassName="cursor-pointer hover:opacity-80"
        expandable={{
          columnWidth: 24,
          rowExpandable(nodeGroup) {
            return nodeGroup.referenceToProperties.length > 1
          },
          expandedRowKeys: [expandedRowKey].compact(),
          expandRowByClick: true,
          expandIcon(properties) {
            if (!properties.expandable) {
              return <></>
            }
            if (properties.expanded) {
              return (
                <MinusCircleOutlined
                  onClick={() => {
                    setExpandedRowKey(undefined)
                  }}
                />
              )
            }
            return (
              <PlusCircleOutlined
                onClick={() => {
                  setExpandedRowKey(properties.record.key)
                }}
              />
            )
          },
          expandedRowRender(nodeGroup) {
            if (nodeGroup.referenceToProperties.length <= 1) {
              return
            }
            return (
              <Table
                dataSource={nodeGroup.referenceToProperties}
                pagination={false}
                showHeader={false}
                onRow={(node, rowIndex) => ({
                  onClick() {
                    addChildNode(node.referenceToProperty)
                  },
                  // className: isIncluded(node, viewQueryFields) ? 'bg-blue-50' : '',
                })}
                columns={[
                  {
                    key: 'description',
                    render(_value, node) {
                      return <span className="text-sm">{node.description}</span>
                    },
                  },
                  {
                    width: 40,
                    render(_, record) {
                      return (
                        <span>
                          <Button
                            // type="text"
                            size="small"
                            icon={<RightOutlined />}
                            className="disable-on-click-outside"
                          />
                        </span>
                      )
                    },
                  },
                ]}
              />
            )
          },
        }}
        onRow={(nodeGroup, rowIndex) => ({
          onClick() {
            if (nodeGroup.referenceToProperties.length > 1) {
              setExpandedRowKey((oldKey) => (nodeGroup.key === oldKey ? undefined : nodeGroup.key))
              return
            }
            setExpandedRowKey(undefined)

            addChildNode(nodeGroup.referenceToProperties.first()!.referenceToProperty)
          },
          // className: isIncluded(record, viewQueryFields) ? 'bg-blue-50' : '',
        })}
        columns={[
          {
            title: '',
            key: 'relationFromParentNode',
            width: 28,
            render(_, nodeGroup) {
              if (nodeGroup.type === 'parent') {
                return <SearchOutlined />
              }
              return <PartitionOutlined />
              // return <ClusterOutlined />
            },
          },
          {
            dataIndex: 'propertyLabel',
            title: t(`オブジェクト名`),
            render(_, nodeGroup) {
              return (
                <div>
                  <span>{nodeGroup.newNodeModel.label}</span>
                  {nodeGroup.referenceToProperties.length === 1 && (
                    <span className="ml-2 text-gray-400" style={{ fontSize: 10 }}>
                      {nodeGroup.referenceToProperties.first()!.description}
                    </span>
                  )}
                </div>
              )
            },
          },
          {
            width: 40,
            render(_, nodeGroup) {
              if (nodeGroup.referenceToProperties.length === 1) {
                return (
                  <span>
                    <Button
                      // type="text"
                      size="small"
                      icon={<RightOutlined />}
                      className="disable-on-click-outside"
                    />
                  </span>
                )
              }
              return <></>
            },
          },
        ]}
      />
    </>
  )
}
