// eslint-disable-next-line @eslint-community/eslint-comments/disable-enable-pair
/* eslint-disable max-lines */
import { SaveOutlined } from '@ant-design/icons'
import { isNull } from '@salescore/buff-common'
import type { ViewForSiderFieldsFragment } from '@salescore/client-api'
import { PickableSearchableList } from '@salescore/client-common'
import {
  CORE_CONSTANT,
  getRecordNodesByPath,
  type ViewConfigKpiParameter,
  type ViewConfigKpiPivot,
  type ViewQueryRecordNode,
  type ViewUiKpiPivot,
} from '@salescore/core'
import { ButtonWithTooltip, generateRandomString, Kanban, type KanbanGroup, useModal } from '@salescore/frontend-common'
import { message, Modal } from 'antd'
import { t } from 'i18next'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useSetRecoilState } from 'recoil'

import {
  useKpiPivotNavigationModal,
  useKpiPivotParameter,
  useKpiPivotPickedPresetName,
} from '../../../recoil/navigation/hooks'
import { useLoadingState, useRecordsValue } from '../../../recoil/records/hooks'
import { hasChangeToViewSchemaAtom } from '../../../recoil/view/atoms'
import {
  useViewAbilityValue,
  useViewConfigKpiPivot,
  useViewsContextValue,
  useViewValue,
} from '../../../recoil/view/hooks'
import { useViewsRelated } from '../../../recoil/view/selectors/viewsRelatedSelector'
import { useColumnsValue } from '../../../rsheet/recoil/models/propModels'
import type { RSheetColumn, RSheetRecordNode } from '../../../rsheet/types'

export function KpiPivotKpisFormModal({ component }: { component: ViewUiKpiPivot }): JSX.Element {
  const { kpiPivotKpisModal } = useKpiPivotNavigationModal()

  return (
    <Modal
      open={kpiPivotKpisModal.isModalVisible}
      onCancel={() => {
        kpiPivotKpisModal.hideModal()
      }}
      title={t(`ダッシュボードに表示するKPIの編集`)}
      width={'90%'}
      cancelText={t(`閉じる`)}
      okButtonProps={{ style: { display: 'none' } }}
      style={{
        // top: '3%',
        maxWidth: 1800,
      }}
      destroyOnClose
      footer={<></>}
    >
      <KpiPivotKpisForm
        onFinish={() => {
          kpiPivotKpisModal.hideModal()
        }}
      />
    </Modal>
  )
}

export function KpiPivotKpisForm({ onFinish }: { onFinish: () => void }): JSX.Element {
  const view = useViewValue()
  const { newKpiFormModal } = useKpiPivotNavigationModal()
  const [kpiPivotParameter, setKpiPivotParameter] = useKpiPivotParameter()
  const ability = useViewAbilityValue()
  const loading = useLoadingState()
  const [config, setConfig] = useViewConfigKpiPivot()
  const { onAddResource } = useViewsContextValue()
  const { kpiViews, searchParentViewGroups } = useViewsRelated()
  const recordNodes = useRecordsValue()
  const columns = useColumnsValue()
  const records = kpiViews
    // 公開されており、削除されていないものに絞る
    // ただし、このビュー自体が非公開だったら、非公開KPIも選択可能
    .filter((x) => (view.private || !x.private) && !x.archived)
    .map((kpiView) => ({
      id: kpiView.id,
      name: kpiView.name,
      path: searchParentViewGroups(kpiView.viewGroupId)
        .reverse()
        .map((viewGroup) => viewGroup.name),
      createdBy: kpiView.createdBy?.name,
    }))
  const setHasChangeToViewSchema = useSetRecoilState(hasChangeToViewSchemaAtom)
  const [groups, setGroups] = useState(() => {
    const kpis = config?.kpis ?? []
    const groups = getSortedKpiGroups({ kpis, kpiViews, pivot: kpiPivotParameter.pivot, records: recordNodes, columns })
    return groups
  })
  const addKpiModal = useModal<{ groupId: string }>()
  const [picked] = useKpiPivotPickedPresetName()
  const {
    query: { organizationId },
  } = useRouter()
  const [copiedKpis, setCopiedKpis] = useState<Array<{ viewId: string; kpiGroupName: string }>>()
  const [needsSaving, setNeedsSaving] = useState(false)

  if (config === undefined) {
    return <></>
  }

  const generateRandomGroupName = (length = 5): string => {
    let temporaryGroupName = ''
    do {
      temporaryGroupName = `${t(`グループ`)}-${generateRandomString(length)}`
    } while (groups.map((g) => g.id).includes(temporaryGroupName))
    return temporaryGroupName
  }

  const save = async () => {
    loading.setTrue()
    try {
      const kpis = groups.flatMap((group) => {
        // KPIグループにKPIが存在しない場合に空グループが作れるように、viewIdに重複しない仮の値を入れる
        if (group.items.isEmpty()) {
          return {
            viewId: `${view.id}-${group.label}-noneKpiGroup`,
            kpiGroupName: group.label,
          }
        }
        return group.items.map((kpi) => ({
          viewId: kpi.id,
          kpiGroupName: group.label,
        }))
      })
      const getNewKpiPivotParameter = (oldValue: ViewConfigKpiParameter) => ({
        ...oldValue,
        pivot: {
          ...oldValue.pivot,
          rows: oldValue.pivot.rows.map((d) => {
            if (d.key !== CORE_CONSTANT.KPI_PIVOT_KPI_GROUP_NAME_COLUMN_NAME) {
              return d
            }
            return {
              ...d,
              sortedValues: kpis.map((x) => x.kpiGroupName),
            }
          }),
          columns: oldValue.pivot.columns.map((d) => {
            if (d.key !== CORE_CONSTANT.KPI_PIVOT_KPI_GROUP_NAME_COLUMN_NAME) {
              return d
            }
            return {
              ...d,
              sortedValues: kpis.map((x) => x.kpiGroupName),
            }
          }),
        },
      })
      const deleteInvisibleKpiIds = (invisibleKpiIds: string[]) =>
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        invisibleKpiIds.filter((id) => kpis.find((kpi) => kpi.viewId === id))
      setConfig((oldValue) => ({
        ...oldValue,
        ...config,
        kpis,
        presets: oldValue?.presets?.map((p) => {
          const newPreset =
            p.name === picked
              ? {
                  ...p,
                  parameter: getNewKpiPivotParameter(kpiPivotParameter),
                }
              : p
          return {
            ...newPreset,
            parameter: {
              ...newPreset.parameter,
              invisibleKpiIds: deleteInvisibleKpiIds(newPreset.parameter.invisibleKpiIds ?? []),
            },
          }
        }),
      }))
      setKpiPivotParameter((oldValue) => {
        const newKpiPivotParameter = getNewKpiPivotParameter(oldValue)
        return {
          ...newKpiPivotParameter,
          invisibleKpiIds: deleteInvisibleKpiIds(newKpiPivotParameter.invisibleKpiIds ?? []),
        }
      })
      setHasChangeToViewSchema(true)
      await onAddResource({ viewIds: kpis.map((x) => x.viewId) })
      void message.success(t(`KPI設定を保存しました。`))
      if (picked !== undefined) {
        void message.success(t(`集計プリセットを保存しました`))
      }
      loading.setFalse()
    } catch (error) {
      loading.setFalse()
      if (error instanceof Error) {
        void message.error(`${t(`エラーが発生しました。`)}${error.message}`)
      } else {
        void message.error(t(`エラーが発生しました。`))
      }
    }
  }

  const onSave = async () => {
    await save()
    onFinish()
  }

  useEffect(() => {
    if (copiedKpis === undefined) {
      return
    }

    const newItems = copiedKpis
      .map((ck) => {
        const item = kpiViews.find((kv) => kv.id === ck.viewId)
        if (item === undefined) {
          return
        }
        return [
          ck.kpiGroupName,
          {
            id: item.id,
            label: item.name,
            item,
            checked: false,
          },
        ] as const
      })
      .compact()

    setGroups((previous) =>
      previous.map((g) => ({
        ...g,
        items: [
          // eslint-disable-next-line max-nested-callbacks
          ...g.items.map((index) => ({ ...index, checked: false })),
          // eslint-disable-next-line max-nested-callbacks
          ...newItems.filter((ni) => ni[0] === g.id).map((ni) => ni[1]),
          // eslint-disable-next-line max-nested-callbacks
        ].uniqueBy((index) => index.id),
      })),
    )

    setCopiedKpis(undefined)
    setNeedsSaving(true)
  }, [kpiViews])

  useEffect(() => {
    if (!needsSaving) {
      return
    }

    void save()
    setNeedsSaving(false)
  }, [needsSaving])

  return (
    <div className="w-full">
      <p>
        {t(`ダッシュボードに表示したいKPIを選択してください。`)}
        <br />
        {t(`ドラッグアンドドロップで並び替えを行うこともできます。`)}
      </p>
      <Kanban
        groups={groups}
        setGroups={setGroups}
        addGroupLabel={t(`KPIグループを追加`)}
        addItemLabel={t(`KPIを追加`)}
        addGroup={() => {
          setGroups((groups) => {
            const newGroupName = generateRandomGroupName()
            return [
              ...groups,
              {
                id: newGroupName,
                label: newGroupName,
                group: newGroupName,
                items: [],
              },
            ]
          })
        }}
        addItem={(groupId) => {
          addKpiModal.showModal({ groupId })
        }}
        editGroup={(groupId, groupName) => {
          const otherGroups = groups.filter((x) => x.id !== groupId)
          if (otherGroups.map((x) => x.label).includes(groupName)) {
            void message.error(t(`同じグループ名を2つ以上設定することはできません`))
            return
          }
          setGroups((groups) =>
            groups.map((group) => {
              if (group.id !== groupId) {
                return group
              }
              return {
                id: groupName,
                label: groupName,
                group: groupName,
                items: group.items,
              }
            }),
          )
        }}
      />
      <div className="relative mt-4 flex flex-row-reverse">
        <ButtonWithTooltip
          tooltipTitle={t(`権限がないため保存できません`)}
          showTooltip={!ability.canUpdate}
          disabled={!ability.canUpdate}
          type="primary"
          icon={<SaveOutlined />}
          loading={loading.isTrue}
          onClick={async () => {
            await onSave()
          }}
        >
          {t(`保存`)}
        </ButtonWithTooltip>
      </div>
      <Modal
        open={addKpiModal.isModalVisible}
        onCancel={addKpiModal.hideModal}
        width={'90%'}
        cancelText={t(`閉じる`)}
        okButtonProps={{ style: { display: 'none' } }}
        title={<div>{t(`追加するKPIの選択`)}</div>}
        style={{ top: '3%', maxWidth: 600 }}
        footer={<div></div>}
        destroyOnClose
      >
        {addKpiModal.content !== undefined && (
          <div>
            {t(`{{groupId}}に追加するKPIを選択してください。`, { groupId: addKpiModal.content.groupId })}
            <PickableSearchableList
              records={records}
              initialValues={groups.find((x) => x.id === addKpiModal.content?.groupId)?.items.map((x) => x.id) ?? []}
              onAdd={
                ability.canCreate
                  ? (addValue) => {
                      newKpiFormModal.showModal({
                        onFinish: (kpiId) => {
                          addValue(kpiId)
                        },
                      })
                      addKpiModal.hideModal()
                    }
                  : undefined
              }
              onFinish={(picked) => {
                const pickedIds = new Set(picked.map((x) => x.id))
                setGroups((groups) =>
                  groups.map((group) => {
                    if (group.id !== addKpiModal.content!.groupId) {
                      return {
                        ...group,
                        items: group.items.filter((x) => !pickedIds.has(x.id)),
                      }
                    }
                    return {
                      ...group,
                      items:
                        // ...group.items,
                        picked
                          .map((kpiView) => ({
                            id: kpiView.id,
                            label: kpiView.name,
                            item: kpiView,
                            // eslint-disable-next-line max-nested-callbacks
                            checked: group.items.find((index) => index.id === kpiView.id)?.checked ?? false,
                          }))
                          .uniqueBy((x) => x.id),
                    }
                  }),
                )
                addKpiModal.hideModal()
              }}
            />
          </div>
        )}
      </Modal>
    </div>
  )
}

function getKpiGroupDimension(pivot: ViewConfigKpiParameter['pivot']) {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const columns = pivot.columns ?? []
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const rows = pivot.rows ?? []
  const dimension = [...columns, ...rows].find((x) => x.key === CORE_CONSTANT.KPI_PIVOT_KPI_GROUP_NAME_COLUMN_NAME)
  return dimension
}

function getSortedKpiGroups({
  kpis,
  kpiViews,
  pivot,
  records,
  columns,
}: {
  kpis: ViewConfigKpiPivot['kpis']
  kpiViews: ViewForSiderFieldsFragment[]
  pivot: ViewConfigKpiParameter['pivot']
  records: ViewQueryRecordNode[]
  columns: Array<RSheetColumn<RSheetRecordNode>>
}) {
  // eslint-disable-next-line unicorn/consistent-function-scoping
  function addCheckedFieldToItem<G, I>(g: KanbanGroup<G, I>): KanbanGroup<G, I> {
    return {
      ...g,
      items: g.items.map((index) => ({ ...index, checked: false })),
    }
  }

  const groups = getKpiGroups({ kpis, kpiViews })
  const dimension = getKpiGroupDimension(pivot)

  // view と preset の保存は別々のタイミングで行えるので、view の kpi groups と preset の kpi groups の集合に差分が生じる場合がある
  // ユニークな集合の差分がない場合のみ、ソート可能とする
  const shouldSort =
    groups
      .map((x) => x.label)
      .uniqueBy((x) => x)
      .sort()
      .join(',') ===
    dimension?.sortedValues
      ?.uniqueBy((x) => x)
      .sort()
      .join(',')
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (shouldSort && dimension.sortedValues) {
    return dimension.sortedValues
      .map((value) => groups.find((x) => x.label === value))
      .compact()
      .uniqueBy((x) => x.label)
      .map((element) => addCheckedFieldToItem(element))
  }

  const dimensionRowIndex = pivot.rows.findIndex((x) => x.key === CORE_CONSTANT.KPI_PIVOT_KPI_GROUP_NAME_COLUMN_NAME)
  if (dimensionRowIndex !== -1) {
    const field = columns[dimensionRowIndex + 1]?.field
    if (isNull(field)) {
      return []
    }

    const flattenRecords = records.flatMap((x) => getRecordNodesByPath(x, field.nodePath))
    return flattenRecords
      .map((x) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
        const value = x.attributes[field.name] as string
        return groups.find((kpi) => kpi.label === value)
      })
      .compact()
      .uniqueBy((x) => x.label)
      .map((element) => addCheckedFieldToItem(element))
  }

  return groups.map((element) => addCheckedFieldToItem(element))
}

function getKpiGroups({
  kpis,
  kpiViews,
}: {
  kpis: ViewConfigKpiPivot['kpis']
  kpiViews: ViewForSiderFieldsFragment[]
}) {
  const groups = kpis
    .groupBy((x) => x.kpiGroupName ?? CORE_CONSTANT.KPI_GROUP_DEFAULT_NAME)
    .map(
      (groupName, kpis): KanbanGroup<unknown, unknown> => ({
        id: groupName,
        label: groupName,
        group: groupName, // 使われない想定
        items: kpis
          .map((kpi) => {
            const kpiView = kpiViews.find((x) => x.id === kpi.viewId)
            if (kpiView === undefined) {
              return // TODO
            }
            return {
              id: kpi.viewId,
              label: kpiView.name,
              item: kpiView,
            }
          })
          .compact(),
      }),
    )
  if (groups.isBlank()) {
    // i18n: 影響反映の確認が必要なため一旦保留
    return [
      {
        id: `メインKPI`,
        label: `メインKPI`,
        group: `メインKPI`,
        items: [],
      },
    ]
  }
  return groups
}
