import { logger } from '@salescore/frontend-common'
import dayjs from 'dayjs'
import posthog from 'posthog-js'
import { useEffect } from 'react'

import type { PosthogEvent } from './posthog/posthog_events'

const isEnabled =
  process.env.POSTHOG_API_KEY !== undefined &&
  process.env.POSTHOG_API_URL !== undefined &&
  typeof window !== 'undefined'
if (isEnabled) {
  posthog.init(process.env.POSTHOG_API_KEY!, { api_host: process.env.POSTHOG_API_URL })
}

const track = (eventName: PosthogEvent, properties = {}) => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-type-assertion
    if (isEnabled && (properties as any).role !== 'admin') {
      posthog.capture(eventName, properties)
    } else {
      logger.debug(`[POSTHOG]`, eventName, properties)
    }
  } catch (error) {
    logger.debug(error)
  }
}

function getKey(identityId: string, key: string) {
  return `posthog-cloud/${identityId}/${key}`
}

const getPosthogCache = (identityId: string, key: string) => localStorage.getItem(getKey(identityId, key))

const setPosthogCache = (identityId: string, key: string, data: string) => {
  localStorage.setItem(getKey(identityId, key), data)
}

export const Posthog = {
  identifyOnce(identityId: string, properties: Array<Record<string, unknown>>) {
    useEffect(() => {
      const cache = getPosthogCache(identityId, 'identifyOnce')
      const newData = JSON.stringify(properties)
      if (cache !== newData) {
        if (isEnabled) {
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          posthog.identify(identityId, ...(properties ?? []))
        }
        setPosthogCache(identityId, 'identifyOnce', newData)
      }
    }, [identityId])
  },
  setPeopleOnce(identityId: string, properties: Array<Record<string, unknown>>) {
    useEffect(() => {
      const cache = getPosthogCache(identityId, 'setPeopleOnce')
      const newData = JSON.stringify(properties)
      if (cache !== newData) {
        for (const property of properties) {
          if (isEnabled) {
            // set_onceもあるが、このonceは「propertyがまだなかったら」の意味であり、後からpropertyに渡したい値が変わった時に対応できなくなるため使わない
            posthog.people.set(property)
          }
        }
        setPosthogCache(identityId, 'setPeopleOnce', newData)
      }
    }, [identityId])
  },
  trackAccess(pathname: string, property: Record<string, unknown>) {
    useEffect(() => {
      track('visit', {
        ...property,
        pathname,
        timestampJa: dayjs().format('YYYY年M月D日 H時m分s秒'),
      })
    }, [pathname])
  },
  trackOnce(value: PosthogEvent, properties = {}) {
    useEffect(() => {
      track(value, properties)
    }, [])
  },
  track,
}
