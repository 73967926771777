import { PlusOutlined } from '@ant-design/icons'
import { isPresent } from '@salescore/buff-common'
import type { ViewConfigKpiPivot } from '@salescore/core'
import { useBooleanState } from '@salescore/frontend-common'
import { Button, Empty, Input, message, Spin } from 'antd'
import { t } from 'i18next'
import { useState } from 'react'

import { useKpiPivotNavigationModal } from '../../../recoil/navigation/hooks'
import { useViewConfigKpiPivot, useViewsContextValue, useViewValue } from '../../../recoil/view/hooks'
import { useViewsRelated } from '../../../recoil/view/selectors/viewsRelatedSelector'
import { ViewListItem } from './KpiForm/ListItem/ViewListItem'

export function KpiAdditionForm({ prevKpiId, hide }: { hide: () => void; prevKpiId?: string }) {
  const view = useViewValue()
  const [config, setConfig] = useViewConfigKpiPivot()
  const existKpiViewIds = config?.kpis.map((x) => x.viewId) ?? []
  const { kpiViews } = useViewsRelated()
  const candidateViews = kpiViews.filter(
    (x) => !existKpiViewIds.includes(x.id) && !x.archived && (view.private ? true : !x.private),
  ) // ゴミ箱のビューは除外、公開設定が一致しないものも除外
  const [searchQuery, setSearchQuery] = useState('')
  const { newKpiFormModal } = useKpiPivotNavigationModal()
  const context = useViewsContextValue()
  const loading = useBooleanState()
  const { updateView } = useViewsContextValue()

  const filtered =
    searchQuery === ''
      ? candidateViews
      : candidateViews.filter((view) =>
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          searchQuery.split(/\s/).every((q) => view.name.includes(q) || view.createdBy?.name.includes(q)),
        )

  const addKpi = async (kpiViewId: string) => {
    const oldKpis = config?.kpis ?? []
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const previousKpiIndex = [oldKpis.findIndex((x) => x.viewId === prevKpiId) ?? -1, -1].max()! + 1
    const previousKpi = oldKpis[previousKpiIndex]
    const newKpi: ViewConfigKpiPivot['kpis'][0] = {
      viewId: kpiViewId,
      kpiGroupName: previousKpi?.kpiGroupName,
    }
    const kpis = [...oldKpis.slice(0, previousKpiIndex), newKpi, ...oldKpis.slice(previousKpiIndex)].uniqueBy(
      (x) => x.viewId,
    )
    try {
      loading.setTrue()
      await context.onAddResource({ viewIds: [kpiViewId] })

      const newConfig = {
        ...config!,
        kpis,
      }

      setConfig(newConfig)

      // WORKAROUND:
      // configの変更の保存ロジックは、変更後にhasChangeToViewSchemaAtomをtrueにすることで、
      // それをトリガーにuseEffectが呼ばれてその中でupdateViewを実行するのが一般的。
      // （ここのロジックはuseWatchを参照）
      // しかし、KPIを新規作成するとき、別タブが開いてしまう影響でこのuseEffectが呼ばれず、updateViewが実行されない。
      // そのため、ここで明示的にupdateViewを行う
      // 権限がない場合はここまで辿り着かず、またサーバーサイドでエラーになる想定
      await updateView({
        id: view.id,
        config: newConfig,
      })
    } catch {
      void message.error(t(`エラーが発生しました`))
    } finally {
      loading.setFalse()
    }
    hide()
  }

  if (candidateViews.isEmpty()) {
    return (
      <Empty
        className="pb-4"
        description={
          <div>
            {t(`追加できるKPIがありません。`)}
            <br />
            {t(`まずはKPIを新規作成してください。`)}
            <br />
            <Button
              type="primary"
              className="mb-2 mt-4"
              icon={<PlusOutlined />}
              onClick={() => {
                newKpiFormModal.showModal({
                  onFinish: async (kpiId) => {
                    await addKpi(kpiId)
                    void message.success(t(`ダッシュボードにKPIを追加しました。続いてKPIの設定を行なってください。`))
                  },
                })
              }}
            >
              {t(`KPIを新規作成`)}
            </Button>
          </div>
        }
      />
    )
  }

  return (
    <div>
      <div className="mb-2 px-1 font-bold">{t(`ダッシュボードにKPIを追加`)}</div>
      <div className="mb-2 border border-gray-100">
        <Input.Search
          placeholder={t(`作成済みKPIから検索...`)}
          className="w-full"
          onSearch={(e) => {
            setSearchQuery(e)
          }}
          onChange={(e) => {
            setSearchQuery(e.target.value)
          }}
          onKeyDown={(e) => {
            e.stopPropagation()
          }}
        />
      </div>
      <Spin spinning={loading.isTrue}>
        <div
          className="result"
          style={{ height: 400, overflowY: 'scroll', borderTop: `1px solid #eee`, borderBottom: `1px solid #eee` }}
        >
          {filtered.map((view) => (
            <div
              className="hover:bg-gray-100"
              onClick={async () => {
                await addKpi(view.id)
              }}
            >
              <div className={isPresent(searchQuery) ? 'p-1 pb-2' : 'p-1'}>
                <ViewListItem view={view} showPath={isPresent(searchQuery)} />
              </div>
            </div>
          ))}
        </div>
      </Spin>
      <div className="pt-2">
        <Button
          type="text"
          className="w-full text-blue-500"
          icon={<PlusOutlined />}
          onClick={() => {
            newKpiFormModal.showModal({
              onFinish: async (kpiId) => {
                await addKpi(kpiId)
                void message.success(t(`ダッシュボードにKPIを追加しました。続いてKPIの設定を行なってください。`))
              },
            })
          }}
        >
          {t(`KPIを新規作成`)}
        </Button>
      </div>
    </div>
  )
}
