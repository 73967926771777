import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import Editor from '@monaco-editor/react'
import {
  CreateSnapshotModelDocument,
  type EltModelTransformInput,
  FetchEltModelsDocument,
  FetchPropertiesBoilerplateDocument,
} from '@salescore/client-api'
import { Posthog, POSTHOG_EVENTS } from '@salescore/client-base'
import { getOrganizationIdFromPath } from '@salescore/client-common'
import { CORE_CONSTANT, type CoreModel, modelPropertySchema } from '@salescore/core'
import { parseJsonIfValid } from '@salescore/frontend-common'
import { App, Button, Col, Form, Input, Row, Space } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { t } from 'i18next'
import { astVisitor, type FromTable, parseFirst, type SelectFromStatement, toSql } from 'pgsql-ast-parser'
import { format } from 'sql-formatter'

const generateModelName = (name: string): string => [CORE_CONSTANT.SNAPSHOT_MODEL_PREFIX, name].join('_')

interface FormValue {
  label: string
  name: string
  propertiesJson: string
  sql: string
}

export const CreateSnapshotModelForm = ({
  onAfterFinish,
  onAfterCancel,
}: {
  onAfterFinish: () => void
  onAfterCancel: () => void
}): JSX.Element => {
  const { message } = App.useApp()
  // 名前の重複回避のvalidationでのみ使う
  const fetchEltModels = useQuery(FetchEltModelsDocument, {
    variables: {
      organizationId: getOrganizationIdFromPath(),
    },
  })
  const [fetchPropertiesBoilerplate] = useLazyQuery(FetchPropertiesBoilerplateDocument)
  const eltModelNames = new Set((fetchEltModels.data?.eltModels ?? []).map((x) => x.name))
  const [form] = useForm<FormValue>()
  const [createSnapshotModel, { loading }] = useMutation(CreateSnapshotModelDocument)

  const onFinish = (value: FormValue): void => {
    const name = generateModelName(value.name)
    const propertiesRaw = parseJsonIfValid(value.propertiesJson)
    const properties = modelPropertySchema.array().safeParse(propertiesRaw)
    if (!properties.success) {
      void message.error(properties.error.message)
      return
    }
    const transform: EltModelTransformInput = {
      sql: value.sql,
      type: 'snapshot',
    }
    const model: CoreModel = {
      name,
      label: value.label,
      properties: properties.data,
    }
    void createSnapshotModel({
      variables: {
        organizationId: getOrganizationIdFromPath(),
        snapshotModel: {
          name,
          transform,
          model,
        },
      },
      onCompleted: (result) => {
        void message.success(t(`スナップショットモデルを作成しました`))
        Posthog.track(POSTHOG_EVENTS.create_custom_object, {
          organizationId: getOrganizationIdFromPath(),
          config: result.createSnapshotModel,
        })

        onAfterFinish()
      },
      onError: (error) => {
        void message.error(error.message)
      },
    })
  }

  const generatePropertiesBoilerplate = async (): Promise<void> => {
    const sql = form.getFieldValue(`sql`) as unknown
    if (typeof sql !== 'string') {
      return
    }

    await fetchPropertiesBoilerplate({
      variables: {
        organizationId: getOrganizationIdFromPath(),
        shouldResolveId: true,
        sql,
      },
      onCompleted: (data) => {
        const json = JSON.stringify(data.propertiesBoilerplate, null, 2)
        form.setFieldsValue({ propertiesJson: json })
      },
      onError: (error) => {
        void message.error(`${t(`エラーが発生しました。`)}${error.message}`)
      },
    })
  }

  return (
    <Form form={form} onFinish={onFinish} layout="vertical" key={'new'}>
      <Row>
        <Col span={12}>
          <Form.Item
            name="sql"
            label={
              <div>
                {t(`スナップショットモデル定義(SQL) `)}
                <Button
                  onClick={() => {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
                    const sql = form.getFieldValue(`sql`) as string
                    try {
                      const ast = parseFirst(sql)
                      const visitor = astVisitor((v) => ({}))
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                      const selectFromStatement: SelectFromStatement = visitor.statement(ast)
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
                      const table = selectFromStatement.from?.first() as FromTable
                      const modelName = table.name.name
                      const hasIdColumn =
                        selectFromStatement.columns?.find(
                          (column) =>
                            column.expr.type === 'ref' &&
                            column.expr.name === 'id' &&
                            column.expr.table?.name === modelName,
                        ) !== undefined
                      if (!hasIdColumn) {
                        selectFromStatement.columns = [
                          {
                            expr: {
                              type: 'ref',
                              table: {
                                name: modelName,
                              },
                              name: 'id',
                            },
                          },
                          ...(selectFromStatement.columns ?? []),
                        ]
                      }
                      const sqlWithIdColumn = toSql.statement(selectFromStatement)
                      form.setFieldsValue({ sql: format(sqlWithIdColumn, { language: 'postgresql' }) })
                    } catch (error) {
                      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                      void message.error(`${t(`エラーが発生しました。`)}${error}`)
                    }
                  }}
                >
                  {t(`フォーマット`)}
                </Button>
              </div>
            }
            rules={[{ required: true, message: t(`スナップショットモデル定義を入力してください`) }]}
          >
            <Editor
              height={600}
              theme="vs-dark"
              defaultLanguage="sql"
              defaultValue={`SELECT
-- ${t(`フィールド定義`)}
FROM
-- ${t(`テーブル名`)}`}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="propertiesJson"
            label={
              <div className="h-8">
                {t(`プロパティ一覧(JSON) `)}
                <Button
                  onClick={() => {
                    void generatePropertiesBoilerplate()
                  }}
                >
                  {t(`SQLからプロパティを生成`)}
                </Button>
              </div>
            }
            rules={[{ required: true, message: t(`プロパティ一覧を入力してください`) }]}
          >
            <Editor height={600} theme="vs-dark" defaultLanguage="json" defaultValue={`[]`} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name="label"
        label={t(`オブジェクト名`)}
        rules={[{ required: true, message: t(`オブジェクト名を入力してください`) }]}
      >
        <Input width={200}></Input>
      </Form.Item>
      <Form.Item
        name="name"
        label={t(`内部名 ※作成後変更不可。小文字アルファベットと_のみ`)}
        rules={[
          { message: t(`使える文字は小文字アルファベットと_のみです`), pattern: /^[_a-z]+$/ },
          { required: true, message: t(`内部名を入力してください`) },
          {
            // eslint-disable-next-line @typescript-eslint/require-await
            validator: async (_, value: string) => {
              if (eltModelNames.has(generateModelName(value))) {
                throw new Error('Stream name must be unique')
              }
            },
            message: t(`既に存在する内部名は入力できません`),
          },
          {
            max: 20,
            message: t(`{{max}}文字以内で入力してください`, { max: 20 }),
          },
        ]}
      >
        <div className="flex items-center align-middle">
          <span className="mr-2">{`${CORE_CONSTANT.SNAPSHOT_MODEL_PREFIX}_`}</span>
          <Input width={200}></Input>
        </div>
      </Form.Item>
      <Space direction="horizontal" className="w-full justify-end">
        <Form.Item>
          <Button htmlType="button" onClick={onAfterCancel}>
            {t('キャンセル')}
          </Button>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            {t(`作成`)}
          </Button>
        </Form.Item>
      </Space>
    </Form>
  )
}
