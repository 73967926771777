import { useMutation, useQuery } from '@apollo/client'
import { isNull } from '@salescore/buff-common'
import {
  CreateSheetThreadCommentDocument,
  FetchUsersDocument,
  MarkSheetThreadCommentNotificationsAsReadDocument,
  type SheetThread,
  SheetThreadCommentNotificationsDocument,
} from '@salescore/client-api'
import { commentDisplayName, ReadonlyMentionTextarea, UserAvatar } from '@salescore/client-common'
import { recoil } from '@salescore/client-recoil'
import { stableStringify } from '@salescore/features'
import { organizationHasFeature } from '@salescore/frontend-common'
import { App, Button, Form, List, Skeleton } from 'antd'
import dayjs from 'dayjs'
import { t } from 'i18next'
import { type FormEventHandler, type KeyboardEventHandler, type MutableRefObject, useEffect, useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { z } from 'zod'

import { useKpiPivotParameter, useSheetPickedPresetName } from '../../recoil/navigation/hooks'
import { additionalConfigAtom } from '../../recoil/records/atoms'
import { useViewConfigSheet, useViewsContextValue, useViewValue } from '../../recoil/view/hooks'
import { useViewSelector } from '../../recoil/view/selectors/viewSelector'
import { openSheetThreadsAtom } from '../../rsheet/recoil/atoms'
import {
  closeSheetThreadMutation,
  openSheetThreadMutation,
} from '../../rsheet/recoil/mutations/openSheetThreadMutation'
import { CommentForm } from './CommentForm'

export const fieldTypeSchema = z.object({
  text: z.string(),
})

type FieldType = z.infer<typeof fieldTypeSchema>

export function SheetThreadForm({
  commentRefs,
  recordId,
  recordName,
  updateDraftComment,
  sheetThread,
  fetchAndUpdateSheetThread,
}: {
  commentRefs: MutableRefObject<Map<string, HTMLElement>>
  recordId: string | undefined
  recordName: string | undefined
  updateDraftComment: (draftComment: string) => void
  sheetThread: SheetThread
  fetchAndUpdateSheetThread: () => Promise<void>
}) {
  const { organization } = recoil.global.useMe()
  const [createSheetThreadComment, result] = useMutation(CreateSheetThreadCommentDocument)
  const view = useViewValue()
  const { backgroundViewId } = useViewSelector()
  const config = useViewConfigSheet()
  const additionalConfig = useRecoilValue(additionalConfigAtom)
  const [form] = Form.useForm()
  const [isSubmitting, setIsSubmitting] = useState(false) // form.isSubmitting が使えるようになったらそちらに移行: https://github.com/ant-design/ant-design/issues/27667
  const openSheetThreads = useRecoilValue(openSheetThreadsAtom)
  const setOpenSheetThread = useSetRecoilState(openSheetThreadMutation)
  const closeSheetThread = useSetRecoilState(closeSheetThreadMutation)
  const users = useQuery(FetchUsersDocument, {
    variables: {
      organizationId: organization.id,
    },
    fetchPolicy: 'cache-first',
  })
  const [kpiPivotParameter] = useKpiPivotParameter()
  const { kpiPivotPresetName } = useViewsContextValue()
  const [sheetPresetName] = useSheetPickedPresetName()
  const [markSheetThreadCommentNotificationsAsReadMutation] = useMutation(
    MarkSheetThreadCommentNotificationsAsReadDocument,
  )
  const sheetThreadCommentNotificationsQueryData = useQuery(SheetThreadCommentNotificationsDocument, {
    variables: {
      organizationId: organization.id,
    },
    // サイダーでフェッチしているため、ビュー内ではそのキャッシュを使う
    // TODO: より細かいキャッシュの制御が必要な場合は、fetchPolicy を変更する
    fetchPolicy: 'cache-first',
  })
  const { message } = App.useApp()
  const options =
    users.data?.users.map((user) => ({
      id: user.id,
      display: user.name,
    })) ?? []
  const isSheetThreadOpen = openSheetThreads.some((t) => t.id === sheetThread.id)
  const comments = isSheetThreadOpen ? sheetThread.comments : [sheetThread.comments.first()].compact()

  async function updateNotifications() {
    await markSheetThreadCommentNotificationsAsReadMutation({
      variables: {
        commentIds: comments.compact().map((comment) => comment.id),
        organizationId: organization.id,
      },
    })
    await sheetThreadCommentNotificationsQueryData.refetch()
  }

  useEffect(() => {
    if (sheetThread.comments.length === 1) {
      // コメントが1件しかない場合は、スレッドを開いておく
      setOpenSheetThread({ threadId: sheetThread.id, view, draftComment: '' })
    }
  }, [sheetThread])

  useEffect(() => {
    void updateNotifications()
  }, [comments.length])

  useEffect(() => {
    // ここで createSheetThreadComment のエラーハンドリングをしている
    if (result.error?.message === undefined) {
      return
    }
    const errorJson = z
      .string()
      .refine((value) => {
        try {
          JSON.parse(value)
          return true
        } catch {
          return false
        }
      })
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      .transform((value) => JSON.parse(value))
      .safeParse(result.error.message)
    if (!errorJson.success) {
      void message.error(result.error.message)
      return
    }
    const parsed = z
      .object({
        error: z.object({ code: z.literal('slack_webapi_platform_error'), data: z.object({ error: z.string() }) }),
        postMessageRequestBody: z.any(),
        channel: z.object({ id: z.string(), name: z.string() }),
      })
      .safeParse(errorJson.data)
    if (parsed.success) {
      if (parsed.data.error.data.error === 'channel_not_found' || parsed.data.error.data.error === 'not_in_channel') {
        void message.error(
          t(`コメントするには次の Slack チャネルに所属している必要があります。${stableStringify(parsed.data.channel)}`),
        )
        return
      }
      if (parsed.data.error.data.error === 'token_revoked') {
        void message.error(t('Slack トークンが失効しています。再連携をお試しください。'))
        return
      }
    }
    void message.error(result.error.message)
  }, [result.error?.message])

  const commentFeatureEnabled = organizationHasFeature(organization, 'enable_slack_comment')

  if (!commentFeatureEnabled || recordId === undefined || config.tree?.modelName === undefined) {
    return null
  }
  const { modelName } = config.tree
  const onFinish = async (fields: FieldType) => {
    if (isSubmitting) {
      return
    }
    try {
      const { text } = fieldTypeSchema.parse(fields)
      if (backgroundViewId === undefined) {
        return
      }
      setIsSubmitting(true)
      await createSheetThreadComment({
        variables: {
          sheetThreadCommentCreateInput: {
            viewId: backgroundViewId,
            organizationId: organization.id,
            threadId: sheetThread.id,
            recordId,
            modelName,
            recordName,
            message: text,
            sheetViewId: view.id,
            kpiPivotParameter,
            kpiPivotPresetName,
            sheetPresetName,
            additionalConfigFilterLeafs: additionalConfig.filterLeafs,
          },
        },
      })
    } catch {
      // TODO: エラー処理
    } finally {
      setIsSubmitting(false)
    }
    setOpenSheetThread({ threadId: sheetThread.id, view, draftComment: '' })
    form.resetFields()
    await fetchAndUpdateSheetThread()
    setIsSubmitting(false)
  }
  const onChange: FormEventHandler<HTMLFormElement> | undefined = (event) => {
    const parsed = z.object({ text: z.object({ value: z.string() }) }).safeParse(event.currentTarget)
    if (parsed.success) {
      updateDraftComment(parsed.data.text.value)
    }
  }

  const handleKeyDown: KeyboardEventHandler<HTMLFormElement> = (event) => {
    if (event.key === 'Enter' && (event.metaKey || event.ctrlKey)) {
      event.preventDefault()
      form.submit()
    }
  }

  return (
    <div className="w-full">
      <List>
        {comments.compact().map((comment, index) => {
          const displayName = commentDisplayName(comment)
          return (
            <List.Item
              ref={(reference) => {
                if (isNull(reference)) {
                  return
                }
                commentRefs.current.set(comment.id, reference)
              }}
              key={comment.id}
              className={'!border-none' + ' ' + (index === 0 ? '' : '!pl-4')}
            >
              <Skeleton avatar title={false} loading={result.loading} active>
                <div className="flex w-full flex-col">
                  <List.Item.Meta
                    avatar={
                      <UserAvatar
                        user={{
                          name: displayName,
                          image_url: comment.createdBy?.imageUrl,
                        }}
                        size="small"
                      />
                    }
                    title={
                      <div className="flex items-center justify-between break-all">
                        <span>{displayName}</span>
                        <span className="text-xs">{dayjs(comment.createdAt).format('YYYY-MM-DD HH:mm')}</span>
                      </div>
                    }
                    description={<ReadonlyMentionTextarea message={comment.message} options={options} />}
                  />
                  {!isSheetThreadOpen && (
                    <div className="pl-8">
                      <Button
                        type="link"
                        size="small"
                        color="primary"
                        onClick={() => {
                          setOpenSheetThread({ threadId: sheetThread.id, view, draftComment: '' })
                        }}
                      >
                        {sheetThread.comments.length - 1} 件のコメント
                      </Button>
                    </div>
                  )}
                </div>
              </Skeleton>
            </List.Item>
          )
        })}
      </List>
      {isSheetThreadOpen && (
        <div className="pl-4">
          <Form
            form={form}
            onFinish={(values) => {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              void onFinish(values)
            }}
            onChange={onChange}
            onKeyDown={handleKeyDown}
          >
            <CommentForm
              isReply={true}
              isSmall={true}
              isDisabled={isSubmitting}
              onClickCancelButton={() => {
                closeSheetThread(sheetThread.id)
              }}
            />
          </Form>
        </div>
      )}
    </div>
  )
}
