import { PageHeader } from '@ant-design/pro-layout'
import { useSuspenseQuery } from '@apollo/client'
import { isSome } from '@salescore/buff-common'
import type { ViewFieldsFragment, ViewGroupFieldsFragment } from '@salescore/client-api'
import { FetchViewsDocument, ViewConfigTypeEnum } from '@salescore/client-api'
import { getOrganizationIdFromPath, SuspenseWithLoading } from '@salescore/client-common'
import type { ViewConfigField } from '@salescore/core'
import { getColumnSearchProps, useMessage } from '@salescore/frontend-common'
import { Button, Col, Divider, Modal, Row, Table } from 'antd'
import { t } from 'i18next'
import { useEffect, useMemo, useState } from 'react'

import { useNavigationModal } from '../../../../recoil/navigation/hooks'
import { useChangesValue } from '../../../../recoil/records/hooks'
import { useFieldMutations } from '../../../../recoil/view/mutations/fieldMutations'
import { useConfigSheetSelector } from '../../../../recoil/view/selectors/configSheetSelector'
import { useViewsRelated } from '../../../../recoil/view/selectors/viewsRelatedSelector'

export const ImportFieldsModal = () => {
  const { importFieldsModal } = useNavigationModal()
  const field = importFieldsModal.content
  const changes = useChangesValue()
  const message = useMessage()

  useEffect(() => {
    if (importFieldsModal.isModalVisible && changes.length > 0) {
      message.warning(t('設定を変更すると変更情報が失われます。'))
    }
  }, [importFieldsModal.isModalVisible])

  return (
    <Modal
      open={importFieldsModal.isModalVisible}
      onCancel={() => {
        importFieldsModal.hideModal()
      }}
      width={'90%'}
      cancelText={t(`閉じる`)}
      okButtonProps={{ style: { display: 'none' } }}
      style={{
        // top: '3%',
        maxWidth: 1000,
      }}
      destroyOnClose
      footer={<></>}
    >
      <SuspenseWithLoading>
        <>
          {isSome(field) && (
            <Body
              field={field}
              onAfterFinish={() => {
                importFieldsModal.hideModal()
              }}
            />
          )}
        </>
      </SuspenseWithLoading>
    </Modal>
  )
}

function Body({ field, onAfterFinish }: { field: ViewConfigField; onAfterFinish: () => void }) {
  const { getModelProperty } = useConfigSheetSelector()
  const { viewGroups, searchViewGroup } = useViewsRelated()
  const { data } = useSuspenseQuery(FetchViewsDocument, {
    variables: { organizationId: getOrganizationIdFromPath(), type: ViewConfigTypeEnum.Sheet },
  })
  const { views } = data
  const { viewsWithViewGroup } = useMemo(() => {
    const viewsWithViewGroup = views

      .flatMap((view) => {
        const viewGroup = searchViewGroup(view.viewGroupId)
        if (viewGroup === undefined) {
          return
        }
        const { config } = view
        if (config.type !== 'sheet') {
          return
        }
        const pickableFields =
          /* eslint-disable @typescript-eslint/no-unnecessary-condition */
          (
            config === undefined
              ? /* eslint-enable @typescript-eslint/no-unnecessary-condition */
                []
              : (config.fields ?? []).filter((x) => x.property.modelName === field.property.modelName)
          ).map((x) => ({
            ...x,
            name: [x.property.nodeName, x.property.propertyName].join('_'),
          }))
        if (pickableFields.isBlank()) {
          return
        }

        return {
          view,
          viewGroup,
          pickableFields,
        }
      })
      .compact()

    return {
      viewsWithViewGroup,
    }
  }, [viewGroups])
  const [pickableFields, setPickedView] = useState<Array<ViewConfigField & { name: string }>>([])
  const [pickedFieldNames, setPickedFieldNames] = useState<string[]>([])
  const { addByFields } = useFieldMutations()
  const onFinish = () => {
    const pickedFields = pickedFieldNames.map((name) => pickableFields.find((field) => field.name === name)).compact()
    addByFields({ fields: pickedFields, targetField: field })
    onAfterFinish()
  }

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (pickableFields !== undefined) {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      setPickedFieldNames(pickableFields.map((x) => x.name) ?? [])
    }
  }, [pickableFields])

  return (
    <PageHeader title={t(`列をインポート`)}>
      <div className="mb-8">{t(`他のシートから列を一括でインポートすることができます。`)}</div>
      <Row gutter={8} className="">
        <Col span={12}>
          <div className="mb-2 font-bold">1. {t(`インポートするシートを選択`)}</div>
          <Table
            size="small"
            dataSource={viewsWithViewGroup}
            pagination={false}
            columns={[
              {
                key: 'viewGroupName',
                title: t(`フォルダ`),
                ...getColumnSearchProps((record: { viewGroup: ViewGroupFieldsFragment }) => record.viewGroup.name),
                render(_, record) {
                  return <span>{record.viewGroup.name}</span>
                },
              },
              {
                key: 'name',
                title: t(`シート`),
                ...getColumnSearchProps(({ view }: { view: ViewFieldsFragment }) => view.name),
                render(_, record) {
                  return <span>{record.view.name}</span>
                },
              },
              {
                key: 'pick',
                width: 60,
                render(_, record) {
                  return (
                    <Button
                      size="small"
                      onClick={() => {
                        setPickedView(record.pickableFields)
                      }}
                    >
                      {t(`選択`)}
                    </Button>
                  )
                },
              },
            ]}
          />
        </Col>
        <Col span={1}>
          <Divider type="vertical" className="" style={{ height: '100%' }} />
        </Col>
        <Col span={11}>
          <div className="mb-2 font-bold">2. {t(`インポートする列を選択`)}</div>
          <Table
            size="small"
            pagination={false}
            dataSource={pickableFields.map((x) => ({ ...x, key: x.name }))}
            rowSelection={{
              selectedRowKeys: pickedFieldNames,
              onChange: (newPickedFieldNames) => {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
                setPickedFieldNames(newPickedFieldNames as string[])
              },
            }}
            columns={[
              {
                key: 'metaLabel',
                title: t(`フィールド`),
                render(_, record) {
                  const property = getModelProperty(record.property.modelName, record.property.propertyName)
                  return <span>{property?.label ?? record.property.propertyName}</span>
                },
              },
            ]}
          />
          <div className="mt-4 flex w-full flex-row-reverse">
            <Button
              type="primary"
              disabled={pickedFieldNames.isBlank()}
              onClick={() => {
                onFinish()
              }}
            >
              {t(`インポート`)}
            </Button>
          </div>
        </Col>
      </Row>
    </PageHeader>
  )
}
