import { isNull } from '@salescore/buff-common'
import { type Period, usePeriod } from '@salescore/client-common'
import { DatePicker } from 'antd'
import type { Dayjs } from 'dayjs'
import { t } from 'i18next'
import type { ReactNode } from 'react'

import { useViewConfigKpiTimeSeries } from '../../../../recoil/view/hooks'
import { useSavedPeriod, useUpdateKpiTimeSeriesView } from '../hooks'
import type { ViewUIRiPreviewProperties } from '../viewUIRiPreviewProperties'
import { filterSavedMessage } from './ViewUiRiPreviewWrapper'

export function ViewUiRiWaterfallPreviewHeader(properties: ViewUIRiPreviewProperties): ReactNode {
  const [config] = useViewConfigKpiTimeSeries()
  const { serializePeriod } = usePeriod()
  const { getSavedPeriod } = useSavedPeriod()
  const { updateKpiTimeSeriesView } = useUpdateKpiTimeSeriesView()
  // const userGroupsQuery = useFetchUserGroupsQuery({
  //   variables: { organizationId: getOrganizationIdFromPath() },
  // })

  if (isNull(config)) {
    return <></>
  }

  // const period = getSavedPeriod(config.filters?.dateY)
  const startPeriod = getSavedPeriod(config.filters?.startPeriod)
  const endPeriod = getSavedPeriod(config.filters?.endPeriod)
  // const userGroups = userGroupsQuery.data
  // const isDisabledPeriodFilter = isNull(config.kpiFragment?.dateY)
  // const isDisabledUserOrGroupIdsFilter = isNull(config.kpiFragment?.user)

  return (
    <div className="flex flex-wrap items-center gap-x-8 gap-y-2">
      <div className="flex flex-wrap items-center gap-2">
        {/* <ViewUiRiPeriodFilter
          period={period ?? getAllPeriod()}
          onChange={(v) => {
            if (isNull(period) && v.name === '全期間') {
              return
            }

            void updateKpiTimeSeriesView({
              configForm: {
                ...config,
                filters: {
                  ...config.filters,
                  dateY: serializePeriod(v),
                },
              },
              savedMessage: filterSavedMessage,
            })
          }}
          disabled={isDisabledPeriodFilter}
        /> */}
        {/* <ViewUiRiUserOrGroupIdsFilter
          userOrUserGroupIds={config.filters?.userOrUserGroupIds ?? []}
          onChange={(v) => {
            void updateKpiTimeSeriesView({
              configForm: {
                ...config,
                filters: {
                  ...config.filters,
                  userOrUserGroupIds: v,
                },
              },
              savedMessage: filterSavedMessage,
            })
          }}
          userGroups={userGroups}
          disabled={isDisabledUserOrGroupIdsFilter}
        /> */}
      </div>
      <div className="flex flex-wrap items-center gap-2">
        <ViewUiRiWaterfallPeriodFilter
          label={t('期間①')}
          value={startPeriod?.startAt}
          onChange={(v) => {
            void updateKpiTimeSeriesView({
              configForm: {
                ...config,
                filters: {
                  ...config.filters,
                  startPeriod: serializePeriod(v),
                },
              },
              savedMessage: filterSavedMessage,
            })
          }}
        />
        <ViewUiRiWaterfallPeriodFilter
          label={t('期間②')}
          value={endPeriod?.endAt}
          onChange={(v) => {
            void updateKpiTimeSeriesView({
              configForm: {
                ...config,
                filters: {
                  ...config.filters,
                  endPeriod: serializePeriod(v),
                },
              },
              savedMessage: filterSavedMessage,
            })
          }}
        />
      </div>
    </div>
  )
}

function ViewUiRiWaterfallPeriodFilter({
  value,
  label,
  onChange,
}: {
  value: Dayjs | undefined
  label: string
  onChange: (v: Period) => void
}): ReactNode {
  return (
    <div className="flex items-center gap-2">
      <span>{label}</span>
      <DatePicker
        picker="date"
        value={value}
        onChange={(value) => {
          onChange({
            // カスタム期間として設定する
            name: 'カスタム',
            groupName: 'カスタム',
            nameLabel: t(`カスタム`),
            groupNameLabel: t(`カスタム`),
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            startAt: value ?? undefined,
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            endAt: value ?? undefined,
          })
        }}
      />
    </div>
  )
}
