import { DownOutlined, ExclamationCircleOutlined, RedoOutlined, SwapOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { type Connection, SyncContextEnum, SyncHubConnectionDocument } from '@salescore/client-api'
import { Posthog } from '@salescore/client-base'
import { getOrganizationIdFromPath } from '@salescore/client-common'
import { SubscribeJobQueues } from '@salescore/client-feature-view'
import { ButtonWithTooltip } from '@salescore/frontend-common'
import { App, Dropdown, Popconfirm } from 'antd'
import { t } from 'i18next'
import { type ReactElement, useState } from 'react'

type ConnectionWithSourceProvider = Pick<Connection, 'id' | 'active'> & {
  source: Pick<Connection['source'], 'provider'>
}

export const SyncButton = ({
  connection,
  onSuccess,
}: {
  connection: ConnectionWithSourceProvider
  onSuccess: () => void
}): ReactElement => {
  const [jobQueueIds, setJobQueueIds] = useState<string[]>([])
  const { message } = App.useApp()

  const [syncHubConnectionMutation] = useMutation(SyncHubConnectionDocument)
  const queueSync = (syncContext: SyncContextEnum): void => {
    void syncHubConnectionMutation({
      variables: {
        id: connection.id,
        organizationId: getOrganizationIdFromPath(),
        syncContext,
      },
      onCompleted: (data) => {
        const { syncConnection } = data
        if (syncConnection == null) {
          // queueが一杯で入れられなかった
          message.warning(t('別の同期が実行中です'))
        } else {
          message.info(t('同期を開始しました'))
          setJobQueueIds([syncConnection.id])
        }
      },
      onError: (error) => {
        void message.error(error.message)
      },
    })
    Posthog.track('click-syncConnectionButton', {
      organizationId: getOrganizationIdFromPath(),
    })
  }
  const items = [
    {
      key: 'incremental',
      icon: <SwapOutlined />,
      label: t(`差分のみ取得`),
      onClick: () => {
        queueSync(SyncContextEnum.ManualIncremental)
      },
    },
    {
      key: 'bulk',
      icon: <ExclamationCircleOutlined />,
      label: (
        <Popconfirm
          title={
            <div>
              {t(`全てのレコードを取得するには非常に時間がかかる可能性があります。よろしいですか？`)}
              <br />
              {t(`※取得データに不整合がおきた場合などのみお使いください。`)}
            </div>
          }
          onConfirm={() => {
            queueSync(SyncContextEnum.ManualBulk)
          }}
          okText={t(`実行`)}
          cancelText={t(`キャンセル`)}
        >
          {t(`全てのレコードを取得`)}
        </Popconfirm>
      ),
    },
  ]

  return (
    <Dropdown menu={{ items }} trigger={['click']} arrow disabled={!connection.active}>
      <ButtonWithTooltip
        tooltipTitle={t(`連携が停止中のため同期できません`)}
        showTooltip={!connection.active}
        icon={<RedoOutlined />}
        disabled={!connection.active}
        loading={!jobQueueIds.isEmpty()}
      >
        {!jobQueueIds.isEmpty() && (
          <SubscribeJobQueues
            jobQueueIds={jobQueueIds}
            onSuccess={() => {
              onSuccess()
              setJobQueueIds([])
            }}
          />
        )}
        {t(`同期`)}
        <DownOutlined />
      </ButtonWithTooltip>
    </Dropdown>
  )
}
