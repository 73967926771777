import { useCursorSelector } from '../../recoil/selectors/cursorSelector'
import { RSheetsStyle } from '../../util/RSheetsStyle'

export function ExpandCursorBox({ asFixed }: { asFixed: boolean }) {
  const { columnBlocksRelated, selectedArea, cursor } = useCursorSelector()
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (cursor?.expand === undefined || selectedArea === undefined || columnBlocksRelated === undefined) {
    return <></>
  }
  const { x, y, width, height, fixedRightWidth } = selectedArea

  const boxWidth = asFixed ? Math.min(width, fixedRightWidth) : width
  if (boxWidth === 0) {
    return <></>
  }
  return (
    <div
      id={`expand-cursor-box-as-fixed-${asFixed}`}
      style={{
        position: 'absolute',
        top: y + columnBlocksRelated.headerHeight,
        left: x,
        width: boxWidth,
        height,
        pointerEvents: 'none',

        border: `2px #3274E0 solid`,
        borderRadius: 4,
        backgroundColor: 'rgba(14, 101, 235, 0.1)',
        transition: 'all 0.2s cubic-bezier(.23, 1, .32, 1)',
        zIndex: asFixed ? RSheetsStyle.zIndex.cursorWhenLeftFixed : RSheetsStyle.zIndex.cursor,
      }}
    />
  )
}
