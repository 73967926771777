import { isRequiredProperty, type ModelProperty } from '@salescore/core'
import { t } from 'i18next'

function removeIdLabel(x: string) {
  return x.replace(/\s*ID$/, '')
}

export function getFieldLabel(property: ModelProperty) {
  const isRequired = isRequiredProperty(property)
  if (property.referenceTo?.isPresent() ?? false) {
    const label = removeIdLabel(property.label)
    return isRequired ? `${label}(${t(`必須`)})` : label
    // return isRequired ? `${label}(参照,必須)` : `${label}(参照)`
  }
  return [property.label, isRequired ? `(${t(`必須`)})` : ''].join('')
}
