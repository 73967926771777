import { useApolloClient } from '@apollo/client'
import { isSome } from '@salescore/buff-common'
import { type SheetThread, SheetThreadsDocument } from '@salescore/client-api'
import { recoil } from '@salescore/client-recoil'
import { CORE_CONSTANT } from '@salescore/core'
import { organizationHasFeature } from '@salescore/frontend-common'
import { useRecoilState } from 'recoil'

import { useViewsContextValue, useViewValue } from '../../view/hooks'
import { sheetThreadsAtom } from '../atoms'

export function useSheetThreads() {
  const me = recoil.global.useMe()
  const view = useViewValue()
  const views = useViewsContextValue()
  const [sheetThreads, setSheetThreads] = useRecoilState(sheetThreadsAtom)
  const client = useApolloClient()
  const commentFeatureEnabled = organizationHasFeature(me.organization, 'enable_slack_comment')

  async function fetchAndUpdateSheetThreads(recordIds: string[]) {
    if (
      !commentFeatureEnabled ||
      view.config.type !== 'sheet' || // シートビュー以外の時
      view.id.startsWith(CORE_CONSTANT.KPI_PREVIEW_DRILL_DOWN_VIEW_ID_PREFIX) || // KPIプレビューの時
      view.id === '' || // 目標設定の時
      views.views.first()?.type === 'kpi' // KPI タブ内の場合
    ) {
      return
    }
    const threadsResult = await client.query({
      query: SheetThreadsDocument,
      variables: {
        organizationId: me.organization.id,
        recordIds,
      },
      fetchPolicy: 'cache-first',
    })
    if (isSome(threadsResult.error)) {
      throw threadsResult.error
    }
    if (!isSome(threadsResult.data)) {
      throw new Error('Failed to fetch sheet threads')
    }
    const { sheetThreads } = threadsResult.data
    setSheetThreads((oldSheetThreads) =>
      // uniqueBy は重複があった場合、先頭にある要素を優先する。
      [...sheetThreads, ...oldSheetThreads].uniqueBy((thread) => thread.id),
    )
  }

  return { sheetThreads, setSheetThreads, fetchAndUpdateSheetThreads }
}

export function useSheetThread(recordId: string | undefined) {
  const me = recoil.global.useMe()
  const client = useApolloClient()
  const [sheetThreads, setSheetThreads] = useRecoilState(sheetThreadsAtom)
  const sheetThreadsOfRecord = sheetThreads.filter((thread) => thread.recordId === recordId)
  const commentFeatureEnabled = organizationHasFeature(me.organization, 'enable_slack_comment')

  function setSheetThread(newSheetThread: SheetThread) {
    setSheetThreads((oldSheetThreads) => [newSheetThread, ...oldSheetThreads].uniqueBy((thread) => thread.id))
  }

  async function fetchAndUpdateSheetThread() {
    if (!commentFeatureEnabled) {
      return
    }
    if (recordId === undefined) {
      return
    }
    const threadsResult = await client.query({
      query: SheetThreadsDocument,
      variables: {
        organizationId: me.organization.id,
        recordIds: [recordId],
      },
    })
    if (isSome(threadsResult.error)) {
      throw threadsResult.error
    }
    if (!isSome(threadsResult.data)) {
      throw new Error('Failed to fetch sheet threads')
    }
    const { sheetThreads } = threadsResult.data
    setSheetThreads((oldSheetThreads) =>
      // uniqueBy は重複があった場合、先頭にある要素を優先する。
      [...sheetThreads, ...oldSheetThreads].uniqueBy((thread) => thread.id),
    )
  }

  return { sheetThreadsOfRecord, setSheetThread, fetchAndUpdateSheetThread }
}
