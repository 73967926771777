import { useLazyQuery, useMutation } from '@apollo/client'
import Editor from '@monaco-editor/react'
import {
  type EltModelFieldsFragment,
  FetchPropertiesBoilerplateDocument,
  UpdateSnapshotModelDocument,
} from '@salescore/client-api'
import { Posthog, POSTHOG_EVENTS } from '@salescore/client-base'
import { getOrganizationIdFromPath } from '@salescore/client-common'
import { modelPropertySchema } from '@salescore/core'
import { parseJsonIfValid } from '@salescore/frontend-common'
import { App, Button, Col, Form, Input, Row, Space } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { t } from 'i18next'

interface FormValue {
  label: string
  name: string
  propertiesJson: string
  sql: string
}

export const UpdateSnapshotModelForm = ({
  editingEltModel,
  onAfterFinish,
  onAfterCancel,
}: {
  editingEltModel: EltModelFieldsFragment
  onAfterFinish: () => void
  onAfterCancel: () => void
}): JSX.Element => {
  const { message } = App.useApp()
  const [form] = useForm<FormValue>()
  const [fetchPropertiesBoilerplate] = useLazyQuery(FetchPropertiesBoilerplateDocument)
  const [updateSnapshotModel, { loading }] = useMutation(UpdateSnapshotModelDocument)
  const initialValue: Partial<FormValue> = {
    label: editingEltModel.model.label,
    name: editingEltModel.name,
    sql: editingEltModel.transform?.sql,
    propertiesJson: JSON.stringify(editingEltModel.model.properties, null, 2),
  }

  const onFinish = (value: FormValue): void => {
    const propertiesRaw = parseJsonIfValid(value.propertiesJson)
    const properties = modelPropertySchema.array().safeParse(propertiesRaw)
    if (!properties.success) {
      void message.error(properties.error.message)
      return
    }
    void updateSnapshotModel({
      variables: {
        organizationId: getOrganizationIdFromPath(),
        snapshotModel: {
          id: editingEltModel.id,
          label: value.label,
          properties: properties.data,
        },
      },
      onCompleted: (result) => {
        void message.success(t(`スナップショットモデルを更新しました`))
        Posthog.track(POSTHOG_EVENTS.create_custom_object, {
          organizationId: getOrganizationIdFromPath(),
          config: result.updateSnapshotModel,
        })

        onAfterFinish()
      },
      onError: (error) => {
        void message.error(error.message)
      },
    })
  }

  const initializePropertiesBoilerplate = async (): Promise<void> => {
    const sql = form.getFieldValue(`sql`) as unknown
    if (typeof sql !== 'string') {
      return
    }

    await fetchPropertiesBoilerplate({
      variables: {
        organizationId: getOrganizationIdFromPath(),
        shouldResolveId: true,
        sql,
      },
      onCompleted: (data) => {
        const json = JSON.stringify(data.propertiesBoilerplate, null, 2)
        form.setFieldsValue({ propertiesJson: json })
      },
      onError: (error) => {
        void message.error(`${t(`エラーが発生しました。`)}${error.message}`)
      },
    })
  }

  return (
    <Form form={form} onFinish={onFinish} layout="vertical" initialValues={initialValue} key={'new'}>
      <Row>
        <Col span={12}>
          <Form.Item
            name="sql"
            label={<div className="flex h-8 items-center">{t(`スナップショットモデル定義(SQL) `)}</div>}
            rules={[{ required: true, message: t(`スナップショットモデル定義を入力してください`) }]}
          >
            <Editor
              height={600}
              theme="vs-dark"
              defaultLanguage="sql"
              defaultValue={`SELECT
-- ${t(`フィールド定義`)}
FROM
-- ${t(`テーブル名`)}`}
              options={{ readOnly: true }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="propertiesJson"
            label={
              <div className="h-8">
                {t(`プロパティ一覧(JSON) `)}
                <Button
                  onClick={() => {
                    void initializePropertiesBoilerplate()
                  }}
                >
                  {t(`SQLからプロパティを初期化`)}
                </Button>
              </div>
            }
            rules={[{ required: true, message: t(`プロパティ一覧を入力してください`) }]}
          >
            <Editor height={600} theme="vs-dark" defaultLanguage="json" defaultValue={`[]`} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name="label"
        label={t(`オブジェクト名`)}
        rules={[{ required: true, message: t(`オブジェクト名を入力してください`) }]}
      >
        <Input width={200}></Input>
      </Form.Item>
      <Space direction="horizontal" className="w-full justify-end">
        <Form.Item>
          <Button htmlType="button" onClick={onAfterCancel}>
            {t('キャンセル')}
          </Button>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            {t(`更新`)}
          </Button>
        </Form.Item>
      </Space>
    </Form>
  )
}
