import { Alert, type ModalFuncProps } from 'antd'
import { t } from 'i18next'

export const buildSyncUserPlanNotFoundModalProperties = (): ModalFuncProps => ({
  icon: null,
  width: '60%',
  okButtonProps: { style: { display: 'none' } },
  cancelText: t(`閉じる`),
  maskClosable: true,
  title: <div className="font-bold">{t(`SALESCORE SYNCのご案内`)}</div>,
  content: <Body />,
  style: { top: '3%' },
})

function Body() {
  return (
    <div>
      <Alert
        showIcon
        type="warning"
        description={
          <div>
            {t(`この機能はSYNCプランをご契約の方のみご利用可能です。`)}
            <br />
            {t(`組織の管理者に、SYNCプランの契約とライセンス割り当てを依頼してください。`)}
          </div>
        }
      />
    </div>
  )
}
