import { convertRecordNodeToSheetRow, type SheetCell, type SheetRow } from '@salescore/core'
import { selectorFamily, useRecoilValue } from 'recoil'

import { recordAtomFamily, recordIdsAtom } from '../../../recoil/records/atoms'
import { sheetUiComponentSelector } from '../../../recoil/view/selectors/uiSelecotr'
import { columnsAtom, rowConfigAtomFamily } from '../atoms'

export const rowRelatedSelector = selectorFamily({
  key: 'rowRelatedSelector',
  get:
    (id: string) =>
    ({ get }) => {
      // XXX: ロジックに不整合があった場合、指定したidのrowがrecordAtomに存在しないこともありうるが
      //      このselectorが呼ばれるタイミングでidに相当するrowがないことはありえないはずなので、ここでは無視して!を使っている
      const row = get(recordAtomFamily({ id }))!

      const config = get(rowConfigAtomFamily({ id }))
      const sheet = get(sheetUiComponentSelector)
      const columns = get(columnsAtom)
      const sheetRow = convertRecordNodeToSheetRow(
        row,
        columns.map((column) =>
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          column.node === undefined
            ? null
            : {
                fieldName: column.field?.name,
                nodePath: column.node.path,
              },
        ),
      )
      const visibleRowNumber = sheet?.visibleRowNum
      const expanded = config?.expanded === true
      const visibleSheetRow: SheetRow =
        visibleRowNumber === undefined || expanded
          ? sheetRow
          : {
              columns: sheetRow.columns.map((column) => ({
                uiColumn: column.uiColumn,
                height: Math.min(column.height, visibleRowNumber),
                innerCells: column.innerCells
                  .reduce<SheetCell[]>((accumulator, cell) => {
                    const previousHeight = accumulator.map((x) => x.height).sum()
                    const maxHeight = Math.max(visibleRowNumber - previousHeight, 0)
                    return [
                      ...accumulator,
                      {
                        ...cell,
                        // innerRowIndexStart: Math.min(cell.innerRowIndexStart, visibleRowNum),
                        // innerRowIndexEnd: Math.min(cell.innerRowIndexEnd, visibleRowNum),
                        height: Math.min(cell.height, maxHeight),
                      },
                    ]
                  }, [])
                  .filter((x) => x.height > 0),
              })),
              height: Math.min(sheetRow.height, visibleRowNumber),
            }
      const expandable = visibleRowNumber !== undefined && sheetRow.height > visibleRowNumber

      return {
        // sheetRow,
        visibleSheetRow,
        getSheetCell: (columnIndex: number, innerRowIndex: number) => {
          const xs = visibleSheetRow.columns[columnIndex]?.innerCells ?? []
          return xs.find((x) => x.innerRowIndexStart <= innerRowIndex && innerRowIndex < x.innerRowIndexEnd)
        },
        getInnerCells: (columnIndex: number) => visibleSheetRow.columns[columnIndex]?.innerCells ?? [],
        config,
        expandable,
        expanded,
        visibleRowNum: config?.expanded === true ? 1000 : visibleRowNumber,
      }
    },
})

export const useRowRelatedSelector = (id: string) => useRecoilValue(rowRelatedSelector(id))

export const rowRelatedWithRowIndexSelector = selectorFamily({
  key: 'view/rowRelatedWithRowIndexSelector',
  get:
    (rowIndex: number) =>
    ({ get }) => {
      const recordIds = get(recordIdsAtom)
      const recordId = recordIds[rowIndex]
      if (recordId === undefined) {
        return
      }
      return get(rowRelatedSelector(recordId))
    },
})
