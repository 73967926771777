import { SearchOutlined } from '@ant-design/icons'
import { PageHeader } from '@ant-design/pro-layout'
import Editor from '@monaco-editor/react'
import { Alert, Button, Col, Form, Input, Row, Space, Table, Typography } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { t } from 'i18next'
import { useState } from 'react'
import { format } from 'sql-formatter'

import { useSearchQueue } from '../../../../recoil/hooks/searchRelation'

interface FormValue {
  sql: string
}

interface Option {
  value: string
  label: string
}

export function SearchSqlForm({
  sql,
  onAfterFinish,
  title,
}: {
  sql: string
  onAfterFinish: (sql: string) => void
  title: string
}) {
  const [form] = useForm<FormValue>()
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState<Option[]>([])
  const [searchedSql, setSearchedSql] = useState<null | string>()
  const { queueSearch } = useSearchQueue({
    setLoading,
    setOptions,
    setSql: setSearchedSql,
  })
  const onFinish = (value: FormValue) => {
    onAfterFinish(value.sql)
  }

  // 将来的にちゃんとしたGUIを用意するが、いったんSQLを直で書いてもらう
  return (
    <PageHeader
      title={
        <Space>
          <SearchOutlined />
          <span>{title} </span>
        </Space>
      }
    >
      <Alert
        className="mb-4"
        message={<div>{t(`開発中の機能のため、管理者のみの利用に制限しています。`)}</div>}
        type="warning"
        showIcon
      />
      <div className="mb-8">
        {t(`設定方法`)}
        {/* i18nの対応が難しいので一旦保留 */}
        <br />・<Typography.Text code>value</Typography.Text>,<Typography.Text code>label</Typography.Text>
        というフィールドを含むSQLを設定してください。
        <br />
        ・mustache記法が使用可能です。検索クエリは<Typography.Text code>query</Typography.Text>という変数で渡されます。
        <br />（{t(`例`)}）<Typography.Text code>{`"name" LIKE '%{{ query }}%' `}</Typography.Text>
        <br />
        ・また、検索クエリ文字列をスペースで分割した配列が<Typography.Text code>queries</Typography.Text>
        という変数で渡されます。
        <br />（{t(`例`)}）
        <Typography.Text code>{`{{# queries }} "name" LIKE '%{{ . }}%' AND {{/ queries }}  `}</Typography.Text>
        <br />
      </div>

      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        initialValues={{
          sql: formatSqlWithMustache(sql),
        }}
      >
        <Row gutter={12}>
          <Col span={16}>
            <Form.Item
              name="sql"
              label={
                <div>
                  {t(`検索条件`)}(SQL){' '}
                  <Button
                    size="small"
                    onClick={() => {
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
                      const sql = form.getFieldValue(`sql`) as string
                      form.setFieldsValue({ sql: formatSqlWithMustache(sql) })
                    }}
                  >
                    {t(`フォーマット`)}
                  </Button>
                </div>
              }
              rules={[{ required: true, message: t(`検索条件を入力してください`) }]}
            >
              <Editor
                height={350}
                // width={500}
                theme="vs-dark"
                defaultLanguage="sql"
                options={{
                  minimap: {
                    enabled: false,
                  },
                }}
                defaultValue={`SELECT
id as value, name as label
FROM
companies`}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="searchKey" label={t(`検索のテスト`)}>
              {/* <Input
                width={200}
                onChange={(e) => {
                  const sql = form.getFieldValue(`sql`) as string
                  queueSearch(sql, e.target.value, undefined)
                }}
              /> */}
              <Input.Search
                onSearch={(searchKey) => {
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
                  const sql = form.getFieldValue(`sql`) as string
                  queueSearch(sql, searchKey)
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault()
                    e.stopPropagation()
                  }
                }}
              />
            </Form.Item>
            <Table
              size="small"
              pagination={false}
              dataSource={options.slice(0, 8)}
              loading={loading}
              columns={[
                {
                  dataIndex: `label`,
                },
              ]}
            />
          </Col>
        </Row>
        <div className="flex flex-row-reverse">
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {t(`更新`)}
            </Button>
          </Form.Item>
        </div>
      </Form>
      {searchedSql !== null && (
        <pre>
          {t(`検索された`)}SQL:
          {searchedSql ?? ''}
        </pre>
      )}
    </PageHeader>
  )
}

function formatSqlWithMustache(sql: string) {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return format(sql ?? '', { language: 'postgresql' })
    .replace('{ { #', '{{#')
    .replace('{ { /', '{{/')
    .replaceAll('{ {', '{{')
    .replaceAll('} }', '}}')
}
