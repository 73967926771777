import { PlusOutlined } from '@ant-design/icons'
import { isPresent } from '@salescore/buff-common'
import { Button, Divider, Empty, Input } from 'antd'
import { t } from 'i18next'
import { useState } from 'react'

import { useKpiPivotParameter } from '../../../recoil/navigation/hooks'
import { useUiValue } from '../../../recoil/view/hooks'

export function DimensionAdditionForm({ prevRowIndex, hide }: { hide: () => void; prevRowIndex: number }) {
  const [parameter, setParameter] = useKpiPivotParameter()
  const ui = useUiValue()
  const kpiComponent = ui[0]
  const [searchKey, setSearchKey] = useState(``)
  if (kpiComponent?.type !== 'KpiPivotSheet') {
    return <></>
  }
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const rows = parameter.pivot.rows ?? []
  const candidates = kpiComponent.dimensions.filter((x) => !rows.map((x) => x.key).includes(x.key))

  if (candidates.isEmpty()) {
    return <Empty className="pb-4" description={<div>{t(`追加できる集計軸がありません。`)}</div>} />
  }

  return (
    <div className="overflow-y-scroll" style={{ maxHeight: 400 }}>
      <div className="px-2 py-1">
        <Input.Search
          placeholder={t(`検索`)}
          onKeyDown={(e) => {
            e.stopPropagation()
          }}
          onSearch={(key) => {
            setSearchKey(key)
          }}
          allowClear
        />
      </div>
      <Divider style={{ marginTop: 6, marginBottom: 2 }} />
      {candidates.map((dimension) => {
        if (isPresent(searchKey) && !dimension.label.includes(searchKey)) {
          return <></>
        }
        return (
          <>
            <Button
              type="text"
              icon={<PlusOutlined />}
              className="w-full justify-start"
              onClick={() => {
                setParameter({
                  ...parameter,
                  pivot: {
                    // Pivotの行・列の項目は互いに排他的なため、行に追加した項目が列に存在する場合は削除する
                    columns: parameter.pivot.columns.filter((x) => x.key !== dimension.key),
                    rows: [
                      ...parameter.pivot.rows.slice(0, prevRowIndex + 1),
                      dimension,
                      ...parameter.pivot.rows.slice(prevRowIndex + 1),
                    ],
                  },
                })
                hide()
              }}
            >
              {dimension.label}
            </Button>
            <Divider style={{ marginTop: 2, marginBottom: 2 }} />
          </>
        )
      })}
    </div>
  )
}
