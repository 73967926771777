import {
  ArrowRightOutlined,
  DollarCircleOutlined,
  DownCircleOutlined,
  FileTextOutlined,
  Html5Outlined,
  LinkOutlined,
  PhoneOutlined,
  TagOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons'
import { isNull } from '@salescore/buff-common'
import { PropertyMetaEnum } from '@salescore/client-api'
import { Tag } from 'antd'

const COLORS: Record<PropertyMetaEnum, string> = {
  [PropertyMetaEnum.Id]: 'default',
  [PropertyMetaEnum.Address]: 'default',
  [PropertyMetaEnum.Currency]: 'default',
  [PropertyMetaEnum.Email]: 'default',
  [PropertyMetaEnum.MultiSelect]: 'default',
  [PropertyMetaEnum.Percent]: 'default',
  [PropertyMetaEnum.PhoneNumber]: 'default',
  [PropertyMetaEnum.Relation]: 'red',
  [PropertyMetaEnum.Select]: 'default',
  [PropertyMetaEnum.Text]: 'default',
  [PropertyMetaEnum.Url]: 'default',
  [PropertyMetaEnum.PolymorphicType]: 'default',
  [PropertyMetaEnum.Name]: 'default',
  [PropertyMetaEnum.RecordUrl]: 'default',
  [PropertyMetaEnum.Html]: 'default',
}

const ICONS: Record<PropertyMetaEnum, JSX.Element> = {
  [PropertyMetaEnum.Id]: <></>,
  [PropertyMetaEnum.Address]: <></>,
  [PropertyMetaEnum.Currency]: <DollarCircleOutlined />,
  [PropertyMetaEnum.Email]: <></>,
  [PropertyMetaEnum.MultiSelect]: <UnorderedListOutlined />,
  [PropertyMetaEnum.Percent]: <></>,
  [PropertyMetaEnum.PhoneNumber]: <PhoneOutlined />,
  [PropertyMetaEnum.Relation]: <ArrowRightOutlined />,
  [PropertyMetaEnum.Select]: <DownCircleOutlined />,
  [PropertyMetaEnum.Text]: <FileTextOutlined />,
  [PropertyMetaEnum.Url]: <LinkOutlined />,
  [PropertyMetaEnum.PolymorphicType]: <ArrowRightOutlined />,
  [PropertyMetaEnum.Name]: <TagOutlined />,
  [PropertyMetaEnum.RecordUrl]: <LinkOutlined />,
  [PropertyMetaEnum.Html]: <Html5Outlined />,
}

const TYPE_JA: Record<PropertyMetaEnum, string> = {
  [PropertyMetaEnum.Id]: 'ID',
  [PropertyMetaEnum.Address]: '住所',
  [PropertyMetaEnum.Currency]: '通貨',
  [PropertyMetaEnum.Email]: 'メールアドレス',
  [PropertyMetaEnum.MultiSelect]: '複数選択',
  [PropertyMetaEnum.Percent]: 'パーセント',
  [PropertyMetaEnum.PhoneNumber]: '電話番号',
  [PropertyMetaEnum.Relation]: '参照',
  [PropertyMetaEnum.Select]: '選択',
  [PropertyMetaEnum.Text]: '長文テキスト',
  [PropertyMetaEnum.Url]: 'URL',
  [PropertyMetaEnum.PolymorphicType]: '参照先オブジェクト種別',
  [PropertyMetaEnum.Name]: 'レコード名',
  [PropertyMetaEnum.RecordUrl]: 'レコードURL',
  [PropertyMetaEnum.Html]: 'HTMLテキスト',
}

export const PropertyMetaTag = ({ meta }: { meta?: PropertyMetaEnum | null }) => {
  if (isNull(meta)) {
    return <></>
  }

  return (
    <Tag
      className="text-md px-3"
      color={
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        COLORS[meta] ?? 'default'
      }
      icon={ICONS[meta]}
    >
      {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        TYPE_JA[meta] ?? meta
      }
    </Tag>
  )
}
