import { isPresent } from '@salescore/buff-common'
import {
  CORE_CONSTANT,
  getRecordNodesByPath,
  type ViewConfigKpiParameter,
  type ViewQueryField,
  type ViewQueryRecordNode,
} from '@salescore/core'
import { SortableListWrapper } from '@salescore/frontend-common'
import { Button, Empty, Space } from 'antd'
import { t } from 'i18next'
import { useState } from 'react'
import { useRecoilState } from 'recoil'

import { useKpiPivotParameter } from '../../../recoil/navigation/hooks'
import { additionalConfigAtom } from '../../../recoil/records/atoms'
import { useRecordsValue } from '../../../recoil/records/hooks'
import { useConnectionsSelector } from '../../../recoil/view/selectors/connectionsSelector'
import { getLabeledFieldName } from '../../../state/util'

// TODO: DimensionFilterと共通化
export function DimensionSorter({ field, hide }: { hide: () => void; field: ViewQueryField }) {
  const records = useRecordsValue()
  const [kpiPivotParameter] = useKpiPivotParameter()
  const dimension = getDimension(field, kpiPivotParameter.pivot)
  const [additionalConfig, setAdditionalConfig] = useRecoilState(additionalConfigAtom)
  const { getModelProperty } = useConnectionsSelector()

  const getOptions = (records: ViewQueryRecordNode[]) => {
    const flattenRecords = records.flatMap((x) => getRecordNodesByPath(x, field.nodePath))
    const options = flattenRecords
      .map((x) => {
        const value = x.attributes[field.name]
        // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
        const label = x.attributes[getLabeledFieldName(field.name)] as string
        if (value === undefined) {
          return
        }
        return {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
          value: value as string,
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          label: label ?? value,
        }
      })
      .compact()
      .uniqueBy((x) => x.value)
      .filter((x) => x.value !== CORE_CONSTANT.KPI_PIVOT_TOTAL_STRING)

    const sortedValues = dimension?.sortedValues
    if (isPresent(sortedValues)) {
      options.sort((a, b) => {
        let aIndex = sortedValues.indexOf(a.value)
        let bIndex = sortedValues.indexOf(b.value)

        if (aIndex === -1) {
          aIndex = sortedValues.length
        }
        if (bIndex === -1) {
          bIndex = sortedValues.length
        }

        return aIndex - bIndex
      })
    }

    return options
  }

  const options = getOptions(records)

  if (dimension === undefined) {
    // TODO
    return <>error</>
  }

  return (
    <Body
      options={options}
      onFinish={(values) => {
        setAdditionalConfig((oldValue) => ({
          ...oldValue,
          kpiParameter: {
            ...oldValue.kpiParameter,
            pivot: {
              rows: (oldValue.kpiParameter?.pivot.rows ?? []).map((d) => {
                if (d.key !== dimension.key) {
                  return d
                }
                return {
                  ...d,
                  sortedValues: values,
                }
              }),
              columns: (oldValue.kpiParameter?.pivot.columns ?? []).map((d) => {
                if (d.key !== dimension.key) {
                  return d
                }
                return {
                  ...d,
                  sortedValues: values,
                }
              }),
            },
          },
        }))
        hide()
      }}
      cancel={() => {
        hide()
      }}
      clear={() => {
        setAdditionalConfig((oldValue) => ({
          ...oldValue,
          kpiParameter: {
            ...oldValue.kpiParameter,
            pivot: {
              rows: (oldValue.kpiParameter?.pivot.rows ?? []).map((x) => ({ ...x, sortedValues: undefined })),
              columns: (oldValue.kpiParameter?.pivot.columns ?? []).map((x) => ({ ...x, sortedValues: undefined })),
            },
          },
        }))
        hide()
      }}
    />
  )
}

function getDimension(field: ViewQueryField, pivot: ViewConfigKpiParameter['pivot']) {
  const rowIndex = field.nodePath.length - 1 // TODO: こんなんでいいのか…？？
  const row = pivot.rows[rowIndex]
  return row
}

interface FilterDropdownProperties {
  options: Array<{ value: string; label: string }>
  onFinish: (keys: string[]) => void
  cancel: () => void
  clear: () => void
}

function Body({ options, onFinish, cancel, clear }: FilterDropdownProperties) {
  const [items, setItems] = useState(options.map((x) => ({ ...x, key: x.value })))

  if (options.isBlank()) {
    return (
      <Empty
        description={
          <div>
            {t(`レコードがありません。`)}
            <br />
            <Button
              className="my-3"
              onClick={() => {
                clear()
              }}
            >
              {t(`クリア`)}
            </Button>
          </div>
        }
      />
    )
  }

  return (
    <div
      className="p-2"
      style={{
        maxWidth: 400,
        overflowX: 'auto',
      }}
    >
      <div
        style={{
          maxHeight: 254,
          overflow: 'auto',
        }}
      >
        <SortableListWrapper
          options={{
            bordered: false,
            deletable: false,
          }}
          items={items}
          onChange={(items) => {
            setItems(
              items.map((x) => ({
                value: x.value,
                key: x.key,
                // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
                label: x.label as string, // TODO
              })),
            )
          }}
        />
      </div>
      <hr className="m-0 my-1 opacity-20" />
      <Space className="mt-2 flex justify-end">
        <Button
          onClick={() => {
            clear()
          }}
          type="text"
          size="small"
        >
          {t(`リセット`)}
        </Button>
        <Button
          onClick={() => {
            onFinish(items.map((x) => x.value))
          }}
          type="primary"
          size="small"
        >
          {t(`適用`)}
        </Button>
      </Space>
    </div>
  )
}
