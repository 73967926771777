import { closestCenter, DndContext } from '@dnd-kit/core'
import type { ViewUIComponent, ViewUIRow } from '@salescore/core'
import type * as React from 'react'

import type { useViewMutation } from '../../recoil/view/mutations'

export const DndContextWrapper: React.FC<{
  children: JSX.Element
  isEditMode: boolean
  mutation: ReturnType<typeof useViewMutation>
}> = ({ mutation, isEditMode, children }) => {
  if (!isEditMode) {
    return <>{children}</>
  }

  return (
    <DndContext
      // sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={(event) => {
        const { active, over } = event
        if (over === null) {
          return
        }

        if (active.id !== over.id) {
          mutation.setCurrentViewUI((oldUi) => {
            const newUi = structuredClone(oldUi) // TODO: 破壊的操作を意図して行う方法
            const activeParentComponent = newUi
              .map((x) => findParentComponent(x, `${active.id}`))
              .compact()
              .first()
            const overParentComponent = newUi
              .map((x) => findParentComponent(x, `${over.id}`))
              .compact()
              .first()
            const activeComponent = activeParentComponent?.children.find((x) => x.id === active.id)

            if (
              activeParentComponent === undefined ||
              overParentComponent === undefined ||
              activeComponent === undefined
            ) {
              return oldUi
            }

            // activeから削除し、overにいれる
            const activeComponentIndex = activeParentComponent.children.findIndex((x) => x.id === active.id)
            const overComponentIndex = overParentComponent.children.findIndex((x) => x.id === over.id)
            activeParentComponent.children.splice(activeComponentIndex, 1)
            overParentComponent.children.splice(overComponentIndex, 0, activeComponent)

            return newUi
          })
        }
      }}
    >
      {children}
    </DndContext>
  )
}

function findParentComponent(component: ViewUIComponent, id: string): ViewUIRow | undefined {
  // 親要素となりうるノードであれば、判定を行う
  // TODO: Colに対するD&Dもサポートしたい
  if (component.type === 'Row') {
    const childIds = component.children.map((x) => x.id)
    if (childIds.includes(id)) {
      return component
    }
  }

  // それ以外のとき、子ノードに対して再起的に対応
  // TODO: childrenを持つ全ての要素を洗い出したいが、これ以外の書き方はないか？
  if (
    component.type === 'Row' ||
    component.type === 'Col' ||
    component.type === 'Form' ||
    component.type === 'FormList'
  ) {
    return component.children
      .map((child) => findParentComponent(child, id))
      .compact()
      .first()
  }

  if (component.type === 'FormItem') {
    return findParentComponent(component.child, id)
  }

  return undefined
}
