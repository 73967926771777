import { EditOutlined, PartitionOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@apollo/client'
import {
  FetchGoalDimensionsDocument,
  type GoalDimension,
  type GoalDimensionFieldsFragment,
  UpsertGoalDimensionsDocument,
} from '@salescore/client-api'
import { getOrganizationIdFromPath, SuspenseWithLoading } from '@salescore/client-common'
import { createDestroyColumn, getColumnSearchProps, useModal } from '@salescore/frontend-common'
import { Button, Table } from 'antd'
import Modal from 'antd/es/modal/Modal'
import { t } from 'i18next'
import { useState } from 'react'

import { UpsertGoalDimensionForm } from './UpsertGoalDimensionForm'

export const GoalDimensionsTable = ({
  goalDimensions,
  withDimensionGroup,
  refresh,
}: {
  goalDimensions: GoalDimension[]
  withDimensionGroup?: boolean
  refresh: () => void
}) => {
  const { isModalVisible, showModal, hideModal } = useModal()
  const [editingGoalDimension, setEditingGoalDimension] = useState<GoalDimension>()
  const fetchGoalDimensionsQuery = useQuery(FetchGoalDimensionsDocument, {
    variables: { organizationId: getOrganizationIdFromPath() },
    fetchPolicy: 'cache-and-network',
  })
  const [upsertGoalDimensionsMutation] = useMutation(UpsertGoalDimensionsDocument)
  const [loading, setLoading] = useState(false)

  const onAfterFinish = async () => {
    hideModal()
    await fetchGoalDimensionsQuery.refetch()
    refresh()
  }

  const onDestroy = async (record: GoalDimension) => {
    await upsertGoalDimensionsMutation({
      variables: {
        organizationId: getOrganizationIdFromPath(),
        goalDimensions: [
          {
            id: record.id,
            name: record.name,
            label: record.label,
            selectOptions: [],
            deleted: true,
          },
        ],
      },
    })
    // TODO: recoilのrefreshと、apolloのrefreshが重複していて地獄。ちゃんと統一したい。。
    await fetchGoalDimensionsQuery.refetch()
    refresh()
  }

  return (
    <>
      <Table
        rowKey="id"
        pagination={false}
        columns={[
          {
            title: (
              <span>
                <PartitionOutlined /> {t(`目標軸名`)}
              </span>
            ),
            dataIndex: 'label',
            width: 180,
            ...getColumnSearchProps((x: GoalDimensionFieldsFragment) => x.label),
          },
          {
            title: t(`選択肢`),
            dataIndex: 'selectOptions',
            render: (_, record: GoalDimension) => (
              <span>{record.selectOptionsV3.map((option) => option.label).join(' / ')}</span>
            ),
            ...getColumnSearchProps((x: GoalDimensionFieldsFragment) => x.selectOptions.join(' ')),
          },
          {
            width: 80,
            render: (_, record: GoalDimension) => (
              <Button
                icon={<EditOutlined />}
                onClick={() => {
                  setEditingGoalDimension(record)
                  showModal()
                }}
              >
                {t(`編集`)}
              </Button>
            ),
          },
          createDestroyColumn(loading, setLoading, onDestroy),
        ]}
        dataSource={goalDimensions}
      />
      <Modal
        key="editModal"
        visible={isModalVisible}
        onCancel={hideModal}
        width={'70%'}
        cancelText={t(`閉じる`)}
        okButtonProps={{ style: { display: 'none' } }}
        style={{ top: '3%' }}
        destroyOnClose
      >
        <SuspenseWithLoading>
          <>
            {
              // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
              editingGoalDimension && (
                <UpsertGoalDimensionForm
                  withDimensionGroup={withDimensionGroup}
                  key={editingGoalDimension.id}
                  goalDimension={editingGoalDimension}
                  onAfterFinish={onAfterFinish}
                />
              )
            }
          </>
        </SuspenseWithLoading>
      </Modal>
    </>
  )
}
