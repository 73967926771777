import { isNull, isTruthy, range } from '@salescore/buff-common'
import type { CoreDslFormOperator, CoreDslFormTerm, DateTimeFilterExpressionType, ModelProperty } from '@salescore/core'
import { Button, DatePicker, InputNumber, Select, Space } from 'antd'
import dayjs from 'dayjs'
import { t } from 'i18next'
import { useState } from 'react'
import { z } from 'zod'

interface Properties {
  onFinish: (term: CoreDslFormTerm) => void
  property: ModelProperty
  term: CoreDslFormTerm | undefined
  operator: CoreDslFormOperator | undefined
}

export type DatePickerType = Exclude<
  DateTimeFilterExpressionType,
  | 'datetime'
  // TODO: 実装が間に合ってないので、timeframe系を一旦弾く
  | 'hour'
  | 'day'
  | 'week'
  | 'fiscal_quarter'
  | 'fiscal_half'
  | 'fiscal_year'
  | 'weekday'
>
type DateTimeType = 'date' | 'month' | 'week' | 'year' | 'relative'

interface PickerOption {
  value: DateTimeType
  label: string
}

export interface RelativeOptionGroup {
  label: string
  options: Array<{
    label: string
    value: string
  }>
}

const pickerOptions: PickerOption[] = [
  {
    value: 'date',
    label: t(`日`),
  },
  // {
  //   value: 'week',
  //   label: `週`,
  // },
  {
    value: 'month',
    label: t(`月`),
  },
  // {
  //   value: 'quarter',
  //   label: `四半期`,
  // },
  {
    value: 'year',
    label: t(`年`),
  },
  {
    value: 'relative',
    label: t(`相対日時`),
  },
]

export const relativeOptionGroups: RelativeOptionGroup[] = [
  {
    label: t(`日`),
    options: [
      {
        label: t(`今日`),
        value: '0 day',
      },
      {
        label: t(`昨日`),
        value: '-1 day',
      },
      {
        label: t(`一昨日`),
        value: '-2 day',
      },
      ...range(3, 7).map((x) => ({
        label: t(`{{x}}日前`, { x }),
        value: `-${x} day`,
      })),
      {
        label: t(`N日前`),
        value: '-{N} day',
      },
      {
        label: t(`明日`),
        value: '+1 day',
      },
      {
        label: t(`明後日`),
        value: '+2 day',
      },
      ...range(3, 7).map((x) => ({
        label: t(`{{x}}日後`, { x }),
        value: `+${x} day`,
      })),
      {
        label: t(`N日後`),
        value: '+{N} day',
      },
    ],
  },
  {
    label: t(`週`),
    options: [
      {
        label: t(`今週`),
        value: '0 week',
      },
      {
        label: t(`先週`),
        value: '-1 week',
      },
      ...range(2, 3).map((x) => ({
        label: t(`{{x}}週間前`, { x }),
        value: `-${x} week`,
      })),
      {
        label: t(`N週間前`),
        value: '-{N} week',
      },
      {
        label: t(`来週`),
        value: '+1 week',
      },
      ...range(2, 3).map((x) => ({
        label: t(`{{x}}週間後`, { x }),
        value: `+${x} week`,
      })),
      {
        label: t(`N週間後`),
        value: '+{N} week',
      },
    ],
  },
  {
    label: t(`月`),
    options: [
      {
        label: t(`今月`),
        value: '0 month',
      },
      {
        label: t(`先月`),
        value: '-1 month',
      },
      ...[2, 3, 6, 9].map((x) => ({
        label: t(`{{x}}ヶ月前`, { x }),
        value: `-${x} month`,
      })),
      {
        label: t(`Nヶ月前`),
        value: '-{N} month',
      },
      {
        label: t(`来月`),
        value: '+1 month',
      },
      ...[2, 3, 6, 9].map((x) => ({
        label: t(`{{x}}ヶ月後`, { x }),
        value: `+${x} month`,
      })),
      {
        label: t(`Nヶ月後`),
        value: '+{N} month',
      },
    ],
  },
]

const validDateSchema = z.string().refine((x) => dayjs(x).isValid())

export function getFilterDateValue(filterValue: unknown) {
  const value = validDateSchema.safeParse(filterValue)
  if (value.success) {
    return dayjs(value.data)
  }
}

export function getNOptionValue(filterValue?: string) {
  return filterValue?.replace(/\d+/g, '{N}')
}

export function getNInputValue(filterValue: string) {
  return Number.parseInt(filterValue.replaceAll(/\D/g, ''))
}

export function getFilterOptionAndInputValue({
  filterValue,
  dateTimeType,
  relativeOptionGroups,
}: {
  filterValue: unknown
  dateTimeType?: DateTimeType | DatePickerType
  relativeOptionGroups: RelativeOptionGroup[]
}) {
  if (typeof filterValue !== 'string') {
    return { optionValue: undefined, inputValue: undefined }
  }

  const isOutOfOptions = isNull(searchFilterOption({ filterValue, relativeOptionGroups }))
  if (dateTimeType !== 'relative' || !isOutOfOptions) {
    return { optionValue: filterValue, inputValue: undefined }
  }

  const optionValue = getNOptionValue(filterValue)
  const inputValue = getNInputValue(filterValue)
  return { optionValue, inputValue }
}

export function searchFilterOption({
  filterValue,
  relativeOptionGroups,
}: {
  filterValue?: string
  relativeOptionGroups: RelativeOptionGroup[]
}) {
  return relativeOptionGroups.flatMap((x) => x.options).find((option) => option.value === filterValue)
}

export function isNSelected(filterValue?: string) {
  return isTruthy(filterValue?.includes('{N}'))
}

export function CoreDslDateLiteralFormItem({ onFinish, term }: Properties) {
  const [value, setValue] = useState<string>(() => {
    const defaultValue = dayjs().format('YYYY-MM-DD')
    if (term?.type !== 'literal') {
      return defaultValue
    }
    if (term.literalType !== 'date') {
      return defaultValue
    }
    return term.value
  })

  const [dateTimeType, setDateTimeType] = useState<DateTimeType>(() => {
    if (term?.type !== 'literal' || term.literalType !== 'date') {
      return 'date' as const
    }

    return term.dateTimeType ?? ('date' as const)
  })

  const { optionValue, inputValue } = getFilterOptionAndInputValue({
    filterValue: value,
    dateTimeType,
    relativeOptionGroups,
  })

  const [filterOptionValue, setFilterOptionValue] = useState<string | undefined>(optionValue)
  const [filterInputValue, setFilterInputValue] = useState<number | undefined>(inputValue)

  function onEnterInput() {
    if (isNull(filterOptionValue) || isNull(filterInputValue) || !isNSelected(filterOptionValue)) {
      return
    }

    const filterValue = filterOptionValue.replace('{N}', filterInputValue.toString())
    setValue(filterValue)
  }

  return (
    <Space>
      <Select
        style={{ width: 100 }}
        onChange={(value: PickerOption['value']) => {
          setDateTimeType(value)
          if (dateTimeType === 'relative' && value !== 'relative') {
            setValue(dayjs().format('YYYY-MM-DD'))
          }
          if (dateTimeType !== 'relative' && value === 'relative') {
            setValue('0 month')
          }
        }}
        value={dateTimeType}
        options={pickerOptions}
      />
      {dateTimeType === 'relative' ? (
        <>
          <Select
            allowClear
            style={{ width: isNSelected(filterOptionValue) ? 100 : 168 }}
            onChange={(value) => {
              setFilterOptionValue(value)
              setFilterInputValue(undefined)

              if (!isNSelected(value)) {
                setValue(value)
              }
            }}
            value={filterOptionValue}
          >
            {relativeOptionGroups.map((group) => (
              <Select.OptGroup label={group.label}>
                {group.options.map((option) => (
                  <Select.Option value={option.value}>{option.label}</Select.Option>
                ))}
              </Select.OptGroup>
            ))}
          </Select>
          {isNSelected(filterOptionValue) && (
            <InputNumber
              style={{ width: 60 }}
              onChange={(value) => {
                setFilterInputValue(value ?? undefined)
              }}
              onPressEnter={() => {
                onEnterInput()
              }}
              onBlur={() => {
                onEnterInput()
              }}
              value={filterInputValue}
            />
          )}
        </>
      ) : (
        <DatePicker
          picker={dateTimeType}
          style={{ minWidth: 168 }}
          onChange={(value) => {
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            setValue(value?.format('YYYY-MM-DD') ?? dayjs().format(`YYYY-MM-DD`))
          }}
          value={dayjs(value)}
        />
      )}
      <Button
        type="primary"
        onClick={() => {
          onFinish({
            type: 'literal',
            literalType: 'date',
            dateTimeType,
            value,
          })
        }}
      >
        {t(`決定`)}
      </Button>
    </Space>
  )
}
