import { type FileInfo, type Locale, Widget } from '@uploadcare/react-widget'
import type { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

export const UploadImageButton = ({ onFinish }: { onFinish: (x: string) => void }): ReactElement => {
  const { i18n } = useTranslation()

  const onImageUploaded = (fileInfo: FileInfo): void => {
    if (fileInfo.cdnUrl !== null) {
      onFinish(fileInfo.cdnUrl)
    }
  }

  return (
    <Widget
      publicKey={process.env.UPLOADCARE_API_KEY ?? ''}
      locale={
        // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
        i18n.language as Locale
      }
      imagesOnly={true}
      crop="150x150" // オリジナル画像が150x150でクロップされるわけではなく、レスポンスのCDN URLに150x150のURLが入った状態になるだけ
      onChange={onImageUploaded}
    />
  )
}
