import { PageHeader } from '@ant-design/pro-layout'
import Editor from '@monaco-editor/react'
import { JsonSyntaxHighlighter, parseJsonIfValid, useBooleanState } from '@salescore/frontend-common'
import { Alert, Button, Form, Modal, Space, Switch } from 'antd'
import { t } from 'i18next'
import type { ZodSchema } from 'zod'

import { useNavigationModal } from '../../../recoil/navigation/hooks'

export function ViewDebugModal() {
  const { debugModal } = useNavigationModal()
  const { content } = debugModal
  const onChange = content?.onChange
  const schema = content?.schema
  const isEditMode = useBooleanState()
  const isEditable = onChange !== undefined && schema !== undefined

  return (
    <Modal
      open={debugModal.isModalVisible}
      onCancel={debugModal.hideModal}
      width={'60%'}
      cancelText={t(`閉じる`)}
      okButtonProps={{ style: { display: 'none' } }}
      title={<div></div>}
      style={{ top: '3%' }}
      destroyOnClose
    >
      <PageHeader
        title={<div>{t(`スキーマ表示`)}</div>}
        extra={[
          <Space className="flex items-center align-middle">
            <span>{t(`編集モード`)}</span>
            <Switch
              disabled={!isEditable}
              onChange={() => {
                isEditMode.toggle()
              }}
              checked={isEditMode.isTrue}
            />
          </Space>,
        ]}
      >
        {isEditMode.isTrue && (
          <Alert
            className="mb-4"
            type="warning"
            showIcon
            message={t(`スキーマの直接編集は大変危険です。十分にスキーマの理解がある方だけが編集してください。`)}
          />
        )}
        {isEditable && isEditMode.isTrue ? (
          <EditableContent
            content={content?.content}
            schema={schema}
            onChange={(x) => {
              onChange(x)
              debugModal.hideModal()
            }}
          />
        ) : (
          <ReadonlyContent content={content?.content} />
        )}
      </PageHeader>
    </Modal>
  )
}

function ReadonlyContent({ content }: { content: unknown }) {
  return (
    <div>
      <JsonSyntaxHighlighter
        jsonString={
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          JSON.stringify(content) ?? ''
        }
      />
    </div>
  )
}

interface FormValue {
  content: string
}

function EditableContent({
  content,
  schema,
  onChange,
}: {
  content: unknown
  onChange: (x: unknown) => void
  schema: ZodSchema<unknown>
}) {
  const onFinish = (value: FormValue) => {
    const parsed = parseJsonIfValid(value.content)
    // try {
    //   const validated = viewQueryFieldSchema.safeParse(parsed)
    //   if (validated.success) {
    //     onChange(parsed)
    //     void message.success(`更新しました`)
    //   } else {
    //     void message.error(`スキーマが一致しません。${validated.error.message}`)
    //   }
    // } catch(e) {
    // }
    // validationしたかったが、エラーも発生せずに処理が止まる不具合があったためvalidationしない
    onChange(parsed)
  }
  return (
    <Form
      onFinish={onFinish}
      layout="vertical"
      initialValues={{
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        content: JSON.stringify(content, null, 2) ?? '',
      }}
    >
      <Form.Item
        style={{
          height: 600,
          overflow: 'hidden',
        }}
        name="content"
        label={
          <div>
            {/* 検索条件(SQL){' '} */}
            {/* <Button
              size="small"
              onClick={() => {
                const sql = form.getFieldValue(`sql`) as string
                form.setFieldsValue({ sql: formatSqlWithMustache(sql) })
              }}
            >
              フォーマット
            </Button> */}
          </div>
        }
      >
        <Editor
          height={600}
          // width={500}
          theme="vs-dark"
          defaultLanguage="json"
          options={{
            minimap: {
              enabled: false,
            },
          }}
        />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          {t(`更新`)}
        </Button>
      </Form.Item>
    </Form>
  )
}
