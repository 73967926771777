// eslint-disable-next-line @eslint-community/eslint-comments/disable-enable-pair
/* eslint-disable max-lines */
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { useMutation, useSuspenseQuery } from '@apollo/client'
import { Editor } from '@monaco-editor/react'
import { isPresent } from '@salescore/buff-common'
import {
  CreateEltModelDocument,
  type EltModelFieldsFragment,
  FetchEltModelsDocument,
  FetchSalescoreConnectionDocument,
  PropertyMetaEnum,
  PropertyTypeEnum,
  UpdateEltModelDocument,
} from '@salescore/client-api'
import { getOrganizationIdFromPath, Posthog, POSTHOG_EVENTS } from '@salescore/client-base'
import { CORE_CONSTANT, type ModelProperty, modelPropertySchema, type PropertyType } from '@salescore/core'
import { Button, Col, Form, type FormInstance, Input, message, Row, Select, Space, Table } from 'antd'
import { Array, Option, pipe, Predicate } from 'effect'
import { isNotUndefined } from 'effect/Predicate'
import { t } from 'i18next'
import type { ReactNode } from 'react'

// NOTE: userCustomizedSalescoreSourceの接頭辞をここで定義している
//       バックエンドでのvalidationはしていないので、悪意あるユーザーがリクエストを改変すれば接頭辞を改変することができるが、
//       この接頭辞は分かりやすさのために統一しているだけであり、ロジック上は統一された接頭辞は不要なので、改変を気にする必要性はあまりない
function generateModelName(name: string): string {
  return [CORE_CONSTANT.SALESCORE_CUSTOM_MODEL_PREFIX, name].join('')
}

interface FormProperty {
  name?: string
  label?: string
  type?: PropertyTypeEnum
  referenceTo?: string
  selectOption?: string
}

const systemProperties: ModelProperty[] = [
  {
    name: 'created_at',
    type: PropertyTypeEnum.Datetime,
    label: '作成日時',
  },
  {
    name: 'updated_at',
    type: PropertyTypeEnum.Datetime,
    label: '更新日時',
  },
  {
    name: 'created_by',
    type: PropertyTypeEnum.String,
    label: '作成者',
    meta: 'relation',
    referenceTo: [{ modelName: 'salescore_users' }],
  },
  {
    name: 'updated_by',
    type: PropertyTypeEnum.String,
    label: '更新者',
    meta: 'relation',
    referenceTo: [{ modelName: 'salescore_users' }],
  },
]

interface JsonFormValue {
  label: string
  name: string
  propertiesJson: string
}

interface PropertiesFormValue {
  properties?: Array<FormProperty | undefined>
}

const generatePropertiesFromForm = (formProperties: FormProperty[]): ModelProperty[] =>
  pipe(
    formProperties,

    Array.filterMap((x): Option.Option<ModelProperty> => {
      if (x.label === undefined || x.name === undefined || x.type === undefined) {
        // 必須項目が入力されているもののみを対象
        return Option.none()
      }

      return Option.some({
        name: x.name,
        type: x.type,
        label: x.label,
        meta: x.type === `array` ? PropertyMetaEnum.MultiSelect : x.name === `name` ? PropertyMetaEnum.Name : undefined,
        ...(Predicate.isNotNullable(x.referenceTo) ? { referenceTo: [{ modelName: x.referenceTo }] } : {}),
        ...(isPresent(x.selectOption)
          ? { selectOptions: x.selectOption.split(',').map((x) => ({ label: x, value: x })) }
          : {}),
        write: {
          sourcePropertyName: x.name,
        },
      })
    }),
  )

const PropertiesForm = ({
  propertiesForm,
  jsonForm,
  options,
  properties,
}: {
  propertiesForm: FormInstance<PropertiesFormValue>
  jsonForm: FormInstance<JsonFormValue>
  options: Array<{
    value: string
    key: string
    label: string
  }>
  properties: FormProperty[]
}): ReactNode => (
  <Form
    initialValues={{ properties }}
    form={propertiesForm}
    onFinish={() => {
      const properties = generatePropertiesFromForm(propertiesForm.getFieldsValue().properties?.compact() ?? [])
      jsonForm.setFieldsValue({
        propertiesJson: JSON.stringify(
          [...properties, ...systemProperties].compact().uniqueBy((x) => x.name),
          null,
          2,
        ),
      })
    }}
  >
    <Form.List name="properties">
      {(fields, { add, remove }) => (
        <>
          <Table
            title={() => (
              <>
                <Space>
                  <Button
                    type="default"
                    onClick={() => {
                      add()
                    }}
                    icon={<PlusOutlined />}
                  >
                    {t(`プロパティを追加`)}
                  </Button>
                  <Button type="primary" htmlType="submit">
                    {t(`フォームからプロパティを生成`)}
                  </Button>
                </Space>
              </>
            )}
            dataSource={fields.map(({ key, name }, index) => ({
              key,
              label: (
                <Form.Item
                  name={[name, 'label']}
                  rules={[{ required: true, message: t(`カラム名を入力してください`) }]}
                >
                  <Input width={200}></Input>
                </Form.Item>
              ),
              name: (
                <Form.Item name={[name, 'name']} rules={[{ required: true, message: t(`内部名を入力してください`) }]}>
                  <Input width={200}></Input>
                </Form.Item>
              ),
              type: (
                <Form.Item name={[name, 'type']} rules={[{ required: true, message: t(`型を入力してください`) }]}>
                  <Select
                    className="min-w-28 max-w-28"
                    options={[
                      {
                        value: PropertyTypeEnum.String,
                        label: t(`文字列`),
                      },
                      {
                        value: PropertyTypeEnum.Numeric,
                        label: t(`数値`),
                      },
                      {
                        value: PropertyTypeEnum.Date,
                        label: t(`日付`),
                      },
                      {
                        value: PropertyTypeEnum.Datetime,
                        label: t(`日時`),
                      },
                      {
                        value: PropertyTypeEnum.Boolean,
                        label: t(`真偽値`),
                      },
                      {
                        value: PropertyTypeEnum.Integer,
                        label: t(`整数(Int)`),
                      },
                      {
                        value: PropertyTypeEnum.Array,
                        label: t(`複数選択肢`),
                      },
                    ]}
                  />
                </Form.Item>
              ),
              referenceTo: (
                <Form.Item name={[name, 'referenceTo']}>
                  <Select className="w-32 min-w-32 max-w-32" allowClear options={options} />
                </Form.Item>
              ),
              selectOption: (
                <Form.Item
                  name={[name, 'selectOption']}
                  rules={[
                    ({ getFieldValue }) => ({
                      validator: async (_, value: string) => {
                        if (
                          getFieldValue(['properties', index, 'type']) === PropertyTypeEnum.Array &&
                          !isPresent(value)
                        ) {
                          // eslint-disable-next-line unicorn/error-message
                          return await Promise.reject(new Error())
                        }
                      },
                      message: t(`型に複数選択肢を選んだ場合は選択肢を入力してください`),
                    }),
                  ]}
                >
                  <Input width={200}></Input>
                </Form.Item>
              ),
              delete: (
                <Form.Item>
                  <Button
                    type="text"
                    onClick={() => {
                      remove(name)
                    }}
                  >
                    <DeleteOutlined />
                  </Button>
                </Form.Item>
              ),
            }))}
            columns={[
              { title: t(`カラム名`), dataIndex: 'label' },
              { title: t(`内部名`), dataIndex: 'name' },
              { title: t(`型`), dataIndex: 'type' },
              { title: t(`参照先`), dataIndex: 'referenceTo' },
              { title: t(`選択肢(,)`), dataIndex: 'selectOption' },
              { title: t(`削除`), dataIndex: 'delete' },
            ]}
          ></Table>
        </>
      )}
    </Form.List>
  </Form>
)

const JsonCreateForm = ({
  invalidNames,
  form,
  onAfterFinish,
  onAfterCancel,
}: {
  invalidNames: string[]
  form: FormInstance<JsonFormValue>
  onAfterFinish: () => void
  onAfterCancel: () => void
}): ReactNode => {
  const [createEltModel, { loading }] = useMutation(CreateEltModelDocument)
  const { salescoreConnection } = useSuspenseQuery(FetchSalescoreConnectionDocument, {
    variables: {
      organizationId: getOrganizationIdFromPath(),
    },
  }).data

  const onFinish = (value: JsonFormValue): void => {
    const name = generateModelName(value.name)
    const properties = modelPropertySchema.array().safeParse(JSON.parse(value.propertiesJson))
    if (!properties.success) {
      void message.error(properties.error.message)
      return
    }
    void createEltModel({
      variables: {
        organizationId: getOrganizationIdFromPath(),
        eltModel: {
          type: 'userCustomizedSalescoreSource',
          name,
          connectionId: salescoreConnection.id,
          transform: {
            sql: generateSqlFromProperties(name, properties.data),
            type: 'table',
          },
          model: {
            name,
            label: value.label,
            properties: properties.data,
          },
        },
      },
      onCompleted: (result) => {
        void message.success(t(`カスタムモデルを作成しました`))
        onAfterFinish()
        Posthog.track(POSTHOG_EVENTS.create_salescore_object, {
          organizationId: getOrganizationIdFromPath(),
          config: result.createEltModel,
        })
      },
      onError: (error) => {
        void message.error(error.message)
      },
    })
  }

  return (
    <Form form={form} onFinish={onFinish} layout="vertical">
      <Form.Item
        name="propertiesJson"
        label={<div>{t(`プロパティ一覧(JSON)`)}</div>}
        rules={[{ required: true, message: t(`プロパティ一覧を入力してください`) }]}
      >
        <Editor height={600} theme="vs-dark" defaultLanguage="json" />
      </Form.Item>
      <Form.Item name="label" label={t(`名前`)} rules={[{ required: true, message: t(`名前を入力してください`) }]}>
        <Input width={200}></Input>
      </Form.Item>
      <Form.Item
        name="name"
        label={t(`内部名 ※作成後変更不可`)}
        rules={[
          { required: true, message: t(`内部名を入力してください`) },
          {
            validator: async (_, value: string) => {
              if (invalidNames.includes(generateModelName(value))) {
                return await Promise.reject(new Error('duplicated'))
              }
            },
            message: t(`既に存在する内部名は入力できません`),
          },
        ]}
      >
        {
          <div className="flex items-center align-middle">
            <Input width={200} addonBefore={CORE_CONSTANT.SALESCORE_CUSTOM_MODEL_PREFIX}></Input>
          </div>
        }
      </Form.Item>
      <Space direction="horizontal" className="w-full justify-end">
        <Form.Item>
          <Button
            htmlType="button"
            onClick={() => {
              onAfterCancel()
            }}
          >
            {t('閉じる')}
          </Button>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            {t(`作成`)}
          </Button>
        </Form.Item>
      </Space>
    </Form>
  )
}

const JsonEditForm = ({
  editingEltModel,
  form,
  onAfterFinish,
  onAfterCancel,
}: {
  editingEltModel: EltModelFieldsFragment
  form: FormInstance<JsonFormValue>
  onAfterFinish: () => void
  onAfterCancel: () => void
}): ReactNode => {
  const [updateEltModel, { loading }] = useMutation(UpdateEltModelDocument)
  const { salescoreConnection } = useSuspenseQuery(FetchSalescoreConnectionDocument, {
    variables: {
      organizationId: getOrganizationIdFromPath(),
    },
  }).data

  const onFinish = (value: JsonFormValue): void => {
    const { name } = editingEltModel
    const properties = modelPropertySchema.array().safeParse(JSON.parse(value.propertiesJson))
    if (!properties.success) {
      void message.error(properties.error.message)
      return
    }
    void updateEltModel({
      variables: {
        organizationId: getOrganizationIdFromPath(),
        eltModel: {
          id: editingEltModel.id,
          connectionId: salescoreConnection.id,
          transform: {
            sql: generateSqlFromProperties(name, properties.data),
            type: 'table',
          },
          model: {
            name,
            label: value.label,
            properties: properties.data,
          },
        },
      },
      onCompleted: (result) => {
        void message.success(t(`カスタムモデルを更新しました`))
        onAfterFinish()
        Posthog.track(POSTHOG_EVENTS.save_salescore_object, {
          organizationId: getOrganizationIdFromPath(),
          config: result.updateEltModel,
        })
      },
      onError: (error) => {
        void message.error(error.message)
      },
    })
  }
  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout="vertical"
      initialValues={{
        name: editingEltModel.name,
        label: editingEltModel.model.label,
        propertiesJson: JSON.stringify(editingEltModel.model.properties, null, 2),
      }}
    >
      <Form.Item
        name="propertiesJson"
        label={<div>{t(`プロパティ一覧(JSON)`)}</div>}
        rules={[{ required: true, message: t(`プロパティ一覧を入力してください`) }]}
      >
        <Editor height={600} theme="vs-dark" defaultLanguage="json" />
      </Form.Item>
      <Form.Item name="label" label={t(`名前`)} rules={[{ required: true, message: t(`名前を入力してください`) }]}>
        <Input width={200}></Input>
      </Form.Item>
      <Form.Item name="name" label={t(`内部名 ※作成後変更不可`)}>
        <Input disabled={true} width={200}></Input>
      </Form.Item>
      <Space direction="horizontal" className="w-full justify-end">
        <Form.Item>
          <Button
            htmlType="button"
            onClick={() => {
              onAfterCancel()
            }}
          >
            {t('閉じる')}
          </Button>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            {t(`更新`)}
          </Button>
        </Form.Item>
      </Space>
    </Form>
  )
}

export const SalescoreModelForm = ({
  editingEltModel,
  onAfterFinish,
  onAfterCancel,
}: {
  editingEltModel?: EltModelFieldsFragment
  onAfterFinish: () => void
  onAfterCancel: () => void
}): ReactNode => {
  const { data } = useSuspenseQuery(FetchEltModelsDocument, {
    variables: {
      organizationId: getOrganizationIdFromPath(),
    },
  })
  const { eltModels } = data
  const [form] = Form.useForm<JsonFormValue>()
  const [propertiesForm] = Form.useForm<{ properties?: Array<FormProperty | undefined> }>()

  const properties: FormProperty[] = (editingEltModel?.model.properties ?? [])
    .map((x) => ({
      name: x.name,
      label: x.label,
      type: x.type,
      referenceTo: x.referenceTo?.map((x) => x.modelName).first() ?? undefined,
      selectOption: (x.selectOptions ?? []).map((x) => x.value).join(','),
    }))
    .filter((x) => !systemProperties.map((y) => y.name).includes(x.name))

  return (
    <div className="">
      <Row>
        <Col span={15} className="pr-2">
          <PropertiesForm
            properties={properties}
            jsonForm={form}
            propertiesForm={propertiesForm}
            options={eltModels
              .uniqueBy((x) => x.name)
              .map((model) => ({
                value: model.name,
                key: model.name,
                label: model.model.label,
              }))}
          />
        </Col>
        <Col span={9}>
          {isNotUndefined(editingEltModel) ? (
            <JsonEditForm
              onAfterFinish={onAfterFinish}
              onAfterCancel={onAfterCancel}
              form={form}
              editingEltModel={editingEltModel}
            />
          ) : (
            <JsonCreateForm
              onAfterFinish={onAfterFinish}
              onAfterCancel={onAfterCancel}
              form={form}
              invalidNames={eltModels.map((x) => x.name)}
            />
          )}
        </Col>
      </Row>
    </div>
  )
}

const NUMERIC_TYPE_IGNORE_CHARACTER_REGEXP = '[¥,]'

function generateTransformationSql(name: string, type: PropertyTypeEnum): string {
  // JSON内の配列をPostgreSQLの配列フォーマットに変換
  // e.g. ["xx", "yy"] -> {xx,yy}
  if (type === PropertyTypeEnum.Array) {
    return `ARRAY(select jsonb_array_elements_text(data->'${name}'))`
  }

  if (!([PropertyTypeEnum.Numeric, PropertyTypeEnum.Integer] as PropertyTypeEnum[]).includes(type)) {
    return castColum(`data->>'${name}'`, type)
  }

  return castColum(`regexp_replace((data->>'${name}')::TEXT, '${NUMERIC_TYPE_IGNORE_CHARACTER_REGEXP}', '', 'g')`, type)
}

function generateSqlFromProperties(name: string, properties: ModelProperty[]): string {
  const columnsSql = properties
    .map((property) => {
      if (property.name === 'created_at' || property.name === 'updated_at') {
        return `"${property.name}" as "${property.name}"`
      }

      return `${generateTransformationSql(property.name, property.type)} as "${property.name}"`
    })
    .join(',\n')
  return `SELECT
  id,
  ${columnsSql}
FROM
"${name}_raw"`
}

const nullifEmpty = (column: string): string => `NULLIF((${column})::TEXT, '')`

function castColum(column: string, propertyType: PropertyType): string {
  switch (propertyType) {
    case 'datetime': {
      return `${nullifEmpty(column)}::timestamptz`
    }
    case 'date': {
      return `TO_DATE(${nullifEmpty(column)}, 'YYYY-MM-DD')`
    }
    case 'time': {
      return `${nullifEmpty(column)}::timetz`
    }
    case 'numeric': {
      return `${nullifEmpty(column)}::NUMERIC`
    }
    case 'integer': {
      return `(${nullifEmpty(column)}::NUMERIC)::INTEGER`
    }
    case 'boolean': {
      return `(${nullifEmpty(column)})::BOOLEAN`
    }
    case 'array':
    case 'object':
    case 'string': {
      return column
    }
    // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
    default: {
      throw new Error(propertyType satisfies never)
    }
  }
}
