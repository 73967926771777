// eslint-disable-next-line @eslint-community/eslint-comments/disable-enable-pair
/* eslint-disable max-lines */
import { CaretDownOutlined, CaretUpOutlined, HighlightOutlined, HistoryOutlined } from '@ant-design/icons'
import { useSuspenseQuery } from '@apollo/client'
import { findValueInRecord, isNull, isPresent, r } from '@salescore/buff-common'
import { FetchRecordChangeHistoriesDocument } from '@salescore/client-api'
import { getOrganizationIdFromPath } from '@salescore/client-base'
import { SuspenseWithLoading, UserAvatar } from '@salescore/client-common'
import {
  type ConditionalEffect,
  CORE_CONSTANT,
  CoreDsl,
  type CoreDslFormNodeState,
  type CoreDslFormOperator,
  type CoreDslFormTerm,
  getRecordNodeParents,
  type SheetCell,
  type ViewQueryRecordNodeWithParent,
} from '@salescore/core'
import { logger } from '@salescore/frontend-common'
import { Divider, Popover } from 'antd'
import { t } from 'i18next'
import { type CSSProperties, useEffect, useMemo, useRef, useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { z } from 'zod'

import { SheetThreadsFormPopoverIfAllowed } from '../../../../components/form/SheetThreadsFormPopoverIfAllowed'
import { useRowConfigState, useRowHeightState } from '../../../../recoil/navigation/hooks'
import { snapshotsAtom } from '../../../../recoil/records/atoms'
import { useConnectionsSelector } from '../../../../recoil/view/selectors/connectionsSelector'
import { useUsersSelector } from '../../../../recoil/view/selectors/usersSelector'
import { useContextValue } from '../../../recoil/models/propModels'
import { selectRowCellMutation } from '../../../recoil/mutations/cursor/moveCursor'
import {
  type SetMainCursorByRecordIdArguments,
  useCursorByRecordIdMutation,
} from '../../../recoil/mutations/cursorByRecordIdMutation'
import { useKpisSelector } from '../../../recoil/selectors/kpisSelector'
import type { RSheetColumn, RSheetContext, RSheetRecordNode } from '../../../types'
import { RSheetsStyle } from '../../../util/RSheetsStyle'
import { highlightRibonMapper } from '../../footer/ConditionalEffectsFormModalV2'
import { CellBody } from './CellBody'
import { RsheetsEmptyCell } from './RsheetsEmptyCell'

interface RsheetsCellProperties {
  sheetCell: SheetCell
  rootRecordNode: RSheetRecordNode
  column: RSheetColumn<RSheetRecordNode>
  rowIndex: number
  columnIndex: number
  expandable: boolean
  expanded: boolean
  isNewRecord: boolean
  isFieldChanged: boolean
  overrideValue?: unknown
}

export function RsheetsCell(properties: RsheetsCellProperties) {
  const { rowHeight } = useRowHeightState()

  const { setMainCursorByRecordId } = useCursorByRecordIdMutation()
  const setMainCursorByRecordIdReference = useRef(setMainCursorByRecordId)
  const setMainCursorByRecordIdReferenceCurrent = useMemo(() => setMainCursorByRecordIdReference.current, [])
  useEffect(() => {
    setMainCursorByRecordIdReference.current = setMainCursorByRecordId
  }, [setMainCursorByRecordId])

  return (
    <RsheetsCellInner
      {...properties}
      rowHeight={rowHeight}
      setMainCursorByRecordId={setMainCursorByRecordIdReferenceCurrent}
    />
  )
}

type RsheetsCellInnerProperties = RsheetsCellProperties & {
  rowHeight: number
  setMainCursorByRecordId: (arguments_: SetMainCursorByRecordIdArguments) => void
}

export const CELL_WIDTH = 150

// eslint-disable-next-line complexity
function RsheetsCellInner({
  sheetCell,
  rootRecordNode,
  column,
  rowIndex,
  columnIndex,
  expandable,
  expanded,
  isNewRecord,
  isFieldChanged,
  overrideValue,
  rowHeight,
  setMainCursorByRecordId,
}: RsheetsCellInnerProperties) {
  const { innerRowIndexStart, height, recordNode, value } = sheetCell
  const innerRowIndex = innerRowIndexStart
  const [_, setRowConfig] = useRowConfigState(rootRecordNode.id ?? '')
  const context = useContextValue()
  const width = column.width ?? CELL_WIDTH
  const snapshots = useRecoilValue(snapshotsAtom)
  const snapshot = snapshots[column.node.name]?.[recordNode?.id ?? ''] ?? {}
  const selectRowCell = useSetRecoilState(selectRowCellMutation)
  const { kpisMapper } = useKpisSelector()

  const sheetStyle: CSSProperties = {
    border: `1px ${RSheetsStyle.color.border} solid`,
  }
  const kpiStyle: CSSProperties = {
    // KPIセルのとき、横の罫線は表示しない
    borderBottom: `1px ${RSheetsStyle.color.border} solid`,
    borderRight: column.kpiBorderRight === true ? `1px ${RSheetsStyle.color.borderBlack} solid` : undefined,
  }
  const defaultStyle: CSSProperties = {
    width,
    height: height * rowHeight,
    position: 'relative',
    overflow: column.type === 'kpi' ? undefined : 'hidden',
    transition: RSheetsStyle.cellTransition,
    ...(column.type === 'kpi' ? kpiStyle : sheetStyle),
  }

  if (column.type === 'rowIndex') {
    const nameParsed = z.string().safeParse(findValueInRecord(rootRecordNode.attributes, (k) => k.endsWith('_name')))
    return (
      <div
        className="rsheet-cell-row-index"
        style={{
          ...defaultStyle,
          fontSize: 10,
          textAlign: 'center',
          paddingTop: 15,
          color: '#aaa',
        }}
        onClick={() => {
          selectRowCell({ rowIndex, rootRecordNode })
        }}
      >
        <SheetThreadsFormPopoverIfAllowed
          recordId={rootRecordNode.id}
          recordName={nameParsed.success ? nameParsed.data : undefined}
        >
          <div
            style={{
              fontSize: 10,
              textAlign: 'center',
              color: '#aaa',
            }}
          >
            {rowIndex + 1}
          </div>
        </SheetThreadsFormPopoverIfAllowed>
        {expandable && (
          <div
            className="absolute inset-x-0 bottom-0 cursor-pointer"
            onClick={() => {
              setRowConfig((x) => ({
                ...x,
                expanded: !expanded,
              }))
            }}
          >
            {expanded ? <CaretUpOutlined /> : <CaretDownOutlined />}
          </div>
        )}
      </div>
    )
  }

  if (recordNode === undefined) {
    return (
      <RsheetsEmptyCell
        column={column}
        rowIndex={rowIndex}
        columnIndex={columnIndex}
        innerRowIndex={innerRowIndexStart}
        defaultStyle={defaultStyle}
      />
    )
  }

  // KPI表示の時、合計・小計の考慮
  //
  // KPIピボット表示時、列に並べ替えが指定されているとツリーの形式が変わる（子レコードが存在しなくなる）
  // そのため、ルートノードに子レコードが存在するかどうかによって、小計・合計セルの判定を分けている
  const hasNoChildrenRecord = rootRecordNode.children.isBlank()
  const totalStringNodes = getTotalStringNodes({ recordNode, hasNoChildrenRecord, column })
  const isSubTotalOrTotal = totalStringNodes.some(Boolean)
  const isTotal = isSubTotalOrTotal && totalStringNodes.length === 1
  const isSubTotal = isSubTotalOrTotal && totalStringNodes.length > 1
  const isSubTotalChild = totalStringNodes.filter(Boolean).length > 1
  const isTotalChild = totalStringNodes.filter(Boolean).length > 1 && totalStringNodes.last() === true

  // kpiで、行軸の小計の表示は、もはやここで対応してしまう
  if (context.asKpiTable === true && column.type !== 'kpi') {
    const totalStyleCommon = {
      height: defaultStyle.height,
      borderBottom: `1px ${RSheetsStyle.color.border} solid`,
      borderTop: `1px ${RSheetsStyle.color.border} solid`,
      fontWeight: 'bold',
    }
    if (isTotal) {
      return (
        <TotalCellWrapper
          type="total"
          style={{
            ...totalStyleCommon,
            backgroundColor: RSheetsStyle.color.kpiTotalCell,
          }}
        >
          {t(`合計`)}
        </TotalCellWrapper>
      )
    }

    if (isSubTotal) {
      // 現在の列に対応するdimenstion。KPIプレビュー、ダッシュボードで使用
      // column.indexは行番号表示用に追加された一列を加味した値なので、-1する
      const dimension = context.pivot?.rows[column.index - 1]
      const dimensionName = `dimension${column.index}`
      const isKpi = dimension?.key === CORE_CONSTANT.KPI_PIVOT_KPI_ID_COLUMN_NAME
      const isKpiGroup = dimension?.key === CORE_CONSTANT.KPI_PIVOT_KPI_GROUP_NAME_COLUMN_NAME

      if (isKpi) {
        const kpiId = hasNoChildrenRecord ? recordNode.attributes[dimensionName] : recordNode.id
        const kpi = typeof kpiId === 'string' ? kpisMapper[kpiId] : undefined
        if (isNull(kpi)) {
          return <></>
        }

        // KPIを行にした時の小計の表示
        return (
          <TotalCellWrapper
            type="subtotal"
            style={{
              ...totalStyleCommon,
              backgroundColor: RSheetsStyle.color.kpiSubTotalCell,
              fontWeight: 'normal',
            }}
          >
            <SubTotalWithLabel label={kpi.label} />
          </TotalCellWrapper>
        )
      }

      if (isKpiGroup) {
        const kpiGroupName = hasNoChildrenRecord ? recordNode.attributes[dimensionName] : recordNode.id
        if (typeof kpiGroupName !== 'string') {
          return <></>
        }

        return (
          <TotalCellWrapper
            type="subtotal"
            style={{
              ...totalStyleCommon,
              backgroundColor: RSheetsStyle.color.kpiSubTotalCell,
              fontWeight: 'normal',
            }}
          >
            <SubTotalWithLabel label={kpiGroupName} />
          </TotalCellWrapper>
        )
      }

      return (
        <TotalCellWrapper
          type="subtotal"
          style={{
            ...totalStyleCommon,
            backgroundColor: RSheetsStyle.color.kpiSubTotalCell,
          }}
        >
          {t(`小計`)}
        </TotalCellWrapper>
      )
    }

    if (isTotalChild) {
      return (
        <TotalCellWrapper
          type="total-child"
          style={{
            ...totalStyleCommon,
            backgroundColor: RSheetsStyle.color.kpiTotalCell,
          }}
        />
      )
    }

    if (isSubTotalChild) {
      return (
        <TotalCellWrapper
          type="subtotal-child"
          style={{
            ...totalStyleCommon,
            backgroundColor: RSheetsStyle.color.kpiSubTotalCell,
          }}
        />
      )
    }
  }

  const changed = isFieldChanged || isNewRecord
  const currentValue = changed ? overrideValue : value
  const effectRelated = getEffectRelated(recordNode, changed, currentValue, column, snapshot)
  const { disabled } = effectRelated
  const highlighted = effectRelated.effects.some((e) => e.effect === 'highlight')
  const kpiRelatedStyle = getStyleByKpi({ column, context, isSubTotal, isTotal, isTotalChild })

  const boxStyle: CSSProperties = {
    ...defaultStyle,
    padding: '10px 10px',
    ...(context.asKpiTable === true ? kpiRelatedStyle : effectRelated.style),
    fontSize: 14,
    ...(height === 1
      ? {
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }
      : {}),
  }
  const highlightColor = getActiveConditionalEffects(column.conditionalEffects, recordNode, snapshot)[0]?.highlightColor
  // gray に fallback しているが、highlightColorMap は highlightColor に対応する値を持つように定義しているので fallback には入らない想定
  const highlightRibonColor =
    highlightColor === undefined ? 'rgb(209 213 219)' : (highlightRibonMapper.get(highlightColor) ?? 'rgb(209 213 219)')

  return (
    <>
      <div
        data-e2e={`rsheet-cell-${rowIndex}-${columnIndex}-${innerRowIndexStart}`}
        className="rsheet-cell"
        key={column.key}
        style={boxStyle}
        onContextMenu={(e) => {
          const contextMenuPosition = {
            x: e.clientX,
            y: e.clientY,
          }
          setMainCursorByRecordId({
            position: {
              recordId: rootRecordNode.id!,
              columnIndex,
              innerRowIndex,
            },
            option: {
              contextMenuPosition,
              disabled,
              keepExpand: true,
            },
          })
          e.preventDefault()
          e.stopPropagation()
        }}
        onClick={(e) => {
          const contextMenuPosition = {
            x: e.clientX,
            y: e.clientY,
          }
          setMainCursorByRecordId({
            position: {
              recordId: rootRecordNode.id!,
              columnIndex,
              innerRowIndex,
            },
            option:
              context.asKpiTable === true && column.type === 'rowKpi'
                ? {
                    contextMenuPosition,
                    disabled,
                    keepExpand: true,
                  }
                : {
                    toggle: true,
                    disabled,
                  },
          })
        }}
      >
        {disabled && <img src="/images/sheets/write-inhibit-icon.svg" style={{ marginRight: 4 }} />}
        {highlighted && (
          <div className="absolute right-0 top-0 z-[1]">
            <Popover
              content={<HighlightInfo column={column} recordNode={recordNode} snapshot={snapshot} />}
              placement="right"
            >
              <div className="relative">
                <div
                  // 右上向きの三角形
                  style={{
                    borderTop: `12px solid ${highlightRibonColor}`,
                    borderRight: `12px solid ${highlightRibonColor}`,
                    borderBottom: '12px solid transparent',
                    borderLeft: '12px solid transparent',
                    position: 'absolute',
                    top: 0,
                    right: 0,
                  }}
                />
                <HighlightOutlined className="absolute right-0 top-0 size-3 text-white" />
              </div>
            </Popover>
          </div>
        )}
        <CellBody
          column={column}
          value={currentValue}
          recordNode={recordNode}
          sheetCell={sheetCell}
          height={height}
          style={{
            color: boxStyle.color,
            backgroundColor: boxStyle.backgroundColor,
          }}
          setCursorAndUseEditMode={() => {
            setMainCursorByRecordId({
              position: {
                recordId: rootRecordNode.id!,
                columnIndex,
                innerRowIndex,
              },
              option: {
                isEditing: true,
                disabled,
              },
            })
          }}
        />
        {/* <div
          style={
            isExpandSelected
              ? {
                  position: 'absolute',
                  bottom: 0,
                  right: 0,
                  width: 4,
                  height: 4,
                  backgroundColor: '#1A73E8',
                }
              : {}
          }
        /> */}
      </div>
    </>
  )
}

function TotalCellWrapper({
  type,
  style,
  children,
}: {
  type: 'total' | 'subtotal' | 'total-child' | 'subtotal-child'
  style: CSSProperties
  children?: React.ReactNode
}) {
  return (
    <div className={`rsheet-cell ${type} flex items-center justify-center align-middle`} style={style}>
      {children}
    </div>
  )
}

function SubTotalWithLabel({ label }: { label: string }) {
  return (
    <div className="flex w-full flex-col items-center justify-center gap-2">
      <span className="block w-full text-center" style={{ fontSize: 11, whiteSpace: 'normal', wordBreak: 'break-all' }}>
        {label}
      </span>
      <span className="block font-bold">{t(`小計`)}</span>
    </div>
  )
}

function getTotalStringNodes({
  recordNode,
  column,
  hasNoChildrenRecord,
}: {
  recordNode: ViewQueryRecordNodeWithParent
  column: RSheetColumn<RSheetRecordNode>
  hasNoChildrenRecord: boolean
}): boolean[] {
  if (hasNoChildrenRecord) {
    const dimensionKeys = Array.from({ length: column.index }).map((_, index) => `dimension${index + 1}`)
    return dimensionKeys.map((key) => recordNode.attributes[key] === CORE_CONSTANT.KPI_PIVOT_TOTAL_STRING)
  }

  const parents = getRecordNodeParents(recordNode)
  return parents.map((x) => r(x.attributes).values().includes(CORE_CONSTANT.KPI_PIVOT_TOTAL_STRING))
}

export function getActiveConditionalEffects(
  conditionalEffects: ConditionalEffect[] | undefined,
  recordNode: ViewQueryRecordNodeWithParent,
  snapshot: Record<string, Record<string, unknown>>,
) {
  if (conditionalEffects === undefined) {
    return []
  }

  const result = conditionalEffects.filter((effect) => {
    const result = CoreDsl.compileWithRecordNode(effect.condition, recordNode, { snapshot })
    if (r(snapshot).toArray().isPresent()) {
      // 2023/05 本番での検証のため、しばらくログ表示しておく

      logger.debug('effects for snapshot', snapshot, result, effect)
    }
    return result.success && result.data === true
  })
  return result
}

const mapper: Record<string, string> = {
  negative: 'red',
  positive: 'blue',
}

function getConditionalEffectHighlightColor(color: string | undefined) {
  if (color === undefined) {
    return
  }

  return mapper[color] ?? color
}

// eslint-disable-next-line @typescript-eslint/max-params
function getEffectRelated(
  recordNode: RSheetRecordNode,
  changed: boolean,
  value: unknown,
  column: RSheetColumn<RSheetRecordNode>,
  snapshot: Record<string, Record<string, unknown>>,
) {
  const highlight = column.highlight === undefined ? undefined : column.highlight(recordNode)

  const isValid = column.required !== true || isPresent(value)
  // TODO: 同様の実装をuseHandleKeyDownでも必要
  const effects = getActiveConditionalEffects(column.conditionalEffects, recordNode, snapshot)
  const disabled = effects.some((x) => x.effect === 'disable')
  const highlightedV2 = effects.some((x) => x.effect === 'highlight')
  const emphasised = effects.some((x) => x.effect === 'emphasis') || highlightedV2

  const getColorRelated = (): CSSProperties => {
    if (column.cellBackgroundColor !== undefined) {
      return { backgroundColor: column.cellBackgroundColor }
    }

    if (!isValid) {
      return { backgroundColor: RSheetsStyle.color.error }
    }

    if (changed) {
      return { backgroundColor: 'rgba(0,0,0,0.06)' }
    }

    if (highlightedV2) {
      return {
        color: 'white',
        backgroundColor: getConditionalEffectHighlightColor(
          effects.find((x) => x.effect === 'highlight')?.highlightColor,
        ),
      }
    }

    if (disabled) {
      return { backgroundColor: '#ddd' }
    }

    if (highlight === true) {
      return { backgroundColor: '#FEF9C3' }
    }

    return { backgroundColor: 'inherit' }
  }

  const style: CSSProperties = {
    ...getColorRelated(),
    fontWeight: emphasised ? `bold` : undefined,
    opacity: disabled ? 0.7 : 1,
  }

  return {
    style,
    effects,
    disabled,
  }
}

function getStyleByKpi({
  context,
  isTotal,
  isSubTotal,
  isTotalChild,
  column,
}: {
  context: RSheetContext
  isTotal: boolean
  isSubTotal: boolean
  isTotalChild: boolean
  column: RSheetColumn<RSheetRecordNode>
}): CSSProperties {
  const withoutPadding = column.type === 'rowKpi' || column.type === 'kpi' // TODO

  const getBackgroundColor = () => {
    if (
      context.asKpiTable === true && // 小計が含まれていれば色を変更
      column.type === 'kpi'
    ) {
      if (isTotal || isTotalChild) {
        return RSheetsStyle.color.kpiTotalCell
      }
      if (isSubTotal) {
        return RSheetsStyle.color.kpiSubTotalCell
      }
      if (column.sortState !== undefined) {
        return RSheetsStyle.color.sortedCell
      }
      return RSheetsStyle.color.kpiCell
    }
    // typeがkpiでない = 行軸の列ではなく、このパターンは別で処理しているはず
  }
  return {
    backgroundColor: getBackgroundColor(),
    padding: withoutPadding ? 0 : '10px 10px',
  }
}

interface HighlightInfoProperties {
  column: RSheetColumn<RSheetRecordNode>
  recordNode: RSheetRecordNode
  snapshot: Record<string, Record<string, unknown>>
}

function HighlightInfo(properties: HighlightInfoProperties) {
  const activeConditionalEffect = getActiveConditionalEffects(
    properties.column.conditionalEffects,
    properties.recordNode,
    properties.snapshot,
  )[0]
  const conditionalEffect = properties.column.configField?.conditionalEffects?.find(
    (ce) => ce.effect === 'highlight' && ce.highlightColor?.color === activeConditionalEffect?.highlightColor,
  )
  const ast = conditionalEffect?.effect === 'highlight' ? conditionalEffect.ast : undefined
  const astDeepKeyValues = getDeepKeyValues(ast ?? {})
  // ハイライト条件に「過去の値」を用いているかのフラグ
  const isComparingWithSnapshotVariable = astDeepKeyValues
    .filter((v) => v.includes('.type::snapshotVariable'))
    .map((v) => v.replace('.type::snapshotVariable', '.before'))
    .map((v1) => astDeepKeyValues.find((v2) => v2.includes(v1)))
    .compact()
    .map((v) => v.split('::')[1])
    .some((v) => Number(v) > 0)

  const highlightConditionCount = astDeepKeyValues.filter((v) => v.includes('operator::')).length

  const recordId = properties.recordNode.id
  const modelName = properties.column.configField?.property.modelName
  const propertyName = properties.column.configField?.property.propertyName

  const { getModelProperty } = useConnectionsSelector()
  const property = getModelProperty(modelName, propertyName)

  const showRecordChangeHistory = property?.trackable === true && isComparingWithSnapshotVariable

  const [highlightConditionViewState, setHighlightConditionViewState] = useState<'normal' | 'open' | 'close' | 'only'>(
    'normal',
  )
  const [recordChangeHistoryViewState, setRecordChangeHistoryViewState] = useState<'normal' | 'open' | 'close'>(
    'normal',
  )

  const [highlightConditionListNormalWidth, setHighlightConditionListNormalWidth] = useState(0)
  const [recordChangeHistoryListNormalWidth, setRecordChangeHistoryListNormalWidth] = useState(0)

  return (
    <div
      className="flex flex-col overflow-y-auto"
      style={{
        maxHeight: '60vh',
        minWidth: Math.max(highlightConditionListNormalWidth, recordChangeHistoryListNormalWidth),
      }}
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
      }}
    >
      {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
      {ast && (
        <div>
          <div className="flex gap-2">
            <div className="font-bold">
              <HighlightOutlined />
            </div>
            <div className="font-bold">{t(`ハイライト条件`)}</div>
          </div>

          <HighlightConditionList
            ast={ast}
            count={highlightConditionCount}
            viewState={showRecordChangeHistory ? highlightConditionViewState : 'only'}
            onOpen={() => {
              setHighlightConditionViewState('open')
              setRecordChangeHistoryViewState('close')
            }}
            onClose={() => {
              setHighlightConditionViewState('normal')
              setRecordChangeHistoryViewState('normal')
            }}
            onSetNormalWidth={setHighlightConditionListNormalWidth}
          />
        </div>
      )}

      {showRecordChangeHistory && recordId !== undefined && modelName !== undefined && propertyName !== undefined && (
        <>
          <Divider style={{ margin: '12px 0' }} />

          <div>
            <div className="flex gap-2">
              <div className="font-bold">
                <HistoryOutlined />
              </div>
              <div className="font-bold">{t(`履歴`)}</div>
            </div>

            <SuspenseWithLoading type="table">
              <RecordChangeHistoryList
                recordId={recordId}
                modelName={modelName}
                propertyName={propertyName}
                viewState={recordChangeHistoryViewState}
                onOpen={() => {
                  setHighlightConditionViewState('close')
                  setRecordChangeHistoryViewState('open')
                }}
                onClose={() => {
                  setHighlightConditionViewState('normal')
                  setRecordChangeHistoryViewState('normal')
                }}
                onSetNormalWidth={setRecordChangeHistoryListNormalWidth}
              />
            </SuspenseWithLoading>
          </div>
        </>
      )}
    </div>
  )
}

interface HighlightConditionListProperties {
  ast: CoreDslFormNodeState
  count: number
  viewState: 'normal' | 'open' | 'close' | 'only'
  onOpen: () => void
  onClose: () => void
  onSetNormalWidth: (width: number) => void
}

function HighlightConditionList({
  ast,
  count,
  viewState,
  onOpen,
  onClose,
  onSetNormalWidth,
}: HighlightConditionListProperties) {
  const reference = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (viewState === 'normal') {
      onSetNormalWidth(reference.current?.clientWidth ?? 0)
    }
  }, [viewState])

  if (viewState === 'close') {
    return null
  }

  return (
    <div className="mt-2 space-y-2" ref={reference}>
      <HighlightConditionListNode viewState={viewState} ast={ast} isRoot={true} />

      {count > 3 && (
        <>
          {viewState === 'normal' && (
            <div
              className="ml-1 max-w-max cursor-pointer text-xs font-semibold"
              onClick={() => {
                onOpen()
              }}
            >
              {t(`+{{count}}の条件を展開`, { count: count - 3 })}
            </div>
          )}

          {viewState === 'open' && (
            <div
              className="ml-1 max-w-max cursor-pointer text-xs font-semibold"
              onClick={() => {
                onClose()
              }}
            >
              {t(`閉じる`)}
            </div>
          )}
        </>
      )}
    </div>
  )
}

interface HighlightConditionListNodeProperties {
  ast: CoreDslFormNodeState
  isRoot: boolean
  viewState: 'normal' | 'open' | 'close' | 'only'
}

function HighlightConditionListNode({ ast, isRoot, viewState }: HighlightConditionListNodeProperties) {
  const leafs = {
    normal: isRoot ? ast.leafs.slice(0, 3) : ast.leafs.slice(0, 1),
    open: ast.leafs,
    close: [],
    only: ast.leafs,
  }[viewState]

  const children = {
    normal: isRoot ? ast.children.slice(0, 3 - leafs.length) : [],
    open: ast.children,
    close: [],
    only: ast.children,
  }[viewState]

  return (
    <div className={isRoot ? '' : 'rounded border border-solid border-gray-300 p-2'}>
      {leafs.map((leaf, index, ary) => (
        <>
          <div className="flex items-center rounded bg-gray-100 p-2 text-xs">
            <div className="min-w-20">
              <ValueLabelText term={leaf.left} />
            </div>
            <Divider type="vertical" />
            <div className="min-w-20">
              <OperatorLabelText op={leaf.operator} />
            </div>
            {leaf.right !== undefined && (
              <>
                <Divider type="vertical" />
                <div className="min-w-20">
                  <ValueLabelText term={leaf.right} />
                </div>
              </>
            )}
          </div>

          {index !== ary.length - 1 && (
            <div className="px-2 py-1 text-xs capitalize text-gray-500">{ast.logicalOperator}</div>
          )}
        </>
      ))}

      {children.map((child) => (
        <>
          {ast.leafs.length > 0 && (
            <div className="px-2 py-1 text-xs capitalize text-gray-500">{ast.logicalOperator}</div>
          )}
          <HighlightConditionListNode viewState={viewState} ast={child} isRoot={false} />
        </>
      ))}
    </div>
  )
}

function ValueLabelText({ term }: { term: CoreDslFormTerm }) {
  const dateSpanDictionary = {
    day: t(`日`),
    week: t(`週間`),
    month: t(`月`),
    year: t(`年`),
  }
  const { getModel } = useConnectionsSelector()
  switch (term.type) {
    case 'literal': {
      return term.value
    }
    case 'snapshotVariable': {
      return term.before === 0 ? t(`現在の値`) : `${term.before}${dateSpanDictionary[term.dateSpan]}${t(`前の値`)}`
    }
    case 'recordNodeVariable': {
      return `${getModel(term.nodePropertyName.modelName)?.label}: ${term.property.label}`
    }
  }
}

function OperatorLabelText({ op }: { op: CoreDslFormOperator }) {
  const operatorDictionary: Record<CoreDslFormOperator, string> = {
    in: t(`次のどれか`),
    not_in: t(`次のどれでもない`),
    '=': t(`等しい`),
    '!=': t(`異なる`),
    null: t(`入力なし`),
    not_null: t(`入力あり`),
    blank: t(`入力なし`),
    present: t(`入力あり`),
    include: t(`次の要素を全て含む`),
    not_include: t(`次の要素を全て含まない`),
    starts_with: t(`次の文字列から始まる`),
    not_starts_with: t(`次の文字列から始まらない`),
    overlap: t(`次の要素を含む`),
    not_overlap: t(`次の要素を含まない`),
    '<': t(`より小さい`),
    '<=': t(`以下`),
    '>': t(`より大きい`),
    '>=': t(`以上`),
  }
  return operatorDictionary[op]
}

interface RecordChangeHistoryListProperties {
  recordId: string
  modelName: string
  propertyName: string
  viewState: 'normal' | 'open' | 'close'
  onOpen: () => void
  onClose: () => void
  onSetNormalWidth: (width: number) => void
}

function RecordChangeHistoryList({
  recordId,
  modelName,
  propertyName,
  viewState,
  onOpen,
  onClose,
  onSetNormalWidth,
}: RecordChangeHistoryListProperties) {
  const { data } = useSuspenseQuery(FetchRecordChangeHistoriesDocument, {
    variables: {
      recordId,
      modelName,
      propertyNames: [propertyName],
      organizationId: getOrganizationIdFromPath(),
    },
  })
  const { recordChangeHistories } = data

  const snapshots = {
    normal: recordChangeHistories.slice(0, 5),
    open: recordChangeHistories,
    close: [],
  }[viewState]

  const reference = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (viewState === 'normal') {
      onSetNormalWidth(reference.current?.clientWidth ?? 0)
    }
  }, [viewState])

  return (
    <div className="mt-2 space-y-2" ref={reference}>
      {snapshots.map((snapshot, index) => (
        <div className="flex items-center justify-between gap-5 text-xs">
          <div className="flex items-center gap-1">
            {index === 0 ? <div className="size-2 rounded-full bg-gray-200" /> : <div className="w-2"></div>}
            <div className="font-semibold text-gray-500">{snapshot.newValue}</div>
            <div className="text-gray-400">{t(`{{datetime, datetimestr}}`, { datetime: snapshot.changedAt })}</div>
          </div>

          <User salesforceId={snapshot.changedById} fallbackName={snapshot.changedByName ?? t(`不明なユーザー`)} />
        </div>
      ))}

      {recordChangeHistories.length > 5 && (
        <>
          {viewState === 'normal' && (
            <div
              className="ml-1 max-w-max cursor-pointer text-xs font-semibold"
              onClick={() => {
                onOpen()
              }}
            >
              {t(`展開`)}
            </div>
          )}

          {viewState === 'open' && (
            <div
              className="ml-1 max-w-max cursor-pointer text-xs font-semibold"
              onClick={() => {
                onClose()
              }}
            >
              {t(`閉じる`)}
            </div>
          )}
        </>
      )}
    </div>
  )
}

function User({ salesforceId, fallbackName }: { salesforceId: string | undefined; fallbackName: string }) {
  const { salesforceUsersMapper } = useUsersSelector()
  const resourceUser = salesforceId === undefined ? undefined : salesforceUsersMapper[salesforceId]?.resourceUser
  const user = resourceUser ?? {
    id: ``,
    name: fallbackName,
  }

  return (
    <div className="flex items-center gap-1">
      <span className="text-xs">{user.name}</span>
      <UserAvatar user={user} size="small" />
    </div>
  )
}

function getDeepKeyValues(object: Record<string, unknown> | unknown[], prefix = ''): string[] {
  const keyValues: string[] = []

  if (Array.isArray(object)) {
    for (const [index, item] of object.entries()) {
      const fullPath = `${prefix}[${index}]`
      if (typeof item === 'object' && item !== null) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
        keyValues.push(...getDeepKeyValues(item as Record<string, unknown>, fullPath))
      } else {
        keyValues.push(`${fullPath}::${String(item)}`)
      }
    }
  } else {
    // eslint-disable-next-line guard-for-in
    for (const key in object) {
      const value = object[key]
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      const fullPath = prefix ? `${prefix}.${key}` : key
      if (typeof value === 'object' && value !== null) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
        keyValues.push(...getDeepKeyValues(value as Record<string, unknown>, fullPath))
      } else {
        keyValues.push(`${fullPath}::${String(value)}`)
      }
    }
  }

  return keyValues
}
