import { useMutation } from '@apollo/client'
import type { ViewForSiderFieldsFragment, ViewGroupFieldsFragment } from '@salescore/client-api'
import { MoveViewGroupsDocument, MoveViewsDocument } from '@salescore/client-api'
import type { DataNode, EventDataNode } from 'antd/es/tree'
import type { NodeDragEventParams } from 'rc-tree/es/contextTypes'
import type { Key } from 'react'
import { useRecoilState } from 'recoil'

import { useMe } from '../../global/hooks'
import { privateViewGroupTreeAtom, viewGroupTreeAtom } from '../atoms'
import { dragAntdNode } from '../functions/tree2'

type DragEvent = NodeDragEventParams & {
  dragNode: EventDataNode<DataNode>
  dragNodesKeys: Key[]
  dropPosition: number
  dropToGap: boolean
}

export const useMoveViewOrViewGroup = ({ asPrivate }: { asPrivate?: boolean }) => {
  const [tree, setTree] = useRecoilState(asPrivate === true ? privateViewGroupTreeAtom : viewGroupTreeAtom)
  const [moveViewGroups] = useMutation(MoveViewGroupsDocument)
  const [moveViews] = useMutation(MoveViewsDocument)
  const me = useMe()
  // const refetch = useRefetchViewGroups()

  return async ({ info }: { info: DragEvent }) => {
    const result = dragAntdNode(tree, info)
    if (result === undefined) {
      return
    }
    setTree(result.tree)

    // 変更されたview,viewGroupについて、永続化する
    const { target, parent, siblings } = result
    if (target.isLeaf ?? false) {
      // ビューのとき
      const targets = siblings.filter((x) => x.isLeaf === true && x.item.type === target.item.type)
      const rank = targets.findIndex((x) => x.key === target.key)
      if (rank === -1) {
        return // TODO
      }
      await moveViews({
        variables: {
          organizationId: me.organization.id,
          inputs: [
            {
              id: target.item.id,
              viewGroupId: parent?.item.id ?? null,
              rank,
            },
          ],
        },
      })
    } else {
      // ビューグループのとき
      const targets = siblings.filter((x) => x.isLeaf !== true && x.item.type === target.item.type)
      const rank = targets.findIndex((x) => x.key === target.key)
      if (rank === -1) {
        return // TODO
      }
      await moveViewGroups({
        variables: {
          organizationId: me.organization.id,
          inputs: [
            {
              id: target.item.id,
              viewGroupId: parent?.item.id ?? null,
              rank,
            },
          ],
        },
      })
    }
    // 都度refetchするのを本当は避けたい。
    // treeに関してはrefetchしなくても更新されるのだが、
    // ビューやフォルダの編集UIで、選択するフォルダのリストのデータを更新する必要があり、refetchが必要
    // -> 2023/06 localでは発生しなかったが、ステージング環境で不可解な挙動をしていたため、結局refetchをやめた
    // await refetch()
  }
}

// TODO: 都度全てのノードを計算する非常に非効率なロジック
//       ノードの数は高々1000程度なので、パフォーマンスが落ちない想定。
//       また、現状表示されているviews,viewGroupsに応じてrankを計算するので、「権限がなくて表示されないビュー」などがあると整合性が保てない可能性がある
interface ViewRankChange {
  id: string
  rank: number
  viewGroupId: string | null
  name?: string
}

// eslint-disable-next-line @typescript-eslint/max-params
function updateRank(
  parent: DataNode | undefined,
  children: DataNode[],
  viewsMap: Record<string, ViewForSiderFieldsFragment>,
  viewGroupsMap: Record<string, ViewGroupFieldsFragment>,
  targetViewGroupKeys: string[],
): {
  viewChanges: ViewRankChange[]
  viewGroupChanges: ViewRankChange[]
} {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
  const isTargetGroup = targetViewGroupKeys.includes((parent?.key ?? '') as string)
  const [viewNodes, viewGroupNodes] = children.partition((x) => x.isLeaf === true)

  const viewChanges = isTargetGroup
    ? viewNodes

        .map((viewNode, rank): undefined | ViewRankChange => {
          const view = viewsMap[Number(viewNode.key)]
          const newPosition = {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
            viewGroupId: (parent?.key as unknown as string | undefined) ?? null, // 基本的にundefinedとnullは使い分けしないが、apolloでupdateを実行する際にundefinedが無視されるので、明確にnullを代入する
            rank,
          }
          if (view === undefined || isSamePosition(view, newPosition)) {
            return undefined
          }
          return {
            id: view.id,
            // name: view.name,
            ...newPosition,
            viewGroupId: newPosition.viewGroupId?.replace(`g_`, '') ?? null,
          }
        })
        .compact()
    : []
  const viewGroupChanges = isTargetGroup
    ? viewGroupNodes

        .map((viewGroupNode, rank): undefined | ViewRankChange => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
          const viewGroup = viewGroupsMap[(viewGroupNode.key as string).replace(`g_`, '')]
          if (viewGroup === undefined) {
            // ありえないはず
            return undefined
          }
          const oldPosition = {
            viewGroupId: viewGroup.viewGroupId ?? null,
            rank: viewGroup.rank,
          }
          const newPosition = {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
            viewGroupId: (parent?.key as unknown as string | undefined) ?? null, // 基本的にundefinedとnullは使い分けしないが、apolloでupdateを実行する際にundefinedが無視されるので、明確にnullを代入する
            rank,
          }
          if (isSamePosition(oldPosition, newPosition)) {
            return undefined
          }
          return {
            id: viewGroup.id,
            // name: viewGroup.name,
            ...newPosition,
            viewGroupId: newPosition.viewGroupId?.replace(`g_`, '') ?? null,
          }
        })
        .compact()
    : []
  const childChanges = viewGroupNodes.map((viewGroupNode) =>
    updateRank(viewGroupNode, viewGroupNode.children ?? [], viewsMap, viewGroupsMap, targetViewGroupKeys),
  )

  return {
    viewChanges: [...viewChanges, ...childChanges.flatMap((x) => x.viewChanges)],
    viewGroupChanges: [...viewGroupChanges, ...childChanges.flatMap((x) => x.viewGroupChanges)],
  }
}

interface Position {
  rank: number
  viewGroupId?: string | null
}

function isSamePosition(x: Position, y: Position) {
  return x.rank === y.rank && (x.viewGroupId ?? '') === (y.viewGroupId ?? '')
}
