// TODO: Monacoに型がつかないため、anyを許している

import { PlayCircleOutlined, UnorderedListOutlined } from '@ant-design/icons'
import Editor from '@monaco-editor/react'
import { isSome, range } from '@salescore/buff-common'
import { getNodeByName, type ViewConfigField } from '@salescore/core'
import { Button, Col, Divider, Dropdown, Form, type FormListFieldData, Menu, Row } from 'antd'
// @monaco-editor/reactがexportするtypeがなぜかanyになるので、自前で同等の型を付与したかったが、compileでエラーになるのでボツ
// import { editor, Range } from 'monaco-editor'
import { t } from 'i18next'
import { type CSSProperties, useMemo, useRef } from 'react'

import { useViewConfigSheet } from '../../../recoil/view/hooks'
import { useConnectionsSelector } from '../../../recoil/view/selectors/connectionsSelector'

// const CORE_DSL_FUNCTIONS = [{ label: `等しい`, value: `equal($1)` }]

const languageName = `coredsl`
const NAVIGATION_HEIGHT = 33

export function ConditionalEffectExpressionEditor({
  field,
  configField,
  height,
  onExecute,
}: {
  field: FormListFieldData
  configField: ViewConfigField
  height: number
  onExecute: () => void
}): JSX.Element {
  const sheetConfig = useViewConfigSheet()
  const { getModelAndProperty } = useConnectionsSelector()
  const fieldNames = useMemo(() => {
    const { tree } = sheetConfig
    if (tree === undefined) {
      return []
    }
    const nodeAndPath = getNodeByName(tree, configField.property.nodeName)
    if (nodeAndPath === undefined) {
      return []
    }
    return nodeAndPath.path.reverse().flatMap((nodeName, index) => {
      const sameNodeFields = (sheetConfig.fields ?? []).filter((x) => x.property.nodeName === nodeName)
      return sameNodeFields.map((x) => {
        const attributeName = [x.property.nodeName, x.property.propertyName].join('_') // TODO
        // eslint-disable-next-line max-nested-callbacks
        const parents = range(0, index - 1).map((_) => `parent`)
        const propertyWithModel = getModelAndProperty(x.property.modelName, x.property.propertyName)
        return {
          value: [...parents, attributeName].join('.'),
          label:
            propertyWithModel === undefined
              ? x.property.propertyName
              : `${propertyWithModel.model.label}: ${propertyWithModel.property.label}`,
        }
      })
    })
  }, [sheetConfig, configField])

  // const monaco = useMonaco()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const editorReference = useRef<any>(null)
  // const editorRef = useRef<editor.IStandaloneCodeEditor | null>(null)

  const insert = (text: string): void => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const editor = editorReference.current
    if (editor === null) {
      return
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
    const selection = editor.getSelection()
    // https://qiita.com/lumis/items/311b8c39d61312957195#%E3%82%A8%E3%83%87%E3%82%A3%E3%82%BF%E3%81%A7%E7%B7%A8%E9%9B%86%E3%81%99%E3%82%8B
    // editor.executeEdits(``, [{ range: selection ?? new Range(1, 1, 1, 1), text }])
    if (isSome(selection)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
      editor.executeEdits(``, [{ range: selection, text }])
    }
  }

  return (
    <div className="overflow-hidden">
      <Row>
        <Col span={24}>
          <MonacoEditorNavigationHeader title={t(`CORE関数エディタ`)}>
            <>
              <FieldDropdown
                fieldNames={fieldNames}
                onFinish={(text) => {
                  insert(text)
                }}
              >
                <MonacoEditorNavigationButton icon={<UnorderedListOutlined />}>
                  {t(`項目`)}
                </MonacoEditorNavigationButton>
              </FieldDropdown>
              <MonacoEditorNavigationButton icon={<PlayCircleOutlined />} onClick={onExecute}>
                {t(`実行`)}
              </MonacoEditorNavigationButton>
            </>
          </MonacoEditorNavigationHeader>
          <Form.Item
            // label="条件(CORE式)"
            name={[field.name, 'expression']}
            rules={[{ required: true, message: t(`条件を入力してください`) }]}
          >
            <Editor
              height={height - NAVIGATION_HEIGHT}
              theme="vs-dark"
              defaultLanguage={languageName}
              // defaultValue={`equal(salesforce_account.name, 'a')`}
              onMount={(editor, monaco) => {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                editorReference.current = editor

                // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
                monaco.languages.register({ id: languageName })
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
                monaco.languages.setMonarchTokensProvider(languageName, {
                  keywords: [], // TODO: functionがあればここへ
                  tokenizer: {
                    root: [
                      [
                        /[a-z]+/i,
                        {
                          cases: {
                            '@keywords': 'keyword',
                            '@default': `variables`,
                          },
                        },
                      ],
                      [/'[^'\\]'/, 'string'],
                      // [/"[^\\"]"/, 'string'],
                      [/\d+/, 'number'],
                      [/'/, 'string.invalid'],
                      [/[*/]/, 'comment'],
                    ],
                  },
                })

                const suggestions =
                  // ...CORE_DSL_FUNCTIONS.map((command) => {
                  //   return {
                  //     label: command.value,
                  //     detail: command.label,
                  //     kind: monaco.languages.CompletionItemKind.Function,
                  //     insertText: `${command.value} `,
                  //   }
                  // }),
                  fieldNames.map(({ value, label }) => ({
                    // label: propertyWithNode.column_name,
                    label: value,
                    detail: label,
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
                    kind: monaco.languages.CompletionItemKind.Field,
                    insertText: value,
                  }))

                // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
                monaco.languages.registerCompletionItemProvider(languageName, {
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  provideCompletionItems(model: any, position: any) {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
                    const word = model.getWordUntilPosition(position)
                    return {
                      suggestions: suggestions
                        .uniqueBy((x) => x.insertText)
                        .map((x) => ({
                          ...x,
                          range: {
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
                            startLineNumber: position.lineNumber,
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
                            endLineNumber: position.lineNumber,
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
                            startColumn: word.startColumn,
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
                            endColumn: word.endColumn,
                          },
                        })),
                    }
                  },
                })
              }}
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  )
}

// function FunctionDropdown({ onFinish, children }: { onFinish: (text: string) => void; children: JSX.Element }) {
//   const menu = (
//     <Menu
//       items={CORE_DSL_FUNCTIONS.map(({ value, label }) => ({
//         key: value,
//         label,
//         onClick: () => {
//           onFinish(value)
//         },
//       }))}
//     />
//   )

//   return (
//     <Dropdown overlay={menu} trigger={['click']}>
//       {children}
//     </Dropdown>
//   )
// }

function FieldDropdown({
  onFinish,
  children,
  fieldNames,
}: {
  onFinish: (text: string) => void
  children: JSX.Element
  fieldNames: Array<{ value: string; label: string }>
}): JSX.Element {
  const menu = (
    <Menu
      items={fieldNames.map(({ value, label }) => ({
        key: value,
        label,
        onClick: () => {
          onFinish(value)
        },
      }))}
    />
  )

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      {children}
    </Dropdown>
  )
}

const navigationStyle: CSSProperties = {
  backgroundColor: `#1E1E1E`,
  color: `white`,
}

export function MonacoEditorNavigationButton({
  children,
  icon,
  onClick,
}: {
  children: string
  icon: JSX.Element
  onClick?: () => void
}): JSX.Element {
  return (
    <>
      <Button
        style={{
          ...navigationStyle,
        }}
        className="opacity-80 hover:bg-gray-500  hover:opacity-100"
        type="text"
        icon={icon}
        onClick={onClick}
      >
        {children}
      </Button>
      <Divider
        type="vertical"
        style={{
          borderColor: '#444',
          height: 30,
          top: 0,
        }}
      />
    </>
  )
}

export function MonacoEditorNavigationHeader({
  title,
  children,
}: {
  title: string
  children: JSX.Element
}): JSX.Element {
  return (
    <div
      className="flex items-center px-4 align-middle"
      style={{
        ...navigationStyle,
        // backgroundColor: `#050505`,
        borderBottom: `1px solid #444`,
      }}
    >
      <div className="opacity-80">{title}</div>
      <Divider
        type="vertical"
        style={{
          borderColor: '#444',
          height: 30,
          top: 0,
        }}
      />
      {children}
    </div>
  )
}
